export const customFieldCmsData = {
  add_column_tooltip: 'Add Column',
  cf_tooltip_modal_options: [
    {
      category_id: 1,
      category_name: 'Essentials',
    },
    {
      category_id: 2,
      category_name: 'Most Commonly Used',
    },
  ],
};

export const customField = [
  {
    details: [
      {
        default_meta: {
          id: 1,
          name: 'Priority',
          options: [
            {
              color: '#E0465E',
              value: 'Critical',
            },
            {
              color: '#1FC778',
              value: 'High',
            },
            {
              color: '#FBAA49',
              value: 'Medium',
            },
            {
              color: '#C4C4C4',
              value: 'Low',
            },
          ],
          sortable: true,
          description: '',
        },
        display_rank: 2,
        field_type_id: 1,
        field_type_name: 'Priority',
      },
      {
        default_meta: {
          id: 4,
          name: 'Single-Select',
          options: [
            {
              color: '#E0465E',
              value: 'Option 1',
            },
            {
              color: '#FBAA49',
              value: 'Option 2',
            },
            {
              color: '#C4C4C4',
              value: 'Option 3',
            },
            {
              color: '#1FC778',
              value: 'Option 4',
            },
          ],
          sortable: true,
          description: '',
        },
        display_rank: 1,
        field_type_id: 4,
        field_type_name: 'Single-Select',
      },
    ],
    category_id: 1,
    category_name: 'Essentials',
  },
  {
    details: [
      {
        default_meta: {
          id: 1,
          name: 'Priority',
          options: [
            {
              color: '#E0465E',
              value: 'Critical',
            },
            {
              color: '#1FC778',
              value: 'High',
            },
            {
              color: '#FBAA49',
              value: 'Medium',
            },
            {
              color: '#C4C4C4',
              value: 'Low',
            },
          ],
          sortable: true,
          description: '',
        },
        display_rank: 1,
        field_type_id: 1,
        field_type_name: 'Priority',
      },
      {
        default_meta: {
          id: 2,
          name: 'Number',
          sortable: true,
          alignment: 3,
          direction: 1,
          precision: 0,
          alignments: [
            {
              id: 1,
              value: 'left',
              description: 'Left',
            },
            {
              id: 2,
              value: 'center',
              description: 'Center',
            },
            {
              id: 3,
              value: 'right',
              description: 'Right',
            },
          ],
          directions: [
            {
              id: 1,
              description: 'Left',
            },
            {
              id: 2,
              description: 'Right',
            },
          ],
          description: '',
          number_format: [
            {
              id: 1,
              description: null,
            },
            {
              id: 2,
              description: '$',
            },
            {
              id: 3,
              description: '€',
            },
            {
              id: 4,
              description: '£',
            },
            {
              id: 5,
              description: '%',
            },
          ],
          selectedFormat: 1,
          negative_number_format: [
            {
              id: 1,
              colour: null,
              signed: false,
              brackets: false,
              description: null,
            },
            {
              id: 2,
              colour: null,
              signed: true,
              brackets: false,
              description: '123.45',
            },
            {
              id: 3,
              colour: null,
              signed: false,
              brackets: true,
              description: '123.45',
            },
            {
              id: 4,
              colour: '#FF0000',
              signed: true,
              brackets: false,
              description: '123.45',
            },
            {
              id: 5,
              colour: '#FF0000',
              signed: false,
              brackets: true,
              description: '123.45',
            },
          ],
          selectedNegativeNumberFormat: 1,
        },
        display_rank: 2,
        field_type_id: 2,
        field_type_name: 'Number',
      },
    ],
    category_id: 2,
    category_name: 'Most Commonly Used',
  },
];
