import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import userService from 'modules/services/UsersManageService/User.manage.service';
import { useNavigate } from 'react-router';
import { FullPageLoader } from 'modules/sharedComponents/components';
import { message } from 'antd';

type FormValues = {
    email: string;
    fullName: string;
    password: string;
    confirmPassword: string;
};

const AccountSetup: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [urlparam, setUrlParam] = useState<string | null>('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [apiMessageId, setApiMessageId] = useState<number | null>();
    const [initialValue, setInitialValues] = useState<FormValues>();
    const [userData, setUserData] = useState<{
        emailId: string | null;
        full_name: string | null;
    }>({
        emailId: null,
        full_name: null,
    });

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };

    const userVerify = async (params: string | null) => {
        console.log(params);
        try {
            if (params) {
                const response = await userService.accountVerify({ param: params });
                console.log(response);
                setApiMessageId(response.messageId);
                setUserData({
                    emailId: response?.data?.emailId ?? null,
                    full_name: response?.data?.full_name ?? null,
                });

                setInitialValues({
                    email: response?.data?.emailId ?? '',
                    fullName: response?.data?.full_name ?? '',
                    password: '',
                    confirmPassword: '',
                })

                if (response.message === 'user verified') {
                    navigate("/dashboard");
                }

            }
            else {
                console.log("url not valid");
            }
        }
        catch (error) {
            console.log(error);
        }

    };

    const navigate = useNavigate();

    useEffect(() => {
        const currentUrl = window.location.href;
        const paramRegex = /param=([^&]+)/;
        const match = paramRegex.exec(currentUrl);
        const paramValue = match ? match[1] : null;
        setUrlParam(paramValue);
        console.log('paramValue:', paramValue);
        userVerify(paramValue);

        if (paramValue) {
            userVerify(paramValue).then(() => {
                setLoading(false);
            });
        } else {
            console.log("url not valid");
            setLoading(false);
        }
    }, []);



    const initialValues: FormValues = {
        email: userData?.emailId || '',
        fullName: userData?.full_name || '',
        password: '',
        confirmPassword: '',
    };
    console.log(initialValues)

    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.email) {
            errors.email = 'Email is required';
        }

        if (!values.fullName) {
            errors.fullName = 'Full name is required';
        }

        if (
            !/(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,12}/.test(
                values.password
            )
        ) {
            errors.password =
                'Invalid format';
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Please confirm your password';
        } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        return errors;
    };

    const [messageApi, contextHolder] = message.useMessage();
    const verify_success = () => {
        messageApi.open({
            type: "success",
            content: "Verified successfully"
        });
    };

    const verify_error = () => {
        messageApi.open({
            type: "error",
            content: "Oops, something went wrong. Please try agian later."
        });
    };

    const redirectUrl = () => {
        navigate("/dashboard");
    }

    const handleSubmit = async (values: FormValues) => {

        try {
            setLoading(true);
            const response = await userService.userActivation({
                emailId: values.email,
                name: values.fullName,
                password: values.confirmPassword,
                param: urlparam
            });
            console.log(response);
            if (response.messageId == '1') {
                verify_success();
                setLoading(false);
                setTimeout(redirectUrl, 1500);
            }
            else {
                verify_error();
            }
        } catch (error) {
            verify_error();
        }

    };



    return (
        <Fragment>
            {contextHolder}{loading && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}><FullPageLoader /></div>
            )}
            {!loading && apiMessageId !== 1 ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}><h2>Your invitation link has been expired</h2></div>
            ) : (
                <div className="mainWrapper">
                    <div className="setup-info-panel">
                        <div className="logo">
                            <a href="#" className="logoDefault" title="Cleversort"></a>
                        </div>
                        {(initialValues.email.length > 0) ? (
                            <div className="progress-wrapper">
                                <div className="customizedTabNavigation" id="progress-bar-container">
                                    <div id="progress-content-section" className="adminDashboardSetupAcc">
                                        <div className="section-content  active" id="step11">
                                            <h1>Account Setup</h1>
                                            <div className="subHeading">
                                                <h2>Your information</h2>
                                            </div>
                                            <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit} validate={validateForm}>
                                                <Form>
                                                    <div className="completeProfileFields workspace">
                                                        <div className="row">
                                                            <div className="fldWrap readOnlyEmail">
                                                                <Field type="email" name="email" className="teamName" id="email" readOnly />
                                                                <label htmlFor="email">Email Address</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="fldWrap">
                                                                <Field type="text" name="fullName" className="teamName" id="FullName" />
                                                                <label htmlFor="FullName">Full Name</label>
                                                                <ErrorMessage name="fullName" component="div" className="error msgError" />
                                                            </div>
                                                        </div>
                                                        <div className="subHeading">
                                                            <h2>Set up your password</h2>
                                                        </div>
                                                        <div className="row">
                                                            <div className="fldWrap">
                                                                <Field
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    name="password"
                                                                    className="userPassword"
                                                                    id="password"
                                                                />
                                                                <span className="material-icons-outlined">lock</span>
                                                                <span
                                                                    className="material-icons-outlined rightIcon"
                                                                    onClick={togglePasswordVisibility}
                                                                >
                                                                    {showPassword ? 'visibility' : 'visibility_off'}
                                                                </span>
                                                                <label htmlFor="password">Password</label>
                                                                <ErrorMessage name="password" component="div" className="error msgError" />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="fldWrap">
                                                                <Field
                                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                                    name="confirmPassword"
                                                                    className="userPassword"
                                                                    id="confirmpassword"
                                                                />
                                                                <span className="material-icons-outlined">lock</span>
                                                                <span
                                                                    className="material-icons-outlined rightIcon"
                                                                    onClick={toggleConfirmPasswordVisibility}
                                                                >
                                                                    {showConfirmPassword ? 'visibility' : 'visibility_off'}
                                                                </span>
                                                                <label htmlFor="confirmpassword">Confirm Password</label>
                                                                <ErrorMessage
                                                                    name="confirmPassword"
                                                                    component="div"
                                                                    className="error msgError"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btnDiv">
                                                        <button type="submit" id="step01" className="btnStyle3">
                                                            Continue
                                                        </button>
                                                    </div>
                                                </Form>
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<><div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}><FullPageLoader /></div></>)}

                    </div>
                    <div className="onboarding mainContent">
                        <div className="setupRightPanel">

                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default AccountSetup;
