import { FC } from "react";

const NotFound: FC<{}> = () => {
  return (
    <div className="loginWrapper">
      <h2>Page not found!</h2>
    </div>
  );
};

export default NotFound;
