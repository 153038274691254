import { RBAC, withRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import React, { Fragment } from "react";
import LeftNav from "../../Dashboard/LeftNav/LeftNav";
import AddTaskStickerPack from "./AddTaskStickerPack";
import { useStore } from "./store";
import TaskStickersTable from "./TaskStickersTable";

const TaskStickerManagement: React.FC<{}> = () => {
    const { isDrawer, setIsDrawer } = useStore();
    const { stickerPack } = useStore();

    const handleDrawer = () => {
        setIsDrawer(!isDrawer);
    };


    return (
        <RBAC allowedPermissions={[ERbacPermissions.TASK_STICKER_VIEW]}>
            <div className={`adminPanelMainWrapper mainWrapper ${isDrawer ? "body-overlay" : ""}`}>
                <LeftNav />
                <div className="adminPanelMainContent mainContent">
                    <Fragment>
                        <div className="contentHeadingPanel">
                            <h2>Task Sticker Management</h2>
                        </div>
                        <div className="inner-container">

                            <TaskStickersTable />

                            <div className={`addTaskStickerDrawerWrap createNewProjectForm ${isDrawer ? "addToggle" : ""}`} id="AdmincreateNewProjectForm1">
                                <div className="header">
                                <h2>{stickerPack.pack_id ? "Update " : "New "}Task Sticker Pack</h2>
                                    <span className="material-icons-outlined close" onClick={handleDrawer}>close</span>
                                </div>
                                <AddTaskStickerPack />
                            </div>
                        </div>
                    </Fragment>
                </div>
            </div>
        </RBAC>
    )
}
export default withRbac(TaskStickerManagement, { allowedPermissions: [ERbacPermissions.TASK_STICKER_VIEW], isAccessFromPage: true });