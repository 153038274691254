import { ILoaderState } from 'modules/models/interfaces';
import { useLoaderStore } from 'modules/store';
import { FC } from 'react';

const FullPageLoader: FC<{ className?: string }> = ({ className = '' }) => {
  const { loaderState } = useLoaderStore((state: ILoaderState) => state);

  return (
    <div className={`mainContent ${className}`}>
      <div className={`customLoader ${loaderState}`}></div>
    </div>
  );
};

export default FullPageLoader;
