import { Popover, Tooltip } from 'antd';
import { TaskListView } from 'migration/models/enums/tasklistView';
import {
  IBlockAssigneeDetails,
  IGridList,
  IGridListBlockDetail,
  ITaskListDetails,
  IUserDetails,
} from 'migration/models/interface';
import { FC, Fragment } from 'react';
import { getUserGravatar } from 'utils/commonFunctions';
import ProfilePic from 'assets/e-images/profile-no-pic.jpg';
import { ContextTypes } from 'migration/models/enums/constants';

const SharedAssigneeDropDown2: FC<{
  blockId?: number;
  gridListDetails?: IGridList;
  setGridListDetails?: React.Dispatch<React.SetStateAction<IGridList>>;
  blockDetails?: IGridListBlockDetail;
  assigneeDetails?: IBlockAssigneeDetails;
  updateBlock?: (block: IGridListBlockDetail) => Promise<void>;
  showDropDown?: number;
  setShowDropDown?: React.Dispatch<React.SetStateAction<number>>;
  eachTaskDetail?: ITaskListDetails;
  viewType?: TaskListView;
  sectionId?: number | string;
  notifyChannel?: (
    userDetails: IUserDetails,
    eventType: string,
    eventValue?: string,
  ) => void;
  setEachTaskDetail?: React.Dispatch<React.SetStateAction<ITaskListDetails>>;
  findTaskIdAndUpdateMainTaskList?: (
    eachTaskDetailUpdated: ITaskListDetails,
  ) => void;

  contextType: ContextTypes;
  currentSection?:
    | {
        sectionId: number;
        popup: 'assignee' | 'dueDate' | 'message';
      }
    | undefined;
  setCurrentSection?: React.Dispatch<
    React.SetStateAction<
      | {
          sectionId: number;
          popup: 'assignee' | 'dueDate' | 'message';
        }
      | undefined
    >
  >;
  randomKey?: string;
}> = ({
  blockDetails,
  gridListDetails,
  setGridListDetails,
  blockId,
  assigneeDetails,
  updateBlock,
  showDropDown,
  setShowDropDown,
  eachTaskDetail,
  viewType,
  contextType,
}) => {
  const contentForTaskList = () => {
    return (
      <Tooltip title={'Unassigned'} color={'#2E364C'} placement="bottom">
        <Popover
          placement="bottom"
          trigger="click"
          overlayClassName="gridAssigneeListPopOver"
        >
          <div
            className="unassignUserBlkWrap userBlkWrap cursorDisabled"
            onKeyDown={() => {}}
          >
            <span className="unassign"></span>
            <div className="userName textMuted">Unassigned</div>
          </div>
        </Popover>
      </Tooltip>
    );
  };

  const getGridListAssigneeBlockClassName = () => {
    let classNameString = 'blkAvtr ';
    if (
      !blockDetails?.assignee_profile_picture &&
      !blockDetails?.assignee_name
    ) {
      classNameString += 'blkNoAvtr  cursorDisabled';
    }
    if (
      blockDetails?.assignee_email &&
      blockDetails?.assignee_inactive &&
      ![2].includes(gridListDetails?.view_id ?? 0)
    ) {
      classNameString += 'userAvtrBlank';
    }
    return classNameString;
  };
  return (
    <Fragment>
      {contextType === ContextTypes.GRIDLIST ? (
        <Tooltip
          title={blockDetails?.assignee_inactive}
          color={'#2E364C'}
          placement="left"
        >
          <Popover
            placement="bottom"
            trigger="click"
            overlayClassName="gridAssigneeListPopOver"
          >
            <div className={getGridListAssigneeBlockClassName()}>
              {[2, 3, 4].includes(gridListDetails?.view_id ?? 0) ? (
                <div>
                  {blockDetails?.assignee_inactive &&
                  gridListDetails?.view_id !== 2
                    ? null
                    : getUserGravatar(
                        blockDetails?.assignee_name ??
                          blockDetails?.assignee_email!,
                      )}
                </div>
              ) : (
                <>
                  {!blockDetails?.assignee_inactive &&
                  blockDetails?.assignee_profile_picture ? (
                    <img
                      src={blockDetails?.assignee_profile_picture ?? ProfilePic}
                      alt=""
                    />
                  ) : (
                    <div>
                      {!blockDetails?.assignee_inactive &&
                        getUserGravatar(blockDetails?.assignee_name ?? '')}
                    </div>
                  )}
                </>
              )}
            </div>
          </Popover>
        </Tooltip>
      ) : viewType && viewType === TaskListView.CARD ? (
        contentForTaskList()
      ) : (
        <td className="assignWrap">{contentForTaskList()}</td>
      )}
    </Fragment>
  );
};

export default SharedAssigneeDropDown2;
