import { UsersData } from 'modules/models/interfaces';
import { create } from 'zustand';

interface User {
  name: string;
  email: string;
  user_id: number;
  roles: string[];
  status: boolean;
  invite_accepted: boolean;
}

interface AppState {
  isDrawer: boolean;
  setIsDrawer: (isDrawer: boolean) => void;
  iuser: User;
  setIUser: (iuser: User) => void;
  users: UsersData[];
  setUsers: (users: UsersData[]) => void;
  tloading: boolean;
  setTLoading: (tloading: boolean) => void;
}

export const useStore = create<AppState>((set) => ({
  isDrawer: false,
  setIsDrawer: (isDrawer) => set({ isDrawer }),

  tloading: false,
  setTLoading: (tloading) => set({tloading}),

  iuser: {
    name: '',
    email: '',
    user_id: 0,
    roles: [],
    status: false,
    invite_accepted: false
  },
  setIUser: (iuser) => set({ iuser }),

  users: [{
    email: '',
    roles: [],
    auth_id: '',
    user_id: '',
    full_name: '',
    is_active: false,
    invite_accepted: false,
    profile_picture: ''
  }],

  setUsers: (users) => set({ users }),

}));

export { };
