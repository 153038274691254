import React, { useEffect, useRef, useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import { ConfigProvider, message, Popover, Tooltip } from 'antd';
import TableView from './TableComponents/ColumnDragTable';
import Task from '../Task/Task';
import dayjs from 'dayjs';
import UploadField from './CustomCells/UploadField';
import ActionDropdown from './ColumnAction/ActionDropdown';
import AddCustomField from './AddCustomField';
import useTableService, {
  DEFAULT_COLUMN_DATA_INDEX,
  mappingDetails,
} from '../services/table.service';
import useTaskService from '../services/task.service';
import { useLocation } from 'react-router-dom';
import PriorityDropdown from './CustomFieldCells/PriorityDropdown';
import {
  ITaskList,
  ITaskListDetails,
  IUpdatedTasks,
} from 'migration/models/interface';
import { useUserStore } from 'modules/store';
import { newCustomFieldColumnWidth } from './constant';
import templateService from 'modules/services/TemplateService/Template.service';
import { useTemplateStore } from 'modules/components/Pages/Template/store';
import CustomNumberField from './CustomCells/CustomNumberField';

export interface DataType extends ITaskListDetails {
  key: React.Key;
  id: string | number;
  dataIndex: string;
  name: string;
  platform: string;
  version: string;
  label: string;
  title: string;
  upgradeNum: number;
  creator: string;
  createdAt: string;
  show: boolean;
  options?: any[];
  fixed: boolean;
  hidden: boolean;
  columnIndex: number;
  isDefaultColumn: boolean;
  width: number;
}

export interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}
export let custom_meta = [
  {
    mapping_id: '1',
    fixed: false,
    width: 90,
    hidden: false,
    key: 'task_status_id',
  },
  {
    mapping_id: '2',
    fixed: false,
    width: 200,
    hidden: false,
    key: 'task_name',
  },
  {
    mapping_id: '3',
    fixed: false,
    width: 150,
    hidden: false,
    key: 'due_date',
  },
  {
    mapping_id: '4',
    fixed: false,
    width: 150,
    hidden: false,
    key: 'assignee_name',
  },
];

const CTable: React.FC<{
  taskDetails?: ITaskList;
  sectionToggleState: 'collapsed' | 'expanded';
  socket?: any;
  setTaskListDetails: React.Dispatch<React.SetStateAction<ITaskList>>;
  isTaskEmpty?: boolean;
  customfieldCmsData?: any;
  setIsTaskEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  section?: any;
}> = (props) => {
  const {
    taskDetails,
    setTaskListDetails,
    isTaskEmpty,
    setIsTaskEmpty,
    customfieldCmsData,
    section,
  } = props ?? {};
  const location = useLocation();
  const sectionId = taskDetails?.section_id ?? '';
  const packId = taskDetails?.pack_id;
  const sectionName = taskDetails?.section_name ?? '';
  const org_key = location.pathname.split('/')[2];
  const [status, task, dueDate, assignee] = DEFAULT_COLUMN_DATA_INDEX;

  const { templateDetails, sections, setSections } = useTemplateStore();

  let taskNameExist = taskDetails?.section_details?.some(
    (data: any) => data?.task_name,
  );

  const ACTION_COLUMN = {
    title: 'Action',
    hidden: false,
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 60,
    fixed: 'right',
    render: (_: any, record: any) => {
      return (
        <Task
          setIsTaskEmpty={setIsTaskEmpty}
          key={record.task_id}
          eachTaskDetail={record}
          sectionId={sectionId}
          // setTaskListDetails={props?.setTaskListDetails}
          statusDoneId={taskDetails?.status_done_id}
          sectionName={sectionName}
          packId={packId}
          taskDetails={props?.taskDetails}
          // section={section}
          renderType="action"
        />
      );
    },
  };

  const DEFAULT_COLUMNS: any = [];
  // Stores

  const customFieldCmsData = {
    add_column_tooltip: 'Add Column',
    cf_tooltip_modal_options: [
      {
        category_id: 1,
        category_name: 'Essentials',
      },
      {
        category_id: 2,
        category_name: 'Most Commonly Used',
      },
    ],
  };
  const customField = [
    {
      details: [
        {
          default_meta: {
            id: 1,
            name: 'Priority',
            options: [
              {
                color: '#E0465E',
                value: 'Critical',
              },
              {
                color: '#1FC778',
                value: 'High',
              },
              {
                color: '#FBAA49',
                value: 'Medium',
              },
              {
                color: '#C4C4C4',
                value: 'Low',
              },
            ],
            sortable: true,
            description: '',
          },
          display_rank: 2,
          field_type_id: 1,
          field_type_name: 'Priority',
        },
        {
          default_meta: {
            id: 4,
            name: 'Single-Select',
            options: [
              {
                color: '#E0465E',
                value: 'Option 1',
              },
              {
                color: '#FBAA49',
                value: 'Option 2',
              },
              {
                color: '#C4C4C4',
                value: 'Option 3',
              },
              {
                color: '#1FC778',
                value: 'Option 4',
              },
            ],
            sortable: true,
            description: '',
          },
          display_rank: 1,
          field_type_id: 4,
          field_type_name: 'Single-Select',
        },
      ],
      category_id: 1,
      category_name: 'Essentials',
    },
    {
      details: [
        {
          default_meta: {
            id: 1,
            name: 'Priority',
            options: [
              {
                color: '#E0465E',
                value: 'Critical',
              },
              {
                color: '#1FC778',
                value: 'High',
              },
              {
                color: '#FBAA49',
                value: 'Medium',
              },
              {
                color: '#C4C4C4',
                value: 'Low',
              },
            ],
            sortable: true,
            description: '',
          },
          display_rank: 1,
          field_type_id: 1,
          field_type_name: 'Priority',
        },
        {
          default_meta: {
            id: 2,
            name: 'Number',
            sortable: true,
            alignment: 3,
            direction: 1,
            precision: 0,
            alignments: [
              {
                id: 1,
                value: 'left',
                description: 'Left',
              },
              {
                id: 2,
                value: 'center',
                description: 'Center',
              },
              {
                id: 3,
                value: 'right',
                description: 'Right',
              },
            ],
            directions: [
              {
                id: 1,
                description: 'Left',
              },
              {
                id: 2,
                description: 'Right',
              },
            ],
            description: '',
            number_format: [
              {
                id: 1,
                description: null,
              },
              {
                id: 2,
                description: '$',
              },
              {
                id: 3,
                description: '€',
              },
              {
                id: 4,
                description: '£',
              },
              {
                id: 5,
                description: '%',
              },
            ],
            selectedFormat: 1,
            negative_number_format: [
              {
                id: 1,
                colour: null,
                signed: false,
                brackets: false,
                description: null,
              },
              {
                id: 2,
                colour: null,
                signed: true,
                brackets: false,
                description: '123.45',
              },
              {
                id: 3,
                colour: null,
                signed: false,
                brackets: true,
                description: '123.45',
              },
              {
                id: 4,
                colour: '#FF0000',
                signed: true,
                brackets: false,
                description: '123.45',
              },
              {
                id: 5,
                colour: '#FF0000',
                signed: false,
                brackets: true,
                description: '123.45',
              },
            ],
            selectedNegativeNumberFormat: 1,
          },
          display_rank: 2,
          field_type_id: 2,
          field_type_name: 'Number',
        },
      ],
      category_id: 2,
      category_name: 'Most Commonly Used',
    },
  ];

  const { userDetails } = useUserStore((state: any) => state);
  // States
  const [columns, setColumns] = useState<TableColumnsType<DataType>>([]);
  const [dataSource, setDataSource] = useState<ITaskListDetails[]>();
  let deleteOptionsRef = useRef<any>(null);
  // Services
  const {
    updateExistingTask,
    updateCustomField,
    deleteCustomFieldAPI,
    updateSettings,
  } = useTaskService({
    sectionId: taskDetails?.section_id ?? 0,
    org_key,
    userDetails,
  });
  const {
    revertCustomField,
    addCustomField,
    getDataSource,
    convertColumnsToCustomMeta,
    deleteCustomField,
    changeCustomField,
    handleToggleEdit,
    updateCFData,
  } = useTableService({
    taskDetails,
    // setTaskListDetails,
  });
  const composeColumns = (newCols: any) => {
    const columnList = [...newCols];
    if (taskDetails?.section_id) {
      columnList.push(ACTION_COLUMN);
    }
    setColumns(columnList);
  };

  // Functions
  const getColumns = (taskDetail?: ITaskList) => {
    const metaData = (taskDetail?.custom_meta ?? [])
      .map((i: any, index) => {
        if (!i) return;

        if (+i.mapping_id >= 1 && +i.mapping_id <= 4) {
          if (+i.mapping_id === 1) {
            return {
              mapping_id: i.mapping_id,
              title: 'Status',
              width: i.width,
              hidden: i.hidden,
              dataIndex: status,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon' },
              key: status,
              fixed: 'left',
              align: 'center',
              sorter: (a: any, b: any) =>
                (a?.[status] ?? 0) - (b?.[status] ?? 0),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    // socket={props?.socket}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="status"
                  />
                );
              },
            };
          }

          if (+i.mapping_id === 2) {
            return {
              mapping_id: i.mapping_id,
              title: (
                <Popover
                  content={<div>Actions</div>}
                  trigger="contextMenu"
                  placement="bottomLeft"
                >
                  <span style={{ width: '100%' }}>Task</span>
                </Popover>
              ),
              width: i.width,
              hidden: i.hidden,
              dataIndex: task,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon' },
              key: task,
              fixed: 'left',
              sorter: (a: any, b: any) =>
                (a?.[task] ?? '').localeCompare(b?.[task] ?? ''),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="task"
                    section={section}
                  />
                );
              },
            };
          }

          if (+i.mapping_id === 3) {
            return {
              mapping_id: i.mapping_id,
              title: <span className="displayFlex">Due Date</span>,
              width: i.width,
              hidden: i.hidden,
              dataIndex: dueDate,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon' },
              key: dueDate,
              sorter: (a: any, b: any) =>
                dayjs(a?.[dueDate]).diff(dayjs(b?.[dueDate])),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="dueDate"
                  />
                );
              },
            };
          }

          if (+i.mapping_id === 4) {
            return {
              mapping_id: i.mapping_id,
              title: <span className="displayFlex">Assignee</span>,
              width: i.width,
              hidden: i.hidden,
              dataIndex: assignee,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon' },
              key: assignee,
              sorter: (a: any, b: any) =>
                (a?.[assignee] ?? '').localeCompare(b?.[assignee] ?? ''),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="assignee"
                  />
                );
              },
            };
          }
        } else {
          let column = {
            ...i,
            title: (
              <div className="custom-column-actions">
                <span className="custom-column-name">
                  <Tooltip title={i?.name} color={'#2E364C'}>
                    {i?.name}
                  </Tooltip>
                </span>
                {
                  // <RBAC allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}>
                  <ActionDropdown
                    onDelete={() => {
                      customFieldOps(i, 'delete');
                    }}
                    onDeleteOptions={(deletedOptions: any) => {
                      let tempDelRef = deleteOptionsRef.current ?? [];
                      const { color, name } = deletedOptions[0];
                      let deletedField = {
                        value: name,
                        mapping_id: i?.mapping_id,
                      };
                      tempDelRef.push(deletedField);
                      deleteOptionsRef.current = tempDelRef;
                    }}
                    onChange={(changedValues, allValues) => {
                      if (!changedValues?.width) return;
                      const newWidth = changedValues?.width ?? allValues?.width;
                      let newTaskDetails: any = {};
                      setSections(
                        sections.map((section: any) => {
                          if (section.section_id === sectionId) {
                            const newCustomMeta = section.custom_meta?.map(
                              (j: any) => {
                                if (j?.mapping_id === i?.mapping_id) {
                                  j.tempWidth = newWidth;
                                }
                                return j;
                              },
                            );
                            newTaskDetails = {
                              ...section,
                              custom_meta: newCustomMeta,
                            };
                            return newTaskDetails;
                          }
                          return section;
                        }),
                      );
                    }}
                    onSave={(allValues) => {
                      const newWidth = allValues?.width;
                      const newName = allValues?.columnName;
                      const description = allValues?.description;
                      let newTaskDetails: any = {};

                      setSections(
                        sections.map((section: any) => {
                          if (section.section_id === sectionId) {
                            const col_rank = section?.settings?.col_rank ?? [];
                            const newCustomMeta = section.custom_meta
                              .map((j: any) => {
                                if (j?.mapping_id !== i?.mapping_id) return j;

                                j.width = newWidth;
                                j.name = newName ? newName : j.name;
                                j.description = description;

                                if (j.id === 2) {
                                  j.number_format =
                                    allValues?.number_format ?? j.number_format;
                                  j.precision =
                                    allValues?.precision ?? j?.precision;
                                  j.direction =
                                    allValues?.direction ?? j?.direction;
                                  j.alignment =
                                    allValues?.alignment ?? j?.alignment;
                                  j.selectedFormat =
                                    allValues?.selectedFormat ??
                                    j?.selectedFormat;
                                  j.selectedNegativeNumberFormat =
                                    allValues?.selectedNegativeNumberFormat ??
                                    j?.selectedNegativeNumberFormat;
                                }

                                if (j.tempWidth) delete j.tempWidth;

                                if (
                                  (j.id === 1 || j.id === 4) &&
                                  allValues?.options?.length
                                ) {
                                  j.options = allValues.options?.map(
                                    (option: any) => {
                                      return {
                                        value: option?.name ?? option?.value,
                                        color: option?.color,
                                      };
                                    },
                                  );
                                }
                                customFieldOps(j, 'update');
                                return j;
                              })
                              .sort((a: any, b: any) => {
                                const indexA = col_rank.indexOf(a.key);
                                const indexB = col_rank.indexOf(b.key);
                                return indexA - indexB;
                              });
                            newTaskDetails = {
                              ...section,
                              custom_meta: newCustomMeta,
                            };
                            if (deleteOptionsRef.current?.length) {
                              newTaskDetails?.section_details?.map(
                                (task: any) => {
                                  task.custom_data = task?.custom_data?.filter(
                                    (cd: any) =>
                                      !deleteOptionsRef.current?.some(
                                        (doItem: any) =>
                                          cd.mapping_id === doItem.mapping_id &&
                                          cd.value === doItem.value,
                                      ),
                                  );
                                  return task;
                                },
                              );
                            }
                            return newTaskDetails;
                          }
                          return section;
                        }),
                      );
                      // setTaskListDetails((prev: any) => {
                      //   const col_rank = prev?.settings?.col_rank ?? [];
                      //   const newCustomMeta = prev.custom_meta
                      //     .map((j: any) => {
                      //       if (j?.mapping_id !== i?.mapping_id) return j;
                      //       j.width = newWidth;
                      //       j.name = newName ? newName : j.name;
                      //       j.description = description;
                      //       if (j.id === 2) {
                      //         j.number_format =
                      //           allValues?.number_format ?? j.number_format;
                      //         j.precision =
                      //           allValues?.precision ?? j?.precision;
                      //         j.direction =
                      //           allValues?.direction ?? j?.direction;
                      //         j.alignment =
                      //           allValues?.alignment ?? j?.alignment;
                      //         j.selectedFormat =
                      //           allValues?.selectedFormat ?? j?.selectedFormat;
                      //         j.selectedNegativeNumberFormat =
                      //           allValues?.selectedNegativeNumberFormat ??
                      //           j?.selectedNegativeNumberFormat;
                      //       }
                      //       if (j.tempWidth) delete j.tempWidth;
                      //       if (j.id === 1 && allValues?.options?.length) {
                      //         j.options = allValues.options?.map(
                      //           (option: any) => {
                      //             return {
                      //               value: option?.name ?? option?.value,
                      //               color: option?.color,
                      //             };
                      //           },
                      //         );
                      //       }
                      //       customFieldOps(j, 'update');
                      //       return j;
                      //     })
                      //     .sort((a: any, b: any) => {
                      //       const indexA = col_rank.indexOf(a.key);
                      //       const indexB = col_rank.indexOf(b.key);
                      //       return indexA - indexB;
                      //     });
                      //   newTaskDetails = {
                      //     ...prev,
                      //     custom_meta: newCustomMeta,
                      //   };
                      //   if (deleteOptionsRef.current?.length) {
                      //     newTaskDetails?.section_details?.map((task: any) => {
                      //       task.custom_data = task?.custom_data?.filter(
                      //         (cd: any) =>
                      //           !deleteOptionsRef.current?.some(
                      //             (doItem: any) =>
                      //               cd.mapping_id === doItem.mapping_id &&
                      //               cd.value === doItem.value,
                      //           ),
                      //       );
                      //       return task;
                      //     });
                      //   }
                      //   return newTaskDetails;
                      // });
                      handleUpdateSettings(newTaskDetails, 'width');
                      const newSettings = newTaskDetails?.settings;
                      const col_rank = newSettings?.col_rank ?? [];
                      const newCols = [...getColumns(newTaskDetails)].sort(
                        (a, b) => {
                          const indexA = col_rank.indexOf(a.key);
                          const indexB = col_rank.indexOf(b.key);
                          return indexA - indexB;
                        },
                      );
                      composeColumns(newCols);
                      const data = getDataSource(
                        newTaskDetails?.section_details,
                      );
                      setDataSource(data);
                    }}
                    onSaveSetting={() => {}}
                    defaultValue={{
                      width: i.width ? i.width : newCustomFieldColumnWidth,
                      columnName: i.name,
                    }}
                    editForm={{
                      ...i,
                      addToQuickFilter: i?.addToQuickFilter || false,
                    }}
                    onClose={() => (deleteOptionsRef.current = null)}
                  >
                    <div onClick={(e) => e.stopPropagation()}>
                      <MoreOutlined />
                    </div>
                  </ActionDropdown>
                  // </RBAC>s
                }
              </div>
            ),
            width: newCustomFieldColumnWidth,
            hidden: i?.hidden ?? false,
            dataIndex: i.mapping_id,
            key: i.mapping_id,
            fixed: false,
            columnIndex: index,
            showSorterTooltip: { target: 'sorter-icon' },
          };

          // if (i.id === 1 || i.id === 4) {
          //   column.render = (text: any, record: any) => {
          //     const options = i.options
          //       .map((i: any) => ({
          //         ...i,
          //         label: i.value,
          //         value: i.value,
          //       }))
          //       .filter((i: any) => i.value?.trim());
          //     const currentValue = options.find((i: any) => i.value === text);
          //     const color = currentValue?.color ?? 'white';
          //     const currentCustomData = record?.custom_data?.find(
          //       (cf: any) => cf.mapping_id === i.mapping_id,
          //     );

          //     return (
          //       <PriorityDropdown
          //         options={options}
          //         value={currentValue?.value}
          //         disabled={true}
          //         onChange={(value) => {
          //           const newTaskDetails = updateCFData(
          //             value,
          //             i.id,
          //             record.task_id,
          //             i.mapping_id,
          //             taskDetail!,
          //             sectionId,
          //           );

          //           const newSettings = newTaskDetails?.settings;
          //           const col_rank = newSettings?.col_rank ?? [];
          //           const newCols = [...getColumns(newTaskDetails)].sort(
          //             (a, b) => {
          //               const indexA = col_rank.indexOf(a.key);
          //               const indexB = col_rank.indexOf(b.key);
          //               return indexA - indexB;
          //             },
          //           );
          //           composeColumns(newCols);

          //           const data = getDataSource(newTaskDetails?.section_details);
          //           setDataSource(data);
          //           handleUpdateTaskList(newTaskDetails);
          //         }}
          //         onSelect={(value) => {
          //           if (value === currentValue?.value) {
          //             handleToggleEdit(
          //               i.id,
          //               record.task_id,
          //               i.mapping_id,
          //               sectionId,
          //             );
          //           }
          //         }}
          //       />
          //     );
          //   };

          //   if (i.sortable) {
          //     column.sorter = (a: any, b: any) => {
          //       return (a?.[i?.mapping_id] ?? '').localeCompare(
          //         b?.[i?.mapping_id] ?? '',
          //       );
          //     };
          //   }
          // }
          if (i.id === 1 || i.id === 4) {
            column.render = (text: any, record: any) => {
              const options = i.options
                .map((i: any) => ({
                  ...i,
                  label: i.value,
                  value: i.value,
                }))
                .filter((i: any) => i.value?.trim());
              const currentValue = options.find((i: any) => i.value === text);
              return (
                <PriorityDropdown
                  options={options}
                  value={currentValue?.value}
                  disabled={true}
                  onChange={(value) => {
                    const newTaskDetails = updateCFData(
                      value,
                      i.id,
                      record.task_id,
                      i.mapping_id,
                      taskDetail!,
                      sectionId,
                    );

                    const newSettings = newTaskDetails?.settings;
                    const col_rank = newSettings?.col_rank ?? [];
                    const newCols = [...getColumns(newTaskDetails)].sort(
                      (a, b) => {
                        const indexA = col_rank.indexOf(a.key);
                        const indexB = col_rank.indexOf(b.key);
                        return indexA - indexB;
                      },
                    );
                    composeColumns(newCols);

                    const data = getDataSource(
                      newTaskDetails?.task_list_details,
                    );
                    setDataSource(data);
                    if (typeof record.task_id === 'number') {
                      handleUpdateTaskList(newTaskDetails);
                    }
                  }}
                  onSelect={(value) => {
                    if (value === currentValue?.value) {
                      handleToggleEdit(
                        i.id,
                        record.task_id,
                        i.mapping_id,
                        sectionId,
                      );
                    }
                  }}
                />
              );
            };

            if (i.sortable) {
              column.sorter = (a: any, b: any) => {
                return (a?.[i?.mapping_id] ?? '').localeCompare(
                  b?.[i?.mapping_id] ?? '',
                );
              };
            }
          }

          if (i.id === 2) {
            column.render = (text: any, record: any) => {
              return (
                <CustomNumberField
                  taskDetail={record}
                  disabled={false}
                  value={text as number}
                  configuration={i}
                  sectionId={sectionId}
                />
              );
            };

            if (i.sortable) {
              column.sorter = (a: any, b: any) => {
                return (a?.[i?.mapping_id] ?? 0) - (b?.[i?.mapping_id] ?? 0);
              };
            }
          }

          if (i.id === 3) {
            column.render = (text: any, record: any) => {
              return <UploadField disabled={false} />;
            };
          }

          return column;
        }
      })
      .filter((i) => i?.key);

    return metaData;
  };

  const sumColumnWidth = (columns: TableColumnsType<DataType>) => {
    let tableWidth = columns.reduce(
      (acc, current) =>
        acc + Number(current?.hidden ? 0 : current?.width || 175),
      0,
    );
    tableWidth = false ? tableWidth + 60 : tableWidth;
    return tableWidth;
  };

  async function customFieldOps(
    option: any,
    type: 'add' | 'update' | 'delete' = 'add',
  ) {
    try {
      const mappingId = option?.mapping_id;

      if (type === 'delete' && mappingId) {
        const updatedTaskList = await deleteCustomFieldAPI(mappingId);
        const newDetails = deleteCustomField(
          mappingId,
          updatedTaskList,
          sectionId,
        );
        const newTaskDetails = {
          ...mappingDetails(newDetails),
        };
        const newSettings = newTaskDetails?.settings;
        const col_rank = newSettings?.col_rank ?? [];
        const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
          const indexA = col_rank.indexOf(a.key);
          const indexB = col_rank.indexOf(b.key);
          return indexA - indexB;
        });
        composeColumns(newCols);

        const newDataSource = getDataSource(newTaskDetails?.section_details);
        setDataSource(newDataSource);
      }
      const { data, messageId } = await updateCustomField({
        customMeta: type === 'add' ? option.default_meta : option,
        templateId: Number(templateDetails?.template_id),
        sectionId: Number(taskDetails?.section_id),
        sectionTypeId: Number(taskDetails?.section_type_id),
        deleteOptions: deleteOptionsRef.current,
      });

      if (type === 'add') {
        const newTaskDetails = addCustomField(
          option,
          JSON.parse(data),
          sectionId,
        );
        await handleUpdateSettings(newTaskDetails as any, 'orderRow');
        const response = await templateService.getTemplateById(
          templateDetails.template_id,
        );
        setSections(response.data.sections);
      }

      if (messageId !== 1 && type === 'add')
        revertCustomField(option, sectionId);
    } catch (error) {
      revertCustomField(option, sectionId);
    }
  }

  const handleUpdateTaskList = async (taskDetails: ITaskList) => {
    if (taskDetails) {
      const updatedTaskList = await updateExistingTask(
        (taskDetails?.section_details as IUpdatedTasks[]) ?? [],
      );

      const newDetails = changeCustomField(updatedTaskList, sectionId);

      const newTaskDetails = {
        ...mappingDetails(newDetails),
      };

      const newSettings = newTaskDetails?.settings;
      const col_rank = newSettings?.col_rank ?? [];
      const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
        const indexA = col_rank.indexOf(a.key);
        const indexB = col_rank.indexOf(b.key);
        return indexA - indexB;
      });
      composeColumns(newCols);

      const newDataSource = getDataSource(newTaskDetails?.section_details);
      setDataSource(newDataSource);
    }
  };

  const handleUpdateSettings = async (
    taskDetails?: ITaskList,
    type: 'orderColumn' | 'orderRow' | 'hiding' | 'width' = 'hiding',
    settings?: any,
  ) => {
    if (taskDetails) {
      await updateSettings(taskDetails);

      if (type === 'width') {
        const newTaskDetails = taskDetails;
        const newSettings = newTaskDetails?.settings;
        const col_rank = newSettings?.col_rank ?? [];
        const newCols = [...getColumns(taskDetails)].sort((a, b) => {
          const indexA = col_rank.indexOf(a.key);
          const indexB = col_rank.indexOf(b.key);
          return indexA - indexB;
        });
        composeColumns(newCols);

        const data = getDataSource(newTaskDetails?.section_details);
        setDataSource(data);
        await updateSettings(newTaskDetails);
        return;
      }

      const newTaskDetails = {
        ...mappingDetails(taskDetails),
      };
      const newSettings = newTaskDetails?.settings;
      setSections(
        sections.map((section: any) => {
          if (section.section_id === sectionId) {
            return newTaskDetails;
          }
          return section;
        }),
      );
      await updateSettings(newTaskDetails);

      if (['orderColumn', 'hiding'].includes(type)) return;

      const col_rank = newSettings?.col_rank ?? [];
      const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
        const indexA = col_rank.indexOf(a.key);
        const indexB = col_rank.indexOf(b.key);
        return indexA - indexB;
      });

      composeColumns(newCols);

      const data = getDataSource(newTaskDetails?.section_details);
      setDataSource(data);
    }
  };

  // Effects
  useEffect(() => {
    if (columns && taskDetails) {
      const newTaskDetails = {
        ...mappingDetails(taskDetails),
      };
      const settings = taskDetails?.settings;
      const col_rank = settings?.col_rank ?? [];
      const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
        const indexA = col_rank.indexOf(a.key);
        const indexB = col_rank.indexOf(b.key);
        return indexA - indexB;
      });

      composeColumns(newCols);

      const data = getDataSource(newTaskDetails?.section_details);
      setDataSource(data);
    }
  }, [taskDetails]);
  return (
    <div className="custom-table-container">
      <div className="antTableWrap">
        <div className="simpleTblWrap">
          <ConfigProvider
            theme={{
              token: {
                colorText: '#2e364c',
              },
            }}
            // locale={enUS}
          >
            <TableView
              className="nested-table"
              columns={columns?.length ? columns : DEFAULT_COLUMNS}
              onColumnOrderChange={(_: any, fromKey: any, toKey: any) => {
                setColumns((prev) => {
                  const newColumns = [...prev];
                  const fromIndex = newColumns.findIndex(
                    (i) => i?.key === fromKey,
                  );
                  const toIndex = newColumns.findIndex((i) => i?.key === toKey);
                  const fromColumn = newColumns[fromIndex];
                  const toColumn = newColumns[toIndex];

                  if (fromColumn?.fixed || toColumn?.fixed) {
                    message.warning('Invalid re-order operation!');
                    return newColumns;
                  }

                  if (fromIndex >= 0 && toIndex >= 0) {
                    newColumns.splice(
                      toIndex,
                      0,
                      newColumns.splice(fromIndex, 1)[0],
                    );
                  }

                  if (taskDetails) {
                    const newTaskDetails = {
                      ...taskDetails,
                      custom_meta: convertColumnsToCustomMeta(
                        newColumns,
                      ).filter((i: any) => i),
                    };

                    if (newTaskDetails) {
                      handleUpdateSettings(newTaskDetails, 'orderColumn');
                    }
                  }

                  return newColumns;
                });
              }}
              onRowReOrder={(newDataSource) => {
                if (taskDetails) {
                  setDataSource(getDataSource(newDataSource));
                  handleUpdateSettings(
                    {
                      ...taskDetails,
                      custom_meta: convertColumnsToCustomMeta(columns).filter(
                        (i: any) => i,
                      ),
                      section_details: newDataSource,
                    },
                    'orderRow',
                  );
                }
              }}
              onClickAdd={() => {}}
              dataSource={dataSource}
              size="small"
              bordered
              pagination={false}
              key="task_id"
              rowKey="task_id"
              scroll={{
                x: sumColumnWidth(columns),
              }}
              // loading={!dataSource?.length}
            />
            {taskNameExist && (
              // <Rbac >
              <AddCustomField
                customfieldCmsData={customFieldCmsData}
                options={customField ?? []}
                onClick={(option) => customFieldOps(option, 'add')}
              />
              // </Rbac>
            )}
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default CTable;
