import { Radio, RadioChangeEvent, Select } from "antd";
import { ErrorMessage, Field, Formik, FormikProps, useFormikContext } from "formik";
import {
  ICreateTemplate,
  ISaveTemplateAsDraft,
} from "modules/models/interfaces/template.interface";
import React, { Fragment, useEffect, useRef, useState } from "react";
import UploadThumbnail from "./UploadThumbnail";
import TemplateDescription from "./TemplateDescription";
import templateService from "modules/services/TemplateService/Template.service";
// import ComponentLoader from "modules/components/Loaders/ComponentLoader";
import { IOption, useTemplateStore } from "../store";
import { useBasicInfoStore } from "./store";
import { MessageInstance } from "antd/es/message/interface";
import { TemplateStep } from "modules/models/enums/templateEnums";
import { useNavigate } from "react-router-dom";
import UploadBanner from "./UploadBanner";
import { ComponentLoader } from "modules/sharedComponents/components";

const filterOption = (input: string, option?: IOption) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const BasicInfo: React.FC<{
  messageApi: MessageInstance;
  id: string;
  setIsApiCalled: any;
}> = ({ messageApi, id, setIsApiCalled }) => {
  const initialIsChangeObj = {
    template_name: false,
    banner_title: false,
    banner_description: false,
    template_description: false,
    languages: false,
    thumbnails: false,
    banner: false,
    tags: false,
    industries: false,
    organization_function: false,
    work_type: false,
    countries: false,
    teams: false,
  };

  const initialIsChangeObjWhenCreateNew = {
    template_name: true,
    banner_title: true,
    banner_description: true,
    template_description: true,
    languages: true,
    thumbnails: true,
    banner: true,
    tags: true,
    industries: true,
    organization_function: true,
    work_type: true,
    countries: true,
    teams: true,
  };
  const [tags, setTags] = useState<string[]>([]);
  const [industries, setIndustries] = useState<number[]>([]);
  const [organizationFunctions, setOrganizationFunctions] = useState<number[]>(
    [],
  );
  const [countries, setCountries] = useState<number[]>([]);
  const [teams, setTeams] = useState<string[]>(['all']);
  const [languages, setLanguages] = useState<number[]>([]);
  const [shortDescription, setShortDescription] = useState('');
  const [isChanged, setIsChanged] = useState(initialIsChangeObjWhenCreateNew);
  const { thumbnails, banner, setThumbnails, setUploadedImages, setBanner } =
    useBasicInfoStore();
  const [isSaving, setIsSaving] = useState(false);
  const [workType, setWorkType] = useState<number>(0);

  const {
    templateDetails,
    setStep,
    setTemplateDetails,
    languageOption,
    countryOption,
    teamOption,
    industryOption,
    organizationFunctionOption,
    loading,
    sections,
    setSections,
  } = useTemplateStore();
  const navigate = useNavigate();

  const initialValues = {
    template_name: templateDetails?.template_name || '',
    banner_title: templateDetails?.banner_title || '',
    banner_description: templateDetails?.banner_description || '',
    // tags,
  };

  useEffect(() => {
    if (templateDetails?.template_id) setIsChanged(initialIsChangeObj);
    setTags(templateDetails?.tags || []);
    setLanguages(templateDetails?.languages?.map((lang) => lang.id) || []);
    setIndustries(
      templateDetails?.industries?.map((industry) => industry.id) || [],
    );
    setOrganizationFunctions(
      templateDetails?.organization_functions?.map((orgFunc) => orgFunc.id) ||
      [],
    );
    setCountries(templateDetails.countries?.map((country) => country.id) || []);
    if (templateDetails?.part_of_all_organization) {
      setTeams(['all']);
    } else {
      setTeams(templateDetails.teams?.map((team) => team.key) || []);
    }
    setShortDescription(templateDetails.template_description);
    setThumbnails([...templateDetails.template_thumbnails]);
    setBanner(templateDetails.banner);
    setWorkType(templateDetails.work_type || 0);
  }, [
    templateDetails,
    templateDetails.template_description,
    templateDetails.template_id,
  ]);

  const validateTemplateName = async (value: string) => {
    let error: string | undefined;
    let _value = value.trim();
    if (!value) {
      error = 'Template name is required';
    } else if (_value.length < 1 || _value.length > 200) {
      error = 'Template name must be between 1 and 200 characters';
    } else if (
      (templateDetails?.template_id &&
        templateDetails.template_name !== value) ||
      !templateDetails?.template_id
    ) {
      try {
        const response = await templateService.isUniqueTemplateName({
          template_name: _value,
        });

        const msgId = response.messageId;

        if (msgId === -1 && !templateDetails?.template_id) {
          error = 'Template name already exist';
        }
      } catch (error) {
        console.error('Error checking template uniqueness:', error);
      }
    }
    return error;
  };

  const handleChangeTemplateName = (value: string, setFieldValue: any) => {
    setFieldValue('template_name', value);
    if (
      templateDetails?.template_id &&
      templateDetails.template_name !== value
    ) {
      const updatedIsChanged = {
        ...isChanged,
        template_name: true,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      templateDetails.template_name === value
    ) {
      const updatedIsChanged = {
        ...isChanged,
        template_name: false,
      };
      setIsChanged(updatedIsChanged);
    }

    if (!templateDetails?.template_id) {
      setIsChanged(initialIsChangeObjWhenCreateNew);
    }
  };

  const validateBannerTitle = (value: string) => {
    let error: string | undefined;
    let _value = value.trim();

    if (!value) {
      error = 'Banner title is required';
    } else if (_value.length < 1 || _value.length > 50) {
      error = 'Banner title must be between 1 and 50 characters';
    }
    return error;
  };

  const handleChangeBannerTitle = (value: string, setFieldValue: any) => {
    setFieldValue('banner_title', value);
    if (
      templateDetails?.template_id &&
      templateDetails.banner_title !== value
    ) {
      const updatedIsChanged = {
        ...isChanged,
        banner_title: true,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      templateDetails.banner_title === value
    ) {
      const updatedIsChanged = {
        ...isChanged,
        banner_title: false,
      };
      setIsChanged({ ...updatedIsChanged });
    }

    if (!templateDetails?.template_id)
      setIsChanged(initialIsChangeObjWhenCreateNew);
  };

  const validateBannerDescription = (value: string) => {
    let error: string | undefined;
    let _value = value.trim();

    if (!value) {
      error = 'Banner description is required';
    } else if (_value.length < 1 || _value.length > 255) {
      error = 'Banner description must be between 1 and 255 characters';
    }
    return error;
  };

  const handleChangeBannerDescription = (value: string, setFieldValue: any) => {
    setFieldValue('banner_description', value);
    if (
      templateDetails?.template_id &&
      templateDetails.banner_description !== value
    ) {
      const updatedIsChanged = {
        ...isChanged,
        banner_description: true,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      templateDetails.banner_description === value
    ) {
      const updatedIsChanged = {
        ...isChanged,
        banner_description: false,
      };
      setIsChanged({ ...updatedIsChanged });
    }

    if (!templateDetails?.template_id)
      setIsChanged(initialIsChangeObjWhenCreateNew);
  };

  const handleChangeTag = (values: string[]) => {
    if (
      templateDetails?.template_id &&
      (templateDetails?.tags?.length || 0) !== values.length
    ) {
      const updatedIsChanged = {
        ...isChanged,
        tags: true,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      (templateDetails?.tags?.length || 0) === values.length &&
      values.every((value) => templateDetails.tags.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        tags: false,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      (templateDetails?.tags?.length || 0) === values.length &&
      !values.every((value) => templateDetails.tags.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        tags: true,
      };
      setIsChanged({ ...updatedIsChanged });
    }
    setTags(values);
  };

  const handleChangeIndustry = (values: number[]) => {
    const industryIds =
      templateDetails?.industries?.map((industry) => industry.id) || [];
    if (templateDetails?.template_id && industryIds.length !== values.length) {
      const updatedIsChanged = {
        ...isChanged,
        industries: true,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      industryIds.length === values.length &&
      values.every((value) => industryIds.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        industries: false,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      industryIds.length === values.length &&
      !values.every((value) => industryIds.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        industries: true,
      };
      setIsChanged({ ...updatedIsChanged });
    }
    setIndustries(values);
  };

  const handleChangeOrganizationFunction = (values: number[]) => {
    const organizationFunctionIds =
      templateDetails?.organization_functions?.map((orgFunc) => orgFunc.id) ||
      [];
    if (
      templateDetails?.template_id &&
      organizationFunctionIds.length !== values.length
    ) {
      const updatedIsChanged = {
        ...isChanged,
        organization_function: true,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      organizationFunctionIds.length === values.length &&
      values.every((value) => organizationFunctionIds.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        organization_function: false,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      organizationFunctionIds.length === values.length &&
      !values.every((value) => organizationFunctionIds.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        organization_function: true,
      };
      setIsChanged({ ...updatedIsChanged });
    }
    setOrganizationFunctions(values);
  };

  const handleChangeCountry = (values: number[]) => {
    const countryIds =
      templateDetails?.countries?.map((country) => country.id) || [];
    if (templateDetails?.template_id && countryIds.length !== values.length) {
      const updatedIsChanged = {
        ...isChanged,
        countries: true,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      countryIds.length === values.length &&
      values.every((value) => countryIds.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        countries: false,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      countryIds.length === values.length &&
      !values.every((value) => countryIds.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        countries: true,
      };
      setIsChanged({ ...updatedIsChanged });
    }
    setCountries(values);
  };

  const handleChangeTeam = (values: string[]) => {
    const teamKeys = templateDetails?.teams?.map((team) => team.key) || [];
    if (templateDetails?.template_id && teamKeys.length !== values.length) {
      const updatedIsChanged = {
        ...isChanged,
        teams: true,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      teamKeys.length === values.length &&
      values.every((value) => teamKeys.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        teams: false,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      teamKeys.length === values.length &&
      !values.every((value) => teamKeys.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        teams: true,
      };
      setIsChanged({ ...updatedIsChanged });
    }
    setTeams(values);
  };

  const handleChangeLanguage = (values: number[]) => {
    const languageIds = templateDetails.languages?.map((lang) => lang.id) || [];

    if (templateDetails?.template_id && languageIds.length !== values.length) {
      const updatedIsChanged = {
        ...isChanged,
        languages: true,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      languageIds.length === values.length &&
      values.every((value) => languageIds.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        languages: false,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      languageIds.length === values.length &&
      !values.every((value) => languageIds.includes(value))
    ) {
      const updatedIsChanged = {
        ...isChanged,
        languages: true,
      };
      setIsChanged({ ...updatedIsChanged });
    }
    setLanguages(values);
  };

  const handleChangeWorkType = (e: RadioChangeEvent) => {
    if (
      templateDetails?.template_id &&
      templateDetails?.work_type !== e.target.value
    ) {
      const updatedIsChanged = {
        ...isChanged,
        work_type: true,
      };
      setIsChanged({ ...updatedIsChanged });
    } else if (
      templateDetails?.template_id &&
      templateDetails?.work_type === e.target.value
    ) {
      const updatedIsChanged = {
        ...isChanged,
        work_type: false,
      };
      setIsChanged({ ...updatedIsChanged });
    }

    if (!templateDetails?.template_id) {
      const updatedIsChanged = {
        ...isChanged,
        work_type: true,
      };
      setIsChanged({ ...updatedIsChanged });
    }

    setWorkType(e.target.value);
  };

  const handleSuccess = () => {
    messageApi.open({
      type: 'success',
      content: `Template ${templateDetails?.template_id ? 'updated' : 'saved as draft'
        } successfully`,
    });
  };

  const handleError = () => {
    messageApi.open({
      type: 'error',
      content: 'Some error occurred. Please try it again!',
    });
  };

  const handleSubmitTemplate = async (
    values: ICreateTemplate,
    isContinue: boolean,
    { resetForm }: { resetForm: () => void },
  ) => {
    try {
      if (!isChanged) {
        if (isContinue) setStep(TemplateStep.CONFIGURE);
        return;
      }

      setIsSaving(true);

      const requestUploadThumbnailPayloads = thumbnails.map((thumbnail) => {
        if ('dataURL' in thumbnail) {
          return {
            dataURL: thumbnail.dataURL,
            template_name: values.template_name,
          };
        }
        return thumbnail;
      });

      let uploadedBanner = null;

      if (banner !== null && !banner.includes('template-thumbnail/')) {
        const uploadResponse = await templateService.uploadThumbnail({
          dataURL: banner,
          template_name: values.template_name,
        });
        uploadedBanner = uploadResponse.data;
      } else if (banner !== null && banner.includes('template-thumbnail/')) {
        uploadedBanner = banner;
      }

      const uploadedThumbnails = await Promise.all(
        requestUploadThumbnailPayloads.map(async (payload, index) => {
          if ('dataURL' in payload) {
            const uploadResponse = await templateService.uploadThumbnail(
              payload,
            );
            return {
              id: index,
              thumbnail_url: uploadResponse.data,
              is_default: thumbnails[index].is_default,
            };
          }
          return payload;
        }),
      );

      const contenteditable = document.querySelectorAll('[contenteditable]')[1];
      const text = contenteditable?.textContent ?? '';

      const requestPayload: ISaveTemplateAsDraft = {
        template_id: templateDetails?.template_id || 0,
        template_name: values.template_name.trim(),
        template_description: shortDescription,
        languages: languages,
        preview_url: `${process.env.REACT_APP_PLATFORM_URL}/${text}`,
        template_thumbnails: uploadedThumbnails,
        tags,
        industries,
        organization_functions: organizationFunctions,
        countries,
        teams: teams.includes('all') ? [] : teams,
        part_of_all_organization: teams.includes('all'),
        banner_title: values.banner_title,
        banner_description: values.banner_description,
        work_type: workType,
        banner: uploadedBanner,
      };

      const response = await templateService.saveTemplate(requestPayload);
      if (response.messageId === 1) {
        if (!isContinue) {
          handleSuccess();
          setTimeout(() => {
            navigate('../templates');
          }, 1000);
        } else {
          // if (!templateDetails?.template_id) {
          setTemplateDetails({
            ...response.data,
          });
          // }
          setStep(TemplateStep.CONFIGURE);
          navigate(`/templates/${response.data.template_id}`);
        }
      } else {
        handleError();
      }
    } catch (error) {
      console.error(error);
      handleError();
    } finally {
      setIsSaving(true);
      if (!isContinue) {
        setTimeout(() => {
          resetForm();
          setShortDescription('');
          document.querySelectorAll('[contenteditable]')[0].innerHTML = '';
          setTags([]);
          setIndustries([]);
          setOrganizationFunctions([]);
          setCountries([]);
          setTeams([]);
          setThumbnails([]);
          setBanner(null);
          setUploadedImages([]);
          setLanguages([]);
          setWorkType(0);
        }, 1000);
      } else {
        resetForm();
        setShortDescription('');
        document.querySelectorAll('[contenteditable]')[0].innerHTML = '';
        setTags([]);
        setIndustries([]);
        setOrganizationFunctions([]);
        setCountries([]);
        setTeams([]);
        setThumbnails([]);
        setBanner(null);
        setUploadedImages([]);
        setLanguages([]);
        setWorkType(0);
      }
    }
  };

  return (
    <Fragment>
      <div className="contentHeadingPanel">
        <h2>{id !== 'create' ? 'Update Template' : 'New Template'}</h2>
      </div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ComponentLoader isLoading={loading} children={undefined} />
        </div>
      ) : (
        <div className="inner-container">
          <div className="manageUsersTableHeading manageTemplateHeading">
            <span className="itemName">Template Settings</span>
          </div>
          <div className="newTemplateWrapper">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={() => { }}
            >
              {({
                handleSubmit,
                values,
                errors,
                setFieldValue,
                resetForm,
              }: FormikProps<ICreateTemplate>) => (
                <>
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="inputHolder">
                          <label htmlFor="templateName">Template Name*</label>
                          <Field
                            type="text"
                            name="template_name"
                            id="templateName"
                            validate={validateTemplateName}
                            aria-label="Template Name"
                            onChange={(e: any) =>
                              handleChangeTemplateName(
                                e.target.value,
                                setFieldValue,
                              )
                            }
                          />
                          <ErrorMessage
                            name="template_name"
                            component="div"
                            className="error-message errorColor msgError"
                          />
                        </div>
                        <div className="inputHolder">
                          <label htmlFor="bannerTitle">Banner Title*</label>
                          <Field
                            type="text"
                            name="banner_title"
                            id="bannerTitle"
                            validate={validateBannerTitle}
                            aria-label="Banner Title"
                            onChange={(e: any) =>
                              handleChangeBannerTitle(
                                e.target.value,
                                setFieldValue,
                              )
                            }
                          />
                          <ErrorMessage
                            name="banner_title"
                            component="div"
                            className="error-message errorColor msgError"
                          />
                        </div>
                        <div className="inputHolder">
                          <label htmlFor="bannerDescription">
                            Banner Short Description*
                          </label>
                          <Field
                            type="text"
                            name="banner_description"
                            id="bannerDescription"
                            validate={validateBannerDescription}
                            aria-label="Banner Description"
                            onChange={(e: any) =>
                              handleChangeBannerDescription(
                                e.target.value,
                                setFieldValue,
                              )
                            }
                          />
                          <ErrorMessage
                            name="banner_description"
                            component="div"
                            className="error-message errorColor msgError"
                          />
                        </div>
                        <div className="inputHolder">
                          <label htmlFor="templateDescription">
                            Short Description
                          </label>
                          <TemplateDescription
                            shortDescription={
                              templateDetails.template_description || ''
                            }
                            setShortDescription={setShortDescription}
                            isChanged={isChanged}
                            setIsChanged={setIsChanged}
                          />
                          <ErrorMessage
                            name="template_description"
                            component="div"
                            className="error-message errorColor msgError"
                          />
                          {/* <label htmlFor="templateDescription">Short Description</label>
                                            <Field type="text" name="template_description" id="templateDescription" aria-label="Template Description" component="textarea" placeholder="Write description here" />
                                            <ErrorMessage name="template_description" component="div" className="error-message errorColor msgError" /> */}
                        </div>
                        <div className="inputHolder customSelectChip">
                          <label htmlFor="Languages">Languages*</label>
                          {/* <select name="language" value={language} onChange={(e) => setLanguage(Number(e.target.value))}>
                                                {languageOption.map(option => (
                                                    <option value={option.value}>{option.label}</option>
                                                ))}
                                                <option value="English">English</option>
                                                <option value="German">German</option>
                                                <option value="French">French</option>
                                            </select> */}
                          <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            allowClear
                            placeholder="Please select"
                            value={languages}
                            onChange={handleChangeLanguage}
                            options={languageOption}
                            notFoundContent={null}
                            suffixIcon={null}
                            showSearch
                            filterOption={filterOption}
                          />
                        </div>

                        <div className="inputHolder">
                          <label htmlFor="templatePreviewUrl">
                            Public Preview URL/Slug
                          </label>
                          <div className="static-dynamic">
                            <span className="static">
                              {process.env.REACT_APP_PLATFORM_URL}/
                            </span>
                            <span
                              className="dynamic"
                              contentEditable="true"
                              suppressContentEditableWarning={true}
                            >
                              {values.template_name
                                .toLowerCase()
                                .split(' ')
                                .join('-')}
                            </span>
                          </div>
                        </div>

                        <div className="inputHolder uploadedFiles">
                          <label htmlFor="Upload thumbnail">
                            Upload Thumbnail Image*
                          </label>
                          <p>(Max size 1 MB)</p>
                          <UploadThumbnail
                            isChanged={isChanged}
                            setIsChanged={setIsChanged}
                          />
                        </div>

                        <div className="inputHolder uploadedFiles">
                          <label htmlFor="Upload banner">
                            Upload Banner Image
                          </label>
                          <p>(Max size 1 MB)</p>
                          <UploadBanner
                            isChanged={isChanged}
                            setIsChanged={setIsChanged}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="inputHolder customSelectChip">
                          <label htmlFor="Tags">Add Tags</label>
                          <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            allowClear
                            placeholder="Please select"
                            value={tags}
                            onChange={handleChangeTag}
                            options={[]}
                            notFoundContent={null}
                            suffixIcon={null}
                          />
                        </div>
                        <div className="inputHolder customSelectChip">
                          <label htmlFor="Industries">Industries</label>
                          <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            allowClear
                            placeholder="Please select"
                            value={industries}
                            onChange={handleChangeIndustry}
                            options={industryOption}
                            showSearch
                            filterOption={filterOption}
                          />
                        </div>
                        <div className="inputHolder customSelectChip">
                          <label htmlFor="Organization Function">
                            Organization Function
                          </label>
                          <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            allowClear
                            placeholder="Please select"
                            value={organizationFunctions}
                            onChange={handleChangeOrganizationFunction}
                            options={organizationFunctionOption}
                            showSearch
                            filterOption={filterOption}
                          />
                        </div>

                        <div className="inputHolder customSelectChip">
                          <label htmlFor="Work Type">Work Type*</label>
                          <Radio.Group
                            onChange={handleChangeWorkType}
                            value={workType}
                          >
                            <Radio value={1}>Work</Radio>
                            <Radio value={2}>Personal</Radio>
                            <Radio value={3}>School</Radio>
                          </Radio.Group>
                        </div>
                        <div className="inputHolder customSelectChip">
                          <label htmlFor="Countries">Countries</label>
                          <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            allowClear
                            placeholder="Please select"
                            value={countries}
                            onChange={handleChangeCountry}
                            options={countryOption}
                            showSearch
                            filterOption={filterOption}
                          />
                        </div>
                        <div className="inputHolder customSelectChip">
                          <label htmlFor="Teams">Teams</label>
                          <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            allowClear
                            placeholder="Please select"
                            defaultValue={['all']}
                            value={teams}
                            onChange={handleChangeTeam}
                            options={teamOption}
                            showSearch
                            filterOption={filterOption}
                          />
                        </div>


                      </div>
                    </div>
                  </form>
                  <div className="btnPart multipleBtn">
                    <div className="cancelCreate">
                      <div
                        className="noStyle"
                        onClick={() => navigate('../templates')}
                      >
                        Cancel
                      </div>
                      {/* <div className="btnStyle1" >Add Sticker Pack</div> */}
                      <button
                        onClick={async (e) => {
                          e.preventDefault();
                          await handleSubmitTemplate(values, true, {
                            resetForm,
                          });
                          setIsApiCalled(true);
                        }}
                        className="btnStyle4"
                        disabled={
                          !values.template_name ||
                          Object.keys(errors).length !== 0 ||
                          !languages ||
                          languages.length === 0 ||
                          thumbnails.length < 1 ||
                          isSaving ||
                          workType === 0 ||
                          !values.banner_title ||
                          !values.banner_description
                        }
                      >
                        Continue
                      </button>
                      <button
                        className="btnStyle1"
                        onClick={async (e) => {
                          e.preventDefault();
                          await handleSubmitTemplate(values, false, {
                            resetForm,
                          });
                        }}
                        disabled={
                          !values.template_name ||
                          Object.keys(errors).length !== 0 ||
                          !languages ||
                          languages.length === 0 ||
                          thumbnails.length < 1 ||
                          Object.values(isChanged).filter((ele) => ele)
                            .length === 0 ||
                          isSaving ||
                          workType === 0 ||
                          !values.banner_title ||
                          !values.banner_description
                        }
                      >
                        {templateDetails?.template_id
                          ? 'Save'
                          : 'Save as Draft'}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default BasicInfo;
