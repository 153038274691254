import React, { Fragment, useEffect, useState } from 'react';
import { MenuProps, Dropdown, Space, Checkbox, Col, Row } from 'antd';
import { useListStore } from './store';
import templateService from 'modules/services/TemplateService/Template.service';
import { Industry } from 'modules/models/interfaces/template.interface';


const TemplateFilterButton: React.FC = () => {
    const [status, setStatus] = useState<string[]>([]);
    const { originalTemplateTableData, setTemplateTableData } = useListStore();
    const {
        industryOption,
        organizationFunctionOption,
        setLanguageOption,
        setCountryOption,
        setTeamOption,
        setIndustryOption,
        setOrganizationFunctionOption,
    } = useListStore();
    const [selectIndustries, setSelectIndustries] = useState<string[]>([]);
    const [selectOrg, setSelectOrg] = useState<string[]>([]);
    const [allTeam, setAllTeam] = useState<string[]>([]);

    const getAllMasterData = React.useCallback(async () => {
        const response = await templateService.getAllMasterData();

        const { organization, languages, industries, countries, organization_functions } = response.data;

        setLanguageOption(languages.map(lang => ({
            label: lang.language,
            value: lang.id,
        })));

        setCountryOption(countries.map(country => ({
            label: country.country,
            value: country.id,
        })));

        setTeamOption([
            {
                label: "All Team",
                value: "all",
            },
            ...organization.map(organization => ({
                label: organization.name,
                value: organization.key,
            }))]);

        setIndustryOption(
            industries
                ?.sort((a: Industry, b: Industry) =>
                    a.industry?.localeCompare(b.industry)
                )
                .map((industry) => ({
                    label: industry.industry,
                    value: industry.id,
                }))
        );

        setOrganizationFunctionOption(organization_functions.map(organizationFunction => ({
            label: organizationFunction.organization_function,
            value: organizationFunction.id,
        })))

    }, [setCountryOption, setIndustryOption, setLanguageOption, setOrganizationFunctionOption, setTeamOption]);

    useEffect(() => {
        getAllMasterData();
    }, [])

    const filterData = (() => {
        const searchStatus1 = ((status.length !== 0) ? status[0] : 'Published');
        const searchStatus2 = ((status.length !== 0) ? status[1] : 'Unpublished');
        const searchAllTeam1 = ((allTeam.length !== 0) ? allTeam[0] : 'true');
        const searchAllTeam2 = ((allTeam.length !== 0) ? allTeam[1] : 'false');

        const filteredData = originalTemplateTableData.filter((record) => {
            const ftemplateStatus = (record.is_active) ? 'Published' : 'Unpublished';
            const findustries = (record.industries) ? record.industries.map(data => data.industry).join(',') : '';
            const forganization_functions = (record.organization_functions) ? record.organization_functions.map(data => data.organization_function).join(',') : '';
            const fpart_of_all_organization = record.part_of_all_organization.toString();
            return (
                (
                    ftemplateStatus.includes(searchStatus1) ||
                    ftemplateStatus.includes(searchStatus2)
                ) &&
                (
                    selectIndustries.some(element => {
                        if (findustries.includes(element)) { return true }
                        return false;
                    }) || (selectIndustries.length == 0) ? true : false
                ) &&
                (
                    selectOrg.some(element => {
                        if (forganization_functions.includes(element)) { return true }
                        return false;
                    }) || (selectOrg.length === 0) ? true : false
                ) &&
                (
                    fpart_of_all_organization.includes(searchAllTeam1) ||
                    fpart_of_all_organization.includes(searchAllTeam2)
                )
            );
        });

        setTemplateTableData(((status.length + selectIndustries.length + selectOrg.length + allTeam.length) > 0) ? filteredData : originalTemplateTableData);

    })


    useEffect(() => {
        filterData();
    }, [status, selectIndustries, selectOrg, allTeam]);


    const onChangeStatus = (checkedValues: any) => {
        setStatus(checkedValues);
    };


    const onChangesIndusType = (checkedValues: any) => {
        setSelectIndustries(checkedValues);
    };

    const onChangesOrgType = (checkedValues: any) => {
        setSelectOrg(checkedValues);
    }

    const onChangeAllTeam = (checkedValues: any) => {
        setAllTeam(checkedValues);
    }


    const clearAll = (() => {
        setStatus([]);
        setSelectIndustries([]);
        setSelectOrg([]);
        setAllTeam([]);
        setTemplateTableData(originalTemplateTableData);
    });

    const items: MenuProps['items'] = [

        {
            key: '1',
            label: <p onClick={clearAll}>Clear All</p>
        },

        {
            key: '2',
            label: <p>Publish status</p>,
            children: [
                {
                    key: '2-1',
                    label:
                        <Space>
                            <Checkbox.Group style={{ width: '200px' }} value={status} onChange={onChangeStatus}>
                                <Row>
                                    <Col span={20}>
                                        <Checkbox value="Published">Published</Checkbox>
                                    </Col>
                                    <Col span={20}>
                                        <Checkbox value="Unpublished">Unpublished</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Space>
                    ,
                },
            ]
        },

        {
            key: '3',
            label: <p>Industries type</p>,
            children: [
                {
                    key: '3-1',
                    label:
                        <Space>
                            <Checkbox.Group style={{ width: '200px' }} value={selectIndustries} onChange={onChangesIndusType}>
                                <Row>
                                    {industryOption.map((data) => {
                                        return (
                                            <Col span={20} key={data.label}>
                                                <Checkbox value={data.label}>{data.label}</Checkbox>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Checkbox.Group>
                        </Space>
                    ,
                },
            ]
        },
        {
            key: '4',
            label: <p>Organization Function type</p>,
            children: [
                {
                    key: '4-1',
                    label:
                        <Space>
                            <Checkbox.Group style={{ width: '200px' }} value={selectOrg} onChange={onChangesOrgType}>
                                <Row>
                                    {organizationFunctionOption.map((data) => {
                                        return (
                                            <Col span={20} key={data.label}>
                                                <Checkbox value={data.label}>{data.label}</Checkbox>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Checkbox.Group>
                        </Space>
                    ,
                },
            ]
        },
        {
            key: '5',
            label: <p>Part of all teams</p>,
            children: [
                {
                    key: '5-1',
                    label:
                        <Space>
                            <Checkbox.Group style={{ width: '200px' }} value={allTeam} onChange={onChangeAllTeam}>
                                <Row>
                                    <Col span={20}>
                                        <Checkbox value="true">Yes</Checkbox>
                                    </Col>
                                    <Col span={20}>
                                        <Checkbox value="false">No</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Space>
                    ,
                },
            ]
        }
    ];


    return (
        <Fragment>
            <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()}>
                    <Space >
                        <span className="cmnIcon filter"></span>
                    </Space>
                </a>
            </Dropdown>

        </Fragment >
    )
};

export default TemplateFilterButton;
