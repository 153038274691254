import { RoutesEnum } from "../../models/enums/apiRoutes";
import {
  ADminRolesData, ApiIUserDetails, ApiMessage, ApiResponesData, EmailParam, ReInviteEmail, UserActivationData, UserData, UserDataUpdate, UserDelete, UsersTableData, UserParam
} from "../../models/interfaces";
import apiService from "../Api.service";

class UserService {
  public adminRolesData = async () => {
    return apiService.get<ADminRolesData>(RoutesEnum.ADMIN_ROLES_DATA);
  };

  public uniqueEmail = async (email_param: EmailParam) => {
    return apiService.get<ApiMessage>(RoutesEnum.CHECK_UNIQUE_EMAIL, email_param);
  };

  public userCreate = async (UserDataPayload: UserData) => {
    return apiService.post<ApiMessage>(
      RoutesEnum.INVITE_USER,
      UserDataPayload
    );
  }

  public accountVerify = async (user_param: UserParam) => {
    return apiService.post<ApiResponesData>(
      RoutesEnum.USER_VERIFY,
      user_param
    );
  }

  public userActivation = async (ActivationPayload: UserActivationData) => {
    return apiService.post<ApiMessage>(
      RoutesEnum.USER_ACTIVATION,
      ActivationPayload
    );
  }

  public usersTable = async () => {
    return apiService.get<UsersTableData>(RoutesEnum.USERS_TABLE_DATA);
  }

  public userUpdate = async (UserDataPayload: UserDataUpdate) => {
    return apiService.post<ApiMessage>(
      RoutesEnum.USER_UPDATE,
      UserDataPayload 
    );
  }

  public userReInvitation = async (UserEmailPayload: ReInviteEmail) => {
    return apiService.post<ApiMessage>(
      RoutesEnum.RE_INVITE_USER,
      UserEmailPayload
    );
  }

  public userDetails = async () => {
    return apiService.get<ApiIUserDetails>(RoutesEnum.USER_DETAILS);
  }

  public userDelete = async (UserDeletePayload: UserDelete) => {
    return apiService.post<ApiMessage>(
      RoutesEnum.USER_DELETE,
      UserDeletePayload
    );
  }

}

const userService = new UserService();
export default userService;
