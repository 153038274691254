import { ErrorMessage, Field, Formik, FormikProps } from "formik";
import { AddTaskStickerValue, UploadedStickers } from "modules/models/interfaces/sticker.interface";
import { Fragment, useEffect, useState } from "react";
import { Select, message, Modal, SelectProps, Switch } from 'antd';
import UploadSticker from "./UploadSticker";
import { Stickers, useStore } from "./store";
import taskStickerService from "modules/services/TaskStickerService/TaskSticker.services";
import apiService from "modules/services/Api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { ComponentLoader } from "modules/sharedComponents/components";
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';

interface Status {
    status_key: number,
    status_label: string,
    name_alias: string,
    sort_value: number,
}

const AddTaskStickerPack = () => {
    const [status, setStatus] = useState(true);
    const { languages, setLanguage } = useStore();
    const { teams, setTeams } = useStore();
    const { stickers, setStickers } = useStore();
    const [loading, setLoading] = useState(true);
    const { setTLoading } = useStore();
    const [messageApi, contextHolder] = message.useMessage();
    const [allsatusoptions, setAllsatusoptions] = useState<Status[]>([]);
    const [selectLanguage, setSelectLanguage] = useState<string[]>([]);
    const [selectTeams, setSelectTeams] = useState<string[]>([]);
    const { isDrawer, setIsDrawer } = useStore();
    const { setSelectedImages } = useStore();
    const { hasPermissions } = useRbac();
    const { stickerPack } = useStore();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const initialValues: AddTaskStickerValue = {
        sticker_pack_name: stickerPack.pack_name || '',
    };

    useEffect(() => {
        setSelectLanguage(stickerPack.languages || []);
        if (stickerPack.part_of_all_organization) {
            setSelectTeams(['all']);
        } else {
            setSelectTeams(stickerPack.teams?.map(team => team.org_key) || []);
        }
        setSelectedImages(stickerPack.pack_configuration)
        setStatus(stickerPack.is_active);
    }, [stickerPack])


    const handleDrawer = () => {
        setIsDrawer(!isDrawer);
    };

    const statusArr = stickers.map((sdata: Stickers | UploadedStickers) => {
        return ((sdata as Stickers).status || (sdata as UploadedStickers).status_label);
    })
    const uniqueStatusArr = statusArr.filter(function (v, i, self) {
        return i == self.indexOf(v);
    });

    const getLangAndTeam = async () => {
        const responseLang = await taskStickerService.getLanguage();
        const langs = responseLang.data.map((sdata) => {
            return sdata.language;
        })
        setLanguage(langs);

        const responseTeam = await taskStickerService.getTeams();
        setTeams(responseTeam.data);

        const response = await taskStickerService.getStatusOption();
        setAllsatusoptions(response.data);

        if (response) {
            setLoading(false);
        }
    }

    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        apiService.setTokenGenerator(getAccessTokenSilently);
        if (hasPermissions([ERbacPermissions.TASK_STICKER_VIEW])) {
            getLangAndTeam();
        }

    }, []);

    const options_lang: SelectProps['options'] = [];
    for (let value of languages) {
        options_lang.push({
            label: value,
            value,
            disabled: false,
        });
    }

    const handleChange_lang = (value: string[]) => {
        if (value.length == 1 && value[0] == '') {
            value.unshift();
        }
        setSelectLanguage(value);
    };


    const options_team: SelectProps['options'] = [];
    options_team.push({
        label: "All Team",
        value: "all",
        disabled: false,
    });

    teams.forEach((tdata) => {
        const value = tdata.name + "(" + tdata.key + ")";
        options_team.push({
            label: value,
            value: tdata.key,
            disabled: false,
        });
    })

    const handleChange_team = (value: string[]) => {
        if (value.length == 1 && value[0] == '') {
            value.unshift();
        }
        setSelectTeams(value);
    };

    const onChange = (checked: boolean) => {
        setStatus(checked);
    };

    const validatePackName = async (value: string) => {
        let error: string | undefined;
        let _value = value.trim();
        if (!value) {
            error = 'Sticker Pack Name  is required';
        }
        else if (_value.length < 4 || _value.length > 50) {
            error = 'Sticker Pack Name must be between 4 and 100 characters';
        } else if ((stickerPack?.pack_id && stickerPack.pack_name !== value) || !stickerPack?.pack_id) {
            try {

                const response = await taskStickerService.uniqueTaskStickerPackName({ sticker_pack_name: _value });

                const msgId = response.messageId.toString();

                if (msgId === '-1') {
                    error = 'Sticker Pack Name already exist';
                }
            } catch (error) {
                console.error('Error checking sticker pack uniqueness:', error);
            }
        }
        return error;
    };

    const add_success = () => {
        messageApi.open({
            type: "success",
            content: `Task sticker pack ${stickerPack.pack_id ? "updated" : "added"} successfully`
        });
    };

    const add_error = () => {
        messageApi.open({
            type: "error",
            content: "Some error occurred. Please try it again!"
        });
    };

    const del_success = () => {
        messageApi.open({
            type: "success",
            content: `Task sticker pack deleted successfully`
        });
    };

    const handleOk = async () => {
        deleteSticker();
        setIsModalOpen(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const deleteSticker = async () => {
        setTLoading(true);
        handleDrawer();
        const deletRes = await taskStickerService.taskStickersDelete({ pack_id: stickerPack.pack_id });
        if (deletRes.messageId == '1') {
            del_success();
        } else {
            add_error();
        }
        setTLoading(false);
    }

    const handleSubmit = async (values: AddTaskStickerValue, { resetForm }: { resetForm: () => void }) => {
        if (values.sticker_pack_name.length > 0) { handleDrawer(); }
        try {
            const upload_sticker_json = await Promise.all(stickers.map(async (data) => {
                setTLoading(true);

                if (!("status_value" in data)) {
                    const urlresponse = await taskStickerService.uploadStickers({
                        dataURL: (data as Stickers).dataURL,
                        packName: values.sticker_pack_name
                    });

                    const status_key = allsatusoptions.find(fstatusk => (data as Stickers).status === fstatusk.status_label)?.status_key;
                    const sort_value = allsatusoptions.find(fstatus => (data as Stickers).status === fstatus.status_label)?.sort_value;

                    const url = urlresponse.message;

                    return ({
                        status_key: status_key,
                        status_label: (data as Stickers).status,
                        status_value: url,
                        sort_value: sort_value
                    });
                }
                return data;
            }));

            const part_of_all_organization = selectTeams.find(fteam => fteam === "all") ? true : false;

            const pack_name = values.sticker_pack_name.trim();
            const bucket_path_suffex = pack_name.replace(/\s+/g, '_').toLocaleLowerCase();

            const requestPayload = {
                pack_id: stickerPack?.pack_id,
                pack_name: values.sticker_pack_name,
                pack_configuration: upload_sticker_json as UploadedStickers[],
                is_active: status,
                bucket_path_suffix: bucket_path_suffex,
                part_of_all_organization: part_of_all_organization,
                languages: selectLanguage,
                teams: selectTeams,
            }

            const responseFinal = await taskStickerService.addTaskStickerPack(requestPayload)

            if (responseFinal.messageId == '1') {
                setTLoading(false);
                add_success();
            }
            else {
                setTLoading(false);
                add_error();
            }
            resetForm();

        } catch (error) {
            console.log(error);
            setTLoading(false);
            add_error();
        }
        resetForm();
        setSelectLanguage([]);
        setSelectTeams([]);
        setStickers([]);
        setSelectedImages([]);
    }


    return (
        <Fragment>
            {contextHolder}{loading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}><ComponentLoader isLoading={loading} children={undefined} /></div>
            ) :

                <div className="formContent">
                    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
                        {({ handleSubmit, values, errors, setFieldValue }: FormikProps<AddTaskStickerValue>) => (
                            <form onSubmit={handleSubmit}>
                                <div className="inputHolder">
                                    <label htmlFor="stickerPackName">Sticker Pack Name*</label>
                                    <Field type="text" name="sticker_pack_name" id="stickerPackName" validate={validatePackName} aria-label="Sticker Pack Name" />
                                    <ErrorMessage name="sticker_pack_name" component="div" className="error-message errorColor msgError" />
                                </div>
                                <div className="inputHolder customSelectChip">
                                    <label htmlFor="Languages">Languages*</label>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        allowClear
                                        placeholder="Please select"
                                        value={selectLanguage}
                                        onChange={handleChange_lang}
                                        options={options_lang}
                                    />
                                </div>

                                <div className="inputHolder customSelectChip">
                                    <label htmlFor="Teams">Teams*</label>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        allowClear
                                        placeholder="Please select"
                                        value={selectTeams}
                                        onChange={handleChange_team}
                                        options={options_team}
                                    />
                                </div>

                                <div className="inputHolder uploadedFiles">
                                    <label htmlFor="Upload Stickers" >Upload Stickers*</label>
                                    <p>(Upload 4 status sticker icons, max size 50 KB each & 50X50 pixels)</p>
                                    <UploadSticker />
                                </div>

                                <div className="switchCaseWrapper">
                                    <h3>Status</h3>
                                    <div className="sound-switcher mode-wrapper">
                                        <label >
                                            <span>
                                                <Switch checked={status} onChange={onChange} />
                                            </span>

                                        </label>
                                        {status === true ? (
                                            <div className="switchText">
                                                <p>Active</p>
                                            </div>
                                        ) : (
                                            <div className="switchText">
                                                <p>In active</p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <Modal title="Task Sticker Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                    <p>Are you sure you want to delete?</p>
                                </Modal>

                                <div className="btnPart multipleBtn">
                                    {(stickerPack?.pack_id) ?
                                        (<div className="deleteUser" >
                                            <span className="cmnIcon deleteBin"></span> <span className="delProject" onClick={showModal}>Delete Sticker Pack</span>
                                        </div>) : (<div className="deleteUser" ></div>)
                                    }
                                    <div className="cancelCreate">
                                        <div className="noStyle" onClick={handleDrawer}>Cancel</div>
                                        <button
                                            type="submit"
                                            className="btnStyle1"
                                            disabled={Object.keys(errors).length !== 0 || selectLanguage.length === 0 || stickers.length != 4 || stickers.length != uniqueStatusArr.length || selectTeams.length === 0}
                                        >{!stickerPack.pack_id ? 'Add Sticker Pack' : 'Save Changes'}</button>
                                    </div>
                                </div>
                            </form>)}
                    </Formik>
                </div>
            }
        </Fragment>

    )
}

export default AddTaskStickerPack;