import React, { Fragment, useEffect, useState } from 'react';
import { MenuProps, Checkbox, Col, Row, Dropdown, Space, Select, SelectProps } from 'antd';
import { useStore } from './store';


const TaskStickerFilter: React.FC = () => {
    const [status, setStatus] = useState<string[]>([]);
    const { languages } = useStore();
    const [selectLanguage, setSelectLanguages] = useState<string[]>([]);
    const { teams } = useStore();
    const { setTaskStickersTable } = useStore();
    const { bTaskStickerTable } = useStore();
    const [selectTeams, setSelectTeams] = useState<string[]>([]);


    const filterData = (() => {
        const searchTerm1 = ((status.length != 0) ? status[0] : 'Active');
        const searchTerm2 = ((status.length != 0) ? status[1] : 'In-active');

        const filteredData = bTaskStickerTable.filter((record) => {
            const stickerStatus = record.packStatus;
            const flanguages = record.mergeLanguages;
            const fTeams = record.mergeTeams;

            return (
                (
                    stickerStatus.includes(searchTerm1) ||
                    stickerStatus.includes(searchTerm2)
                ) &&
                (
                    selectLanguage.some(element1 => {
                        if (flanguages.includes(element1)) {
                            return true;
                        }
                        return false;
                    }) || (selectLanguage.length == 0) ? true : false
                ) &&
                (
                    selectTeams.some(element2 => {
                        if (fTeams.includes(element2)) {
                            return true;
                        }
                        return false;
                    }) || (selectTeams.length == 0) ? true : false
                )
            );
        });



        setTaskStickersTable(((status.length + selectLanguage.length + selectTeams.length) > 0) ? filteredData : bTaskStickerTable);

    })

    const options_team: SelectProps['options'] = [];
    options_team.push({
        label: "All Team",
        value: "All",
        disabled: false,
    });

    teams.forEach((tdata) => {
        const value = tdata.name + "(" + tdata.key + ")";
        options_team.push({
            label: value,
            value: value,
            disabled: false,
        });
    })

    useEffect(() => {
        filterData();
    }, [status]);

    useEffect(() => {
        filterData();
    }, [selectLanguage]);

    useEffect(() => {
        filterData();
    }, [selectTeams]);

    const onChangeStatus = (checkedValues: any) => {
        setStatus(checkedValues);
    };


    const onChangeType = (checkedValues: any) => {
        setSelectLanguages(checkedValues);
    };

    const handleChange_team = (value: string[]) => {
        if (value.length == 1 && value[0] == '') {
            value.unshift();
        }
        setSelectTeams(value);
        console.log(value);
    };


    const clearAll = (() => {
        setStatus([]);
        setSelectLanguages([]);
        setSelectTeams([]);
        setTaskStickersTable(bTaskStickerTable);
    });

    const items: MenuProps['items'] = [

        {
            key: '1',
            label: <p onClick={clearAll}>Clear All</p>
        },

        {
            key: '2',
            label: <p>Pack status</p>,
            children: [
                {
                    key: '2-1',
                    label:
                        <Space>
                            <Checkbox.Group style={{ width: '200px' }} value={status} onChange={onChangeStatus}>
                                <Row>
                                    <Col span={15}>
                                        <Checkbox value="Active">Active</Checkbox>
                                    </Col>
                                    <br />
                                    <Col span={15}>
                                        <Checkbox value="In-active">In-active</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Space>
                    ,
                },
            ]
        },

        {
            key: '3',
            label: <p>Language type</p>,
            children: [
                {
                    key: '3-1',
                    label:
                        <Space>
                            <Checkbox.Group style={{ width: '200px' }} value={selectLanguage} onChange={onChangeType}>
                                <Row>
                                    {languages.map((value) => {
                                        return (
                                            <Col span={15} key={value}>
                                                <Checkbox value={value}>{value}</Checkbox>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Checkbox.Group>
                        </Space>
                    ,
                },
            ]
        },

        {
            key: '4',
            label: 'Select Teams :'
        }
    ];


    return (
        <Fragment>
            <Dropdown menu={{ items }}
                dropdownRender={(menu) => (
                    <div>
                        {React.cloneElement(menu as React.ReactElement)}
                        <Space>
                            <Select
                                mode="multiple"
                                style={{ width: '150px' }}
                                allowClear
                                placeholder="Please select"
                                value={selectTeams}
                                onChange={handleChange_team}
                                options={options_team}
                            />
                        </Space>
                    </div>
                )}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space >
                        <span className="cmnIcon filter"></span>
                    </Space>
                </a>
            </Dropdown>

        </Fragment >
    )

};

export default TaskStickerFilter;
