const GetMessageIcon: React.FC<{
  isBlockedView: boolean;
  messages: any;
  toggleMessagePopup: Function;
  threadName: string;
  showMessagesPopup?: boolean;
  classNameForMessageThread?: Function;
  isTemplate?: boolean;
  isCompactView?: boolean;
  isDashBoardOrPlanner?: boolean;
}> = ({
  isBlockedView = false,
  messages,
  toggleMessagePopup,
  showMessagesPopup = true,
  classNameForMessageThread,
  isTemplate = true,
}) => {
  if (!showMessagesPopup && !isBlockedView && classNameForMessageThread) {
    return (
      <div className="messageWrap">
        {isTemplate && messages?.length > 0 ? (
          <span
            className={`cmnIcon message ${classNameForMessageThread(
              messages?.length,
            )}`}
            onClick={(e) => {
              e.preventDefault();
              toggleMessagePopup();
            }}
            onKeyDown={() => {}}
          ></span>
        ) : (
          <span
            className={`cmnIcon message ${classNameForMessageThread(
              messages?.length,
            )}`}
            onClick={(e) => {
              e.preventDefault();
              toggleMessagePopup();
            }}
            onKeyDown={() => {}}
          ></span>
        )}
      </div>
    );
  }

  if (!isBlockedView) {
    return (
      <div className="messageWrap">
        <span
          className="cmnIcon message"
          onClick={() => toggleMessagePopup()}
          onKeyDown={() => {}}
        ></span>
      </div>
    );
  }

  if (messages?.length) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          toggleMessagePopup();
        }}
        className="blkMsg haveMsg"
      >
        Message
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        toggleMessagePopup();
      }}
      className="blkMsg"
    >
      Message
    </div>
  );
};
export default GetMessageIcon;
