import { create } from 'zustand';

interface AppState {
    isCollapse: boolean;
    setIsCollapse: (isCollapse: boolean) => void;
    btnactive: string;
    setBtnactive: (btnactive: string) => void;
}

export const useLeftNavStore = create<AppState>((set) => ({
    isCollapse: false,
    setIsCollapse: (isCollapse) => set({ isCollapse }),
    btnactive: '',
    setBtnactive: (btnactive) => set({ btnactive }),
}))

// const [btnactive, setBtnactive] = useState<string>();