import React from "react";
import TaskList from "./TaskList/TaskList";
import MemoPad from "./MemoPad/MemoPad";
import GridList from "./GridList/GridList";
import { useTemplateStore } from "../store";
import { ISection } from "modules/models/interfaces/template.interface";

const SectionsWrapper: React.FC<{}> = () => {
  const { sections } = useTemplateStore();
  const getSectionsJsxByType = (eachSection: ISection) => {
    switch (eachSection?.section_type_id) {
      case 1: {
        return (
          <TaskList
            key={eachSection?.section_id}
            sectionId={eachSection?.section_id}
            sectionDetails={eachSection}
          />
        );
      }
      case 2:
        return (
          <GridList
            key={eachSection?.section_id}
            sectionId={eachSection?.section_id}
            sectionDetails={eachSection}
          />
        );
      case 3:
        return (
          <MemoPad
            key={eachSection?.section_id}
            sectionId={eachSection?.section_id}
            pageSpecificationData={eachSection}
            sectionDetails={eachSection}
          />
        );
    }
  };

  return (
    <React.Fragment>
      {sections?.map((eachSection: any) => getSectionsJsxByType(eachSection))}
    </React.Fragment>
  );
};

export default SectionsWrapper;
