import { withRbac } from "auth/rbac/rbac";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const LandingPage: React.FC<{}> = () => {
  const navigate = useNavigate();

  useEffect(() => {

    navigate("/dashboard");

  }, []);

  return <></>;
};

export default withRbac(LandingPage, { isAccessFromPage: false });