import { ITemplateData } from 'modules/models/interfaces/template.interface';
import { create } from 'zustand';

export interface teamsTableData {
  key: string,
  team_name: string,
  accountStatus: string,
  account_created: string,
  active_user: number,
  team_type: string,
  total_project: number,
  created_on: string,
  view_team_info: {
    id: number,
    key: string,
    team_name: string,
    accountStatus: string,
    account_created: string,
    active_user: number,
    team_type: string,
    total_project: number,
    created_on: string,
  }
}

interface IOption {
  label: string,
  value: string | number,
}

interface AppState {
  tloading: boolean;
  setTLoading: (tloading: boolean) => void;
  templateTableData: ITemplateData[],
  setTemplateTableData: (templateTableData: ITemplateData[]) => void;
  originalTemplateTableData: ITemplateData[],
  setOriginalTemplateTableData: (originalTemplateTableData: ITemplateData[]) => void;
  selectedTemplate: ITemplateData | null;
  setSelectedTemplate: (selectedTemplate: ITemplateData) => void;
  languageOption: IOption[];
  setLanguageOption: (languageOption: IOption[]) => void;
  countryOption: IOption[];
  setCountryOption: (countryOption: IOption[]) => void;
  industryOption: IOption[];
  setIndustryOption: (industryOption: IOption[]) => void;
  teamOption: IOption[];
  setTeamOption: (teamOption: IOption[]) => void;
  organizationFunctionOption: IOption[];
  setOrganizationFunctionOption: (
    organizationFunctionOption: IOption[]
  ) => void;
}

export const useListStore = create<AppState>((set) => ({
  tloading: false,
  setTLoading: (tloading) => set({ tloading }),

  templateTableData: [],
  setTemplateTableData: (templateTableData: ITemplateData[]) => set({ templateTableData }),

  originalTemplateTableData: [],
  setOriginalTemplateTableData: (originalTemplateTableData: ITemplateData[]) => set({ originalTemplateTableData }),

  selectedTemplate: null,
  setSelectedTemplate: (selectedTemplate: ITemplateData) => set({ selectedTemplate }),

  languageOption: [],
  setLanguageOption: (languageOption: IOption[]) => set({ languageOption }),

  countryOption: [],
  setCountryOption: (countryOption: IOption[]) => set({ countryOption }),

  industryOption: [],
  setIndustryOption: (industryOption: IOption[]) => set({ industryOption }),

  teamOption: [],
  setTeamOption: (teamOption: IOption[]) => set({ teamOption }),

  organizationFunctionOption: [],
  setOrganizationFunctionOption: (organizationFunctionOption: IOption[]) =>
    set({ organizationFunctionOption }),
}));

export { };
