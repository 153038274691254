export const messagingCmsData = {
  createdAt: '2023-10-18T09:25:50.439Z',
  updatedAt: '2024-06-25T08:21:17.921Z',
  publishedAt: '2023-10-18T09:25:53.488Z',
  page_preview: '?page=messagings&lang=en',
  locale: 'en',
  lbl_generic_error_message:
    'Maximum of 10 files of 25MB each could be attached at a time',
  lbl_file_extension_not_allowed:
    'We found one or more file, that are not allowed',
  lbl_extension_tooltip: {
    '0': 'Bold (ctrl + B)',
    '1': 'Italic (ctrl + I)',
    '2': 'Bulleted List (ctrl + shift + 8)',
    '3': 'Check List (ctrl + shift + 9)',
    '4': 'Emoji',
    '5': 'Attach file',
    '6': 'Link',
    '7': 'Undo',
    '8': 'Redo',
  },
  lbl_message_modification_option: [
    {
      key: 'edit_message',
      label: 'Edit',
      className: 'cmnIcon edit',
    },
    {
      key: 'delete_message',
      label: 'Delete',
      className: 'actionIons cmnIcon deleteBin',
    },
  ],
  lbl_deleted_message_text: 'This message was deleted',
  lbl_delete_message_popover_config: {
    body: 'The message will not appear to anyone',
    cancel: 'Cancel',
    header: 'Delete the message',
    confirm: 'Delete',
  },
  lbl_file_size_not_allowed:
    'We found one or more file, that exceeds allowed file size ',
  lbl_edited: 'Edited',
  lbl_messaging_section_header: 'Messages',
  lbl_messaging_section_all_message_header: 'ALL TASK MESSAGES',
  lbl_messaging_section_pinned_message_header: 'PINNED MESSAGES',
  lbl_messaging_section_editor_placeholder:
    "Type a new message. Use '@' to mention someone.",
  lbl_messaging_section_search_field_placeholder: 'Search',
  lbl_messaging_section_shared_files_header: 'SHARED TASK FILES',
  lbl_messaging_section_project_section_header: 'PROJECT',
  lbl_messaging_section_project_memeber_type: [
    {
      role_id: 3,
      role_name: 'Owner',
    },
    {
      role_id: 4,
      role_name: 'Editor',
    },
    {
      role_id: 5,
      role_name: 'Collaborator',
    },
  ],
  lbl_messaging_section_filter: [
    {
      id: 1,
      type: 'header',
      class: '',
      label: 'SORT BY',
      hasChildren: false,
    },
    {
      id: 2,
      type: 'item',
      class: 'cmnIcon moveTop',
      label: 'Newest on top',
      action: 'newToTop',
      isVisible: true,
      hasChildren: false,
    },
    {
      id: 3,
      type: 'item',
      class: 'cmnIcon moveBottom',
      label: 'Oldest on top',
      action: 'oldToTop',
      isVisible: true,
      hasChildren: false,
    },
    {
      id: 4,
      type: 'item',
      class: 'cmnIcon atTheRate',
      label: 'Mentions Me',
      action: 'mentionMe',
      isVisible: false,
      hasChildren: false,
    },
    {
      id: 5,
      type: 'item',
      class: 'cmnIcon attachment',
      label: 'Has attachments',
      action: 'hasAttachment',
      isVisible: false,
      hasChildren: false,
    },
    {
      id: 6,
      type: 'header',
      class: '',
      label: 'FILTER BY',
      hasChildren: false,
    },
    {
      id: 7,
      type: 'item',
      class: 'cmnIcon layersGray',
      label: 'Projects',
      action: 'selectProject',
      isVisible: true,
      hasChildren: true,
    },
    {
      id: 8,
      type: 'item',
      class: 'cmnIcon teamMember',
      label: 'Team Members',
      action: 'teamMember',
      isVisible: true,
      hasChildren: true,
    },
  ],
  lbl_messaging_section_project_member_header: 'PROJECT MEMBERS',
  lbl_messaging_section_text_preview: {
    file: 'A file has been added',
    image: 'An image has been added',
    richText: 'Some formatted text has been added',
    multiFile: 'Some files have been added',
    multiImage: 'Some images have been added',
    multiFileImage: 'Some files and images have been added',
  },
  lbl_messaging_section_deleted_task: 'This task no longer exists',
  lbl_messaging_section_logged_in_member_suffix: 'Me',
  lbl_time_difference: 'days ago',
  lbl_messaging_section_no_files_available: 'No files available',
  lbl_time_difference_new: {
    plural: 'days ago',
    singular: 'day ago',
  },
  lbl_messaging_section_filter_action: {
    cancel: 'Clear Filters',
    confirm: 'Apply',
  },
  lbl_no_message_text: 'No Messages',
  lbl_unknown_user: 'Unknown user',
  lbl_no_search_message_text: 'No search result found!',
  lbl_invalid_link: 'Please enter a valid link',
  lbl_messaging_section_deleted_story: 'This story no longer exists',
  lbl_file_download_error: 'Something went wrong while downloading the file!',
  lbl_removed_member: 'has been removed from this conversation',
};
