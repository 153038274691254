import { ITaskList } from "migration/models/interface";

interface TaskServiceProps {
  taskDetails?: ITaskList;
  setTaskListDetails?: React.Dispatch<React.SetStateAction<ITaskList>>;
}

export const DEFAULT_COLUMN_DATA_INDEX = [
  'task_status_id',
  'task_name',
  'due_date',
  'assignee_name',
];
const [status, task, dueDate, assignee] = DEFAULT_COLUMN_DATA_INDEX;
import { useTemplateStore } from 'modules/components/Pages/Template/store';

export const mappingDetails = (taskDetails: ITaskList) => {
  const { col_rank, task_rank, col_freeze, col_hide, col_width } =
    taskDetails.settings || {
      col_rank: [],
      task_rank: [],
      col_freeze: [],
      col_hide: [],
      col_width: [],
    };

  // Re-order row
  let rowOrder = taskDetails.section_details?.sort((a, b) => {
    const indexA = task_rank.indexOf(a.task_id.toString());
    const indexB = task_rank.indexOf(b.task_id.toString());
    return indexA - indexB;
  });

  rowOrder = rowOrder?.map((task) => {
    return {
      ...task,
    };
  });

  // Re-order cols
  let colOrder = taskDetails.custom_meta;

  return {
    ...taskDetails,
    section_details: rowOrder,
    custom_meta: colOrder,
  };
};

const useTableService = (props: TaskServiceProps) => {
  const { taskDetails } = props ?? {};
  const task_list_details = taskDetails?.section_details ?? [];
  const { sections, setSections } = useTemplateStore();

  const convertToSettings = (taskDetails: ITaskList) => {
    const task_list_details = taskDetails.section_details;
    const custom_fields = taskDetails.custom_meta;
    const defaultSettings = taskDetails.settings;
    let newColHide = [
      // ...defaultSettings?.col_hide,
      ...(custom_fields
        ?.filter((field) => field.hidden)
        ?.map((field) => field.mapping_id || field.key) ?? []),
    ];
    newColHide = newColHide.filter(
      (value, index) => newColHide.indexOf(value) === index && Boolean(value),
    );

    let settings = {
      filters: [],
      col_hide: newColHide,
      col_rank: custom_fields?.map((meta) => meta.key || meta.mapping_id) ?? [],
      col_width:
        custom_fields?.map(({ mapping_id, width }) => ({
          mapping_id: mapping_id ?? '',
          width: width ?? 0,
        })) ?? [],
      task_rank:
        task_list_details?.map((task) => task.task_id.toString()) ?? [],
      col_freeze:
        task_list_details?.filter((task) => task.fixed).length?.toString() ??
        '0',
    };

    return { ...taskDetails, settings };
  };

  const addCustomField = (option: any, data: string, sectionId: any) => {
    let newDetails = {};

    // setTaskListDetails((prev) => {
    //   const newTaskDetails = {
    //     ...prev,
    //     custom_meta: [
    //       ...(prev?.custom_meta ?? []),
    //       { ...option.default_meta, mapping_id: data },
    //     ],
    //   };
    //   newDetails = newTaskDetails;
    //   return newTaskDetails;
    // });

    setSections(
      sections.map((sections: any) => {
        if (sections.section_id === sectionId) {
          const newTaskDetails = {
            ...sections,
            custom_meta: [
              ...(sections?.custom_meta ?? []),
              { ...option.default_meta, mapping_id: data },
            ],
          };
          newDetails = newTaskDetails;
          return newTaskDetails;
        }
        return sections;
      }),
    );

    return newDetails;
  };

  const revertCustomField = (option: any, sectionId: any) => {
    // setTaskListDetails((prev) => {
    //   return {
    //     ...prev,
    //     custom_meta: (prev?.custom_meta ?? []).filter(
    //       (i) => i.id !== option.field_type_id,
    //     ),
    //   };
    // });

    setSections(
      sections.map((section: any) => {
        if (section.section_id === sectionId) {
          return {
            ...section,
            custom_meta: (section?.custom_meta ?? []).filter(
              (i: any) => i.id !== option.field_type_id,
            ),
          };
        }
        return section;
      }),
    );
  };

  const getDataSource = (task_list_details: any) => {
    if (!task_list_details) return [];

    return task_list_details.map((i: any) => {
      const metaValue: any = {};

      i.custom_data?.forEach((meta: any) => {
        metaValue[meta.mapping_id as string] = meta.value;
      });

      return { ...i, ...metaValue, key: i.task_id };
    });
  };

  const deleteCustomField = (
    mapping_id: string,
    updatedTaskList: any,
    sectionId: any,
  ) => {
    let newTaskDetails: any = {};
    // setTaskListDetails((prev: any) => {
    //   newTaskDetails = {
    //     ...prev,
    //     custom_meta: prev?.custom_meta?.filter(
    //       (i: any) => i.mapping_id !== mapping_id,
    //     ),
    //     task_list_details: updatedTaskList,
    //   };
    //   return newTaskDetails;
    // });

    setSections(
      sections.map((section: any) => {
        if (section.section_id === sectionId) {
          newTaskDetails = {
            ...section,
            custom_meta: section?.custom_meta?.filter(
              (i: any) => i.mapping_id !== mapping_id,
            ),
            section_details: updatedTaskList,
          };
          return newTaskDetails;
        }
        return section;
      }),
    );
    return newTaskDetails;
  };

  const changeCustomField = (updatedTaskList: any, sectionId: any) => {
    let newTaskDetails: any = {};
    // setTaskListDetails((prev: any) => {
    //   newTaskDetails = {
    //     ...prev,
    //     task_list_details: updatedTaskList,
    //   };
    //   return newTaskDetails;
    // });
    setSections(
      sections.map((section: any) => {
        if (section.section_id === sectionId) {
          newTaskDetails = {
            ...section,
            section_details: updatedTaskList,
          };
          return newTaskDetails;
        }
        return section;
      }),
    );
    return newTaskDetails;
  };

  const convertColumnsToCustomMeta = (columns: any) => {
    // columns = [...columns].filter((i: any) => i?.mapping_id);

    return columns.map((col: any) => {
      const {
        fixed,
        columnIndex,
        render,
        sorter,
        title,
        algin,
        dataIndex,
        ...rest
      } = col;

      return { ...rest };
    });
  };

  const convertDataSourceToRowRank = (dataSource: any) => {
    return dataSource.map((i: any) => i.task_id);
  };

  const removeCFAttributes = (cf: any) => {
    if (!cf) return;

    const { fixed, hidden, show, width, ...rest } = cf;

    return rest;
  };

  const updateCFData = (
    value: any,
    cfId: number,
    taskId: number,
    mappingId: string,
    taskDetailUpdated: ITaskList,
    sectionId: any,
  ) => {
    const newTaskListDetails = taskDetailUpdated.section_details?.map(
      (task) => {
        if (task.task_id !== taskId) return task;

        const customData = task.custom_data;

        // TO-DO: Check cfId and map value to the correct custom field

        if (!customData?.length) {
          task.custom_data = [];
          task.custom_data[0] = { mapping_id: mappingId, value };
          return task;
        } else {
          const findExistIndex = customData.findIndex(
            (cf: any) => cf.mapping_id === mappingId,
          );

          if (findExistIndex === -1) {
            customData.push({ mapping_id: mappingId, value });
            return { ...task, custom_data: customData };
          }

          customData[findExistIndex].value = value;

          customData[findExistIndex].editing = false;

          return { ...task, custom_data: customData };
        }
      },
    );
    let newTaskDetails: any = {};

    // setTaskListDetails((prev: any) => {
    //   newTaskDetails = {
    //     ...taskDetailUpdated,
    //     task_list_details: newTaskListDetails,
    //   };
    //   return newTaskDetails;
    // });

    setSections(
      sections.map((section: any) => {
        if (section.section_id === sectionId) {
          newTaskDetails = {
            ...taskDetailUpdated,
            section_details: newTaskListDetails,
          };
          return newTaskDetails;
        }
        return sections;
      }),
    );

    return newTaskDetails;
  };

  const handleToggleEdit = (
    cfId: number,
    taskId: number,
    mappingId: string,
    sectionId: any,
  ) => {
    const newTaskListDetails = task_list_details.map((task) => {
      if (task.task_id !== taskId) return task;

      const customData = task.custom_data;

      if (!customData?.length) {
        task.custom_data = [];
        task.custom_data[0] = {
          mapping_id: mappingId,
          value: '',
          editing: true,
        };
        return task;
      } else {
        const findExistIndex = customData.findIndex(
          (cf: any) => cf.mapping_id === mappingId,
        );

        if (findExistIndex === -1) {
          customData.push({ mapping_id: mappingId, value: '', editing: true });
          return { ...task, custom_data: customData };
        }

        customData[findExistIndex].editing =
          !customData[findExistIndex].editing;

        return { ...task, custom_data: customData };
      }
    });

    // setTaskListDetails((prev) => {
    //   return {
    //     ...prev,
    //     task_list_details: newTaskListDetails,
    //   };
    // });

    setSections(
      sections.map((section: any) => {
        if (section.section_id === sectionId) {
          return {
            ...section,
            section_details: newTaskListDetails,
          };
        }
      }),
    );
  };

  return {
    mappingDetails,
    convertToSettings,
    revertCustomField,
    addCustomField,
    getDataSource,
    deleteCustomField,
    convertColumnsToCustomMeta,
    convertDataSourceToRowRank,
    removeCFAttributes,
    updateCFData,
    handleToggleEdit,
    changeCustomField,
  };
};

export default useTableService;

var CUSTOM_FIELD_DATA: ITaskList = {
  section_id: 18,
  section_type_id: 1,
  section_name: "POC",
  section_rank: 4,
  hide_completed_tasks: false,
  show_own_tasks: false,
  sort_column: "due_date",
  sort_order: "asc",
  created_by: "213",
  is_private: false,
  custom_meta: [
    {
      id: 2,
      name: "Integer",
      type: "Integer",
      format: "",
      preview: "1,000",
      sortable: true,
      mapping_id: "18_0",
      width: 150,
      description: "number",
    },
    {
      id: 2,
      name: "Numeric",
      type: "numeric",
      format: "",
      preview: "1,000.15",
      sortable: true,
      mapping_id: "18_1",
      width: 150,
      description: "number",
    },
    {
      id: 2,
      name: "Percent",
      type: "percentage",
      format: "",
      preview: "10.15%",
      sortable: true,
      mapping_id: "18_2",
      width: 150,
      description: "number",
    },
    {
      id: 2,
      name: "Currency",
      type: "currency",
      format: "$",
      preview: "$10.15",
      sortable: true,
      mapping_id: "18_3",
      width: 150,
      description: "number",
    },
    {
      id: 3,
      name: "File",
      sortable: false,
      mapping_id: "18_4",
      width: 150,
      description: "file",
    },
    {
      id: 1,
      name: "Status",
      options: [
        {
          color: "#1FC778",
          value: "Done",
          show: true,
        },
        {
          color: "#FBAA49",
          value: "Working on it",
          show: true,
        },
        {
          color: "#E0465E",
          value: "Stuck",
          show: true,
        },
        {
          color: "#C4C4C4",
          value: "Not Started",
          show: true,
        },
      ],
      sortable: true,
      mapping_id: "18_5",
      width: 150,
      description: "status",
    },
  ],
  task_list_details: [
    {
      task_id: 57,
      due_date: null,
      task_name: "1",
      task_rank: 1,
      created_by: "213",
      assignee_dp: undefined,
      assignee_id: null,
      assigner_id: null,
      assignee_name: undefined,
      assignee_email: undefined,
      task_status_id: 1,
      task_priority_id: 2,
      task_status_name: "To Do",
      task_priority_name: "Normal",
      custom_data: [
        {
          mapping_id: "18_5",
          value: "Done",
        },
        {
          mapping_id: "18_0",
          value: "12",
        },
        {
          mapping_id: "18_1",
          value: "12",
        },
        {
          mapping_id: "18_2",
          value: "12",
        },
        {
          mapping_id: "18_3",
          value: "12",
        },
        {
          mapping_id: "18_4",
          value: {
            file_name: "",
          },
        },
      ],
      show: true,
    },
    {
      task_id: 58,
      due_date: null,
      task_name: "1",
      task_rank: 1,
      created_by: "213",
      assignee_dp: undefined,
      assignee_id: null,
      assigner_id: null,
      assignee_name: undefined,
      assignee_email: undefined,
      task_status_id: 1,
      task_priority_id: 2,
      task_status_name: "To Do",
      task_priority_name: "Normal",
      custom_data: [
        {
          mapping_id: "18_5",
          value: "Done",
        },
        {
          mapping_id: "18_0",
          value: "12",
        },
        {
          mapping_id: "18_1",
          value: "12",
        },
        {
          mapping_id: "18_2",
          value: "12",
        },
        {
          mapping_id: "18_3",
          value: "12",
        },
        {
          mapping_id: "18_4",
          value: {
            file_name: "",
          },
        },
      ],
    },
    {
      task_id: 59,
      due_date: null,
      task_name: "1",
      task_rank: 1,
      created_by: "213",
      assignee_dp: undefined,
      assignee_id: null,
      assigner_id: null,
      assignee_name: undefined,
      assignee_email: undefined,
      task_status_id: 1,
      task_priority_id: 2,
      task_status_name: "To Do",
      task_priority_name: "Normal",
      custom_data: [
        {
          mapping_id: "18_5",
          value: "Done",
        },
        {
          mapping_id: "18_0",
          value: "12",
        },
        {
          mapping_id: "18_1",
          value: "12",
        },
        {
          mapping_id: "18_2",
          value: "12",
        },
        {
          mapping_id: "18_3",
          value: "12",
        },
        {
          mapping_id: "18_4",
          value: {
            file_name: "",
          },
        },
      ],
    },
  ],
  settings: {
    filters: [],
    col_hide: [],
    col_rank: [
      status,
      task,
      dueDate,
      assignee,
      "18_0",
      "18_1",
      "18_2",
      "18_3",
      "18_4",
      "18_5",
    ],
    col_width: [
      {
        mapping_id: "1",
        width: 400,
      },
      {
        mapping_id: "39_0",
        width: 215,
      },
      {
        mapping_id: "39_3",
        width: 177,
      },
    ],
    task_rank: ["59", "58", "57"],
    col_freeze: "0",
  },
  status_done_id: 1,
  pack_id: 1,
  task_list_view: "list",
};
