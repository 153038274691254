import {
  IGridList,
  IProjectData,
  IUserDetails,
} from 'migration/models/interface';
import templateService from 'modules/services/TemplateService/Template.service';
import { getCurrentOrganization, groupGridBlock } from 'utils/commonFunctions';
import { message } from 'antd';
import { DataType } from '../Table/Table';
import { useTemplateStore } from 'modules/components/Pages/Template/store';

interface GridService {
  org_key: string;
  userDetails: IUserDetails;
  projectDetails: IProjectData | null;
  sectionDetails: IGridList;
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
}

const useGridService = (props: GridService | any) => {
  const { sectionDetails } = props;

  const { templateDetails, setSections, sections } = useTemplateStore();

  const customFieldSettingsAPI = async (
    columns?: DataType[] | any,
    dataSource?: any[],
  ) => {
    try {
      const payload = {
        templateId: templateDetails.template_id,
        sectionId: sectionDetails.section_id,
        settings: {
          columnSettings: columns
            ?.map((i: any) => ({
              mapping_id: i.dataIndex,
              width: i?.width,
              hidden: false,
            }))
            .filter((i: any) => i.mapping_id !== 'task'),
          taskRank: dataSource?.map((i) => i.key),
        },
      };

      return await templateService.reOrderGrid(payload);
    } catch (err) {
      console.error(err);
    }
  };

  const reOrderTaskStageAPI: any = async (
    columns?: DataType[],
    dataSource?: any[],
  ) => {
    try {
      return await customFieldSettingsAPI(columns, dataSource);
    } catch (err) {
      console.error(err);
    }
  };

  const addEditCustomField = async ({
    customMeta,
    deleteOptions,
  }: {
    customMeta: any;
    deleteOptions: any;
  }) => {
    try {
      const payload = {
        templateId: templateDetails.template_id,
        sectionId: sectionDetails.section_id,
        sectionTypeId: sectionDetails.section_type_id,
        customItem: customMeta,
        deleteOptions,
      };

      const response = await templateService.addEditCustomMeta(payload);
      if (response.messageId === 1) {
        return response.data;
      }
      if (response?.messageId === -4) {
        const error = new Error(response?.message);
        (error as any).messageId = response?.messageId;
        throw error;
      }
    } catch (error: any) {
      if (error.messageId === -4) {
        console.log(error);
      }
      message.error('Failed to add custom field', 3);
    }
  };

  const updateSettings = async (gridList: any) => {
    try {
      const { message, messageId } =
        await templateService.updateCustomFieldSettingV2({
          templateId: templateDetails.template_id,
          sectionId: sectionDetails?.section_id,
          settings: {
            columnSettings: gridList?.custom_meta?.filter(
              (ele: any) => ele.key !== 'action',
            ),
            taskRank: gridList?.grid_list_details?.task_details.map(
              (ele: any) => ele.task_id,
            ),
          },
        });
      if (messageId !== 1) throw Error(message);
    } catch (error: any) {
      if (error?.response?.data?.messageId === -4) {
        return error?.response?.data;
      }
      message.error(
        error?.message ?? 'Failed to update custom field settings',
        3,
      );
    }
  };

  const updateGridCustomFieldData = async (
    taskName: string,
    taskId: number,
    customData: any,
  ) => {
    try {
      await templateService.updateGridTask({
        template_id: templateDetails?.template_id,
        section_id: sectionDetails.section_id,
        task_id: taskId,
        task_name: taskName.trim(),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteCustomFieldColumnAPI = async (mapping_id: string) => {
    try {
      const { data, message, messageId } =
        await templateService.deleteCustomMeta({
          templateId: templateDetails?.template_id,
          sectionId: sectionDetails.section_id,
          mappingId: mapping_id,
        });

      if (messageId !== 1) throw Error(message);
      return data;
    } catch (error: any) {
      message.error(error?.message ?? 'Failed to delete custom field', 3);
    }
  };

  return {
    deleteCustomFieldColumnAPI,
    reOrderTaskStageAPI,
    addEditCustomField,
    updateGridCustomFieldData,
    updateSettings,
  };
};

export default useGridService;
