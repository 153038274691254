import React, { Fragment } from "react";

const MainContent: React.FC<{}> = () => {

    return (
        <Fragment>
            <div className="contentHeadingPanel">
                <h2>Dashboard</h2>
            </div>
        </Fragment>
    )

}

export default MainContent;
