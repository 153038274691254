import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { IGlobalState } from 'migration/models/interface';
import { ILocalizationMasterData } from 'migration/models/interface/localization.interface';
import { create } from 'zustand';

export const useGlobalStore = create<IGlobalState>((set) => ({
    //initial states
    language: 'en',
    country: 'IN',
    theme: 1,
    antLocale: undefined,
    localization: null,

    //actions
    setLanguage: (value: string) => set({ language: value }),
    setCountry: (value: string) => set({ country: value }),
    setTheme: (value: number) => set({ theme: value }),
    setAntLocale: (value: PickerLocale) => set({ antLocale: value }),
    setLocalization: (value: ILocalizationMasterData) => set({ localization: value })
}))