import { StickerPacksData, StickerPacksTable, Team, UploadedStickers } from 'modules/models/interfaces';
import { create } from 'zustand';


export interface Stickers {
  id: number;
  dataURL: string;
  status: string;
}

interface AppState {
  isDrawer: boolean;
  setIsDrawer: (isDrawer: boolean) => void;
  tloading: boolean;
  setTLoading: (tloading: boolean) => void;
  stickers: (Stickers | UploadedStickers)[];
  setStickers: (sticker: (Stickers | UploadedStickers)[]) => void;
  selectedImages: (File | UploadedStickers)[];
  setSelectedImages: (selectedImages: (File | UploadedStickers)[]) => void;
  stickerPack: StickerPacksData;
  setStickerPack: (stickerPack: StickerPacksData) => void;
  taskStickersTable: StickerPacksTable[];
  setTaskStickersTable: (taskStickersTable: StickerPacksTable[]) => void;
  bTaskStickerTable: StickerPacksTable[];
  setBTaskStickerTable: (bTaskStickerTable: StickerPacksTable[]) => void;
  languages: string[];
  setLanguage: (languages: string[]) => void;
  teams: Team[];
  setTeams: (teams: Team[]) => void;
}

export const useStore = create<AppState>((set) => ({
  isDrawer: false,
  setIsDrawer: (isDrawer) => set({ isDrawer }),

  tloading: false,
  setTLoading: (tloading) => set({ tloading }),

  stickers: [{
    id: 0,
    dataURL: '',
    status: ''
  }],
  setStickers: (stickers) => set({ stickers }),

  selectedImages: [],
  setSelectedImages: ((selectedImages) => set({ selectedImages })),

  stickerPack: {
    teams: [],
    languages: [],
    pack_id: 0,
    is_active: false,
    pack_name: '',
    created_on: '',
    is_default: false,
    updated_on: '',
    bucket_path_suffix: '',
    pack_configuration: [],
    part_of_all_organization: false,
  },
  setStickerPack: (stickerPack) => set({ stickerPack }),

  taskStickersTable: [],
  setTaskStickersTable: (taskStickersTable) => set({ taskStickersTable }),

  bTaskStickerTable: [],
  setBTaskStickerTable: (bTaskStickerTable) => set({ bTaskStickerTable }),

  languages: [],
  setLanguage: ((languages) => set({ languages })),

  teams: [{
    id: 0,
    name: '',
    key: ''
  }],
  setTeams: ((teams) => set({ teams }))


}));

export { };

