import { ITaskListDetails } from 'migration/models/interface';

const TaskDueDate2: React.FC<{
  eachTaskDetail?: ITaskListDetails | any;
}> = ({ eachTaskDetail }) => {
  const getDueDateJsx = () => {
    if (!eachTaskDetail?.due_date) {
      return (
        <div className="textMuted cursorDisabled" onKeyDown={() => {}}>
          None
        </div>
      );
    }
  };

  return <div>{getDueDateJsx()}</div>;
};

export default TaskDueDate2;
