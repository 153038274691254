import { create } from 'zustand';
import { IUserDetails, IUserState, IUserDetail} from '../models/interfaces';

export const useUserStore = create<IUserState>((set) => ({
    //initial states
    userDetails: null,
    userDetail: null,
    permissions: [],
    
    //actions
    setUserDetails: (value: IUserDetails | null, permissions: string[]) => set({ userDetails: value, permissions: permissions }),
    // setUserDetails: (value: IUserDetails | null) => set({ userDetails: value}),
    setUserDetail: (value: IUserDetail | null) => set({ userDetail: value }),
}))