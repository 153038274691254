import { CloseOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';

import {
  AttachmentIcon,
  NumberIcon,
  PriorityIcon,
} from 'modules/sharedComponents/CustomAppIcons';

import {
  CustomField,
  CustomFieldCategory,
} from 'migration/models/interface/customField.interface';
import React, { useState } from 'react';

interface AddCustomFieldProps {
  options: CustomFieldCategory[];
  onClick: (option: CustomField) => void;
  customfieldCmsData?: any;
}

const getIcon = (fieldId: number) => {
  switch (fieldId) {
    case 1:
      return <PriorityIcon />;
    case 2:
      return <NumberIcon />;
    case 3:
      return <AttachmentIcon />;
    default:
      return null;
  }
};

const AddCustomField: React.FC<AddCustomFieldProps> = ({
  options,
  onClick,
  customfieldCmsData,
}) => {
  const [visible, setVisible] = useState(false);
  const handleOptionClick = (option: CustomField) => {
    let selectedOption = { ...option };
    if (option.field_type_id === 2) {
      selectedOption.default_meta = {
        ...(option?.default_meta ?? {}),
      };
    }
    onClick(selectedOption);
    setVisible(false);
  };

  const handleClose = () => {
    setVisible(false);
  };
  const getLocalizedCategoryName = (option: CustomFieldCategory) => {
    const foundIndex =
      customfieldCmsData?.cf_tooltip_modal_options.findIndex(
        (item: any) => item.category_id === option.category_id,
      ) ?? -1;
    if (foundIndex !== -1) {
      const item = customfieldCmsData?.cf_tooltip_modal_options[foundIndex];
      return item?.category_name;
    }
    return option?.category_name;
  };
  const content = (
    <>
      {options.map((option) => {
        return (
          <div key={Math.random().toString()}>
            <div className="innerHeading">
              {getLocalizedCategoryName(option)}
            </div>
            <div className="addColRow">
              {option.details.map((field, index) => {
                const fieldId = field?.field_type_id || 1;

                return (
                  <div className="addCol" key={index}>
                    <Tooltip title={field?.default_meta?.description}>
                      <Button
                        onClick={() => handleOptionClick(field)}
                        icon={getIcon(fieldId)}
                      >
                        {field.field_type_name}
                      </Button>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );

  return (
    <Popover
      content={content}
      title={
        <>
          {customfieldCmsData?.add_column_tooltip}
          <Button
            type="text"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={handleClose}
            style={{ float: 'right' }}
          />
        </>
      }
      trigger="click"
      open={visible}
      onOpenChange={setVisible}
      overlayClassName="add-custom-field-popover"
      placement="topLeft"
    >
      <Tooltip
        title={customfieldCmsData?.add_column_tooltip}
        placement={'left'}
        color={'#2E364C'}
      >
        <span
          className="addPlusSolid"
          onKeyDown={() => {}}
          onClick={() => setVisible(true)}
        ></span>
      </Tooltip>
    </Popover>
  );
};

export default AddCustomField;
