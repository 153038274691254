import { Fragment } from 'react';
import { Tooltip } from 'antd';
import { gridListCmsData } from 'migration/GridListV4/GridListCmsDatas/GridListCmsData';

const BlockDueDate = () => {
  const getDueDateJsx = () => {
    return (
      <Fragment>
        {
          <Tooltip
            title={gridListCmsData?.lbl_set_due_date_tooltip}
            color={'#2E364C'}
            placement="right"
          >
            <div className="blkDate blkNoDate cursorDisabled">
              <div className="dateMonth cursorDisabled"></div>
              <div className="dateDay cursorDisabled"></div>
            </div>
          </Tooltip>
        }
      </Fragment>
    );
  };

  return <Fragment>{getDueDateJsx()}</Fragment>;
};

export default BlockDueDate;
