import React, { useState } from 'react';

import AddTask from 'migration/AddTask';
// import AddTask from 'modules/components/Pages/Template/Configuration/AddTask';
import {
  ICustomFieldState,
  IProjectState,
  ITaskList,
} from 'migration/models/interface';
import { useProjectDashboardStore } from 'modules/store/project.store';
import Table from './TableSection/Table';

enum TaskColumnSortIds {
  TASK_STATUS = 'task_status_id',
  TASK_PRIORITY = 'task_priority_id',
  TASK_DUE_DATE = 'due_date',
  TASK_NAME = 'task_name',
  TASK_ASSIGNEE = 'assignee_name',
  TASK_UPDATED_ON = 'updated_on',
}

enum TaskColumnSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

const TaskListDetails2: React.FC<{
  taskDetails?: any;
  setTaskListDetails: React.Dispatch<React.SetStateAction<ITaskList | any>>;
  sectionToggleState: 'collapsed' | 'expanded';
  isTaskEmpty?: boolean;
  setIsTaskEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  section?: any;
  sectionId?: any;
}> = ({
  taskDetails,
  setTaskListDetails,
  sectionToggleState,
  isTaskEmpty,
  setIsTaskEmpty,
  section,
  sectionId,
}) => {
  return sectionToggleState !== 'collapsed' ? (
    <div className={` `}>
      {taskDetails.section_name !== '' &&
        taskDetails.section_details !== null && (
          <Table
            isTaskEmpty={isTaskEmpty}
            setIsTaskEmpty={setIsTaskEmpty}
            taskDetails={taskDetails}
            sectionToggleState={sectionToggleState}
            setTaskListDetails={setTaskListDetails}
            section={section}
          />
        )}

      {sectionToggleState === 'expanded' || !sectionToggleState ? (
        <AddTask
          sectionId={sectionId}
          taskDetails={taskDetails}
          // setTaskListDetails={setTaskListDetails}
        />
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};

export default TaskListDetails2;
