import React, { FC } from 'react';
import { Tooltip, message } from 'antd';

import dayjs from 'dayjs';
import { getCurrentOrganization } from 'utils/commonFunctions';
import {
  IPackConfiguration,
  IPackDetail,
  IProjectState,
  ITaskStatus,
} from 'migration/models/interface';
import projectService from 'modules/services/ProjectService/project.service';
import { useProjectDashboardStore } from 'modules/store/project.store';
import { useTaskStatusStore } from 'modules/store/taskStatus.store';
import { useUserStore } from 'modules/store';
import { IUserState } from 'modules/models/interfaces';

interface ITeamMember {
  email: string;
  role_id: number;
  user_id: number;
  full_name: string;
  should_delete: boolean;
  profile_picture: string;
}

export interface IEachTaskDetail {
  org_id: number;
  org_key: string;
  task_id: number;
  due_date: string;
  org_logo: string;
  org_color: string;
  task_name: string;
  team_name: string;
  created_by: string;
  project_id: number;
  section_id: number;
  stage_name: string | null;
  assignee_id: number;
  assigner_id: number;
  team_member: ITeamMember[];
  project_name: string;
  section_name: string;
  assignee_name: string;
  assigner_name: string;
  task_status_id: number;
  section_type_id: number;
  task_priority_id: number;
  task_rank?: number;
  block_id?: number;
}

interface IStatusChange {
  eachTaskDetail: IEachTaskDetail;
  packId?: number;
  setEachTaskDetail?: any;
  packList?: any;
  project_role_id?: number | undefined;
  index: number;
  taskListDetails?: any;
  setTaskListDetails?: React.Dispatch<React.SetStateAction<IEachTaskDetail[]>>;
  // socket?: any;
  findTaskIdAndUpdateMainTaskList?: any;
  apiVersion?: 'v1' | 'v2';
}

const StatusChange: FC<IStatusChange> = ({ eachTaskDetail, packId }) => {
  const packList = [
    {
      pack_id: 1,
      pack_name: 'Default',
      pack_configuration: [
        {
          sort_value: 2,
          status_key: 1,
          status_label: 'To Do',
          status_value: 'sticker_pack/default/sticker-default-todo.svg',
        },
      ],
    },
  ];

  const getStatusImage = (status: number | undefined, packId: number = 1) => {
    if (packList) {
      let pack = packList?.filter(
        (ele: IPackDetail) => ele?.pack_id === packId,
      );
      if (status && pack?.length) {
        return pack[0].pack_configuration.find(
          (ele: IPackConfiguration) =>
            ele?.status_key === eachTaskDetail?.task_status_id,
        )!;
      } else {
        return { status_value: '', status_label: '' };
      }
    }
  };

  const getTaskStatusJsx = () => {
    return (
      <Tooltip
        title={
          getStatusImage(eachTaskDetail?.task_status_id!, packId)?.status_label
        }
        placement="bottom"
        color={'#2E364C'}
      >
        <img
          src={`${process.env.REACT_APP_BUCKET_BASE_PATH}${
            getStatusImage(eachTaskDetail?.task_status_id!, packId)
              ?.status_value
          }`}
          alt={
            getStatusImage(eachTaskDetail?.task_status_id!, packId)
              ?.status_label
          }
        />
      </Tooltip>
    );
  };
  return getTaskStatusJsx();
};

export default StatusChange;
