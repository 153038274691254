export const DEMO_CF_DATA = {
  section_id: 18,
  section_type_id: 1,
  section_name: "POC",
  section_rank: 4,
  hide_completed_tasks: false,
  show_own_tasks: false,
  sort_column: "due_date",
  sort_order: "asc",
  created_by: "213",
  is_private: false,

  custom_meta: [
    {
      id: 2,
      name: "Integer",
      type: "Integer",
      format: "",
      preview: "1,000",
      sortable: true,
      mapping_id: "18_0",
      description: "number",
    },
    {
      id: 2,
      name: "Numeric",
      type: "numeric",
      format: "",
      preview: "1,000.15",
      sortable: true,
      mapping_id: "18_1",
      description: "number",
    },
    {
      id: 2,
      name: "Percent",
      type: "percentage",
      format: "",
      preview: "10.15%",
      sortable: true,
      mapping_id: "18_2",
      description: "number",
    },
    {
      id: 2,
      name: "Currency",
      type: "currency",
      format: "$",
      preview: "$10.15",
      sortable: true,
      mapping_id: "18_3",
      description: "number",
    },
    {
      id: 3,
      name: "File",
      sortable: false,
      mapping_id: "18_4",
      description: "file",
    },
    {
      id: 1,
      name: "Status",
      options: [
        {
          color: "#1FC778",
          value: "Done",
        },
        {
          color: "#FBAA49",
          value: "Working on it",
        },
        {
          color: "#E0465E",
          value: "Stuck",
        },
        {
          color: "#C4C4C4",
          value: "Not Started",
        },
      ],
      sortable: true,
      mapping_id: "18_5",
      description: "status",
    },
    {
      id: 2,
      name: "New Number",
      type: "Integer",
      format: "",
      preview: "1,000",
      sortable: true,
      mapping_id: "18_6",
      description: "",
    },
    {
      id: 1,
      name: "status",
      options: [
        {
          color: "#1FC778",
          value: "Done",
        },
        {
          color: "#FBAA49",
          value: "Working on it",
        },
        {
          color: "#E0465E",
          value: "Stuck",
        },
        {
          color: "#C4C4C4",
          value: "Not Started",
        },
      ],
      sortable: true,
      mapping_id: "18_7",
      description: "",
    },
    {
      id: 1,
      name: "status",
      options: [
        {
          color: "#1FC778",
          value: "Done",
        },
        {
          color: "#FBAA49",
          value: "Working on it",
        },
        {
          color: "#E0465E",
          value: "Stuck",
        },
        {
          color: "#C4C4C4",
          value: "Not Started",
        },
      ],
      sortable: true,
      mapping_id: "18_8",
      description: "",
    },
    {
      id: 1,
      name: "status",
      options: [
        {
          color: "#1FC778",
          value: "Done",
        },
        {
          color: "#FBAA49",
          value: "Working on it",
        },
        {
          color: "#E0465E",
          value: "Stuck",
        },
        {
          color: "#C4C4C4",
          value: "Not Started",
        },
      ],
      sortable: true,
      mapping_id: "18_9",
      description: "",
    },
    {
      id: 1,
      name: "status",
      options: [
        {
          color: "#1FC778",
          value: "Done",
        },
        {
          color: "#FBAA49",
          value: "Working on it",
        },
        {
          color: "#E0465E",
          value: "Stuck",
        },
        {
          color: "#C4C4C4",
          value: "Not Started",
        },
      ],
      sortable: true,
      mapping_id: "18_10",
      description: "",
    },
  ],
  task_list_details: [
    {
      task_id: 57,
      due_date: null,
      task_name: "1",
      task_rank: 1,
      created_by: "213",
      assignee_dp: null,
      assignee_id: null,
      assigner_id: null,
      custom_data: [
        {
          value: "Stuck",
          mapping_id: "18_5",
        },
        {
          value: "13",
          mapping_id: "18_0",
        },
        {
          value: "12",
          mapping_id: "18_1",
        },
        {
          value: "15",
          mapping_id: "18_2",
        },
        {
          value: "12",
          mapping_id: "18_3",
        },
        {
          value: null,
          mapping_id: "18_4",
        },
      ],
      assignee_name: null,
      assignee_email: null,
      task_status_id: 3,
      task_priority_id: 2,
      task_status_name: "Done",
      task_priority_name: "Normal",
    },
    {
      task_id: 59,
      due_date: null,
      task_name: "3",
      task_rank: 3,
      created_by: "213",
      assignee_dp: null,
      assignee_id: null,
      assigner_id: null,
      custom_data: [
        {
          value: "Stuck",
          mapping_id: "18_5",
        },
        {
          value: "13",
          mapping_id: "18_0",
        },
        {
          value: "12",
          mapping_id: "18_1",
        },
        {
          value: "15",
          mapping_id: "18_2",
        },
        {
          value: "12",
          mapping_id: "18_3",
        },
        {
          value: null,
          mapping_id: "18_4",
        },
      ],
      assignee_name: null,
      assignee_email: null,
      task_status_id: 1,
      task_priority_id: 2,
      task_status_name: "To Do",
      task_priority_name: "Normal",
    },
    {
      task_id: 58,
      due_date: null,
      task_name: "2",
      task_rank: 2,
      created_by: "213",
      assignee_dp: null,
      assignee_id: null,
      assigner_id: null,
      custom_data: [
        {
          value: "Stuck",
          mapping_id: "18_5",
        },
        {
          value: "13",
          mapping_id: "18_0",
        },
        {
          value: "12",
          mapping_id: "18_1",
        },
        {
          value: "15",
          mapping_id: "18_2",
        },
        {
          value: "12",
          mapping_id: "18_3",
        },
        {
          value: null,
          mapping_id: "18_4",
        },
      ],
      assignee_name: null,
      assignee_email: null,
      task_status_id: 1,
      task_priority_id: 2,
      task_status_name: "To Do",
      task_priority_name: "Normal",
    },
  ],
  settings: {
    filters: [],
    col_hide: ["4", "33_1"],
    col_rank: ["57", "59", "58", "4", "33_0", "33_1", "33_2"],
    task_rank: ["87", "88"],
    col_freeze: "3",
  },
  status_done_id: 1,
  pack_id: 1,
  task_list_view: "list",
};
export const CUSTOM_FIELD_DATA: any = {
  section_id: 18,
  section_type_id: 1,
  section_name: "POC",
  section_rank: 4,
  hide_completed_tasks: false,
  show_own_tasks: false,
  sort_column: "due_date",
  sort_order: "asc",
  created_by: "213",
  is_private: false,
  custom_meta: [
    {
      id: 2,
      name: "Integer",
      type: "Integer",
      format: "",
      preview: "1,000",
      sortable: true,
      mapping_id: "18_0",
      width: 150,
      description: "number",
    },
    {
      id: 2,
      name: "Numeric",
      type: "numeric",
      format: "",
      preview: "1,000.15",
      sortable: true,
      mapping_id: "18_1",
      width: 150,
      description: "number",
    },
    {
      id: 2,
      name: "Percent",
      type: "percentage",
      format: "",
      preview: "10.15%",
      sortable: true,
      mapping_id: "18_2",
      width: 150,
      description: "number",
    },
    {
      id: 2,
      name: "Currency",
      type: "currency",
      format: "$",
      preview: "$10.15",
      sortable: true,
      mapping_id: "18_3",
      width: 150,
      description: "number",
    },
    {
      id: 3,
      name: "File",
      sortable: false,
      mapping_id: "18_4",
      width: 150,
      description: "file",
    },
    {
      id: 1,
      name: "Status",
      options: [
        {
          color: "#1FC778",
          value: "Done",
          show: true,
        },
        {
          color: "#FBAA49",
          value: "Working on it",
          show: true,
        },
        {
          color: "#E0465E",
          value: "Stuck",
          show: true,
        },
        {
          color: "#C4C4C4",
          value: "Not Started",
          show: true,
        },
      ],
      sortable: true,
      mapping_id: "18_5",
      width: 150,
      description: "status",
    },
  ],
  task_list_details: [
    {
      task_id: 57,
      due_date: null,
      task_name: "1",
      task_rank: 1,
      created_by: "213",
      assignee_dp: null,
      assignee_id: null,
      assigner_id: null,
      assignee_name: null,
      assignee_email: null,
      task_status_id: 1,
      task_priority_id: 2,
      task_status_name: "To Do",
      task_priority_name: "Normal",
      custom_data: [
        {
          mapping_id: "18_5",
          value: "Done",
        },
        {
          mapping_id: "18_0",
          value: "12",
        },
        {
          mapping_id: "18_1",
          value: "12",
        },
        {
          mapping_id: "18_2",
          value: "12",
        },
        {
          mapping_id: "18_3",
          value: "12",
        },
        {
          mapping_id: "18_4",
          value: {
            file_name: "",
          },
        },
      ],
      show: true,
    },
    {
      task_id: 58,
      due_date: null,
      task_name: "1",
      task_rank: 1,
      created_by: "213",
      assignee_dp: null,
      assignee_id: null,
      assigner_id: null,
      assignee_name: null,
      assignee_email: null,
      task_status_id: 1,
      task_priority_id: 2,
      task_status_name: "To Do",
      task_priority_name: "Normal",
      custom_data: [
        {
          mapping_id: "18_5",
          value: "Done",
        },
        {
          mapping_id: "18_0",
          value: "12",
        },
        {
          mapping_id: "18_1",
          value: "12",
        },
        {
          mapping_id: "18_2",
          value: "12",
        },
        {
          mapping_id: "18_3",
          value: "12",
        },
        {
          mapping_id: "18_4",
          value: {
            file_name: "",
          },
        },
      ],
    },
    {
      task_id: 59,
      due_date: null,
      task_name: "1",
      task_rank: 1,
      created_by: "213",
      assignee_dp: null,
      assignee_id: null,
      assigner_id: null,
      assignee_name: null,
      assignee_email: null,
      task_status_id: 1,
      task_priority_id: 2,
      task_status_name: "To Do",
      task_priority_name: "Normal",
      custom_data: [
        {
          mapping_id: "18_5",
          value: "Done",
        },
        {
          mapping_id: "18_0",
          value: "12",
        },
        {
          mapping_id: "18_1",
          value: "12",
        },
        {
          mapping_id: "18_2",
          value: "12",
        },
        {
          mapping_id: "18_3",
          value: "12",
        },
        {
          mapping_id: "18_4",
          value: {
            file_name: "",
          },
        },
      ],
    },
  ],
  settings: {
    filters: [],
    col_hide: [],
    col_rank: ["task_status_id", "task_name", "due_date", "assignee_name", "18_1", "18_2", "18_3", "18_4", "18_5"],
    task_rank: ["57", "58", "59"],
    col_freeze: "3",
  },
  status_done_id: 1,
  pack_id: 1,
  task_list_view: "list",
};

export const newCustomFieldColumnWidth = 150;
