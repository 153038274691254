export const gridListCmsData = {
  lbl_task_header: 'TASKS',
  lbl_stage_header: 'STAGES',
  cta_add_task: 'Add Task',
  cta_add_stage: 'Add Stage',
  lbl_add_task_tooltip: 'Add new task',
  lbl_add_stage_tooltip: 'Add new stage',
  lbl_add_task_placeholder: 'Task Name',
  lbl_add_stage_placeholder: 'Stage name',
  lbl_set_assignee_tooltip: 'Set assignee',
  lbl_set_due_date_tooltip: 'Set due date',
  createdAt: '2023-07-18T08:30:11.121Z',
  updatedAt: '2024-07-29T09:21:46.342Z',
  publishedAt: '2023-07-18T08:32:00.451Z',
  locale: 'en',
  lbl_delete_block_tooltip: 'Delete block',
  lbl_set_due_date_placeholder: 'Set Date',
  lbl_delete_task_modal_header: 'Delete Task',
  lbl_delete_task_modal_content1: 'Are you sure you want to delete this task',
  lbl_delete_task_modal_content2:
    'This will delete the entire row of task cells. ',
  cta_delete_task_modal_confirm: 'Delete Task',
  cta_delete_modal_cancel: 'Cancel',
  lbl_delete_stage_modal_header: 'Delete Stage',
  lbl_delete_stage_modal_content1: 'Are you sure you want to delete this stage',
  lbl_delete_stage_modal_content2:
    'This will delete the entire column of stage cells.',
  cta_delete_stage_modal_confirm: 'Delete Stage',
  page_preview: '?page=grid-lists&lang=en',
  lbl_generic_error_message: 'Something went wrong!',
  lbl_section_delete_warning_1: 'Are you sure you want to delete the widget',
  lbl_section_delete_warning_2: 'This action cannot be undone.',
  lbl_delete_section: 'Delete Widget',
  cta_toggle_view: 'Toggle view',
  lbl_menu_toggle_view_show_profile: 'Show Profile',
  lbl_menu_toggle_view_show_date: 'Show Date',
  lbl_menu_toggle_view_show_sticker: 'Show Stickers',
  lbl_menu_status_todo: 'To Do',
  lbl_menu_status_done: 'Done',
  lbl_menu_status_blocked: 'Blocked',
  lbl_menu_toggle_view_show_initials: 'Show Initials',
  lbl_search_project_member: 'Search project member',
  lbl_error_message_permission_denied:
    'Project permissions may have changed. Refreshing the page',
};
