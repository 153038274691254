import { StickersPacksData, StickersPacksTable, Team, UploadStickersconfig } from 'modules/models/interfaces';
import { create } from 'zustand';


export interface Stickers {
  id: number;
  dataURL: string;
  is_dafault: boolean;
}

interface AppState {
  isDrawer: boolean;
  setIsDrawer: (isDrawer: boolean) => void;
  tloading: boolean;
  setTLoading: (tloading: boolean) => void;
  stickers: (Stickers | UploadStickersconfig)[];
  setStickers: (sticker: (Stickers | UploadStickersconfig)[]) => void;
  selectedImages: (File | UploadStickersconfig)[];
  setSelectedImages: (selectedImages: (File | UploadStickersconfig)[]) => void;
  stickerPack: StickersPacksData;
  setStickerPack: (stickerPack: StickersPacksData) => void;
  languages: string[];
  setLanguage: (languages: string[]) => void;
  teams: Team[];
  setTeams: (teams: Team[]) => void;
  features_types: string[];
  setFeatures_types:(features_types: string[]) => void;
  stickersTable: StickersPacksTable[];
  setStickersTable: (stickersTable: StickersPacksTable[]) => void;
  bStickersTable: StickersPacksTable[];
  setBStickersTable : (bStickersTable: StickersPacksTable[]) => void;
}

export const useStore = create<AppState>((set) => ({
  isDrawer: false,
  setIsDrawer: (isDrawer) => set({ isDrawer }),

  tloading: false,
  setTLoading: (tloading) => set({ tloading }),

  stickers: [{
    id: 0,
    dataURL: '',
    is_dafault: false,
  }],
  setStickers: (stickers) => set({ stickers }),

  selectedImages: [],
  setSelectedImages: ((selectedImages) => set({ selectedImages })),

  stickerPack: {
    teams: [],
    languages: [],
    pack_id: 0,
    is_active: false,
    pack_name: '',
    created_on: '',
    is_default: false,
    updated_on: '',
    bucket_path_suffix: '',
    pack_configuration: [],
    part_of_all_organization: false,
  },
  setStickerPack: (stickerPack) => set({ stickerPack }),

  languages: [],
  setLanguage: ((languages) => set({ languages })),

  teams: [{
    id: 0,
    name: '',
    key: ''
  }],
  setTeams: ((teams) => set({ teams })),

  stickersTable: [],
  setStickersTable: (stickersTable) => set({ stickersTable }),

  bStickersTable:[],
  setBStickersTable: (bStickersTable) => set({ bStickersTable }),

  features_types: [],
  setFeatures_types:(features_types) => set({features_types}),

}));

export { };


// const {bStickersTable, setBStickersTable} = useStore();
// const {stickersTable, setStickersTable} = useStore();
// const [features_types, setFeatures_types] = useState<string[]>([]);