import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL as string,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer authtoken`,
      },
    });
  }

  private tokenGenerator() {}

  private async handleError<T>(
    config: AxiosRequestConfig,
    error: AxiosError
  ): Promise<T> {
    throw error;
  }

  private async request<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const token = await this.getToken();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        user: "",
      };


      const response: AxiosResponse<T> = await this.axiosInstance.request<T>({
        ...config,
        headers,
      });
      return response.data;
    } catch (error) {
      return this.handleError(config, error as AxiosError);
    }
  }

  public async get<T>(
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: "get",
      params,
    });
  }

  public async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: "post",
      data,
    });
  }

  public setTokenGenerator(tokenGenerator: any) {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  private async getToken() {
    return this.tokenGenerator();
  }
}

const apiService = new ApiService();
export default apiService;
