import { Fragment, useEffect, useRef, useState } from 'react';
import TaskListDetails from './TaskListDetails';
import TaskListDetails2 from 'migration/TaskListV2/TaskListDetails';
import { message, Skeleton, Tooltip } from 'antd';
import { RBAC } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import templateService from 'modules/services/TemplateService/Template.service';
import {
  ISection,
  ITemplateData,
} from 'modules/models/interfaces/template.interface';
import { useTemplateStore } from '../../store';
import MeatBallMenu from '../MeatBallMenu';
// import { mappingDetails } from 'migration/TaskListV2/services/table.service';
// import cryptoRandomString from 'crypto-random-string';
import { SectionTypeForViewPort } from 'migration/models/enums/notification';
import { useIsInViewport } from 'modules/sharedComponents/hooks/useIsInViewport';

interface ITaskList {
  sectionId: string | number;
  sectionDetails: ISection;
}

const TaskList: React.FC<ITaskList> = ({ sectionId, sectionDetails }) => {
  const { sections, setSections } = useTemplateStore();
  const { templateDetails } = useTemplateStore();
  const [isEditingSectionName, setIsEditingSectionName] = useState(false);
  const [sectionName, setSectionName] = useState<string>('');
  const [sectionToggleState, setSectionToggleState] = useState<
    'expanded' | 'collapsed'
  >('expanded');

  const [, setShowSidePanel] = useState<boolean>(false);
  const [isTaskEmpty, setIsTaskEmpty] = useState<boolean>(false);
  const [taskListDetails, setTaskListDetails] = useState<any>(sectionDetails);
  const sectionNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.currentTarget?.value;
    setSectionName(value);
  };
  const [isLoadingTaskListDetails, setIsLoadingTaskListDetails] =
    useState(true);

  const simpleTaskListSection = useRef(null);
  let isSimpleTaskListSectionInViewport = useIsInViewport(
    simpleTaskListSection,
  );

  const sectionNameOnBlurHandler = async (
    _event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    setIsEditingSectionName(false);
    setSectionName((prev) => prev?.trim());

    if (sections) {
      const oldSection = sections.find(
        (eachSection: any) => eachSection.section_id === sectionId,
      );
      if (typeof oldSection?.section_id === 'string' && sectionName === '') {
        setSections(
          sections?.filter(
            (eachSection: any) => eachSection.section_id !== sectionId,
          ),
        );
      } else if (
        typeof oldSection?.section_id === 'string' &&
        sectionName !== ''
      ) {
        await createSection(templateDetails, oldSection);
      } else if (
        typeof oldSection?.section_id === 'number' &&
        sectionName?.trim() !== '' &&
        oldSection.section_name !== sectionName
      ) {
        await updateSection(templateDetails, oldSection);
      }
    }
  };

  const saveOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sectionNameOnBlurHandler(event);
    }
  };

  const handleSectionToggle = () => {
    setSectionToggleState((prev) =>
      prev === 'collapsed' ? 'expanded' : 'collapsed',
    );
  };

  const updateSection = async (
    templateDetails: ITemplateData,
    oldSection: ISection,
  ) => {
    const result = await templateService.updateSection({
      template_id: templateDetails.template_id!,
      sections: [
        {
          section_id: +oldSection.section_id,
          section_name: sectionName,
        },
      ],
    });

    if (result.messageId === 1) {
      setSections(
        sections?.map((eachSection: any) => {
          const _section = eachSection;
          if (_section.section_id === sectionId) {
            _section.section_name = sectionName;
          }
          return _section;
        }),
      );
    }
  };

  const createSection = async (
    templateDetails: ITemplateData,
    oldSection: ISection,
  ) => {
    const response = await templateService.createNewSection({
      template_id: templateDetails.template_id!,
      section_type_id: 1,
      section_name: sectionName,
      section_rank: oldSection.section_rank,
    });

    if (response.messageId === 1) {
      setSections(
        sections?.map((eachSection: ISection) => {
          const _section = eachSection;
          if (_section.section_id === sectionId) {
            _section.section_name = sectionName;
            _section.section_id = response.data.section_id;
          }
          return _section;
        }),
      );
    }
  };

  useEffect(() => {
    if (taskListDetails.section_details) {
      setIsTaskEmpty(false);
      setIsLoadingTaskListDetails(false);
    }
    // setSections(sections);
  }, []);

  useEffect(() => {
    if (typeof sectionId === 'string') {
      setIsEditingSectionName(true);
    } else {
      setIsEditingSectionName(false);
    }
  }, [sectionId]);

  useEffect(() => {
    if (isEditingSectionName) {
      setSectionName(sectionDetails?.section_name ?? '');
    }
  }, [isEditingSectionName]);

  useEffect(() => {
    if (!isEditingSectionName) {
      setSectionName(sectionDetails?.section_name);
    }
  }, [sectionDetails.section_name]);

  return isSimpleTaskListSectionInViewport ? (
    <div
      className="createNewSecMainContent"
      id={`${SectionTypeForViewPort.SIMPLE_TASK_LIST}-${sectionDetails.section_id}`}
      ref={simpleTaskListSection}
    >
      {isLoadingTaskListDetails ? (
        <Skeleton active loading={isLoadingTaskListDetails} />
      ) : (
        <div className="inner-container">
          <div className="templateTaskTable">
            <div className="taskNameHeadingPane">
              <div className="itemNameBlk">
                <div className="nameOuterBlk">
                  <Tooltip
                    title="Toggle task view"
                    color={'#2E364C'}
                    placement="bottom"
                  >
                    <span
                      className={`sidePanelIcon ${
                        sectionToggleState === 'expanded'
                          ? 'arrow-down'
                          : 'arrow-right'
                      }`}
                      color={'#2E364C'}
                      onClick={handleSectionToggle}
                    />
                  </Tooltip>
                  {isEditingSectionName ? (
                    <RBAC allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}>
                      <div className="auto-grow-input">
                        <input
                          className="section-text-input"
                          placeholder="New Section"
                          onBlur={sectionNameOnBlurHandler}
                          onChange={sectionNameChangeHandler}
                          onKeyDown={saveOnEnter}
                          value={sectionName}
                          autoFocus={true}
                          maxLength={50}
                        />
                        <span className="auto-grow-input-hidden">
                          {sectionName}
                        </span>
                      </div>
                    </RBAC>
                  ) : (
                    <span
                      className="section-text-span"
                      onClick={() => {
                        setIsEditingSectionName(true);
                      }}
                      onKeyDown={() => {}}
                    >
                      {sectionName?.trim() === ''
                        ? taskListDetails.section_name
                        : sectionName}
                    </span>
                  )}
                </div>
                {!isEditingSectionName ? (
                  <MeatBallMenu
                    sectionType="task-list"
                    sectionDetails={sectionDetails}
                    setShowSidePanel={setShowSidePanel}
                  />
                ) : (
                  <></>
                )}
                {/* </div> */}
              </div>
            </div>

            {/* <RBAC allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}> */}
            <TaskListDetails2
              isTaskEmpty={isTaskEmpty}
              setIsTaskEmpty={setIsTaskEmpty}
              taskDetails={sectionDetails}
              setTaskListDetails={setTaskListDetails}
              sectionToggleState={sectionToggleState}
              section={sectionDetails}
              sectionId={sectionDetails.section_id}
            />

            {/* </RBAC> */}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div
      className="createNewSecMainContent"
      ref={simpleTaskListSection}
      id={`${SectionTypeForViewPort.SIMPLE_TASK_LIST}-${sectionDetails.section_id}`}
      style={{ minHeight: '150px' }}
    ></div>
  );
};

export default TaskList;
