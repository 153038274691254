import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  IGridList,
  IGridListBlockDetail,
  IProjectState,
} from 'migration/models/interface';
import templateService from 'modules/services/TemplateService/Template.service';
import { useProjectDashboardStore, useUserStore } from 'modules/store';
import { getCurrentOrganization, groupGridBlock } from 'utils/commonFunctions';
import BlockWithCompleteDetailsWrapper from './BlockWithCompleteDetails/BlockWithCompleteDetailsWrapper';
import { SectionTypeMessages } from 'migration/models/enums/messages';
import { markTaskDoneInMessageThread } from 'utils/firebaseCommonFunctions';

const Block: FC<{
  gridListDetails: IGridList;
  taskIndex: number;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  ele?: IGridListBlockDetail;
}> = ({ gridListDetails, taskIndex, setGridListDetails, ele }) => {
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { userDetails } = useUserStore((state: any) => state);
  const [showDropDown, setShowDropDown] = useState<number>(0);
  const [showCalender, setShowCalender] = useState<number>(0);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const updateBlock = async (block: IGridListBlockDetail) => {
    try {
      const response: any = await templateService.updateGridBlock({
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        userId: userDetails?.user_id,
        projectId: projectDetails?.project_id,
        sectionId: gridListDetails!.section_id,
        blockId: block.block_id,
        assignee: block.assignee_id,
        assigner: block.assigner_id,
        dueDate: block.due_date,
        blockStatus: block.block_status_id,
      });

      if (response.messageId !== 1) {
        revertChange(block.block_id);
      } else if (block.block_status_id === 3) {
        markTaskDoneInMessageThread(
          `${SectionTypeMessages.GRID_LIST_BLOCK}-${
            process.env.REACT_APP_STAGE
          }-${getCurrentOrganization(org_key, userDetails?.organization_info)
            ?.organization_id!}-${projectDetails?.project_id}-${
            gridListDetails.section_id
          }-${block.block_id}`,
        );
      } else {
        markTaskDoneInMessageThread(
          `${SectionTypeMessages.GRID_LIST_BLOCK}-${
            process.env.REACT_APP_STAGE
          }-${getCurrentOrganization(org_key, userDetails?.organization_info)
            ?.organization_id!}-${projectDetails?.project_id}-${
            gridListDetails.section_id
          }-${block.block_id}`,
          // false,
        );
      }
    } catch (err) {
      revertChange(block.block_id);
    }
  };

  const revertChange = (blockId: number) => {
    if (gridListDetails) {
      let blockDetails =
        gridListDetails.grid_list_details?.block_details!.flat()!;
      let target = blockDetails.find(
        (ele: IGridListBlockDetail) => ele.block_id === blockId,
      )!;
      let targetIndex = blockDetails.findIndex(
        (ele: IGridListBlockDetail) => ele.block_id === blockId,
      )!;

      target.assignee_email = null;
      target.assignee_id = null;
      target.assignee_name = null;
      target.assignee_profile_picture = null;

      blockDetails.splice(targetIndex, 1, target);
      let groupedBlockData = groupGridBlock(
        blockDetails,
        gridListDetails.grid_list_details!.task_details!,
      );

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          block_details: groupedBlockData as Array<IGridListBlockDetail[]>,
        },
      } as IGridList);
    }
  };

  return (
    <BlockWithCompleteDetailsWrapper
      gridListDetails={gridListDetails}
      setGridListDetails={setGridListDetails}
      blockDetails={ele!}
      showDropDown={showDropDown}
      setShowDropDown={setShowDropDown}
      showCalender={showCalender}
      setShowCalender={setShowCalender}
      updateBlock={async (block: IGridListBlockDetail) =>
        await updateBlock(block)
      }
      index={taskIndex}
    />
  );
};

export default Block;
