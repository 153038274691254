import {
  Timestamp,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from 'utils/firebase';

export const renameTaskInMessageThread = async (
  messageThreadId: string,
  taskName: string,
) => {
  const document = await getDoc(doc(db, 'message-thread', messageThreadId));
  if (document.exists()) {
    await updateDoc(doc(db, 'message-thread', messageThreadId), {
      task_name: taskName,
    });
  }
};

export const renameBlockInMessageThread = async (
  messageThreadId: string,
  name: string,
  type: 'task' | 'stage',
) => {
  const document = await getDoc(doc(db, 'message-thread', messageThreadId));

  if (document.exists()) {
    const currentName = document.data().task_name.split('-');

    let updatedName =
      type === 'stage'
        ? `${currentName[0]}-${name}`
        : `${name}-${currentName[1]}`;

    await updateDoc(doc(db, 'message-thread', messageThreadId), {
      task_name: updatedName,
    });
  }
};

export const markTaskDoneInMessageThread = async (messageThreadId: string) => {
  const document = await getDoc(doc(db, 'message-thread', messageThreadId));
  if (document.exists()) {
    await updateDoc(doc(db, 'message-thread', messageThreadId), {
      is_done: true,
    });
  }
};

export const markTaskDeleteInMessageThread = async (
  messageThreadId: string,
) => {
  const document = await getDoc(
    doc(db, 'message-thread-admin', messageThreadId),
  );
  if (document.exists()) {
    await updateDoc(doc(db, 'message-thread-admin', messageThreadId), {
      is_deleted: true,
    });
  }
};

export const updateMessageThreadForPrivateSection = async (
  messageThreadId: string,
  userId: any,
) => {
  const document = await getDoc(doc(db, 'message-thread', messageThreadId));
  if (document.exists()) {
    let newMember = [userId.user_id];
    let oldMember = document.data().all_members_id;
    await updateDoc(doc(db, 'message-thread', messageThreadId), {
      all_members_id: newMember,
      backup_member_id: oldMember,
    });
  }
};

export const updateMessageThreadForPublicSection = async (
  messageThreadId: string,
) => {
  const document = await getDoc(doc(db, 'message-thread', messageThreadId));
  if (document.exists()) {
    let members = document.data().backup_member_id;

    await updateDoc(doc(db, 'message-thread', messageThreadId), {
      all_members_id: members,
      backup_member_id: deleteField(),
    });
  }
};

const isMemberPresent = (item: any, array: any) => {
  return array.some((itm: any) => itm.user_id == item.user_id);
};

const updateMemberList = (
  existingMembers: any,
  existingMemberIds: any,
  members: any,
) => {
  let newMembers = [...existingMembers];
  let newMemberIds = [...existingMemberIds];
  members.forEach((item: any) => {
    if (!isMemberPresent(item, existingMembers)) {
      newMembers.push({ ...item, removed: false });
      newMemberIds.push(item.user_id);
    }
    if (newMembers.some((itm: any) => itm.user_id === item.user_id)) {
      let findIndex = newMembers.findIndex(
        (itm: any) => itm.user_id === item.user_id,
      );
      newMembers[findIndex]['removed'] = false;
    }
  });

  existingMembers.forEach((item: any) => {
    if (!isMemberPresent(item, members)) {
      let findIndex = newMembers.findIndex(
        (itm: any) => itm.user_id === item.user_id,
      );
      newMembers[findIndex]['removed'] = true;
    }
  });
  return { newMembers, newMemberIds };
};

export const updateMembers = async (
  messageThreadId: string,
  members: any[],
  projectName: string,
) => {
  const document = await getDoc(doc(db, 'message-thread', messageThreadId));
  if (document.exists()) {
    let data = updateMemberList(
      document.data().member_id,
      document.data().all_members_id,
      members,
    );
    await updateDoc(doc(db, 'message-thread', messageThreadId), {
      member_id: data.newMembers,
      all_members_id: data.newMemberIds,
      project_name: projectName,
    });
  }
};

export const markMemberAsRemovedFromOrg = async (
  orgId: number,
  memberId: number,
) => {
  const collectionRef = collection(db, 'message-thread');
  const snapshot = await getDocs(
    query(collectionRef, where('organization_id', '==', orgId)),
  );
  if (!snapshot.empty) {
    const updatePromises = snapshot.docs.map(async (docSnapshot) => {
      const docRef = doc(db, 'message-thread', docSnapshot.id);
      const docData = await getDoc(docRef);
      if (docData.exists()) {
        let memberList = [...docData.data().member_id];
        let userIndex = memberList.findIndex(
          (itm: any) => itm.user_id === memberId,
        );
        if (userIndex !== -1) {
          memberList[userIndex]['removed'] = true;
        }
        return updateDoc(docRef, { member_id: memberList });
      }
    });
    await Promise.all(updatePromises);
  }
};

export const updateProjectName = async (
  messageThreadId: string,
  projectName: string,
) => {
  const document = await getDoc(doc(db, 'message-thread', messageThreadId));
  if (document.exists()) {
    await updateDoc(doc(db, 'message-thread', messageThreadId), {
      project_name: projectName,
    });
  }
};

export const updateMemberName = async (
  messageThreadId: string,
  memberObj: {
    user_id: number;
    user_name: string;
  },
) => {
  const document = await getDoc(doc(db, 'message-thread', messageThreadId));
  if (document.exists()) {
    let members = document
      .data()
      .member_id.filter((ele: any) => ele.user_id !== memberObj.user_id);
    await updateDoc(doc(db, 'message-thread', messageThreadId), {
      member_id: [...members, memberObj],
    });
  }
};

export const getMessageThreadCount = async (messageThreadId: string) => {
  const document = await getDoc(
    doc(db, 'message-thread-admin', messageThreadId),
  );
  if (document.exists()) {
    return document.data().messages.length;
  }
};

export const getMessageThreadByProject = async (
  orgId: number,
  projectId: number,
) =>
  getDocs(
    query(
      collection(db, 'message-thread'),
      where('organization_id', '==', orgId),
      where('project_id', '==', projectId),
    ),
  );

export const getMessageThreadBySection = async (
  orgId: number,
  projectId: number,
  sectionId: number,
) =>
  getDocs(
    query(
      collection(db, 'message-thread'),
      where('organization_id', '==', orgId),
      where('project_id', '==', projectId),
      where('section_id', '==', sectionId),
    ),
  );

export const getMessageThreadByMemberObject = async (memberObj: {
  user_id: number;
  user_name: string;
}) =>
  getDocs(
    query(
      collection(db, 'message-thread'),
      where('member_id', 'array-contains', memberObj),
    ),
  );
