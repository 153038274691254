import { Input, Modal, Skeleton, Spin, Table, Tooltip, message } from 'antd';
// import ComponentLoader from "modules/components/Loaders/ComponentLoader";
import { Fragment, useEffect, useState } from 'react';
import { useListStore } from './store';
import { ColumnsType } from 'antd/es/table';
import templateService from 'modules/services/TemplateService/Template.service';
import {
  IDeleteTemplate,
  ITemplateData,
  Industry,
  OrganizationFunction,
} from 'modules/models/interfaces/template.interface';
import apiService from 'modules/services/Api.service';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { useAuth0 } from '@auth0/auth0-react';
import { useRbac } from 'auth/rbac/rbac';
import { Team } from 'modules/models/interfaces';
import { dateConvert } from 'utils/commonFunctions';
import { useNavigate } from 'react-router-dom';
import TemplateFilterButton from './TemplateFilterButton';

const TemplatesTable: React.FC<{}> = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const { hasPermissions } = useRbac();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalChangeStatusPublishOpen, setIsModalChangeStatusPublishOpen] =
    useState(false);
  const {
    tloading,
    templateTableData,
    setTemplateTableData,
    originalTemplateTableData,
    setOriginalTemplateTableData,
    selectedTemplate,
    setSelectedTemplate,
  } = useListStore();
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  const getAllTemplates = async () => {
    try {
      const response = await templateService.getAllTemplates();
      const tableData = (response?.data ?? []).map((item) => ({
        ...item,
        updated_on: item.updated_on ?? item.created_on,
      }));
      setOriginalTemplateTableData(tableData);
      setTemplateTableData(tableData);
      if (response.messageId === 1) {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    apiService.setTokenGenerator(getAccessTokenSilently);
    if (hasPermissions([ERbacPermissions.TEMPLATE_VIEW])) {
      getAllTemplates();
    }
  }, []);

  const handleCreateNewTemplate = () => {
    navigate('/templates/create');
  };

  const handleSuccess = () => {
    messageApi.open({
      type: 'success',
      content: `Template deleted successfully`,
    });
  };

  const handleError = () => {
    messageApi.open({
      type: 'error',
      content: 'Some error occurred. Please try it again!',
    });
  };

  const handleDeleteTemplate = async (template_id: number) => {
    const requestPayload: IDeleteTemplate = {
      template_id,
      is_delete: true,
    };
    setLoading(true);
    const response = await templateService.deleteTemplate(requestPayload);
    if (response.messageId === 1) {
      const responseGetAllTemplates = await templateService.getAllTemplates();
      const tableData = (responseGetAllTemplates?.data || []).map((item) => ({
        ...item,
        updated_on: item.updated_on || item.created_on,
      }));
      setOriginalTemplateTableData(tableData);
      setTemplateTableData(tableData);
      if (responseGetAllTemplates.messageId === 1) {
        setLoading(false);
        handleSuccess();
      }
    } else {
      handleError();
    }
  };

  const handleChangeStatusPublish = async () => {
    try {
      const response = await templateService.changeStatusPublishTemplate({
        template_id: selectedTemplate?.template_id as number,
        is_active: !selectedTemplate?.is_active,
      });
      if (response.messageId === 1) {
        const updatedTemplateTable = templateTableData.map((template) => {
          if (template.template_id === selectedTemplate?.template_id) {
            return {
              ...template,
              is_active: !selectedTemplate.is_active,
            };
          }
          return template;
        });
        setOriginalTemplateTableData(updatedTemplateTable);
        setTemplateTableData(updatedTemplateTable);
      }
    } catch (error) {
      handleError();
      console.log(error);
    }
  };

  const handleOk = async () => {
    handleDeleteTemplate(selectedTemplate?.template_id as number);
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOkModalChangeStatusPublish = async () => {
    handleChangeStatusPublish();
    setIsModalChangeStatusPublishOpen(false);
  };

  const handleCancelModalChangeStatusPublish = () => {
    setIsModalChangeStatusPublishOpen(false);
  };

  const showModalChangeStatusPublish = () => {
    setIsModalChangeStatusPublishOpen(true);
  };

  const toSortedInd = (industries: any) => {
    return industries
      ?.sort((a: any, b: any) => a.industry?.localeCompare(b.industry))
      .map((industry: any) => (
        <li key={industry.industry}>{industry.industry}</li>
      ));
  };

  const toSortedAndJoinedSliceInd = (industries: any) => {
    const sortedAndJoined = industries
      .sort((a: any, b: any) => a.industry?.localeCompare(b.industry))
      .slice(0, 3)
      .map((industry: any) => industry.industry)
      .join(', ');
    return <div key={sortedAndJoined}>{sortedAndJoined}</div>;
  };

  const toSortedAndJoinedInd = (industries: any) => {
    const sortedAndJoined = industries
      .sort((a: any, b: any) => a.industry?.localeCompare(b.industry))
      .map((industry: any) => industry.industry)
      .join(', ');
    return <div key={sortedAndJoined}>{sortedAndJoined}</div>;
  };

  const toSortedOrg = (organization_functions: any) => {
    return organization_functions
      ?.sort((a: any, b: any) =>
        a.organization_function?.localeCompare(b.organization_function),
      )
      .map((organization_function: any) => (
        <li key={organization_function.organization_function}>
          {organization_function.organization_function}
        </li>
      ));
  };

  const toSortedAndJoinedSliceOrg = (organization_functions: any) => {
    const sortedAndJoined = organization_functions
      .sort((a: any, b: any) =>
        a.organization_function?.localeCompare(b.organization_function),
      )
      .slice(0, 3)
      .map(
        (organization_function: any) =>
          organization_function.organization_function,
      )
      .join(', ');

    return <div key={sortedAndJoined}>{sortedAndJoined}</div>;
  };

  const toSortedAndJoinedOrg = (organization_functions: any) => {
    const sortedAndJoined = organization_functions
      ? organization_functions
          .sort((a: any, b: any) =>
            a.organization_function?.localeCompare(b.organization_function),
          )
          .map(
            (organization_function: any) =>
              organization_function.organization_function,
          )
          .join(', ')
      : '';
    return <div key={sortedAndJoined}>{sortedAndJoined}</div>;
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase().trim();
    const filteredData = templateTableData.filter((record) => {
      const template_name = record.template_name.toLowerCase();
      const industries = record.industries
        ? record.industries
            .map((data) => data.industry)
            .join(',')
            .toLowerCase()
        : '';
      const organization_functions = record.organization_functions
        ? record.organization_functions
            .map((data) => data.organization_function)
            .join(',')
            .toLowerCase()
        : '';

      return (
        template_name.includes(searchTerm) ||
        industries.includes(searchTerm) ||
        organization_functions.includes(searchTerm)
      );
    });
    setSearchText(e.target.value);
    setTemplateTableData(
      e.target.value ? filteredData : originalTemplateTableData,
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Template Name',
      dataIndex: 'template_name',
      key: 'template_name',
      width: '20%',
      render: (template_name: string) => {
        <span>{template_name}...</span>;
        // if (template_name.length > 50) {
        //   const tooltipContent = (
        //     <div>{template_name}</div>
        //   )

        //   return (
        //     <Tooltip title={tooltipContent}>
        //       <span>{template_name.slice(0, 50)}...</span>
        //     </Tooltip>
        //   );
        //}

        return <span>{template_name}</span>;
      },
      sorter: (a: any, b: any) =>
        a.template_name?.localeCompare(b.template_name),
    },
    {
      title: 'Publish Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: '10%',
      render: (is_active: boolean, record: ITemplateData) => {
        if (is_active) {
          return (
            <button
              className="activeBtn"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedTemplate(record);
                showModalChangeStatusPublish();
              }}
            >
              Published
            </button>
          );
        } else if (!is_active) {
          return (
            <button
              className="inactiveBtn"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedTemplate(record);
                showModalChangeStatusPublish();
              }}
            >
              Unpublished
            </button>
          );
        }
        return null;
      },
    },
    {
      title: 'Teams',
      dataIndex: 'teams',
      key: 'teams',
      sorter: (a: Team[], b: Team[]) => a?.length - b?.length,
      render: (teams: Team[], templateInfoForUpdate: ITemplateData) =>
        templateInfoForUpdate.part_of_all_organization ? (
          <span>All</span>
        ) : (
          <span>{teams?.length || '0'}</span>
        ),
    },
    {
      title: 'Industries',
      dataIndex: 'industries',
      key: 'industries',
      render: (industries: Industry[]) => {
        if (industries?.length > 3) {
          const tooltipContent = (
            <div>
              <ul>{toSortedInd(industries)}</ul>
            </div>
          );

          return (
            <Tooltip title={tooltipContent}>
              <span>
                {toSortedAndJoinedSliceInd(industries)}
                <span style={{ color: 'blue' }}>
                  {industries.length > 3 && ` +${industries.length - 3}`}
                </span>
              </span>
            </Tooltip>
          );
        }

        return (
          <span>{industries ? toSortedAndJoinedInd(industries) : ''}</span>
        );
      },
    },
    {
      title: 'Organization Function',
      dataIndex: 'organization_functions',
      key: 'organization_functions',
      width: '15%',
      render: (organization_functions: OrganizationFunction[]) => {
        if (organization_functions?.length > 3) {
          const tooltipContent = (
            <div>
              <ul>{toSortedOrg(organization_functions)}</ul>
            </div>
          );

          return (
            <Tooltip title={tooltipContent}>
              <span>
                {toSortedAndJoinedSliceOrg(organization_functions)}
                <span style={{ color: 'blue' }}>
                  {organization_functions?.length > 3 &&
                    ` +${organization_functions?.length - 3}`}
                </span>
              </span>
            </Tooltip>
          );
        }

        return (
          <span>
            {organization_functions
              ? toSortedAndJoinedOrg(organization_functions)
              : ''}
          </span>
        );
      },
    },
    {
      title: 'Created On',
      dataIndex: 'created_on',
      key: 'created_on',
      width: '5%',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => a.created_on?.localeCompare(b.created_on),
      render: (created_on) => {
        return (
          <Fragment>
            <div className="">
              <p>{created_on ? dateConvert(created_on) : ''}</p>
            </div>
          </Fragment>
        );
      },
    },
    {
      title: 'Last Modified',
      dataIndex: 'updated_on',
      key: 'updated_on',
      width: '5%',
      sorter: (a: any, b: any) => a.updated_on?.localeCompare(b.updated_on),
      render: (updated_on) => {
        return (
          <Fragment>
            <div className="">
              <p>{updated_on ? dateConvert(updated_on) : ''}</p>
            </div>
          </Fragment>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'templateInfoForUpdate',
      render: (_, record: ITemplateData) => (
        <div className="iconList" >
          <span
            className="cmnIcon pencil"
            onClick={() => {
              navigate(`/templates/${record.template_id}`);
            }}
          ></span>
          <span
            className="cmnIcon deleteBin"
            onClick={() => {
              setSelectedTemplate(record);
              showModal();
            }}
          ></span>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      {contextHolder}
      <div className="contentHeadingPanel">
        <h2>Templates Management</h2>
      </div>
      <div className="inner-container">
        <div className="manageUsersTableHeading manageTemplateHeading">
          <span className="itemName">Manage Templates</span>
          {loading ? (
            <Skeleton.Input active />
          ) : (
            <div className="addStickerPackWrap searchFilter">
              <Input
                size="large"
                onChange={onSearch}
                placeholder="Search"
                value={searchText}
              />
              <ul className="actionBtnSec">
                <li>
                  <button
                    title="filter"
                    id="filterTaskBtn"
                    className="filterTaskPopover"
                  >
                    <TemplateFilterButton />
                  </button>
                </li>
              </ul>
              <button
                className="btnStyle3 addPackBtn"
                type="button"
                id="addStickerPack"
                onClick={() => handleCreateNewTemplate()}
              >
                New Template
              </button>
            </div>
          )}
        </div>
        <Skeleton active loading={loading || tloading}>
          <div className="userManagementTable templateManagementTable">
            <Table
              columns={columns}
              dataSource={templateTableData}
              pagination={{
                pageSizeOptions: ['10', '20', '50', '100', '150'],
                showSizeChanger: true,
                defaultPageSize: 10,
                locale: { items_per_page: '' },
              }}
            />
          </div>
        </Skeleton>
        <Modal
          title="Template Delete"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Are you sure you want to delete?</p>
        </Modal>
        <Modal
          title={`Template ${
            selectedTemplate?.is_active ? 'Unpublish' : 'Publish'
          }`}
          open={isModalChangeStatusPublishOpen}
          onOk={handleOkModalChangeStatusPublish}
          onCancel={handleCancelModalChangeStatusPublish}
        >
          <p>{`Are you sure you want to ${
            selectedTemplate?.is_active ? 'unpublish' : 'publish'
          }?`}</p>
        </Modal>
      </div>
    </Fragment>
  );
};

export default TemplatesTable;
