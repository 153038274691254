import React, { Fragment, useEffect, useState } from 'react';
import { MenuProps, Checkbox, Col, Row, Dropdown, Space, Select, SelectProps } from 'antd';
import { useStore } from './store';


const StickersFilter: React.FC = () => {
    const [status, setStatus] = useState<string[]>([]);
    const { languages } = useStore();
    const [selectLanguage, setSelectLanguages] = useState<string[]>([]);
    const { teams } = useStore();
    const { setStickersTable } = useStore();
    const { bStickersTable } = useStore();
    const [selectTeams, setSelectTeams] = useState<string[]>([]);
    const { features_types } = useStore();
    const [selectFeatures_value, setSelectFeatures_value] = useState<any[] | string[]>([]);

    const filterData = () => {
        const searchTerm1 = status.length !== 0 ? status[0] : 'Active';
        const searchTerm2 = status.length !== 0 ? status[1] : 'In-active';

        const matchesStatus = (record: any) => {
            const stickerStatus = record.packStatus;
            return stickerStatus.includes(searchTerm1) || stickerStatus.includes(searchTerm2);
        };

        const matchesLanguage = (record: any) => {
            const flanguages = record.mergeLanguages;
            return (
                selectLanguage.length === 0 ||
                selectLanguage.some((element1) => flanguages.includes(element1))
            );
        };

        const matchesFeatures = (record: any) => {
            const fFeatures = record.mergeFeatures;
            return (
                selectFeatures_value.length === 0 ||
                selectFeatures_value.some((element2) => fFeatures.includes(element2))
            );
        };

        const matchesTeams = (record: any) => {
            const fTeams = record.mergeTeams;
            return (
                selectTeams.length === 0 || selectTeams.some((element3) => fTeams.includes(element3))
            );
        };

        const filteredData = bStickersTable.filter((record) =>
            matchesStatus(record) && matchesLanguage(record) && matchesFeatures(record) && matchesTeams(record)
        );

        setStickersTable(
            status.length + selectLanguage.length + selectTeams.length + selectFeatures_value.length > 0
                ? filteredData
                : bStickersTable
        );
    };

    const options_team: SelectProps['options'] = [];
    options_team.push({
        label: "All Team",
        value: "All",
        disabled: false,
    });

    teams.forEach((tdata) => {
        const value = tdata.name + "(" + tdata.key + ")";
        options_team.push({
            label: value,
            value: value,
            disabled: false,
        });
    })

    useEffect(() => {
        filterData();
    }, [status, selectLanguage, selectTeams, selectFeatures_value]);

    const onChangeStatus = (checkedValues: any) => {
        setStatus(checkedValues);
    };

    const onChangeLangType = (checkedValues: any) => {
        setSelectLanguages(checkedValues);
    };

    const onChangeFeatureType = (checkedValues: any) => {
        setSelectFeatures_value(checkedValues);
    }

    const handleChange_team = (value: string[]) => {
        if (value.length == 1 && value[0] == '') {
            value.unshift();
        }
        setSelectTeams(value);
        console.log(value);
    };


    const clearAll = (() => {
        setStatus([]);
        setSelectLanguages([]);
        setSelectTeams([]);
        setSelectFeatures_value([]);
        setStickersTable(bStickersTable);
    });

    const items: MenuProps['items'] = [

        {
            key: '1',
            label: <p onClick={clearAll}>Clear All</p>
        },

        {
            key: '2',
            label: <p>Pack status</p>,
            children: [
                {
                    key: '2-1',
                    label:
                        <Space>
                            <Checkbox.Group style={{ width: '200px' }} value={status} onChange={onChangeStatus}>
                                <Row>
                                    <Col span={15}>
                                        <Checkbox value="Active">Active</Checkbox>
                                    </Col>
                                    <br />
                                    <Col span={15}>
                                        <Checkbox value="In-active">In-active</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Space>
                    ,
                },
            ]
        },

        {
            key: '3',
            label: <p>Language type</p>,
            children: [
                {
                    key: '3-1',
                    label:
                        <Space>
                            <Checkbox.Group style={{ width: '200px' }} value={selectLanguage} onChange={onChangeLangType}>
                                <Row>
                                    {languages.map((value) => {
                                        return (
                                            <Col span={15} key={value}>
                                                <Checkbox value={value}>{value}</Checkbox>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Checkbox.Group>
                        </Space>
                    ,
                },
            ]
        },

        {
            key: '4',
            label: <p>Features type</p>,
            children: [
                {
                    key: '3-1',
                    label:
                        <Space>
                            <Checkbox.Group style={{ width: '200px' }} value={selectFeatures_value} onChange={onChangeFeatureType}>
                                <Row>
                                    {features_types.map((value) => {
                                        return (
                                            <Col span={15} key={value}>
                                                <Checkbox value={value}>{value}</Checkbox>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Checkbox.Group>
                        </Space>
                    ,
                },
            ]
        },

        {
            key: '5',
            label: 'Select Teams :'
        }
    ];


    return (
        <Fragment>
            <Dropdown menu={{ items }}
                dropdownRender={(menu) => (
                    <div>
                        {React.cloneElement(menu as React.ReactElement)}
                        <Space>
                            <Select
                                mode="multiple"
                                style={{ width: '150px' }}
                                allowClear
                                placeholder="Please select"
                                value={selectTeams}
                                onChange={handleChange_team}
                                options={options_team}
                            />
                        </Space>
                    </div>
                )}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space >
                        <span className="cmnIcon filter"></span>
                    </Space>
                </a>
            </Dropdown>

        </Fragment >
    )

};

export default StickersFilter;
