
import { AppEnv } from "modules/models/enums/appEnv";
import { useEffect } from "react";

export const usePnCScript = () => {
  useEffect(() => {
    const pnc_script = document.createElement('script');

    if (
      process.env.REACT_APP_STAGE === AppEnv.DEV ||
      process.env.REACT_APP_STAGE === AppEnv.LOCAL
    ) {
      pnc_script.src =
        process.env.REACT_APP_PNC_SCRIPT_SRC ??
        'https://cdn.dev-pnc-script.cleversort.com/releases/1.0/pnc-script.js';

      pnc_script.setAttribute(
        'data-pnc-key',
        process.env.REACT_APP_PNC_SCRIPT_ENV_KEY ??
          'zALeoRLzu+ezn7an40M2f5xk4LNhhVv+XD7UUo76Puv3kWXAaSraBE1ZiYTY1kkaC4C51AmX3FmnaYvpkO/BB23W1q1zntLgXCqW7vTKBz0eCG00/JnMbdVPPnzGN5ew9WmwXbh0xWWn5LjNsu3x89Z2d1mXYXuTNq3dVFSh+OT+KZOEo+lbzI8SDgx0gaC7',
      );
      pnc_script.async = true;
      document.body.appendChild(pnc_script);
    }

    return () => {
      document.body.removeChild(pnc_script);
    };
  }, []);
};
