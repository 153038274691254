import { message } from 'antd';
import { IProjectData, ITaskListAttributes, IUpdatedTasks, IUserDetails } from 'migration/models/interface';
import { useTemplateStore } from 'modules/components/Pages/Template/store';
import projectService from 'modules/services/ProjectService/project.service';
import templateService from 'modules/services/TemplateService/Template.service';

import { getCurrentOrganization } from 'utils/commonFunctions';

interface TaskServiceProps {
  projectDetails?: IProjectData | null;
  sectionId: number | string | undefined;
  org_key: string;
  userDetails: IUserDetails;
  taskListCmsData?: ITaskListAttributes | null;
}

const useTaskService = (props: TaskServiceProps) => {
  const {
    projectDetails,
    sectionId,
    org_key = '',
    userDetails,
    taskListCmsData,
  } = props ?? {};
  const { templateDetails } = useTemplateStore();

  const updateExistingTask = async (taskData?: IUpdatedTasks[]) => {
    if (projectDetails?.project_id && typeof sectionId === 'number') {
      try {
        const { organizationId } = validateData(userDetails, org_key);
        const { data, message, messageId } = await projectService.updateTaskV2({
          organizationId,
          project_id: projectDetails.project_id,
          section_id: sectionId,
          section_details: taskData ?? [],
        });
        if (messageId !== 1) throw Error(message);
        return data;
      } catch (error) {
        console.log(error);
        message.error(taskListCmsData?.error_message_options?.msg, 3);
      }
    }
  };

  const updateCustomField = async ({
    customMeta,
    templateId,
    sectionId,
    sectionTypeId,
    deleteOptions = null,
  }: {
    customMeta: any;
    templateId: Number;
    sectionId: Number;
    sectionTypeId: number;
    deleteOptions?: any;
  }) => {
    try {
      const payload = {
        templateId,
        sectionId,
        sectionTypeId,
        customItem: customMeta,
        deleteOptions,
      };

      const response = await templateService.addEditCustomMeta(payload);
      if (response?.messageId !== 1) throw Error(response?.message);
      return response;
    } catch (error: any) {
      console.error(error);
      message.error(error?.message ?? 'Failed to add custom field', 3);
    }
  };

  const deleteCustomFieldAPI = async (mapping_id: string) => {
    try {
      const { data, message, messageId } =
        await templateService.deleteCustomMeta({
          templateId: templateDetails.template_id,
          sectionId,
          mappingId: mapping_id,
        });
      if (messageId !== 1) throw Error(message);

      return data;
    } catch (error: any) {
      console.error(error);
      message.error(error?.message ?? 'Failed to delete custom field', 3);

      return [];
    }
  };

  const updateSettings = async (taskList: any) => {
    try {
      const { message, messageId } =
        await templateService.updateCustomFieldSettingV2({
          templateId: templateDetails.template_id,
          sectionId,
          settings: {
            columnSettings: taskList?.custom_meta?.filter(
              (ele: any) => ele.key !== 'action',
            ),
            taskRank: taskList.section_details.map((ele: any) => ele.task_id),
          },
        });

      if (messageId !== 1) throw Error(message);
    } catch (error: any) {
      console.error(error);
      message.error(
        error?.message ?? 'Failed to update custom field settings',
        3,
      );
    }
  };

  return {
    updateExistingTask,
    updateCustomField,
    deleteCustomFieldAPI,
    updateSettings,
  };
};

export default useTaskService;

function validateData(userDetails: IUserDetails, org_key: string) {
  if (
    !userDetails ||
    !userDetails.organization_info ||
    !userDetails.user_id ||
    !org_key
  )
    throw Error('Organization info not found!');

  const organizationId = getCurrentOrganization(
    org_key,
    userDetails?.organization_info,
  )?.organization_id!;

  if (!organizationId) throw Error('Organization ID not found!');

  return { organizationId };
}
