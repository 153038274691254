import { FC } from 'react';
import { IGridList } from 'migration/models/interface';
import { Tooltip } from 'antd';
import { newTaskId, newTaskRank } from 'migration/models/enums/constants';
import { checkTaskList } from 'utils/commonFunctions';
import { gridListCmsData } from '../GridListCmsDatas/GridListCmsData';

const AddTaskCTA: FC<{
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
}> = ({ gridListDetails, setGridListDetails }) => {
  const gridDetails = gridListDetails?.grid_list_details;
  const isHaveBlocks = gridDetails?.stage_details && gridDetails?.task_details;

  const addNewTask = () => {
    if (gridListDetails) {
      const check = checkTaskList(gridListDetails);

      if (!check) {
        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            task_details: gridListDetails.grid_list_details!.task_details
              ? [
                  ...gridListDetails.grid_list_details!.task_details,
                  {
                    task_id: 99,
                    task_name: '',
                    task_rank: 99,
                    section_id: +gridListDetails.section_id,
                    total_block_count: 0,
                    completed_block_count: 0,
                    isNew: true,
                    isEdit: false,
                    created_by: '',
                    backupName: '',
                  },
                ]
              : [
                  {
                    task_id: 99,
                    task_name: '',
                    task_rank: 99,
                    section_id: +gridListDetails.section_id,
                    total_block_count: 0,
                    completed_block_count: 0,
                    isNew: true,
                    isEdit: false,
                    created_by: '',
                    backupName: '',
                  },
                ],
          },
        } as IGridList);
        return;
      }

      let lastElement =
        gridListDetails.grid_list_details?.task_details?.slice(-1)!;
      if (!lastElement?.[0]?.isNew && !lastElement?.[0]?.isEdit) {
        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            task_details: gridListDetails.grid_list_details!.task_details
              ? [
                  ...gridListDetails.grid_list_details!.task_details,
                  {
                    task_id: newTaskId,
                    task_name: '',
                    task_rank: newTaskRank,
                    section_id: +gridListDetails.section_id,
                    total_block_count: 0,
                    completed_block_count: 0,
                    isNew: true,
                    isEdit: false,
                    created_by: '',
                  },
                ]
              : [
                  {
                    task_id: newTaskId,
                    task_name: '',
                    task_rank: newTaskRank,
                    section_id: +gridListDetails.section_id,
                    total_block_count: 0,
                    completed_block_count: 0,
                    isNew: true,
                    isEdit: false,
                    created_by: '',
                  },
                ],
          },
        } as IGridList);
      }
    }
  };

  return (
    <Tooltip
      title={isHaveBlocks ? gridListCmsData?.lbl_add_task_tooltip : ''}
      color={'#2E364C'}
      placement="right"
    >
      <div
        onClick={() => addNewTask()}
        className={
          'addTask addPlusSolid ' +
          (!gridDetails?.task_details ? 'noTaskAvailable' : '')
        }
      >
        Add new task
      </div>
    </Tooltip>
  );
};

export default AddTaskCTA;
