import { RBAC, withRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import React, { Fragment, useEffect, useRef } from 'react';
import LeftNav from '../../Dashboard/LeftNav/LeftNav';
import InviteUserForm, { IInviteUserFormImperative } from './InviteUserForm';
import { useStore } from './store';
import UserListTable from './UserListTable';

interface EmailNameRoles {
  name: string;
  email: string;
  roles: string[];
  user_id: number;
  status: boolean;
  invite_accepted: boolean;
}

const UserManagement: React.FC<{}> = () => {
  const { isDrawer, setIsDrawer } = useStore();
  const { iuser, setIUser } = useStore();
  const inviteUserForm = useRef<IInviteUserFormImperative>(null);

  const handleDrawer = () => {
    setIsDrawer(!isDrawer);
    if (inviteUserForm?.current) {
      inviteUserForm.current.resetForm();
    }
  };

  useEffect(() => {
    return () => {
      setIsDrawer(false);
    };
  }, []);
  return (
    <RBAC allowedPermissions={[ERbacPermissions.USER_VIEW]}>
      <div
        className={`adminPanelMainWrapper mainWrapper ${
          isDrawer ? 'body-overlay' : ''
        }`}
      >
        <LeftNav />
        <div className="adminPanelMainContent mainContent">
          <UserListTable />

          <div
            className={`addUserDrawerWrap createNewProjectForm ${
              isDrawer ? 'addToggle' : ''
            }`}
            id="AdmincreateNewProjectForm1"
          >
            <div className="header">
              {iuser.email ? <h2>Edit User</h2> : <h2>Invite a user</h2>}
              <span
                className="material-icons-outlined close"
                onClick={handleDrawer}
              >
                close
              </span>
            </div>
            <InviteUserForm ref={inviteUserForm} />
          </div>
        </div>
      </div>
    </RBAC>
  );
};

export default withRbac(UserManagement, {
  allowedPermissions: [ERbacPermissions.USER_VIEW],
  isAccessFromPage: true,
});
