export enum RoutesEnum {
  // Master
  GET_INDUSTRY_MASTER = 'master/industry',
  GET_ORGANIZATION_ROLE_MASTER = 'master/organization-roles',
  GET_JOB_ROLE_MASTER = 'master/job-roles',
  GET_EDUCATION_MAJOR_MASTER = 'master/education-majors',
  GET_ORGANIZATION_COLOR_PALETTE = 'master/organization-colors',

  // Localization
  GET_CMS_DATA = 'cms/getCMSData',
  GET_LOCALIZATION_DATA = 'localization/localizationData',

  // Users
  USER_ACCOUNT_ACTIVATION = 'users/accountActivation',
  USER_STATUS = 'users/login',
  USER_DETAILS = 'users/details',
  USER_SOCIAL_SIGNUP = 'users/userSocialSignup',
  USER_PROFILE_UPDATE = 'users/update-user-profile',
  USER_UPLOAD_IMAGE_PROFILE = 'users/upload-profile-image',
  USER_UPDATE_PASSWORD = 'users/change-password',
  USER_NOTIFICATION = 'users/user-notification',
  USER_INVITATION_DETAIL = '/users/invitation-detail',
  UPDATE_USER_ORG_COLOR = 'users/update-user-org-color',
  UPDATE_USER_PROFILE_PIC = 'users/update-profile-pic',
  ADD_USER_CALENDAR = 'users/add-user-calendar',
  UPDATE_USER_CALENDAR_ASSOC = 'users/update-user-calendar',

  // Teams
  TEAM_DETAIL_UPDATE = 'teams/update-detail',
  DELETE_TEAM_MEMBER = 'teams/delete-team-member',
  TEAM_MEMBER_LIST = 'teams/team-members',
  UPLOAD_TEAM_LOGO = 'teams/upload-organization-logo',
  UPDATE_TEAM_LOGO = 'teams/update-organization-logo',
  GET_MASTER_OF_SETTING = 'teams/master-of-settings',
  GET_ORG_OF_SETTING = 'teams/organization-of-settings',
  UPDATE_ORG_OF_SETTING = 'teams/update-organization-of-settings',

  // Invitation
  INVITE_USERS = 'users/invite-users',
  GET_AUTH_USER_DETAILS = 'users/get-auth-user-details',
  GET_INVITATION_STATUS = 'users/get-invitation-status',
  PROCESS_INVITATION = 'users/process-invitation',

  // Users Onboarding
  USER_ONBOARDING_SETUP_TEAM = 'users/onboarding/setup-team',
  USER_ONBOARDING_SETUP_PROFILE = 'users/onboarding/setup-profile',
  USER_ONBOARDING_ORGANIZATION_CLEANUP = 'users/onboarding/organizationCleanup',

  SAVE_IMAGE = 'users/onboarding/save-image',

  // Project
  CREATE_FOLDER = 'dashboard/createFolder',
  ADD_EDIT_FOLDER = 'dashboard/addEditFolder',
  DELETE_FOLDER = 'dashboard/deleteFolder',
  GET_FOLDER_LIST = 'dashboard/folderList',
  GET_PROJECT_FOLDER_LIST = 'dashboard/projectFolderList',
  GET_ASSOCIATED_USER_LIST = 'dashboard/getOrganizationAssociatedUser',
  GET_PROJECT_ROLES = 'dashboard/getProjectRoles',
  CREATE_PROJECT = 'project/createProject',
  UPDATE_PROJECT = 'project/updateProject',
  UPDATE_PROJECT_NAME = 'project/updateProjectName',
  GET_PROJECT_DETAILS = 'project/details',
  GET_TASKLIST_DETAILS = 'project/section/tasklist-details',
  GET_GRIDLIST_DETAILS = 'project/section/gridlist-details',
  GET_MEMOPAD_DETAILS = 'project/section/memopad-details',
  ADD_NEW_SECTION = 'project/add-new-section',
  UPDATE_SECTION = 'project/update-section',
  ADD_NEW_TASK = 'project/add-new-task',
  UPDATE_TASK = 'project/update-task',
  UPDATE_TASK_V2 = 'project/update-task-v2',
  TASK_STATUS_MASTER = 'project/status-master',
  TASK_PRIORITY_MASTER = 'project/priority-master',
  GET_PROJECT_MEMBERS = 'project/members',
  UPLOAD_MEMOPAD_ASSET = 'project/upload-assets',
  TOGGLE_FAVORITE_PROJECT = 'project/toggle-favorite-project',
  ADD_NEW_GRID_TASK = 'project/add-new-grid-task',
  ADD_NEW_GRID_STAGE = 'project/add-new-grid-stage',
  UPDATE_GRID_TASK = 'project/update-grid-task',
  UPDATE_GRID_STAGE = 'project/update-grid-stage',
  UPDATE_GRID_BLOCK = 'project/update-grid-block',
  UPDATE_GRID_BLOCKS = 'project/update-grid-blocks',
  UPDATE_GRID_BLOCK_FOR_SECTION_PRIVACY = 'project/update-grid-block-for-section-privacy',
  SEND_USER_INVITATION = 'users/invite-user',
  SEND_USER_INVITATION_INDIVIDUAL = 'users/invite-user-individual',
  RESEND_USER_INVITATION = 'users/resend-invitation',
  UPDATE_USER_ROLE = 'users/change-role',
  DELETE_GRID_TASK = 'project/delete-grid-task',
  DELETE_GRID_STAGE = 'project/delete-grid-stage',
  UPDATE_PROJECT_USER_METADATA = 'project/update-project-metadata',
  REORDER_GRID_TASK = 'project/reorder-grid-task',
  REORDER_GRID_STAGE = 'project/reorder-grid-stage',
  CLONE_SECTION = 'project/clone-section',
  SEND_MENTION_NOTIFICATION = 'project/send-mention-notification',
  GET_PUBLISHED_TEMPLATES = 'project/get-published-templates',
  GET_INDUSTRIES_PUBLISHED_TEMPLATES = 'project/get-industries-published-templates',
  CREATE_PROJECT_FROM_TEMPLATE = 'project/create-project-from-template',
  GET_RECENT_TEMPLATES = 'project/get-recent-templates',
  GET_WEBSITE_METADATA = 'project/metascrape',
  GET_TASK_PRIORITIES = 'project/get-task-priorities',
  GET_TASK_IVE_ASSIGNED = 'project/get-task-ive-assigned',
  GET_PLAN_EVENT = 'project/get-plan-event',
  GET_ORG_ALL_PROJECT_LIST = 'project/get-org-all-projects-list',
  UPDATE_PROJECT_USER_ROLE = 'project/update-project-user-role',
  CLONE_PROJECT = 'project/clone-project',
  GET_SECTIONS_DATA = 'project/get-sections-data',
  GET_ALL_TASK = 'project/get-task',
  GET_TASK_BY_TIME = 'project/get-task-by-time',
  GET_PROJECT_COLOR_PALETTE = 'project/get-project-color-palette',
  UPDATE_PROJECT_COLOR = 'project/update-project-color',
  ARCHIVE_OR_RESTORE = 'project/archive-or-restore',
  GET_CUSTOM_FIELDS = 'project/get-custom-fields',
  ADD_EDIT_CUSTOM_META = 'project/add-edit-custom-meta',
  DELETE_CUSTOM_FIELD = 'project/delete-custom-meta',
  UPDATE_CUSTOM_FIELD_SETTING = 'project/add-edit-custom-field-settings',
  GET_TASKLIST_DETAILS_V2 = 'project/section/tasklist-details-v2',
  UPDATE_CUSTOM_FIELD_SETTING_V2 = 'project/add-edit-custom-field-settings-v2',
  RE_ORDER_GRID = 'project/reorder-grid',

  GET_STICKER_PACK_LIST = 'customization/get-status-sticker-list',
  UPDATE_STICKER_PACK_ASSOCIATION = 'customization/update-sticker-pack-association',
  UPDATE_STATUS_DONE_ASSOCIATION = 'customization/update-status-view',

  //Planner
  CREATE_PLANNER_EVENT = 'planner/create-planner-event',
  GET_PLANNER_EVENTS = 'planner/get-planner-events',
  GET_PLANNER_DUE_TASKS = 'planner/get-planner-due-tasks',
  UPDATE_PLANNER_EVENT = 'planner/update-planner-event',
  GET_PLANNER_EVENT_MISC_DETAILS = 'planner/get-planner-event-project-details',
  DELETE_PLANNER_EVENT = 'planner/delete-planner-event',
  GET_PLANNER_DUE_TASKS_FOR_ALL_PLANNER_EVENTS = 'planner/get-planner-due-tasks-for-all-planned-events',
  GET_GOOGLE_EVENTS = 'planner/get-google-events',

  //Messages
  GET_MESSAGES_BY_USER = 'messaging/get-messages-by-user',
  SAVE_MESSAGE_COUNT_BY_USER = 'messaging/save-message-count',
  CHANGE_PINNED_STATUS = 'messaging/pin-or-unpin-message',
  ADD_TAGGED_MEMBERS_THREAD = 'messaging/add-tagged-members-thread',
  GET_FILE_BLOB = 'messaging/getFileBlob',

  //PNC
  GET_ALL_FEEDBACKS = 'feedback/getAllFeedbackData',
  GET_FEEDBACK_BY_ID = 'feedback/getFeedbackById',
  SAVE_FEEDBACK = 'feedback/saveFeedback',
  SEND_FEEDBACK_MENTION_NOTIFICATION = 'feedback/send-mention-feedback-notification',
  DELETE_FEEDBACK = 'feedback/deleteFeedbackDetails',
  EDIT_FEEDBACK = 'feedback/editFeedbackDetails',
  REPLY_FEEDBACK = 'feedback/saveReply',
  UPDATE_STATUS_FEEDBACK = 'feedback/updatedFeedbackStatus',
  EDIT_REPLY = 'feedback/editReply',
  DELETE_REPLY = 'feedback/deleteReply',
  EDIT_FEEDBACK_SCREEN = 'feedback/editFeedbackScreen',
  GET_FEEDBACK_SCREEN_DETAILS = 'feedback/getFeedbackScreenDetails',
  UPDATE_SEVERITY = 'feedback/updateSeverity',
  CREATE_ENVIRONMENT = 'project/pnc-add-environment',
  GET_ENV_DATA = 'project/pnc-get-environment',
  GET_ENV_BY_ID = 'project/pnc-get-environment-by-id',
  EDIT_ENV_DETAILS = 'project/pnc-edit-environment',
  DELETE_ENV = 'project/pnc-delete-environment',
  DELETE_SCREEN = 'feedback/delete-feedback-screen',

  LOG = '/log',

  //UserStoryGenerator
  GEN_USER_STORY = '/gen-user-story',
  ADD_OR_EDIT_EPICS = '/add-or-edit-epics',
  GET_EPIC_USERSTORY = '/get-epic-userstory',
  ADD_OR_EDIT_STORY = '/add-or-edit-story',
  DELETE_EPIC = '/delete-epic',
  DELETE_STORY = '/delete-story',
  CHECK_TASK_STATUS = '/check-task-status',
  HISTORY_OF_REQUIREMENT_FILES = '/history-of-requirement-files',
  DOWNLOAD_REQUIREMENT_FILE = '/download-requirement-file',
  GEN_ACCEPTANCE_CRITERIA = '/gen-acceptance-criteria',
  GET_USERSTORY_DATA = '/get-userstory-data',
  EDIT_ACCEPTANCE_CRITERIA = '/edit-acceptance-criteria',
  GEN_TEST_CASES = '/gen-test-cases',
  EDIT_TEST_CASE = '/edit-test-case',
  DELETE_TEST_CASE = '/delete-test-case',
  NAVIGATION_TO_SINGLE_LINK = '/navigation-to-single-link',
  EXPORT_EPIC = '/usg/export-epic',
}
