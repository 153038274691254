import { Tooltip, message } from 'antd';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { NotificationEventType } from 'migration/models/enums/notification';
import { IProjectState, ITaskListDetails } from 'migration/models/interface';
import { IUserDetails, IUserState } from 'modules/models/interfaces';
import projectService from 'modules/services/ProjectService/project.service';
import { useUserStore } from 'modules/store';
import { useProjectDashboardStore } from 'modules/store/project.store';
import { useLocation } from 'react-router-dom';
import { getCurrentOrganization } from 'utils/commonFunctions';

interface ITaskPriorityComponentProps {
  eachTaskDetail: ITaskListDetails;
  sectionId?: number | string;
  setTaskListDetails: any;
  taskDetails: any;
  notifyChannel?: (
    userDetails: IUserDetails,
    eventType: string,
    eventValue?: string,
  ) => void;
  setEachTaskDetail?: React.Dispatch<React.SetStateAction<ITaskListDetails>>;
  findTaskIdAndUpdateMainTaskList?: (
    eachTaskDetailUpdated: ITaskListDetails,
  ) => void;
}

const TaskPriority: React.FC<ITaskPriorityComponentProps | any> = ({
  eachTaskDetail,
  sectionId,
  notifyChannel,
  setEachTaskDetail,
  findTaskIdAndUpdateMainTaskList,
  setTaskListDetails,
  taskDetails,
}) => {
  return (
    <Tooltip title="Normal" placement="bottom" color={'#2E364C'}>
      <span
        className={`priorityWrap priorityIcon cmnIcon priority cursorDisabled`}
      />
    </Tooltip>
  );
};

export default TaskPriority;
