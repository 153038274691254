import React from 'react';
import Routes from './routes/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0ProviderWithNavigate from './auth/authProviderWithNavigate';
import Modal from 'modules/sharedComponents/components/Modal/Modal';
import { ConfigProvider } from 'antd';
import { usePnCScript } from 'modules/sharedComponents/hooks/usePncScript';

declare global {
  interface Window {
    pnc_key: string;
  }
}

const App: React.FC<{}> = () => {
  usePnCScript();

  return (
    <Router>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Inter, sans-serif',
          },
        }}
      >
        <Auth0ProviderWithNavigate>
          <Routes />
          <Modal />
        </Auth0ProviderWithNavigate>
      </ConfigProvider>
    </Router>
  );
};

export default App;
