import { RoutesEnum } from "../../models/enums/apiRoutes";
import {
    AddStickersValue, ApiAddStickersPackPayload, ApiFeatures, ApiMessage, ApiTaskStickerPacks, StickersDeleteDto, UploadStickerPayload
} from "../../models/interfaces";
import apiService from "../Api.service";

class StickerService {

    public uniqueStickerPackName = async (sticker_pack_name: AddStickersValue) => {
        return apiService.get<ApiMessage>(RoutesEnum.STICKERS_UNIQUE_PACK_NAME, sticker_pack_name);
    };

   
    public uploadStickers = async (upload_sticker_payload: UploadStickerPayload) => {
        return apiService.post<ApiMessage>(RoutesEnum.UPLOAD_STICKERS, upload_sticker_payload);
    }

    public addStickersPack = async (add_task_sticker_pack_payload: ApiAddStickersPackPayload) => {
        return apiService.post<ApiMessage>(RoutesEnum.ADD_STICKERS_PACK, add_task_sticker_pack_payload);
    }

    public getStickersPacks = async () => {
        return apiService.get<ApiTaskStickerPacks>(RoutesEnum.STICKERS_PACKS);
    }

    public getFeatures = async() => {
        return apiService.get<ApiFeatures>(RoutesEnum.FEATURES);
    }

    public stickersDelete = async (delete_pack_id : StickersDeleteDto) => {
        return apiService.post<ApiMessage>(RoutesEnum.DELETE_STICKERS, delete_pack_id)
    }

}

const stickerService = new StickerService();
export default stickerService;
