import { RoutesEnum } from "../../models/enums/apiRoutes";
import {
    AddTaskStickerValue, ApiAddTaskStickersPackPayload, ApiLanguage, ApiMessage, ApiStatusOption, ApiTaskStickerPacks, ApiTeams, StickersDeleteDto, UploadStickerPayload
} from "../../models/interfaces";
import apiService from "../Api.service";

class TaskStickerService {

    public uniqueTaskStickerPackName = async (sticker_pack_name: AddTaskStickerValue) => {
        return apiService.get<ApiMessage>(RoutesEnum.UNIQUE_PACK_NAME, sticker_pack_name);
    };

    public getStatusOption = async () => {
        return apiService.get<ApiStatusOption>(RoutesEnum.STICKER_STATUS_OPTION);
    }

    public getLanguage = async () => {
        return apiService.get<ApiLanguage>(RoutesEnum.LANGUAGES);
    }

    public getTeams = async () => {
        return apiService.get<ApiTeams>(RoutesEnum.TEAM_NAMES);
    }

    public uploadStickers = async (upload_sticker_payload: UploadStickerPayload) => {
        return apiService.post<ApiMessage>(RoutesEnum.UPLOAD_TASK_STICKER, upload_sticker_payload);
    }

    public addTaskStickerPack = async (add_task_sticker_pack_payload: ApiAddTaskStickersPackPayload) => {
        return apiService.post<ApiMessage>(RoutesEnum.ADD_TASK_STICKER_PACK, add_task_sticker_pack_payload);
    }

    public getTaskStickersPacks = async () => {
        return apiService.get<ApiTaskStickerPacks>(RoutesEnum.TASK_STICKER_PACKS);
    }

    public taskStickersDelete = async (delete_pack_id : StickersDeleteDto) => {
        return apiService.post<ApiMessage>(RoutesEnum.DELETE_TASK_STICKERS, delete_pack_id)
    }

}

const taskStickerService = new TaskStickerService();
export default taskStickerService;
