import React, { FC, Fragment, ReactElement, useEffect } from "react";
import { Route, Routes as RouterRoutes } from "react-router-dom";
import { IRouteConfig } from "./routes.interface";
import { routes } from "./routeList";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoaderStore } from "modules/store";
import { ILoaderState } from "modules/models/interfaces";



const ProtectedRoute: React.FC<{ children: ReactElement }> = ({ children }) => {
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  useEffect(() => {
    if (isLoading) {
      setLoaderState("active");
    } else {
      setLoaderState("inactive");
      !isAuthenticated && loginWithRedirect();
    }
  }, [isLoading]);

  return isAuthenticated ? children : null;
};

const SubRoutes: FC<{ subRoutes: IRouteConfig[] }> = ({ subRoutes }) => (
  <RouterRoutes>
    {subRoutes.map((subRoute) => (
      <Fragment key={subRoute.path}>
        {subRoute.isProtected ? (
          <Route
            path={subRoute.path}
            element={
              <ProtectedRoute>
                <subRoute.component
                 
                />
              </ProtectedRoute>
            }
          />
        ) : (
          <subRoute.component

          />
        )}
        {subRoute.subRoutes && <SubRoutes subRoutes={subRoute.subRoutes} />}
      </Fragment>
    ))}
  </RouterRoutes>
);

const Routes = () => {

  return (
    <RouterRoutes>
      {routes.map((route) => (
        <Fragment key={route.path}>
          {route.isProtected ? (
            <Route
              path={route.path}
              element={
                <ProtectedRoute>
                  <route.component

                  />
                </ProtectedRoute>
              }
            />
          ) : (
            <Route
              key={route.path}
              path={route.path}
              element={
                <route.component

                />
              }
            />
          )}
          {route.subRoutes && <SubRoutes subRoutes={route.subRoutes} />}
        </Fragment>
      ))}
    </RouterRoutes>
  );
};

export default Routes;
