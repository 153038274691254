import { Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { getContrastColor } from "utils/commonFunctions";

interface IPriorityDropdownProps {
  options: any[];
  value: string;
  onChange: (value: any) => void;
  onSelect: (value: any) => void;
  disabled?: boolean;
}

const PriorityDropdown = (props: IPriorityDropdownProps) => {
  const { options, value, onChange, onSelect, disabled = false } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = React.useRef<any>(null);

  const currentValue = options.find((i) => i.value === value) ?? {};
  const color = currentValue.color ?? "";

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        !isDropdownOpen
      ) {
        setIsEdit(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef, isDropdownOpen]);

  return (
    <>
      {isEdit || !value ? (
        <div id="selectWrap" ref={selectRef}>
          <Select
            style={{
              width: '100%',
            }}
            disabled={true}
            options={options}
            popupClassName="customSelect"
            value={value}
            onChange={(e) => {
              onChange(e);
              setIsEdit(false);
            }}
            onSelect={onSelect}
            labelRender={(option) => {
              return (
                <span style={{ background: option.value }}>{option.label}</span>
              );
            }}
            virtual={false}
            optionRender={(option, { index }) => {
              const color = options[index].color;
              return (
                <Tooltip
                  title={option.value}
                  placement={'left'}
                  color={'#2E364C'}
                >
                  <div
                    className="customOption"
                    key={index}
                    style={{
                      background: color,
                      color: getContrastColor(color),
                    }}
                    title=""
                  >
                    {option.value}
                  </div>
                </Tooltip>
              );
            }}
            onDropdownVisibleChange={(open) => {
              setIsDropdownOpen(open);
              if (!open) setIsEdit(false);
            }}
          />
        </div>
      ) : (
        <div
          //   onClick={() => handleToggleEdit(i.id, record.task_id, i.mapping_id)}
          onClick={() => !disabled && setIsEdit(true)}
          className="statusCellValue"
          style={{
            background: color,
            color: getContrastColor(color),
          }}
        >
          <span>{currentValue.value}</span>
        </div>
      )}
    </>
  );
};

export default PriorityDropdown;
