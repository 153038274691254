import { FC, Fragment, useEffect, useState } from "react";
import { Tooltip } from "antd";
import GridListDetails from "./GridListDetails";
import GridListDetailsV4 from 'migration/GridListV4/GridListDetails';
import templateService from 'modules/services/TemplateService/Template.service';
import {
  ISection,
  ITemplateData,
} from 'modules/models/interfaces/template.interface';
import { useTemplateStore } from '../../store';
import MeatBallMenu from '../MeatBallMenu';
import { IGridList, IGridListBlockDetail } from 'migration/models/interface';
import { groupGridBlock } from 'utils/commonFunctions';

const GridList: FC<{
  sectionId: string | number;
  sectionDetails: ISection;
}> = ({ sectionDetails, sectionId }) => {
  const [sectionToggleState, setSectionToggleState] = useState<
    'expanded' | 'collapsed'
  >('expanded');
  const [isEditingSectionName, setIsEditingSectionName] =
    useState<boolean>(false);
  const [, setShowSidePanel] = useState<boolean>(false);

  const [sectionName, setSectionName] = useState<string>('');
  const { templateDetails } = useTemplateStore();
  const { sections, setSections } = useTemplateStore();
  const [gridListDetails, setGridListDetails] = useState<any>({
    ...sectionDetails,
    pack_id: 1,
    status_done_id: 1,
  });

  let customfieldCmsData = {
    add_column_tooltip: 'Add Column',
    locale: 'en',
    cf_tooltip_modal_options: [
      {
        category_id: 1,
        category_name: 'Essentials',
      },
      {
        category_id: 2,
        category_name: 'Most Commonly Used',
      },
    ],
  };

  useEffect(() => {
    (async () => {
      if (typeof sectionDetails.section_id === 'number') {
        await getSectionDetails();
      } else {
        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            task_details: null,
            stage_details: null,
            block_details: null,
          },
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (typeof sectionId === 'string') {
      setIsEditingSectionName(true);
    } else {
      setIsEditingSectionName(false);
    }
  }, [sectionId]);

  useEffect(() => {
    if (isEditingSectionName) {
      setSectionName(sectionDetails?.section_name ?? '');
    }
  }, [isEditingSectionName]);

  useEffect(() => {
    if (!isEditingSectionName) {
      setSectionName(sectionDetails?.section_name);
    }
  }, [sectionDetails.section_name]);

  const handleSectionToggle = () => {
    setSectionToggleState((prev) =>
      prev === 'collapsed' ? 'expanded' : 'collapsed',
    );
  };

  useEffect(() => {
    if (sectionDetails.is_edit_mode) {
      setTimeout(() => {
        setIsEditingSectionName(true);
      }, 1500);
    }
  }, [sectionDetails]);

  const createSection = async (
    templateDetals: ITemplateData,
    oldSection: ISection,
  ) => {
    const response = await templateService.createNewSection({
      template_id: templateDetals.template_id!,
      section_type_id: 2,
      section_name: sectionName,
      section_rank: oldSection.section_rank,
    });

    if (response.messageId === 1) {
      setSections(
        sections?.map((eachSection: ISection) => {
          const _section = eachSection;
          if (_section.section_id === sectionId) {
            _section.section_name = sectionName;
            _section.section_id = response.data.section_id;
          }
          return _section;
        }),
      );
    }
  };

  const updateSection = async (
    templateDetals: ITemplateData,
    oldSection: ISection,
  ) => {
    const result = await templateService.updateSection({
      template_id: templateDetals.template_id!,
      sections: [
        {
          section_id: +oldSection.section_id,
          section_name: sectionName,
        },
      ],
    });

    if (result.messageId === 1) {
      setSections(
        sections?.map((eachSection) => {
          const _section = eachSection;
          _section.is_edit_mode = false;
          if (_section.section_id === sectionId) {
            _section.section_name = sectionName;
          }
          return _section;
        }),
      );
    }
  };

  const sectionNameOnBlurHandler = async (
    _event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    setIsEditingSectionName(false);
    setSectionName((prev) => prev?.trim());

    if (templateDetails?.template_id && sections) {
      const oldSection = sections.find(
        (eachSection) => eachSection.section_id === sectionId,
      );
      if (typeof oldSection?.section_id === 'string' && sectionName === '') {
        setSections(
          sections?.filter(
            (eachSection) => eachSection.section_id !== sectionId,
          ),
        );
      } else if (
        typeof oldSection?.section_id === 'string' &&
        sectionName !== ''
      ) {
        await createSection(templateDetails, oldSection);
      } else if (
        typeof oldSection?.section_id === 'number' &&
        sectionName?.trim() !== '' &&
        oldSection.section_name !== sectionName
      ) {
        await updateSection(templateDetails, oldSection);
      }
    }
  };

  const sectionNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.currentTarget?.value;
    setSectionName(value);
  };

  const saveOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sectionNameOnBlurHandler(event);
    }
  };
  const getSectionDetails = async () => {
    try {
      const response = await templateService.getTemplateById(
        templateDetails.template_id,
      );
      let eachDetails = response.data.sections.filter(
        (section: any) => section.section_id === sectionId,
      )[0];
      if (
        eachDetails?.grid_list_details?.block_details !== null &&
        eachDetails?.grid_list_details!.block_details!.length > 0
      ) {
        let updatedSection = {
          ...eachDetails,
          grid_list_details: {
            ...eachDetails.grid_list_details,
          },
        };
        updatedSection.grid_list_details.block_details = updatedSection
          .grid_list_details?.block_details
          ? (groupGridBlock(
              updatedSection.grid_list_details?.block_details,
              updatedSection.grid_list_details.task_details!,
            ) as Array<IGridListBlockDetail[]>)
          : null;

        eachDetails = updatedSection as IGridList;
      }
      setGridListDetails(eachDetails);
      setSections(
        sections.map((section: any) => {
          if (section.section_id === sectionId) {
            return eachDetails;
          }
          return section;
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <div className="createNewSecMainContent">
        <div className="firstTopic">
          <div className="taskNameHeadingPane">
            <div className="itemNameBlk">
              <div className="nameOuterBlk">
                <Tooltip
                  title="Toggle task view"
                  color={'#2E364C'}
                  placement="bottom"
                >
                  <span
                    className={`sidePanelIcon ${
                      sectionToggleState === 'expanded'
                        ? 'arrow-down'
                        : 'arrow-right'
                    }`}
                    color={'#2E364C'}
                    onClick={handleSectionToggle}
                  />
                </Tooltip>
                {isEditingSectionName ? (
                  <input
                    className="section-text-input"
                    placeholder="New Section"
                    onBlur={sectionNameOnBlurHandler}
                    onChange={sectionNameChangeHandler}
                    onKeyDown={saveOnEnter}
                    value={sectionName}
                    autoFocus={true}
                    maxLength={50}
                  />
                ) : (
                  <span
                    className="section-text-span"
                    onClick={() => {
                      setIsEditingSectionName(true);
                    }}
                  >
                    {sectionName?.trim() === ''
                      ? sections?.find(
                          (eachSection) => eachSection.section_id === sectionId,
                        )?.section_name
                      : sectionName}
                  </span>
                )}
              </div>
              {!isEditingSectionName && (
                <Fragment>
                  <MeatBallMenu
                    sectionType="grid-list"
                    sectionDetails={sectionDetails}
                    setShowSidePanel={setShowSidePanel}
                  />
                </Fragment>
              )}
            </div>
          </div>

          <GridListDetailsV4
            sectionDetails={sectionDetails}
            sectionToggleState={sectionToggleState}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            getSectionDetails={getSectionDetails}
            customfieldCmsData={customfieldCmsData}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default GridList;
