import { WarningOutlined } from '@ant-design/icons/lib/icons';
import cryptoRandomString from 'crypto-random-string';

import { getPageSpecificData } from 'utils/commonFunctions';
import { IProjectState, ITaskList } from './models/interface';
import { useLocalizationStore } from 'modules/store/localization.store';
import { useProjectDashboardStore } from 'modules/store/project.store';
import { useTemplateStore } from 'modules/components/Pages/Template/store';

interface IAddTask {
  taskDetails?: ITaskList;
  setTaskListDetails?: React.Dispatch<React.SetStateAction<ITaskList>>;
  sectionId?: any;
}

const AddTask: React.FC<IAddTask> = ({ taskDetails, sectionId }) => {
  const { sections, setSections } = useTemplateStore();
  const addTask = () => {
    const _task_list_details = [...(taskDetails?.section_details ?? [])];
    let max = 0;
    const newTask = {
      task_id: cryptoRandomString({ length: 5 }),
      task_rank: max + 1,
      task_name: '',
      due_date: '',
      assignee_dp: '',
      assignee_id: null,
      assignee_name: '',
      assignee_email: '',
      task_status_id: 1,
      task_priority_id: 2,
      task_status_name: 'To Do',
      task_priority_name: 'Normal',
      created_by: '',
      show: true,
    };

    if (taskDetails?.section_details?.length) {
      let emptyTask = taskDetails?.section_details?.findIndex((ele) => {
        return ele?.task_name?.trim() === '';
      });
      if (emptyTask && emptyTask < 0) {
        max = taskDetails?.section_details?.reduce(function (prev, current) {
          return prev?.task_rank > current?.task_rank ? prev : current;
        }).task_rank;

        _task_list_details.push(newTask);

        setSections(
          sections?.map((mainObj) => {
            if (mainObj.section_id === sectionId) {
              return {
                ...mainObj,
                section_details: _task_list_details,
              };
            }
            return mainObj;
          }),
        );
      }
      return;
    }
    setSections(
      sections?.map((mainObj) => {
        if (mainObj.section_id === sectionId) {
          return {
            ...mainObj,
            section_details: [newTask],
          };
        }
        return mainObj;
      }),
    );
  };

  return (
    <div className="addTask">
      <div
        className="itemNameBlk addFolder"
        onClick={() => addTask()}
        onKeyDown={() => {}}
      >
        <span className="addPlusSolid" />
        <span className="itemName">Add Task</span>
        <span id={`filter-warning-${taskDetails?.section_id}`}></span>
      </div>
    </div>
  );
};

export default AddTask;
