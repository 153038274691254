import { ITemplateThumbnail, IThumbnail } from 'modules/models/interfaces/template.interface';
import { create } from 'zustand';

interface AppState {
  tloading: boolean;
  setTLoading: (tloading: boolean) => void;
  uploadedImages: File[],
  setUploadedImages: (uploadedImages: File[]) => void;
  thumbnails: (IThumbnail | ITemplateThumbnail)[],
  setThumbnails: (thumbnails: (IThumbnail | ITemplateThumbnail)[]) => void;
  banner: string | null;
  setBanner: (banner: string | null) => void;
}

export const useBasicInfoStore = create<AppState>((set) => ({
  tloading: false,
  setTLoading: (tloading) => set({ tloading }),

  thumbnails: [],
  setThumbnails: (thumbnails: (IThumbnail | ITemplateThumbnail)[]) => set({ thumbnails }),

  uploadedImages: [],
  setUploadedImages: (uploadedImages: File[]) => set({ uploadedImages }),

  banner: null,
  setBanner: (banner: string | null) => set({ banner }),
}));

export { };
