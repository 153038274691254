import { FC, Fragment } from 'react';
import { Dropdown, message } from 'antd';
import {
  IGridList,
  IGridListBlockDetail,
  IGridListTaskDetail,
  IModalState,
} from 'migration/models/interface';
import { useModalStore } from 'modules/store';
import templateService from 'modules/services/TemplateService/Template.service';
import { groupGridBlock } from 'utils/commonFunctions';
import { markTaskDeleteInMessageThread } from 'utils/firebaseCommonFunctions';
import { SectionTypeMessages } from 'migration/models/enums/messages';
import { gridListCmsData } from '../GridListCmsDatas/GridListCmsData';
import { useTemplateStore } from 'modules/components/Pages/Template/store';

const TaskDelete: FC<{
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  taskDetails: IGridListTaskDetail;
}> = ({ gridListDetails, setGridListDetails, taskDetails }) => {
  const { modalState, setModalState } = useModalStore(
    (state: IModalState) => state,
  );
  const { templateDetails, setSections } = useTemplateStore();

  const deleteTaskHandler = () => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          deleteItemDetails: {
            type: 'task',
            id: taskDetails.task_id,
          },
        },
      } as IGridList);

      setModalState({
        modalHeader: (
          <Fragment>
            <h3>{gridListCmsData?.lbl_delete_task_modal_header}</h3>
            <span
              className="material-icons-outlined modal-close"
              onClick={() => modalCloseHandler()}
              onKeyDown={() => {}}
            >
              close
            </span>
          </Fragment>
        ),
        modalBody: (
          <Fragment>
            <p>
              {gridListCmsData?.lbl_delete_task_modal_content1}{' '}
              <strong>{taskDetails.task_name}</strong>?
            </p>
            <p>{gridListCmsData?.lbl_delete_task_modal_content2}</p>
          </Fragment>
        ),
        modalFooter: (
          <Fragment>
            <button
              className="noStyle cancel_deleteFolder"
              onClick={() => modalCloseHandler()}
            >
              {gridListCmsData?.cta_delete_modal_cancel}
            </button>

            <button
              className="deleteBtnStyle"
              onClick={() =>
                (async () => {
                  await confirmAction();
                })()
              }
            >
              {gridListCmsData?.cta_delete_task_modal_confirm}
            </button>
          </Fragment>
        ),
        shouldShow: true,
      });
    }
  };

  const modalCloseHandler = () => {
    let section = {
      ...gridListDetails,
    };
    delete section.grid_list_details!.deleteItemDetails;

    setGridListDetails(section);

    setModalState({ ...modalState, shouldShow: false });
  };

  const modalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
  };

  const confirmAction = async (): Promise<void> => {
    try {
      let isLastItem: boolean = false;

      if (gridListDetails.grid_list_details?.task_details!.length === 1) {
        isLastItem = true;
      }

      const response = await templateService.deleteGridTask({
        template_id: templateDetails?.template_id!,
        section_id: +gridListDetails.section_id,
        task_id: taskDetails.task_id,
      });

      if (response.messageId === 1) {
        const templateResponse = await templateService.getTemplateById(
          templateDetails.template_id,
        );
        setSections(templateResponse.data.sections);
        let blockDetails =
          gridListDetails.grid_list_details?.block_details!.flat();
        let newTaskDetails = gridListDetails.grid_list_details?.task_details!;
        let updatedBlockList: IGridListBlockDetail[] = [];

        updatedBlockList = blockDetails!.filter(
          (ele: IGridListBlockDetail) => ele.task_id !== taskDetails.task_id,
        )!;

        let updatedTaskList = newTaskDetails
          .filter(
            (ele: IGridListTaskDetail) => ele.task_id !== taskDetails.task_id,
          )
          .filter((ele: IGridListTaskDetail) => !ele.isNew)!;

        let targetBlockList = blockDetails!.filter(
          (ele: IGridListBlockDetail) => ele.task_id === taskDetails.task_id,
        )!;

        let newTaskList = updatedTaskList.length > 0 ? updatedTaskList : null;

        delete gridListDetails.grid_list_details!.deleteItemDetails;

        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            task_details: newTaskList,
            block_details:
              updatedBlockList.length > 0
                ? (groupGridBlock(updatedBlockList, newTaskList) as Array<
                    IGridListBlockDetail[]
                  >)
                : null,
          },
        } as IGridList);

        modalClose();

        await markTaskDeleteInMessageThread(
          `${SectionTypeMessages.GRID_LIST_TASK}-${
            process.env.REACT_APP_STAGE
          }-CS-${templateDetails?.template_id}-${+gridListDetails.section_id}-${
            taskDetails.task_id
          }`,
        );

        for (let block of targetBlockList) {
          await markTaskDeleteInMessageThread(
            `${SectionTypeMessages.GRID_LIST_BLOCK}-${
              process.env.REACT_APP_STAGE
            }-CS-${
              templateDetails?.template_id
            }-${+gridListDetails.section_id}-${block.block_id}`,
          );
        }
      } else {
        modalClose();
        message.error(gridListCmsData?.lbl_generic_error_message, 3);
      }
    } catch (err) {
      message.error(gridListCmsData?.lbl_generic_error_message, 3);
      modalClose();
    }
  };

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'delete',
            label: (
              <div className="customDropDownItem">
                <span className="deleteBin cmnIcon"></span>
                {gridListCmsData?.lbl_delete_task_modal_header}
              </div>
            ),
          },
        ],
        onClick: () => {
          deleteTaskHandler();
        },
      }}
      placement="bottomLeft"
      trigger={['click']}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="blkMore"
      >
        More
      </div>
    </Dropdown>
  );
};

export default TaskDelete;
