import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useTeamsStore } from '../teamsStore';
import { RangePickerProps } from 'antd/es/date-picker';

interface IAccountCreatedOnProps {
  label: string;
  filterOptions: {
    selectedTeamTypes: string[];
    setSelectedTeamTypes: React.Dispatch<React.SetStateAction<string[]>>;
    selectedTeamStatus: string[];
    setSelectedTeamStatus: React.Dispatch<React.SetStateAction<string[]>>;
    startDate: Dayjs | null;
    setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
    endDate: Dayjs | null;
    setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  }
}
const { RangePicker } = DatePicker;

const AccountCreatedOn: React.FC<IAccountCreatedOnProps> = ({ label, filterOptions }) => {
  const {
    setFilterStartDate,
    setFilterEndDate,
  } = useTeamsStore();
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };
  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[],
  ) => {
    filterOptions.setStartDate(dates![0]);
    filterOptions.setEndDate(dates![1]);
    // setFilterStartDate(dateStrings[0]);
    // setFilterEndDate(dateStrings[1]);
  };
  return (
    <>
      <label>{label}</label>
      <RangePicker
        onChange={onRangeChange}
        disabledDate={disabledDate}
        placement={'bottomRight'}
        value={[
          filterOptions.startDate,
          filterOptions.endDate,
        ]}
      />
    </>
  );
};

export default AccountCreatedOn;
