import { FC } from 'react';
import AddStageCTA from './Stage/AddStageCTA';
import AddTaskCTA from './Task/AddTaskCTA';
import Table from './Table/Table';
import { IGridList, IProjectSections } from 'migration/models/interface';

export const GRID_VIEW_TYPES = [
  {
    id: 1,
    className: '',
  },
  {
    id: 2,
    className: 'gridDateView',
  },
  {
    id: 3,
    className: '',
  },
  {
    id: 4,
    className: 'gridStatusView',
  },
];

const GridListDetailsV4: FC<{
  sectionDetails: IProjectSections | any;
  sectionToggleState: 'collapsed' | 'expanded';
  gridListDetails: IGridList | any;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  getSectionDetails: () => void;
  customfieldCmsData?: any;
}> = ({
  sectionDetails,
  sectionToggleState,
  gridListDetails,
  setGridListDetails,
  getSectionDetails,
  customfieldCmsData,
}) => {
  const getViewClass = () => {
    const view_id = gridListDetails?.view_id;
    if (gridListDetails?.compactView) return '';
    return GRID_VIEW_TYPES.find((i: any) => i.id === view_id)?.className ?? '';
  };

  if (sectionToggleState !== 'expanded') return <></>;

  const taskDetails = gridListDetails?.grid_list_details?.task_details || [];

  return (
    <div className="gridList2DOuterWrap">
      <div className={'gridList2DWrap ' + getViewClass()}>
        <Table
          sectionDetails={sectionDetails}
          gridListDetails={gridListDetails}
          setGridListDetails={setGridListDetails}
          getSectionDetails={getSectionDetails}
          customfieldCmsData={customfieldCmsData}
        />
      </div>
      {taskDetails.length > 0 && (
        <AddStageCTA
          gridListDetails={gridListDetails}
          setGridListDetails={setGridListDetails}
        />
      )}
      <AddTaskCTA
        gridListDetails={gridListDetails}
        setGridListDetails={setGridListDetails}
      />
    </div>
  );
};

export default GridListDetailsV4;
