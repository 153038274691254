import {
  ApiTeamsDetails,
  ApiTeamStickerPack,
  GetTeamsDetailsPayload,
  OganizationIdPayload,
  UpdateTeamStatusPayload,
} from 'modules/models/interfaces/teams.interface';
import { RoutesEnum } from '../../models/enums/apiRoutes';
import apiService from '../Api.service';

class TeamsService {
  public getTeamsDetails = async (
    _getTeamsDetailsPayload: GetTeamsDetailsPayload,
  ) => {
    return apiService.get<ApiTeamsDetails>(
      RoutesEnum.TEAM_DETAILS,
      _getTeamsDetailsPayload,
    );
  };

  public getTeamStickerPacks = async (
    _oganizationIdPayload: OganizationIdPayload,
  ) => {
    return apiService.post<ApiTeamStickerPack>(
      RoutesEnum.TEAM_STICKER_PACK,
      _oganizationIdPayload,
    );
  };

  public updateTeamStatus = async (
    _updateTeamStatusPayload: UpdateTeamStatusPayload,
  ) => {
    return apiService.post<ApiTeamStickerPack>(
      RoutesEnum.TEAM_STATUS_UPDATE,
      _updateTeamStatusPayload,
    );
  };

  public getMasterData = async (payload: { master_data_type: string }) => {
    return apiService.get<{
        messageId: string,
        message: string,
        data: any;
      }>(RoutesEnum.MASTER_DATA, payload);
  };
}

const teamsService = new TeamsService();
export default teamsService;
