import { FC, useMemo } from 'react';
import { Dropdown, Tooltip } from 'antd';
import {
  IGridList,
  IGridListBlockDetail,
  IProjectState,
} from 'migration/models/interface';
import { useProjectDashboardStore } from 'modules/store';
import TodoIcon from 'assets/e-images/todo-icon.svg';

const BlockStatus: FC<{
  blockId: number;
  blockDetails: IGridListBlockDetail;
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  updateBlock: (block: IGridListBlockDetail) => Promise<void>;
}> = ({ blockDetails, gridListDetails }) => {
  const { taskListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const statusItems = useMemo(() => {
    const defaultItems = [
      {
        label: (
          <div className="customDropDownItem">
            <span className="cmnIcon">
              <img src={TodoIcon} alt="UserIcon" />
            </span>
            {taskListCmsData?.task_status_options[0].status_name}
          </div>
        ),
        key: 1,
      },
    ];

    if (blockDetails.block_status_id === 2)
      return defaultItems.filter((item) => item.key !== 1);

    return defaultItems.filter(
      (item) => item.key !== blockDetails.block_status_id,
    );
  }, [blockDetails, gridListDetails]);

  return (
    <Tooltip title={'To Do'} color={'#2E364C'} placement="left">
      {
        <Dropdown
          menu={{
            items: statusItems,
          }}
          placement="bottomLeft"
        >
          <div className="blkStatus cursorDisabled">Status</div>
        </Dropdown>
      }
    </Tooltip>
  );
};

export default BlockStatus;
