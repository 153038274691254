import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { db, storage } from 'utils/firebase';
import { Dropdown, Menu, MenuProps, Popconfirm, Popover, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import cryptoRandomString from 'crypto-random-string';
import { useUserStore } from '../../modules/store';
import { IUserDetails } from '../models/interface';
import {
  formatMessageItems,
  generateUserAvatar,
  getDateFormat,
  modifyMessageString,
  removeOverlay,
} from 'utils/commonFunctions';
import Editor from 'migration/Editor/Editor';
import { deleteObject, ref } from '@firebase/storage';
import { SectionTypeMessages } from 'migration/models/enums/messages';
import { TooltipPlacement } from 'antd/es/tooltip';
import { getMessageThreadCount } from 'utils/firebaseCommonFunctions';
import { useTemplateStore } from 'modules/components/Pages/Template/store';
import { messagingCmsData } from 'migration/CmsDataAsObject/MessageThread';
import GetMessageIcon from './GetMessageIcon';
const { SubMenu } = Menu;

enum MessageThreadOption {
  EDIT_MESSAGE = 'edit_message',
  DELETE_MESSAGE = 'delete_message',
}

const adminUser = {
  full_name: process.env.REACT_APP_CLEVER_SORT_USER_NAME,
  user_id: Number(process.env.REACT_APP_CLEVER_SORT_USER_ID) || -1,
};
// interface IMessageInfo {
//   is_visible: boolean;
//   count: number;
// }

const cleversortUser = {
  full_name: process.env.REACT_APP_CLEVER_SORT_USER_NAME,
  user_id: Number(process.env.REACT_APP_CLEVER_SORT_USER_ID) || -1,
  org_id: process.env.REACT_APP_CLEVER_SORT_ORG_ID,
};

interface ICreateMessageThreadPayLoad {
  created_at: Timestamp;
  updated_at: Timestamp;
  project_id: number | undefined;
  section_id: string | number;
  organization_id: string | undefined;
  member_id: any;
  all_members_id: any;
  task_name: string;
  project_name: string | undefined;
  is_done: boolean;
  is_deleted: boolean;
  messages: ReturnType<typeof arrayUnion>;
  is_usg_thread: boolean;
  epic?: string;
  storyTitle?: string;
}

const MessageThreadV2: React.FC<{
  componentId?: string;
  sectionType: string;
  sectionId: number | string;
  taskId: number | string;
  threadName: string;
  taskDetails?: any;

  placement?: TooltipPlacement | any;
  isBlockedView?: boolean;
  compactView?: boolean;
  isTemplate?: boolean;
  isDashBoardOrPlanner?: boolean;
  isUserStoryThread?: boolean;
  epicName?: string;
}> = ({
  componentId,
  sectionType,
  sectionId,
  taskId,
  threadName,
  taskDetails,
  placement = 'bottomRight',
  isBlockedView = false,
  compactView = false,
  isTemplate = false,
  isDashBoardOrPlanner = false,
}) => {
  const refDiv = useRef<HTMLDivElement>(null),
    messagePopupRef = useRef<HTMLDivElement>(null),
    messageBodyRef = useRef<HTMLDivElement>(null);
  const [showMessagesPopup, setShowMessagesPopup] = useState(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [messageId, setMessageId] = useState<string>('');
  const [messageContent, setMessageContent] = useState<string>('');
  const [messages, setMessages] = useState<any>([]);
  const [openPopover, setOpenPopover] = useState<{
    [key: string]: boolean;
  } | null>(null);
  // const [messageInfo, setMessageInfo] = useState<IMessageInfo>({
  //   is_visible: false,
  //   count: 0,
  // });

  const { templateDetails } = useTemplateStore();

  const [uniqueId, setUniqueId] = useState<string>(''),
    [
      ifClickedOnTextFieldAfterSelectDelete,
      setIfClickedOnTextFieldAfterSelectDelete,
    ] = useState<boolean>(false);

  const { userDetails } = useUserStore((state: any) => state);

  let stageENV =
    process.env.REACT_APP_STAGE === 'prod' ||
    process.env.REACT_APP_STAGE === 'staging'
      ? 'prod'
      : process.env.REACT_APP_STAGE;

  let messageThreadId = `${sectionType}-${stageENV}-CS-${templateDetails?.template_id}-${sectionId}-${taskId}`;

  const isCompactView = compactView;

  const [isMessageCountExist, setIsMessageCountExist] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const count = await getMessageThreadCount(messageThreadId);
      if (count > 0) {
        setIsMessageCountExist(true);
      } else {
        setIsMessageCountExist(false);
      }
    })();
  }, [isMessageCountExist]);

  const sendMessage = async (
    data: string,
    parsedData: any,
    attachmentUrls: any[],
  ) => {
    const docRef = doc(db, 'message-thread-admin', messageThreadId);
    const docSnap = await getDoc(docRef);

    if (messageId === undefined || messageId === '') {
      if (!docSnap.exists()) {
        let createMessageThreadPayload: ICreateMessageThreadPayLoad | any = {
          created_at: Timestamp.now(),
          updated_at: Timestamp.now(),
          section_id: sectionId,
          task_name: threadName,
          is_done: taskDetails?.task_status_id === 3,
          is_deleted: false,
          messages: arrayUnion({
            message_id: cryptoRandomString({ length: 10 }),
            attachments: attachmentUrls ?? [],
            user_id: userDetails.user_id,
            content: modifyMessageString(data),
            created_at: Timestamp.now(),
            updated_at: Timestamp.now(),
            is_delete: false,
            is_edited: false,
            is_archive: false,
          }),
          is_usg_thread: false,
        };
        await setDoc(
          doc(db, 'message-thread-admin', messageThreadId),
          createMessageThreadPayload,
        );
      } else {
        let UpdateMessageThreadPayload: {
          messages: ReturnType<typeof arrayUnion>;
          task_name: string;
          project_name?: string;
          organization_id?: string;
          is_done: boolean;
          updated_at: Timestamp;
          is_usg_thread: boolean;
          epic?: string;
          storyTitle?: string;
        } = {
          messages: arrayUnion({
            message_id: cryptoRandomString({ length: 10 }),
            attachments: attachmentUrls,
            user_id: userDetails.user_id,
            content: modifyMessageString(data),
            created_at: Timestamp.now(),
            updated_at: Timestamp.now(),
            is_delete: false,
            is_edited: false,
            is_archive: false,
          }),
          task_name: threadName,
          is_done: taskDetails?.task_status_id === 3,
          updated_at: Timestamp.now(),
          is_usg_thread: false,
        };

        await updateDoc(
          doc(db, 'message-thread-admin', messageThreadId),
          UpdateMessageThreadPayload,
        );
      }
    } else {
      let document = await getDoc(
        doc(db, 'message-thread-admin', messageThreadId),
      );
      let messages = document.data()!.messages;
      let props = messages.find((ele: any) => ele.message_id === messageId);
      let existingAttachments = [...props.attachments];
      props.content = modifyMessageString(data);
      props.updated_at = Timestamp.now();
      props.attachments = attachmentUrls;
      props.is_edited = true;

      await updateDoc(doc(db, 'message-thread-admin', messageThreadId), {
        messages: messages,
        updated_at: Timestamp.now(),
        is_done: taskDetails?.task_status_id === 3,
      });

      existingAttachments
        .filter(
          (eleOuter: any) =>
            !attachmentUrls.map((ele) => ele.url).includes(eleOuter.url),
        )
        .forEach((ele) => {
          const desertRef = ref(storage, ele.url);
          deleteObject(desertRef);
        });

      cancelEditing();
      localStorage.removeItem('currentMessageId');
    }
  };

  const isAdminMessage = (user_id: number) => {
    return user_id === adminUser.user_id;
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === MessageThreadOption.EDIT_MESSAGE) {
      setMessageId(localStorage.getItem('currentMessageId')!);
      editMessage(localStorage.getItem('currentMessageId')!);
      setEditing(true);
    }
  };

  const editMessage = async (messageId: string) => {
    let data = await getDoc(doc(db, 'message-thread-admin', messageThreadId));
    let messages = data.data()!.messages;

    let text = messages.find(
      (ele: any) => ele.message_id === messageId,
    ).content;
    setMessageContent(text);
  };

  const deleteMessage = async (messageId: string) => {
    let data = await getDoc(doc(db, 'message-thread-admin', messageThreadId));
    let messages = data.data()!.messages;

    let props = messages.find((ele: any) => ele.message_id === messageId);

    props.updated_at = Timestamp.now();
    props.is_delete = true;

    await updateDoc(doc(db, 'message-thread-admin', messageThreadId), {
      messages: messages,
      updated_at: Timestamp.now(),
    });
    setOpenPopover(openPopover ? { ...openPopover, [messageId]: false } : null);
  };

  const closeMessagePopup = () => {
    setShowMessagesPopup(false);
    setShowEmojiPicker(false);
    cancelEditing();
    cancelDeleting();
  };

  const toggleMessagePopup = () => {
    if (showMessagesPopup) {
      closeMessagePopup();
    } else {
      setShowMessagesPopup(true);
    }
  };

  const cancelEditing = () => {
    setMessageId('');
    setMessageContent('');
    setEditing(false);
  };

  const cancelDeleting = () => {
    localStorage.removeItem('currentMessageId');

    if (openPopover) {
      const tempOpenPopover = { ...openPopover };

      for (let key in tempOpenPopover) {
        if (tempOpenPopover.hasOwnProperty(key)) {
          tempOpenPopover[key] = false;
        }
      }

      setOpenPopover(tempOpenPopover);
    } else {
      setOpenPopover(null);
    }
  };
  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeMessagePopup();
      }
    };
    window.addEventListener('keydown', handleEscKeyPress);

    let messageString = 'message-thread-admin';
    const unSub = onSnapshot(doc(db, messageString, messageThreadId), (doc) => {
      if (doc.exists()) {
        setMessages(doc.data().messages);
      }
    });
    return () => {
      window.removeEventListener('keydown', handleEscKeyPress);
      unSub();
      removeOverlay();
    };
  }, []);

  useEffect(() => {
    if (messages.length && messageBodyRef.current) {
      // refDiv.current?.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'end',
      // });
      // if (messageBodyRef.current.children.length > 6) {
      messageBodyRef.current.scrollTo({
        top: messageBodyRef.current.scrollHeight,
        behavior: 'smooth',
      });
      // }

      let tempEl = {};
      messages.forEach((el: any) => {
        tempEl = { ...tempEl, [el.message_id]: false };
      });
      setOpenPopover(tempEl);
    } else {
      setOpenPopover(null);
    }
  }, [messages]);

  useEffect(() => {
    setUniqueId(
      `${componentId}_${templateDetails?.template_id}_${taskDetails?.task_id}_${sectionId}`,
    );
  }, [taskDetails, componentId]);
  useEffect(() => {
    if (
      messageThreadId &&
      messages?.length &&
      (isCompactView ||
        ([
          SectionTypeMessages.GRID_LIST_STAGE,
          SectionTypeMessages.GRID_LIST_BLOCK,
          SectionTypeMessages.GRID_LIST_TASK,
        ].includes(sectionType as SectionTypeMessages) &&
          componentId &&
          ['gridBlk', 'gridStage', 'gridTask'].includes(componentId)))
    ) {
    }
  }, [messages]);

  // Scroll to the bottom of message body
  useEffect(() => {
    if (showMessagesPopup && messageBodyRef.current) {
      messageBodyRef.current.scrollTo(0, messageBodyRef.current.scrollHeight);
    }
  }, [showMessagesPopup]);

  useEffect(() => {
    if (openPopover !== null && openPopover[messageId] === true) {
      setIfClickedOnTextFieldAfterSelectDelete(false);
      const elem: HTMLDivElement | null = document.querySelector(
        `#${uniqueId} .tiptap.ProseMirror`,
      );
      if (elem) {
        elem?.addEventListener('click', () =>
          setIfClickedOnTextFieldAfterSelectDelete(true),
        );
        if (editing) {
          elem.innerText = '';
          cancelEditing();
        }
      }
    }
  }, [openPopover]);

  useEffect(() => {
    if (ifClickedOnTextFieldAfterSelectDelete) {
      cancelDeleting();
    }
  }, [ifClickedOnTextFieldAfterSelectDelete]);

  const content = (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      ref={messagePopupRef}
      className={`messageBox show`}
      id={uniqueId}
    >
      <div className={'msgBoxTop d-flex'}>
        <div className="msgHeading">
          <Tooltip title={threadName} color={'#2E364C'} placement="right">
            {threadName}
          </Tooltip>
        </div>
        {!isCompactView && (
          <>
            <div
              className="closeMsgBox"
              onClick={() => {
                closeMessagePopup();
              }}
              onKeyDown={() => {}}
            >
              <span
                className="material-icons-outlined modal-close"
                id="msgBoxClose"
              >
                close
              </span>
            </div>
          </>
        )}
      </div>

      <div ref={messageBodyRef} className="msgBody">
        {messages.map((ele: any) => {
          let items: MenuProps['items'] | any = [];
          if (ele.user_id === userDetails.user_id) {
            items = messagingCmsData?.lbl_message_modification_option?.map(
              (el: any) => {
                if (el.key === MessageThreadOption.DELETE_MESSAGE) {
                  return {
                    key: el.key,
                    label: (
                      <Popconfirm
                        title={
                          messagingCmsData?.lbl_delete_message_popover_config
                            .header
                        }
                        description={
                          messagingCmsData?.lbl_delete_message_popover_config
                            .body
                        }
                        okText={
                          messagingCmsData?.lbl_delete_message_popover_config
                            .confirm
                        }
                        cancelText={
                          messagingCmsData?.lbl_delete_message_popover_config
                            .cancel
                        }
                        placement="bottom"
                        icon={
                          <QuestionCircleOutlined style={{ color: 'red' }} />
                        }
                        arrow={false}
                        open={Boolean(
                          openPopover && openPopover[ele.message_id],
                        )}
                        onConfirm={() =>
                          deleteMessage(
                            localStorage.getItem('currentMessageId')!,
                          )
                        }
                        onCancel={(e) => {
                          e?.stopPropagation();
                          cancelDeleting();
                        }}
                        overlayClassName="customAntDeletePopover"
                      >
                        <div
                          className="optionWrap"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenPopover({
                              ...openPopover,
                              [ele.message_id]: true,
                            });
                          }}
                          onKeyDown={() => {}}
                        >
                          <span className={el.className}></span>
                          <span>{el.label}</span>
                        </div>
                      </Popconfirm>
                    ),
                  };
                }

                return {
                  key: el.key,
                  label: (
                    <div className="optionWrap">
                      <span className={el.className}></span>
                      <span>{el.label}</span>
                    </div>
                  ),
                };
              },
            );
          }

          return (
            <div className={'masseuse userBlkWrap'} key={ele.message_id}>
              {generateUserAvatar(cleversortUser as unknown as IUserDetails)
                .key === 'string' ? (
                <div className="userAvtr">
                  {
                    generateUserAvatar(
                      cleversortUser as unknown as IUserDetails,
                    ).value
                  }
                </div>
              ) : (
                <div className="userAvtr">
                  <img
                    src={
                      generateUserAvatar(
                        cleversortUser as unknown as IUserDetails,
                      ).value
                    }
                  />
                </div>
              )}
              <div className="msgArea">
                <div className="userName">
                  <span className="userNameElement">
                    {cleversortUser.full_name}
                  </span>

                  {!isAdminMessage(ele.user_id) ? (
                    <span className="msgDueDate">
                      {ele.is_edited
                        ? getDateFormat(
                            ele.updated_at.toDate(),
                            messagingCmsData,
                          )
                        : getDateFormat(
                            ele.created_at.toDate(),
                            messagingCmsData,
                          )}
                    </span>
                  ) : null}
                  {ele.is_edited ? (
                    <span className="editedMessageMarker">
                      {messagingCmsData?.lbl_edited}
                    </span>
                  ) : null}
                </div>
                {!ele?.is_delete ? (
                  <div
                    className="msgText"
                    dangerouslySetInnerHTML={{
                      __html: formatMessageItems(ele.content),
                    }}
                  ></div>
                ) : (
                  <div className="msgTextDeleted">
                    <span className="deletedTxtFormatting">
                      {messagingCmsData?.lbl_deleted_message_text}
                    </span>
                  </div>
                )}
              </div>
              {!isAdminMessage(ele.user_id) && taskDetails ? (
                <Fragment>
                  {ele.user_id === userDetails.user_id && !ele.is_delete ? (
                    <Dropdown
                      menu={{ items, onClick }}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <span
                        className="msgTextMore cmnIcon more"
                        onClick={() => {
                          cancelDeleting();
                          setTimeout(() => {
                            localStorage.setItem(
                              'currentMessageId',
                              ele.message_id,
                            );
                          });
                        }}
                        onKeyDown={() => {}}
                      ></span>
                    </Dropdown>
                  ) : null}
                  {ele.user_id !== userDetails.user_id &&
                  // projectDetails?.associated_role_id === 3 &&
                  !ele.is_delete ? (
                    <Dropdown
                      menu={{ items, onClick }}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <span
                        className="msgTextMore cmnIcon more"
                        onClick={() => {
                          cancelDeleting();
                          setTimeout(() => {
                            localStorage.setItem(
                              'currentMessageId',
                              ele.message_id,
                            );
                          });
                        }}
                        onKeyDown={() => {}}
                      ></span>
                    </Dropdown>
                  ) : null}
                </Fragment>
              ) : null}
            </div>
          );
        })}
        <div ref={refDiv} />
      </div>

      <div className="replyMsg">
        <Editor
          sendMessage={sendMessage}
          editorClassName="messageThread"
          threadId={messageThreadId}
          showEmojiPicker={showEmojiPicker}
          setShowEmojiPicker={setShowEmojiPicker}
          messageContent={messageContent}
          setMessageId={setMessageId}
          setMessageContent={setMessageContent}
          setEditing={setEditing}
          editing={editing}
          allowedExtensions={{
            showBoldOutlined: true,
            showItalicOutlined: true,
            showUnorderedListOutlined: true,
            showEmojiControl: true,
            showUploadOutlined: true,
            showLinkOutlined: true,
            showSend: true,
            showCheckList: true,
          }}
        />
      </div>
    </div>
  );
  const openingLogic = () => {
    return showMessagesPopup;
  };
  const classNameForMessageThread = (count: number) =>
    count === 0 ? 'emptyMessageThread' : '';
  const onClickMessage = (e: any) => {
    e.stopPropagation();
    if (showMessagesPopup) {
      closeMessagePopup();
    } else {
      setShowMessagesPopup(true);
    }
  };
  if (
    [
      SectionTypeMessages.GRID_LIST_STAGE,
      SectionTypeMessages.GRID_LIST_BLOCK,
      SectionTypeMessages.GRID_LIST_TASK,
    ].includes(sectionType as SectionTypeMessages) &&
    componentId &&
    ['gridBlk', 'gridStage', 'gridTask'].includes(componentId)
  ) {
    if (compactView) {
      return (
        <SubMenu
          title={
            <div className="customDropDownItem">
              <span className="cmnIcon">
                <GetMessageIcon
                  isBlockedView={isBlockedView}
                  messages={messages}
                  // messageInfo={messageInfo}
                  toggleMessagePopup={() => {}}
                  threadName={threadName}
                  showMessagesPopup={openingLogic()}
                  classNameForMessageThread={classNameForMessageThread}
                  isTemplate={isTemplate}
                  isCompactView={compactView}
                  isDashBoardOrPlanner={isDashBoardOrPlanner}
                />
              </span>
              {/* @ts-ignore */}
              {'View Message'}
            </div>
          }
          key={taskId}
          popupClassName="customSubMenu twoLavelMsgPopup"
          onTitleClick={() => toggleMessagePopup()}
          disabled={messages?.length === 0}
        >
          <Menu.Item key={taskId}>{content}</Menu.Item>
        </SubMenu>
      );
    }

    return (
      <>
        {!openingLogic() ? (
          <>
            {messages?.length ? (
              <div onClick={onClickMessage} className="blkMsg haveMsg">
                Message
              </div>
            ) : (
              <div onClick={onClickMessage} className="blkMsg">
                Message
              </div>
            )}
          </>
        ) : (
          <Popover
            placement="bottomRight"
            trigger={'click'}
            content={content}
            open={true}
            overlayClassName={'messageThreadClass commentContainer'}
            arrow={false}
            onOpenChange={(event) => {
              if (event === false) {
                closeMessagePopup();
              }
            }}
          >
            {messages?.length ? (
              <div onClick={onClickMessage} className="blkMsg haveMsg">
                Message
              </div>
            ) : (
              <div onClick={onClickMessage} className="blkMsg">
                Message
              </div>
            )}
          </Popover>
        )}
      </>
    );
  }

  return (
    <Fragment>
      {!openingLogic() ? (
        <GetMessageIcon
          isBlockedView={isBlockedView}
          messages={messages}
          toggleMessagePopup={toggleMessagePopup}
          threadName={threadName}
          showMessagesPopup={openingLogic()}
          classNameForMessageThread={classNameForMessageThread}
          isTemplate={isTemplate}
          isCompactView={compactView}
          isDashBoardOrPlanner={isDashBoardOrPlanner}
        />
      ) : (
        <Popover
          placement={placement}
          trigger="click"
          content={content}
          open={true}
          overlayClassName={`messageThreadClass commentContainer ${
            showMessagesPopup ? 'expandedMessagePopupState' : ''
          }`}
          arrow={false}
          onOpenChange={(event) => {
            if (event === false) {
              closeMessagePopup();
            }
          }}
        >
          <GetMessageIcon
            isBlockedView={isBlockedView}
            messages={messages}
            toggleMessagePopup={toggleMessagePopup}
            threadName={threadName}
            isCompactView={compactView}
            isDashBoardOrPlanner={isDashBoardOrPlanner}
          />
        </Popover>
      )}
    </Fragment>
  );
};

export default MessageThreadV2;


