import React, { Fragment, useEffect, useState } from 'react';
import { Input, message, Skeleton, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import teamsService from 'modules/services/TeamsService/Teams.service';
import { useTeamsStore } from './teamsStore';
import { useAuth0 } from '@auth0/auth0-react';
import apiService from 'modules/services/Api.service';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { IViewTeamInfo, TeamsTableData } from 'modules/models/interfaces';
import TeamFilterDrawer from './Filters/TeamFilterDrawer';
import dayjs, { Dayjs } from 'dayjs';

const TeamManageTable: React.FC = () => {
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [, contextHolder] = message.useMessage();
  const { tloading, setTLoading } = useTeamsStore();
  const { tableData, setTableData } = useTeamsStore();
  const [datas, setDatas] = useState<TeamsTableData[]>([]);
  const { isDrawer, setIsDrawer } = useTeamsStore();
  const { setTeamInfo } = useTeamsStore();
  const { hasPermissions } = useRbac();
  const { setBTableData } = useTeamsStore();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRow, setTotalRow] = useState(100);
  const [selectedTeamTypes, setSelectedTeamTypes] = useState<string[]>([]);
  const [selectedTeamStatus, setSelectedTeamStatus] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [open, setOpen] = useState(false);

  const onReset = () => {
    setSelectedTeamTypes([]);
    setSelectedTeamStatus([]);
    setStartDate(null);
    setEndDate(null);
    getTeamsDetails(true);
    setOpen(false);
  };

  const getTeamsDetails = async (reset: boolean = false) => {
    try {
      const getTeamsDetailsPayload = reset
        ? {
            page: 1,
            pageSize: 10,
            search_text: '',
            team_status: '',
            team_type: '',
            start_date: '',
            end_date: '',
          }
        : {
            page: page,
            pageSize: pageSize,
            search_text: searchText,
            team_status: selectedTeamStatus?.toString(),
            team_type: selectedTeamTypes?.toString(),
            start_date: startDate
              ? dayjs(startDate).format('YYYY-MM-DD')
              : undefined,
            end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
          };
      const response = await teamsService.getTeamsDetails(
        getTeamsDetailsPayload,
      );
      const totalData = response?.data?.totalRow;
      setTotalRow(totalData);
      const data: TeamsTableData[] = response?.data?.teams?.map(
        (item: any) => ({
          key: item.org_key,
          team_name: item.team_name.trim(),
          team_status: checkAccountStatus(item.team_status),
          account_created: dateConvert(item.created_on),
          active_users: item.active_users,
          team_type: item.team_type,
          project_count: item.project_count,
          created_on: item.created_on.trim(),
          view_team_info: {
            id: item.id,
            key: item.org_key,
            team_name: item.team_name.trim(),
            team_status: checkAccountStatus(item.team_status),
            account_created: dateConvert(item.created_on),
            active_users: item.active_users,
            team_type: item.team_type,
            project_count: item.project_count,
            created_on: item.created_on.trim(),
          },
        }),
      );
      setTableData(data);
      setDatas(data);
      setBTableData(data);
      if (response) {
        setLoading(false);
        setTLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setTLoading(false);
    }
  };

  const { getAccessTokenSilently } = useAuth0();

  const editTeamDrawer = (_view_team_info: IViewTeamInfo) => {
    setIsDrawer(!isDrawer);
    setTeamInfo(_view_team_info);
  };

  const checkAccountStatus = (is_active: boolean) => {
    if (is_active === true) {
      return 'Active';
    } else if (is_active === false) {
      return 'In-active';
    }
    return '';
  };

  const dateConvert = (stringDate: string) => {
    const date = new Date(stringDate);

    const normalDate = date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });

    return normalDate;
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    apiService.setTokenGenerator(getAccessTokenSilently);
    if (hasPermissions([ERbacPermissions.TEAM_VIEW])) {
      getTeamsDetails();
    }
  }, []);

  const columns: ColumnsType<TeamsTableData> = [
    {
      title: 'Team Name',
      dataIndex: 'team_name',
      // filterMode: 'tree',
      key: 'team_name',
      filterSearch: true,
      onFilter: (value, record) => record.team_name.includes(value as string),
      width: '30%',
      sorter: (a: TeamsTableData, b: TeamsTableData) =>
        a.team_name.localeCompare(b.team_name),
    },
    {
      title: 'Team Status',
      dataIndex: 'team_status',
      key: 'team_status',
      sorter: (a: TeamsTableData, b: TeamsTableData) =>
        a.team_status.localeCompare(b.team_status),
      render: (team_status: string) => {
        if (team_status === 'Active') {
          return <span className="activeBtn">Active</span>;
        } else if (team_status === 'In-active') {
          return <span className="inactiveBtn">In-active</span>;
        }
        return null;
      },
    },
    {
      title: 'Created On',
      dataIndex: 'account_created',
      key: 'account_created',
      sorter: (a: TeamsTableData, b: TeamsTableData) =>
        a.created_on.localeCompare(b.created_on),
    },
    {
      title: 'Active Users',
      dataIndex: 'active_users',
      key: 'active_users',
      sorter: (a, b) => a.active_users - b.active_users,
    },
    {
      title: 'Projects',
      dataIndex: 'project_count',
      key: 'project_count',
      sorter: (a, b) => a.project_count - b.project_count,
    },
    {
      title: 'Team Type',
      dataIndex: 'team_type',
      key: 'team_type',
      sorter: (a: TeamsTableData, b: TeamsTableData) =>
        a.team_type.localeCompare(b.team_type),
    },

    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'view_team_info',
      align: 'right',
      render: (view_team_info: IViewTeamInfo) => (
        <div>
          <span
            className="cmnIcon eye"
            onClick={() => editTeamDrawer(view_team_info)}
          ></span>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      {contextHolder}
      <div className="inner-container">
        <div className="manageUsersTableHeading">
          <span className="itemName">Manage Teams</span>
          {loading ? (
            <Skeleton.Input active />
          ) : (
            <div className="searchFilter">
              <Input
                size="large"
                onChange={onSearch}
                placeholder="Search Team"
                value={searchText}
              />
              <ul className="actionBtnSec">
                <li>
                  <button
                    title="filter"
                    id="filterTaskBtn"
                    className="filterTaskPopover"
                  >
                    <TeamFilterDrawer
                      filterDrawerTitle={'Team Filters'}
                      open={open}
                      setOpen={setOpen}
                      getTeamsDetails={getTeamsDetails}
                      setPage={setPage}
                      filterOptions={{
                        selectedTeamTypes,
                        setSelectedTeamTypes,
                        selectedTeamStatus,
                        setSelectedTeamStatus,
                        startDate,
                        setStartDate,
                        endDate,
                        setEndDate,
                      }}
                      onReset={onReset}
                    />
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
        <Skeleton active loading={loading || tloading}>
          <div className="teamManagementTable userManagementTable">
            <Table
              columns={columns}
              dataSource={tableData}
              pagination={{
                showSizeChanger: true,
                total: totalRow,
                current: page,
                pageSize: pageSize,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </div>
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default TeamManageTable;
