import { FC, Fragment, useCallback, useState } from 'react';
import {
  IGridList,
  IGridListTaskDetail,
  IProjectState,
  ICreateTaskResponse,
  ICreateTaskResponseData,
  IGridListBlockDetail,
  IUpdateTaskResponse,
  IUpdateTaskResponseData,
} from 'migration/models/interface';
import { Tooltip, message } from 'antd';
import debounce from 'lodash.debounce';
import TaskDelete from './TaskDelete';
import MessageThreadV2 from 'migration/MessageThread/MessageThread';
import { checkTaskList, getCurrentOrganization } from 'utils/commonFunctions';
import { useProjectDashboardStore, useUserStore } from 'modules/store';
import { eventTypes } from 'migration/models/enums/constants';

import {
  renameBlockInMessageThread,
  renameTaskInMessageThread,
} from 'utils/firebaseCommonFunctions';
import { SectionTypeMessages } from 'migration/models/enums/messages';
import { useLocation } from 'react-router-dom';
import templateService from 'modules/services/TemplateService/Template.service';
import { gridListCmsData } from '../GridListCmsDatas/GridListCmsData';
import { useTemplateStore } from 'modules/components/Pages/Template/store';

const TaskNameInputField: FC<
  | {
      gridListDetails: IGridList;
      setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
      taskDetails: IGridListTaskDetail;
      index?: number;
    }
  | any
> = ({ taskDetails, gridListDetails, setGridListDetails, index }) => {
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const { templateDetails, setSections, sections } = useTemplateStore();
  // State
  const [input, setInput] = useState(taskDetails.task_name);
  const [openMessageExpandedView, setOpenMessageExpandedView] =
    useState<boolean>(false);

  const checkIfRevertRequired = (taskDetails: IGridListTaskDetail) =>
    taskDetails.backupName === taskDetails.task_name ||
    taskDetails.task_name === '';

  const onKeyUpEventHandler = async (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (taskDetails?.isNew) {
      if (e.key === 'Enter') {
        document
          .getElementById(`${taskDetails.task_id}_input_field`)
          ?.setAttribute('readonly', 'true');
        localStorage.setItem('isTaskAdded', taskDetails.task_id.toString());
        await submitChange(taskDetails.task_name, 'Enter');
        document
          .getElementById(`${taskDetails.task_id}_input_field`)
          ?.removeAttribute('readonly');
        localStorage.removeItem('isTaskAdded');
        setInput('');
      }

      if (e.key === 'Escape') {
        submitChange('', 'Escape');
      }
    } else {
      if (e.key === 'Enter') {
        if (checkIfRevertRequired(taskDetails)) {
          revertChange(taskDetails.task_id, 'Enter');
        } else {
          document.getElementById(`${taskDetails.task_id}_input_field`)?.blur();
        }
      }

      if (e.key === 'Escape') {
        revertChange(taskDetails.task_id, 'Escape');
      }
    }
  };

  const onChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: getUpdateTaskDetails(
            gridListDetails,
            taskDetails,
            e.target.value,
          )!,
        },
      } as IGridList);
    }
  };

  const submitChange = async (taskName: string, eventType: string) => {
    if (taskName.trim() === '') {
      removeEmptyTask();
    } else {
      try {
        const response: ICreateTaskResponse | any =
          await templateService.createNewGridTask({
            template_id: templateDetails?.template_id,
            section_id: gridListDetails.section_id,
            task_name: taskName.trim(),
          });
        if (response.messageId === 1) {
          generateGridListDetails(gridListDetails, eventType, response.data);
        } else {
          handleFailedOperation();
          message.error(response?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateTask = async (taskName: string, taskId: number) => {
    try {
      const response: IUpdateTaskResponse | any =
        await templateService.updateGridTask({
          section_id: gridListDetails.section_id,
          task_id: taskId,
          task_name: taskName.trim(),
          template_id: templateDetails?.template_id,
        });

      if (response.messageId === -4) {
        const error = new Error(response?.message);
        (error as any).messageId = response?.messageId;
        throw error;
      }

      if (response.messageId === 1) {
        const templateResponse = await templateService.getTemplateById(
          templateDetails.template_id,
        );
        setSections(templateResponse.data.sections);
        updateProjectStateForTaskUpdate(response.data);
        renameTaskInMessageThread(
          `${SectionTypeMessages.GRID_LIST_TASK}-${
            process.env.REACT_APP_STAGE
          }-${getCurrentOrganization(org_key, userDetails?.organization_info)
            ?.organization_id!}-${projectDetails?.project_id}-${
            gridListDetails.section_id
          }-${taskId}`,
          taskName.trim(),
        );

        let blockDetails =
          gridListDetails.grid_list_details?.block_details!.flat()!;

        let targetBlockList = blockDetails!.filter(
          (ele: IGridListBlockDetail) => ele.task_id === taskId,
        )!;

        for (let block of targetBlockList) {
          await renameBlockInMessageThread(
            `${SectionTypeMessages.GRID_LIST_BLOCK}-${
              process.env.REACT_APP_STAGE
            }-CS-${
              templateDetails?.template_id
            }-${+gridListDetails.section_id}-${block.block_id}`,
            taskName.trim(),
            'task',
          );
        }
      } else {
        revertChange(taskId, 'Blur');
      }
    } catch (error: any) {
      console.log(error);
      revertChange(taskId, 'Blur');
    }
  };

  const generateGridListDetails = (
    gridListDetails: IGridList,
    eventType: string,
    data: ICreateTaskResponseData,
  ) => {
    if (
      gridListDetails.grid_list_details!.task_details &&
      !gridListDetails.grid_list_details!.task_details[0].isNew
    ) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details:
            gridListDetails.grid_list_details!.task_details !== null
              ? taskListBasedOnEvent(gridListDetails, eventType, data)
              : data.new_task_details,
          block_details:
            gridListDetails.grid_list_details!.block_details !== null
              ? ([
                  ...gridListDetails.grid_list_details!.block_details!,
                  ...data.new_block_details,
                ] as Array<IGridListBlockDetail[]>)
              : data.new_block_details,
          stage_details: gridListDetails.grid_list_details?.stage_details
            ? gridListDetails.grid_list_details?.stage_details
            : null,
        },
      });
      setSections(
        sections.map((section: any) => {
          if (section.section_id === gridListDetails.section_id) {
            return {
              ...section,
              grid_list_details: {
                ...section.grid_list_details,
                task_details:
                  section.grid_list_details!.task_details !== null
                    ? taskListBasedOnEvent(section, eventType, data)
                    : data.new_task_details,
                block_details:
                  section.grid_list_details!.block_details !== null
                    ? ([
                        ...section.grid_list_details!.block_details!,
                        ...data.new_block_details,
                      ] as Array<IGridListBlockDetail[]>)
                    : data.new_block_details,
                stage_details: section.grid_list_details?.stage_details
                  ? section.grid_list_details?.stage_details
                  : null,
              },
            };
          } else {
            return section;
          }
        }),
      );
    } else {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          task_details: taskListBasedOnEvent(gridListDetails, eventType, data),
          block_details: gridListDetails.grid_list_details?.block_details
            ? [
                getUpdatedBlockDetails(
                  gridListDetails.grid_list_details
                    ?.block_details as IGridListBlockDetail[][],
                  data.new_task_details[0].task_id,
                ),
              ]
            : data.new_block_details,
          stage_details: gridListDetails.grid_list_details?.stage_details
            ? gridListDetails.grid_list_details?.stage_details
            : null,
        },
      });
      setSections(
        sections.map((section: any) => {
          if (section.section_id === gridListDetails.section_id) {
            return {
              ...section,
              grid_list_details: {
                task_details: taskListBasedOnEvent(section, eventType, data),
                block_details: section.grid_list_details?.block_details
                  ? [
                      getUpdatedBlockDetails(
                        section.grid_list_details
                          ?.block_details as IGridListBlockDetail[][],
                        data.new_task_details[0].task_id,
                      ),
                    ]
                  : data.new_block_details,
                stage_details: section.grid_list_details?.stage_details
                  ? section.grid_list_details?.stage_details
                  : null,
              },
            };
          } else {
            return section;
          }
        }),
      );
    }
  };

  const getUpdateAllTaskDetails = (
    section: IGridList,
    taskDetails: IGridListTaskDetail[],
  ) => {
    if (section.grid_list_details!.task_details) {
      let task = [...taskDetails];
      let existingTask = section.grid_list_details!.task_details.filter(
        (ele: IGridListTaskDetail) => ele.task_id !== task[0].task_id,
      );
      return [...existingTask, ...task].sort(
        (a, b) => a.task_rank - b.task_rank,
      );
    }
  };

  const updateProjectStateForTaskUpdate = (data: IUpdateTaskResponseData) => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: getUpdateAllTaskDetails(
            gridListDetails,
            data.task_details,
          )!,
        },
      } as IGridList);
    }
  };

  const taskListBasedOnEvent = (
    gridListDetails: IGridList,
    eventType: string,
    data: ICreateTaskResponseData,
  ) => {
    let taskList: any[] = [];
    if (gridListDetails.grid_list_details!.task_details) {
      if (eventType === eventTypes.ENTER) {
        taskList = [
          ...gridListDetails.grid_list_details!.task_details,
          ...data.new_task_details,
        ].filter((ele) => !ele.isNew);
        taskList.push({
          task_id: 99,
          task_name: '',
          task_rank: 99,
          section_id: 99,
          total_block_count: 0,
          completed_block_count: 0,
          isNew: true,
        });
      } else {
        taskList = [
          ...gridListDetails.grid_list_details!.task_details,
          ...data.new_task_details,
        ].filter((ele) => !ele.isNew);
      }
    } else {
      if (eventType === eventTypes.ENTER) {
        taskList = [...data.new_task_details];
        taskList.push({
          task_id: 99,
          task_name: '',
          task_rank: 99,
          section_id: 99,
          total_block_count: 0,
          completed_block_count: 0,
          isNew: true,
        });
      } else {
        taskList = [...data.new_task_details];
      }
    }

    return taskList;
  };

  const removeEmptyTask = () => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: gridListDetails.grid_list_details!.task_details
            ? filterOutTask(gridListDetails.grid_list_details!.task_details)
            : null,
        },
      } as IGridList);
    }
  };

  const filterOutTask = (taskDetails: IGridListTaskDetail[]) => {
    let filteredList = taskDetails.filter((ele: any) => !ele.isNew);

    return filteredList.length > 0 ? filteredList : null;
  };

  const handleFailedOperation = () => {
    if (checkTaskList(gridListDetails)) {
      removeEmptyTask();
    }
  };

  const revertChange = (taskId: number, event: string) => {
    if (gridListDetails) {
      let target = gridListDetails.grid_list_details!.task_details?.filter(
        (ele: any) => ele.task_id === taskId,
      )!;
      let others = gridListDetails.grid_list_details!.task_details?.filter(
        (ele: any) => ele.task_id !== taskId,
      )!;

      target[0].task_name = target[0].backupName!;
      if (event === eventTypes.BLUR) delete target[0].backupName;

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: [...others, ...target].sort(
            (a, b) => a.task_rank - b.task_rank,
          ),
        },
      } as IGridList);
    }
  };

  const getUpdatedBlockDetails = (
    blockDetails: IGridListBlockDetail[][],
    taskId: number,
  ) => {
    if (blockDetails.length === 0) return [];
    return blockDetails[0].map((ele: IGridListBlockDetail) => {
      return {
        ...ele,
        task_id: taskId,
      };
    });
  };

  const debounceUpdate = useCallback(debounce(onChangeEventHandler, 50), [
    gridListDetails,
  ]);

  const onBlurEventHandler = (
    e: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    if (taskDetails?.isNew) {
      submitChange(taskDetails.task_name, 'Blur');
    } else if (checkIfRevertRequired(taskDetails)) {
      revertChange(taskDetails.task_id, 'Blur');
    } else {
      updateTask(taskDetails.task_name, taskDetails.task_id);
    }
  };

  const getUpdateTaskDetails = (
    gridListDetails: IGridList,
    taskDetails: IGridListTaskDetail,
    newName?: string,
  ) => {
    if (gridListDetails.grid_list_details!.task_details) {
      let task = [{ ...taskDetails }];
      if (!task[0]?.backupName) {
        task[0].backupName = task[0].task_name;
      }
      task[0].task_name = newName ?? '';

      let existingTask = gridListDetails.grid_list_details!.task_details.filter(
        (ele: IGridListTaskDetail) => ele.task_id !== task[0].task_id,
      );
      return [...existingTask, ...task].sort(
        (a, b) => a.task_rank - b.task_rank,
      );
    }
  };

  const makeFieldEditable = (taskDetails: IGridListTaskDetail) => {
    // if (hasPermissions([ERbacPermissions.TEMPLATE_VIEW])) {
    if (gridListDetails) {
      let task = [{ ...taskDetails }];
      task[0].isEdit = true;
      let existingTask =
        gridListDetails.grid_list_details!.task_details!.filter(
          (ele: IGridListTaskDetail) => ele.task_id !== task[0].task_id,
        );

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: [...existingTask, ...task].sort(
            (a, b) => a.task_rank - b.task_rank,
          ),
        },
      } as IGridList);
    }
    // }
  };

  const getTaskNameFieldJsx = (taskDetails: IGridListTaskDetail) => {
    if (taskDetails.isNew) {
      return (
        <div className="gridTask">
          <span className="borderColor">border</span>
          <input
            type="text"
            id={`${taskDetails.task_id}_input_field`}
            placeholder={gridListCmsData?.lbl_add_task_placeholder}
            value={input}
            aria-label="sign up / login"
            onChange={(e) => {
              setInput(e.target.value);
              debounceUpdate(e);
            }}
            maxLength={100}
            onKeyUp={(e) => onKeyUpEventHandler(e)}
            onBlur={(e) => onBlurEventHandler(e)}
            autoFocus={taskDetails?.isNew}
          />
        </div>
      );
    }

    if (taskDetails.isEdit) {
      return (
        <div className="gridTask">
          <span className="borderColor">border</span>
          <input
            type="text"
            id={`${taskDetails.task_id}_input_field`}
            placeholder={gridListCmsData?.lbl_add_task_placeholder}
            value={input}
            aria-label="sign up / login"
            onChange={(e) => {
              setInput(e.target.value);
              debounceUpdate(e);
            }}
            maxLength={100}
            onKeyUp={(e) => onKeyUpEventHandler(e)}
            onBlur={(e) => onBlurEventHandler(e)}
            autoFocus={taskDetails?.isEdit}
          />
          {!taskDetails?.isNew && (
            <MessageThreadV2
              componentId="taskListMessaging"
              isBlockedView
              sectionId={gridListDetails.section_id}
              taskId={taskDetails.task_id}
              sectionType={SectionTypeMessages.GRID_LIST_TASK}
              threadName={taskDetails.task_name}
              // sectionName={gridListDetails.section_name!}
              taskDetails={taskDetails}
            />
          )}
          {false && <div className="blkMore">More</div>}
        </div>
      );
    }

    return (
      <Fragment>
        {taskDetails?.task_name?.length > 30 && !openMessageExpandedView ? (
          <Tooltip
            title={taskDetails.task_name}
            color={'#2E364C'}
            placement="top"
          >
            <div
              onClick={() => makeFieldEditable(taskDetails)}
              onKeyDown={() => {}}
              className="gridTask"
            >
              <span className="borderColor">border</span>
              <span className="blkTxt">{taskDetails?.task_name}</span>
              {!taskDetails?.isNew && (
                <MessageThreadV2
                  componentId="taskListMessaging"
                  isBlockedView
                  sectionId={gridListDetails.section_id}
                  taskId={taskDetails.task_id}
                  sectionType={SectionTypeMessages.GRID_LIST_TASK}
                  threadName={taskDetails.task_name}
                  // sectionName={gridListDetails.section_name!}
                  taskDetails={taskDetails}
                />
              )}
              {!taskDetails?.isNew /*&& projectDetails?.is_archived === false */ && (
                // <Rbac
                //   allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}
                //   project_role_id={projectDetails?.associated_role_id}
                // >
                <TaskDelete
                  taskDetails={taskDetails}
                  gridListDetails={gridListDetails}
                  setGridListDetails={setGridListDetails}
                />
                // </Rbac>
              )}
            </div>
          </Tooltip>
        ) : (
          <div
            onClick={() => makeFieldEditable(taskDetails)}
            onKeyDown={() => {}}
            className="gridTask"
          >
            <span className="borderColor">border</span>
            <span className="blkTxt">{taskDetails?.task_name}</span>
            {!taskDetails?.isNew && (
              <MessageThreadV2
                componentId="taskListMessaging"
                isBlockedView
                sectionId={gridListDetails.section_id}
                taskId={taskDetails.task_id}
                sectionType={SectionTypeMessages.GRID_LIST_TASK}
                threadName={taskDetails.task_name}
                // sectionName={gridListDetails.section_name!}
                taskDetails={taskDetails}
              />
            )}
            {!taskDetails?.isNew /* && projectDetails?.is_archived === false*/ && (
              // <Rbac
              //   allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}
              //   project_role_id={projectDetails?.associated_role_id}
              // >
              <TaskDelete
                taskDetails={taskDetails}
                gridListDetails={gridListDetails}
                setGridListDetails={setGridListDetails}
              />
              // </Rbac>
            )}
          </div>
        )}
      </Fragment>
    );
  };

  return getTaskNameFieldJsx(taskDetails);
};

export default TaskNameInputField;
