import { FC, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown, message } from 'antd';
import {
  IGridList,
  IGridListBlockDetail,
  IGridListStageDetail,
  IGridListTaskDetail,
  IModalState,
  IProjectState,
} from 'migration/models/interface';
import {
  useProjectDashboardStore,
  useUserStore,
  useModalStore,
} from 'modules/store';
import templateService from 'modules/services/TemplateService/Template.service';
import { getCurrentOrganization, groupGridBlock } from 'utils/commonFunctions';
import { SectionTypeMessages } from 'migration/models/enums/messages';
import { markTaskDeleteInMessageThread } from 'utils/firebaseCommonFunctions';
import { gridListCmsData } from '../GridListCmsDatas/GridListCmsData';
import { useTemplateStore } from 'modules/components/Pages/Template/store';

const StageDelete: FC<{
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  stageDetails: IGridListStageDetail;
}> = ({ gridListDetails, setGridListDetails, stageDetails }) => {
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { modalState, setModalState } = useModalStore(
    (state: IModalState) => state,
  );
  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const { templateDetails, setSections } = useTemplateStore();
  const deleteStageHandler = () => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          deleteItemDetails: {
            type: 'stage',
            id: stageDetails.stage_id,
          },
        },
      } as IGridList);

      setModalState({
        modalHeader: (
          <Fragment>
            <h3>{gridListCmsData?.lbl_delete_stage_modal_header}</h3>
            <span
              className="material-icons-outlined modal-close"
              onClick={() => modalCloseHandler()}
              onKeyDown={() => {}}
            >
              close
            </span>
          </Fragment>
        ),
        modalBody: (
          <Fragment>
            {/* <div className="icon"></div> */}
            <p>
              {gridListCmsData?.lbl_delete_stage_modal_content1}{' '}
              <strong>{stageDetails.stage_name}</strong>?
            </p>
            <p>{gridListCmsData?.lbl_delete_stage_modal_content2}</p>
          </Fragment>
        ),
        modalFooter: (
          <Fragment>
            <button
              className="noStyle cancel_deleteFolder"
              onClick={() => modalCloseHandler()}
            >
              {gridListCmsData?.cta_delete_modal_cancel}
            </button>

            <button
              className="deleteBtnStyle"
              onClick={() =>
                (async () => {
                  await confirmAction();
                })()
              }
            >
              {gridListCmsData?.cta_delete_stage_modal_confirm}
            </button>
          </Fragment>
        ),
        shouldShow: true,
      });
    }
  };

  const modalCloseHandler = () => {
    let section = {
      ...gridListDetails,
    };
    delete section.grid_list_details!.deleteItemDetails;

    setGridListDetails(section);

    setModalState({ ...modalState, shouldShow: false });
  };

  const modalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
  };

  const confirmAction = async (): Promise<void> => {
    try {
      let isLastItem: boolean = false;

      if (gridListDetails.grid_list_details?.stage_details!.length === 1) {
        isLastItem = true;
      }

      const response = await templateService.deleteGridStage({
        section_id: +gridListDetails.section_id,
        stage_id: stageDetails.stage_id,
        template_id: templateDetails.template_id,
      });

      if (response.messageId === 1) {
        const templateResponse = await templateService.getTemplateById(
          templateDetails.template_id,
        );
        setSections(templateResponse.data.sections);
        let blockDetails = (gridListDetails.grid_list_details?.block_details ||
          [])!.flat()!;
        let newStageDetails = gridListDetails.grid_list_details?.stage_details!;
        let updatedBlockList: IGridListBlockDetail[] = [];

        if (isLastItem) {
          updatedBlockList = blockDetails!.map((ele: IGridListBlockDetail) => {
            return { ...ele, stage_id: 0 };
          })!;

          if (
            updatedBlockList.length === 1 &&
            updatedBlockList[0].stage_id === 0 &&
            updatedBlockList[0].task_id === 0
          ) {
            updatedBlockList = [];
          }
        } else {
          updatedBlockList = blockDetails.filter(
            (ele: IGridListBlockDetail) =>
              ele.stage_id !== stageDetails.stage_id,
          )!;
        }

        let updatedStageList = newStageDetails
          .filter(
            (ele: IGridListStageDetail) =>
              ele.stage_id !== stageDetails.stage_id,
          )
          .filter((ele: IGridListStageDetail) => !ele.isNew)!;

        let targetBlockList = blockDetails!.filter(
          (ele: IGridListBlockDetail) => ele.stage_id === stageDetails.stage_id,
        )!;

        let newTaskDetails = gridListDetails.grid_list_details?.task_details;
        let updateTaskDetails = newTaskDetails
          ? newTaskDetails.map((eleOuter: IGridListTaskDetail) => {
              let completedBlockCount = updatedBlockList.filter(
                (ele: IGridListBlockDetail) =>
                  ele.task_id === eleOuter.task_id && ele.block_status_id === 3,
              )!;

              let totalBlockCount = updatedBlockList.filter(
                (ele: IGridListBlockDetail) => ele.task_id === eleOuter.task_id,
              )!;

              return {
                ...eleOuter,
                total_block_count: totalBlockCount.length,
                completed_block_count: completedBlockCount.length,
              };
            })
          : null;

        delete gridListDetails.grid_list_details!.deleteItemDetails;

        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            task_details: updateTaskDetails,
            stage_details:
              updatedStageList.length > 0 ? updatedStageList : null,
            block_details:
              updatedBlockList.length > 0
                ? (groupGridBlock(updatedBlockList, updateTaskDetails) as Array<
                    IGridListBlockDetail[]
                  >)
                : null,
          },
        });

        modalClose();

        await markTaskDeleteInMessageThread(
          `${SectionTypeMessages.GRID_LIST_STAGE}-${
            process.env.REACT_APP_STAGE
          }-${getCurrentOrganization(org_key, userDetails?.organization_info)
            ?.organization_id!}-${
            projectDetails?.project_id
          }-${+gridListDetails.section_id}-${stageDetails.stage_id}`,
        );

        for (let block of targetBlockList) {
          await markTaskDeleteInMessageThread(
            `${SectionTypeMessages.GRID_LIST_BLOCK}-${
              process.env.REACT_APP_STAGE
            }-${getCurrentOrganization(org_key, userDetails?.organization_info)
              ?.organization_id!}-${
              projectDetails?.project_id
            }-${+gridListDetails.section_id}-${block.block_id}`,
          );
        }
      } else {
        modalClose();
        message.error(gridListCmsData?.lbl_generic_error_message, 3);
      }

      modalClose();
    } catch (err) {
      message.error(gridListCmsData?.lbl_generic_error_message, 3);
      modalClose();
    }
  };

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'delete',
            label: (
              <div className="customDropDownItem">
                <span className="deleteBin cmnIcon"></span>
                {gridListCmsData?.lbl_delete_stage_modal_header}
              </div>
            ),
          },
        ],
        onClick: () => {
          deleteStageHandler();
        },
      }}
      placement="bottomLeft"
      trigger={['click']}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="blkMore"
      >
        More
      </div>
    </Dropdown>
  );
};

export default StageDelete;
