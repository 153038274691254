import CloudUploadOutlined from '@ant-design/icons/lib/icons/CloudUploadOutlined';
import { message } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useBasicInfoStore } from './store';
import { ITemplateThumbnail, IThumbnail } from 'modules/models/interfaces/template.interface';
import { useTemplateStore } from '../store';
import imageCompression from 'browser-image-compression';
const supportedImageTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
];

const UploadBanner: React.FC<{
  isChanged: any;
  setIsChanged: (isChanged: any) => void;
}> = ({ isChanged, setIsChanged }) => {
  const { banner, setBanner } = useBasicInfoStore();
  const [messageApi, contextHolder] = message.useMessage();
  const { templateDetails } = useTemplateStore();

  const upload_error = (name: string) => {
    messageApi.open({
      type: 'error',
      content: `Size of ${name} should less then 1MB`,
    });
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (!supportedImageTypes.includes(file.type) || file.size > 1000000) {
      upload_error(file.name);
      return;
    }

    const dataURL = await getBase64(file);

    setBanner(dataURL);

    // if (files && (files.length < 2)) {
    //   const newUploadedImages: File[] = [...uploadedImages] as File[];

    //   for (const file of files) {
    //     if (supportedImageTypes.includes(file.type) && file.size <= 1000000) {
    //       newUploadedImages.push(file);
    //     } else {
    //       upload_error(file.name);
    //     }
    //   }

    //   setUploadedImages(newUploadedImages);
    // }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];

    const updatedIsChanged = {
      ...isChanged,
      banner: true,
    };
    setIsChanged({ ...updatedIsChanged });

    if (!supportedImageTypes.includes(file.type) || file.size > 1000000) {
      upload_error(file.name);
      return;
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 250,
      useWebWorker: true,
      initialQuality: 0.5,
    };
    let compressedImage = await imageCompression(file, options);
    let dataURL = await imageCompression.getDataUrlFromFile(compressedImage);
    // const dataURL = await getBase64(file);

    setBanner(dataURL);
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  function generateUniqueKey(thumbnail: any, index: any) {
    return thumbnail.id || `generatedKey_${index}`;
  }

  return (
    <Fragment>
      {contextHolder}
      <div
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        className="uploadStickerContainerWrap"
      >
        {banner && (
          <div
            className="image-container uploadStickerContainer"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginRight: '10px',
              marginBottom: '10px',
            }}
          >
            <div className="stickerImgWrap">
              <img
                src={
                  !banner.includes('template-thumbnail/')
                    ? banner
                    : `${process.env.REACT_APP_BUCKET_BASE_PATH_FOR_TEMPLATE}${banner}`
                }
                alt={`Selected`}
                style={{
                  width: '120px',
                  height: '120px',
                  marginBottom: '5px',
                }}
              />
            </div>

            <button
              className="delete-icon"
              onClick={(e) => {
                e.preventDefault();
                setBanner(null);

                const updatedIsChanged = {
                  ...isChanged,
                  banner: true,
                };
                setIsChanged({ ...updatedIsChanged });
              }}
            >
              <span className="cmnIcon deleteBin"></span>
            </button>
          </div>
        )}

        {/* {thumbnails.map((thumbnail, index) => (
          <div
            key={generateUniqueKey(thumbnail, index)}
            className="image-container uploadStickerContainer"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          >
            <div className="stickerImgWrap">
              <img
                src={(thumbnail as IThumbnail)?.dataURL || `${process.env.REACT_APP_BUCKET_BASE_PATH_FOR_TEMPLATE}${(thumbnail as ITemplateThumbnail).thumbnail_url}`}
                alt={`Selected ${index}`}
                style={{
                  width: "120px",
                  height: "120px",
                  marginBottom: "5px",
                }}
              />
            </div>

            <button
              className="delete-icon"
              onClick={(e) => {
                e.preventDefault();
                const newThumbnails = banner.filter(
                  (_, i) => i !== index
                );
                setThumbnails(newThumbnails);
                
                const updatedIsChanged = {
                  ...isChanged,
                  thumbnails: true
                };
                setIsChanged({...updatedIsChanged});
                
              }}
            >
              <span className="cmnIcon deleteBin"></span>
            </button>
          </div>
        ))} */}
      </div>

      {!banner && (
        <div className="stickerUploaderInput">
          <div className="uploadCloudImg">
            <CloudUploadOutlined style={{ fontSize: '300%' }} />
            <div className="uploadFileText">
              <a href="#">Upload files</a> or drag and drop
            </div>
            <div className="instruction">JPG, JPEG, PNG or GIF files</div>
          </div>
          <input
            type="file"
            accept="image/jpeg,image/png,image/gif"
            onChange={handleFileChange}
          />
        </div>
      )}
    </Fragment>
  );
};

export default UploadBanner;
