import { Button, Drawer, Space } from 'antd';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import TeamType from './TeamType';
import teamsService from 'modules/services/TeamsService/Teams.service';
import AccountStatus from './AccountStatus';
import AccountCreatedOn from './AccountCreatedOn';
import { Dayjs } from 'dayjs';

interface ITeamFilterDrawerProps {
  filterDrawerTitle: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getTeamsDetails: Function;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  filterOptions: {
    selectedTeamTypes: string[];
    setSelectedTeamTypes: React.Dispatch<React.SetStateAction<string[]>>;
    selectedTeamStatus: string[];
    setSelectedTeamStatus: React.Dispatch<React.SetStateAction<string[]>>;
    startDate: Dayjs | null;
    setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
    endDate: Dayjs | null;
    setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  };
  onReset: MouseEventHandler<HTMLElement> | undefined;
}

const TeamFilterDrawer: React.FC<ITeamFilterDrawerProps> = ({
  filterDrawerTitle,
  open,
  setOpen,
  getTeamsDetails,
  setPage,
  filterOptions,
  onReset
}) => {
  
  const [teamTypes, setTeamTypes] = useState([]);
  const [accountStatus, setAccountStatus] = useState<
    {
      value: number;
      label: string;
    }[]
  >([]);
  const [isLoadingMaster, setIsLoadingMaster] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onOk = () => {
    setOpen(false);
    getTeamsDetails();
    setPage(1);
  };

  const getTeamTypeMasterData = React.useCallback(async () => {
    const response = await teamsService.getMasterData({
      master_data_type: 'team_type',
    });
    setTeamTypes(response.data);
  }, [setTeamTypes]);

  useEffect(() => {
    setIsLoadingMaster(true);
    getTeamTypeMasterData();
    setAccountStatus([
      {
        value: 1,
        label: 'Active',
      },
      {
        value: 0,
        label: 'In-active',
      },
    ]);
    setIsLoadingMaster(false);
  }, []);

  return (
    <React.Fragment>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <span className="cmnIcon filter" onClick={showDrawer}></span>
        </Space>
      </a>
      <Drawer
        className="filterDrawerGeneric"
        loading={isLoadingMaster}
        title={<span className="itemName">{filterDrawerTitle}</span>}
        open={open}
        onClose={onClose}
        footer={
          <>
            <Button type="link" onClick={onReset}>
              Reset
            </Button>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onOk}>
              Apply
            </Button>
          </>
        }
        closeIcon={false}
        width={'500px'}
      >
        <TeamType
          label={'Team Type'}
          teamTypes={teamTypes}
          filterOptions={filterOptions}
        />
        <AccountStatus
          label={'Team Status'}
          accountStatus={accountStatus}
          filterOptions={filterOptions}
        />
        <AccountCreatedOn label={'Created On'} filterOptions={filterOptions} />
      </Drawer>
    </React.Fragment>
  );
};

export default TeamFilterDrawer;
