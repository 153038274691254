import CloudUploadOutlined from '@ant-design/icons/lib/icons/CloudUploadOutlined';
import { message } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useBasicInfoStore } from './store';
import { ITemplateThumbnail, IThumbnail } from 'modules/models/interfaces/template.interface';
import { useTemplateStore } from '../store';
import imageCompression from 'browser-image-compression';

const supportedImageTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
];

const UploadThumbnail: React.FC<{
  isChanged: any;
  setIsChanged: (isChanged: any) => void;
}> = ({ isChanged, setIsChanged }) => {
  const { uploadedImages, thumbnails, setUploadedImages, setThumbnails } =
    useBasicInfoStore();
  const [indexDefault, setIndexDefault] = useState<number>(0);
  const [messageApi, contextHolder] = message.useMessage();
  const { templateDetails } = useTemplateStore();

  const upload_error = (name: string) => {
    messageApi.open({
      type: 'error',
      content: `Size of ${name} should less then 1MB`,
    });
  };

  useEffect(() => {
    const idx = thumbnails?.findIndex((thumbnail: any) => thumbnail.is_default);
    if (idx !== -1) setIndexDefault(idx);
    else setIndexDefault(0);
  }, [thumbnails]);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    if (files && uploadedImages.length + files.length < 2) {
      const newUploadedImages: File[] = [...uploadedImages] as File[];

      for (const file of files) {
        if (supportedImageTypes.includes(file.type) && file.size <= 1000000) {
          newUploadedImages.push(file);
        } else {
          upload_error(file.name);
        }
      }

      setUploadedImages(newUploadedImages);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const files = event.target.files;

    const updatedIsChanged = {
      ...isChanged,
      thumbnails: true,
    };
    setIsChanged({ ...updatedIsChanged });

    if (files && uploadedImages.length + files.length < 2) {
      const newUploadedImages: File[] = [...uploadedImages] as File[];

      for (const file of files) {
        if (supportedImageTypes.includes(file.type) && file.size <= 1000000) {
          newUploadedImages.push(file);
        } else {
          upload_error(file.name);
        }
      }

      setUploadedImages(newUploadedImages);
    }
  };

  const handleUpload = async () => {
    const newThumbnails: IThumbnail[] = [];
    if (uploadedImages.length < 2) {
      for (let i = 0; i < uploadedImages.length; i++) {
        const image = uploadedImages[i];

        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 250,
          useWebWorker: true,
          initialQuality: 0.5,
        };

        let compressedImage = await imageCompression(image, options);
        let dataURL = await imageCompression.getDataUrlFromFile(
          compressedImage,
        );
        // let dataURL = await getBase64(image);
        if (thumbnails[i]) {
          newThumbnails.push({
            id: Date.now() + i,
            dataURL,
            is_default: thumbnails[i].is_default,
          });
        } else {
          newThumbnails.push({
            id: Date.now() + i,
            dataURL,
            is_default: thumbnails.length === 0 && newThumbnails.length === 0,
          });
        }
      }
    }
    setThumbnails([...thumbnails, ...newThumbnails]);
  };

  const handleChangeDefaultThumbnail = (newIndex: number) => {
    let updatedThumbnails = [...thumbnails];
    updatedThumbnails = updatedThumbnails.map((thumbnail, index) => {
      if (index === newIndex) {
        return {
          ...thumbnail,
          is_default: true,
        };
      }
      return {
        ...thumbnail,
        is_default: false,
      };
    });
    setIndexDefault(newIndex);
    setThumbnails(updatedThumbnails);
    if (
      newIndex !==
      templateDetails.template_thumbnails.findIndex(
        (thumbnail: any) => thumbnail.is_default,
      )
    ) {
      const updatedIsChanged = {
        ...isChanged,
        thumbnails: true,
      };
      setIsChanged({ ...updatedIsChanged });
    } else {
      const updatedIsChanged = {
        ...isChanged,
        thumbnails: false,
      };
      setIsChanged({ ...updatedIsChanged });
    }
  };

  const getBase64 = (file: File): Promise<string | any> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  useEffect(() => {
    if (uploadedImages.length > 0) {
      handleUpload();
      setUploadedImages([]);
    }
  }, [uploadedImages]);

  function generateUniqueKey(thumbnail: any, index: any) {
    return thumbnail.id || `generatedKey_${index}`;
  }

  return (
    <Fragment>
      {contextHolder}
      <div
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        className="uploadStickerContainerWrap"
      >
        {thumbnails.map((thumbnail: any, index: number) => (
          <div
            key={generateUniqueKey(thumbnail, index)}
            className="image-container uploadStickerContainer"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginRight: '10px',
              marginBottom: '10px',
            }}
          >
            <div className="stickerImgWrap">
              <img
                src={
                  (thumbnail as IThumbnail)?.dataURL ||
                  `${process.env.REACT_APP_BUCKET_BASE_PATH_FOR_TEMPLATE}${
                    (thumbnail as ITemplateThumbnail).thumbnail_url
                  }`
                }
                alt={`Selected ${index}`}
                style={{
                  width: '120px',
                  height: '120px',
                  // marginBottom: '5px',
                }}
              />
              <div
                className="setDefaultText"
                onClick={() => handleChangeDefaultThumbnail(index)}
              >
                Set default image
              </div>
              <div
                className={
                  index === indexDefault ? 'defaultText' : 'hideDefaultText'
                }
              >
                Default image
              </div>
            </div>

            <button
              className="delete-icon"
              onClick={(e) => {
                e.preventDefault();
                const newThumbnails = thumbnails.filter(
                  (_: any, i: number) => i !== index,
                );
                setThumbnails(newThumbnails);

                const updatedIsChanged = {
                  ...isChanged,
                  thumbnails: true,
                };
                setIsChanged({ ...updatedIsChanged });
              }}
            >
              <span className="cmnIcon deleteBin"></span>
            </button>
          </div>
        ))}
      </div>

      {thumbnails.length < 1 && (
        <div className="stickerUploaderInput">
          <div className="uploadCloudImg">
            <CloudUploadOutlined style={{ fontSize: '300%' }} />
            <div className="uploadFileText">
              <a href="#">Upload files</a> or drag and drop
            </div>
            <div className="instruction">JPG, JPEG, PNG or GIF files</div>
          </div>
          <input
            type="file"
            accept="image/jpeg,image/png,image/gif"
            multiple
            onChange={handleFileChange}
          />
        </div>
      )}
    </Fragment>
  );
};

export default UploadThumbnail;
