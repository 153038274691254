import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import { Editor, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useEffect } from 'react';
import CharacterCount from '@tiptap/extension-character-count';
import { useTemplateStore } from '../store';

const MenuBar = ({ editor }: { editor: Editor | null }) => {

  if (!editor) {
    return null
  }

  return (
    <div className='menuShortDescriptionTemplate'>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().toggleHeading({ level: 1 }).run() }}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
      >
        h1
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().toggleHeading({ level: 2 }).run() }}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        h2
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().toggleHeading({ level: 3 }).run() }}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
      >
        h3
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().toggleHeading({ level: 4 }).run() }}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
      >
        h4
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().toggleHeading({ level: 5 }).run() }}
        className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
      >
        h5
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().toggleHeading({ level: 6 }).run() }}
        className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
      >
        h6
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().toggleBold().run() }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        bold
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().toggleItalic().run() }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        italic
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().toggleStrike().run() }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        strike
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().toggleCode().run() }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleCode()
            .run()
        }
        className={editor.isActive('code') ? 'is-active' : ''}
      >
        code
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().toggleBulletList().run() }}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        bullet list
      </button>
      <button onClick={(e) => { e.preventDefault(); editor.chain().focus().setHardBreak().run() }}>
        hard break
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().setParagraph().run() }}
        className={editor.isActive('paragraph') ? 'is-active' : ''}
      >
        paragraph
      </button>
      <button onClick={(e) => { e.preventDefault(); editor.chain().focus().setHorizontalRule().run() }}>
        horizontal rule
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().undo().run() }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .undo()
            .run()
        }
      >
        undo
      </button>
      <button
        onClick={(e) => { e.preventDefault(); editor.chain().focus().redo().run() }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .redo()
            .run()
        }
      >
        redo
      </button>
    </div>
  )
}


const TemplateDescription = ({ shortDescription, setShortDescription, isChanged, setIsChanged }: {
  shortDescription: string,
  setShortDescription: (shortDescription: any) => void,
  isChanged: any;
  setIsChanged: (isChanged: any) => void
}) => {
  const { templateDetails } = useTemplateStore();

  const editor = useEditor({
    extensions: [
      // TextStyle.configure({ HTMLAttributes: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      CharacterCount.configure({
        limit: 500,
      })
    ],
    content: '',
    onUpdate: ({ editor }) => {
      if (editor.getHTML() !== templateDetails.template_description) {
        const updatedIsChanged = {
          ...isChanged,
          template_description: true
        };
        setIsChanged({...updatedIsChanged});
      }
      else {
        const updatedIsChanged = {
          ...isChanged,
          template_description: false
        };
        setIsChanged({...updatedIsChanged});
      };
      setShortDescription(editor.getHTML())
    }
  })
  useEffect(() => {
    if (templateDetails.template_id !== 0) {
      editor?.commands.setContent(shortDescription);
    }
  }, [shortDescription, editor])

  return (
    <>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </>
  )
}

export default TemplateDescription;
