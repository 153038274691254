import { RightOutlined, SettingOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popover } from 'antd';
import React, { useState } from 'react';
import ColumnEditForm from './ColumnSetting';

interface ActionDropdownProps {
  children: React.ReactNode;
  defaultValue?: any;
  editForm?: any;
  onDelete?: () => void;
  onSave?: (form: any) => void;
  onSaveSetting?: (width: number) => void;
  onChange?: (changedValues: any, allValues: any) => void;
  onDeleteOptions?: (deletedOptions: any) => void;
  onClose?: () => void;
}

const ActionDropdown: React.FC<ActionDropdownProps> = ({
  children,
  ...restProps
}) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleMenuClick = (e: any) => {
    e.domEvent.stopPropagation();
    setSelectedOption(e.key);
  };

  const renderComponent = () => {
    switch (selectedOption) {
      case 'settings':
        return (
          <ColumnEditForm
            {...restProps}
            {...restProps.editForm}
            onSave={(form) => {
              restProps?.onSave?.(form);
              setSelectedOption('');
            }}
            onSaveSetting={(form) => {
              restProps?.onSaveSetting?.(form);
              setSelectedOption('');
            }}
          />
        );
      case 'view':
        return <>View</>;
      case 'editSettings':
        return <>editSettings</>;
      default:
        return null;
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="settings">
        <div className="antdSettingsDropdown">
          <SettingOutlined /> <span>Settings</span>
          <span className="ddArrow">
            <RightOutlined />
          </span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {!selectedOption && (
        <Dropdown dropdownRender={() => <>{menu}</>} trigger={['click']}>
          {children}
        </Dropdown>
      )}
      {selectedOption && (
        <Popover
          open
          trigger="click"
          onOpenChange={(e) => {
            if (!e) {
              restProps?.onChange?.(
                { width: restProps?.defaultValue?.width },
                null,
              );
              setSelectedOption('');
            }
          }}
          content={renderComponent()}
        >
          {children}
        </Popover>
      )}
    </div>
  );
};

export default ActionDropdown;
