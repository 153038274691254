export enum ERbacPermissions {
    // Organization

    // Users
    USER_VIEW = "user:view",

    // Teams
    TEAM_VIEW = "team:view",

    // Message
    MESSAGE_VIEW = "messages:view",

    // Reports
    REPORTS_VIEW = "reports:view",

    // Task sticker
    TASK_STICKER_VIEW = "task_sticker:view",
    
    // Templates
    TEMPLATE_VIEW = "template:view",
    
    // Sticker
    STICKER_VIEW = "sticker:view",
    
}