import { IViewTeamInfo, TeamsTableData } from 'modules/models/interfaces';
import { create } from 'zustand';



interface AppState {
  isDrawer: boolean;
  setIsDrawer: (isDrawer: boolean) => void;
  tloading: boolean;
  setTLoading: (tloading: boolean) => void;
  teamInfo: IViewTeamInfo;
  setTeamInfo: (teamInfo: IViewTeamInfo) => void;
  tableData: TeamsTableData[];
  setTableData: (tableData: TeamsTableData[]) => void;
  btableData: TeamsTableData[];
  setBTableData: (tableData: TeamsTableData[]) => void;

  filterStatus: string;
  setFilterStatus: (filterStatus: string) => void;
  filterTeamsType: string;
  setFilterTeamsType: (filterTeamsType: string) => void;
  filterStartDate: string;
  setFilterStartDate: (filterStartDate: string) => void;
  filterEndDate: string;
  setFilterEndDate: (filterEndDate: string) => void;
}

export const useTeamsStore = create<AppState>((set) => ({
  isDrawer: false,
  setIsDrawer: (isDrawer) => set({ isDrawer }),

  tloading: false,
  setTLoading: (tloading) => set({ tloading }),

  teamInfo: {
    id: 0,
    key: '',
    team_name: '',
    team_status: '',
    account_created: '',
    active_users: 0,
    team_type: '',
    project_count: 0,
    created_on: '',
  },
  setTeamInfo: (teamInfo) => set({ teamInfo }),

  tableData: [],
  setTableData: (tableData) => set({ tableData }),

  btableData: [],
  setBTableData: (btableData) => set({ btableData }),

  filterStatus: '',
  setFilterStatus: (filterStatus) => set({ filterStatus }),

  filterTeamsType: '',
  setFilterTeamsType: (filterTeamsType) => set({ filterTeamsType }),

  filterStartDate: '',
  setFilterStartDate: (filterStartDate) => set({ filterStartDate }),

  filterEndDate: '',
  setFilterEndDate: (filterEndDate) => set({ filterEndDate }),
}));
