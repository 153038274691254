import { Select } from 'antd';
import React from 'react';
import { useTeamsStore } from '../teamsStore';
import { Dayjs } from 'dayjs';

interface IAccountStatusProps {
  label: string;
  accountStatus: {
    value: number;
    label: string;
  }[];
  filterOptions: {
    selectedTeamTypes: string[];
    setSelectedTeamTypes: React.Dispatch<React.SetStateAction<string[]>>;
    selectedTeamStatus: string[];
    setSelectedTeamStatus: React.Dispatch<React.SetStateAction<string[]>>;
    startDate: Dayjs | null;
    setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
    endDate: Dayjs | null;
    setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  }
}

const AccountStatus: React.FC<IAccountStatusProps> = ({
  label,
  accountStatus,
  filterOptions
}) => {
  const { setFilterStatus } = useTeamsStore();
  const handleChange = (value: string[]) => {
    filterOptions.setSelectedTeamStatus(value);
    // if (value?.length) {
    //   setFilterStatus(value.toString());
    // }
  };
  return (
    <>
      <label>{label}</label>
      <Select
        options={accountStatus}
        mode="multiple"
        onChange={handleChange}
        allowClear
        placeholder="Select Status"
        value={filterOptions.selectedTeamStatus}
      />
    </>
  );
};

export default AccountStatus;
