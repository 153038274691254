import { message, Tooltip } from 'antd';

import cryptoRandomString from 'crypto-random-string';
import { SectionTypeMessages } from 'migration/models/enums/messages';
import {
  IProjectState,
  ITaskList,
  ITaskListDetails,
  IUpdatedTasks,
} from 'migration/models/interface';
import MessageThreadV2 from 'migration/MessageThread/MessageThread';
import BrowserStorageService from 'modules/services/browserStorage.service';
import StatusChange from 'modules/sharedComponents/StatusChange/StatusChange';
import { useProjectDashboardStore } from 'modules/store/project.store';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getCurrentOrganization,
  isEmptyString,
  markTaskDeleteInMessageThread,
} from 'utils/commonFunctions';
import { renameTaskInMessageThread } from 'utils/firebaseCommonFunctions';
import TaskDueDate2 from './TaskDueDateOld';
import TaskPriority from './TaskPriority';
import templateService from 'modules/services/TemplateService/Template.service';
import { useTemplateStore } from 'modules/components/Pages/Template/store';
import classNames from 'classnames';
import { ContextTypes } from 'migration/models/enums/constants';
import SharedAssigneeDropDown2 from 'migration/SharedAssigneeDropDown/SharedAssigneeDropDown2';

const Task: React.FC<{
  eachTaskDetail: ITaskListDetails;
  sectionId: number | string;
  setTaskListDetails?: React.Dispatch<React.SetStateAction<ITaskList>>;
  setIsTaskEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
  statusDoneId?: number;
  packId?: number;
  socket?: any;
  sectionName: string;
  taskDetails?: ITaskList;
  renderType?: 'status' | 'task' | 'dueDate' | 'assignee' | 'action';
  section?: any;
}> = ({
  eachTaskDetail,
  sectionId,
  statusDoneId,
  packId,
  taskDetails,
  renderType,
  setIsTaskEmpty,
}) => {
  const {
    projectDetails,
    taskListCmsData,
    customizeSectionUpdateCmsData: customizeCmsData1,
  } = useProjectDashboardStore((state: IProjectState) => state);
  const [taskName, setTaskName] = useState(eachTaskDetail.task_name);
  const browserStorage = BrowserStorageService.getInstance();
  const userDetails = browserStorage.getUserDetails();
  const [taskDetail, setTaskDetail] = useState(eachTaskDetail);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const { templateDetails, sections, setSections } = useTemplateStore();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  let customizeCmsData = {
    done_task_list: [
      {
        classname: 'customDefaultTaskName',
        is_deleted: false,
        status_name: 'Default',
        status_done_view_id: 1,
      },
    ],
  };

  useEffect(() => {
    setTaskDetail(eachTaskDetail);
  }, [eachTaskDetail, taskDetails]);
  const taskNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.target?.value;
    setTaskName(value);
  };
  const removeBlankNewTask = () => {
    setSections(
      sections.map((mainObj) => {
        if (mainObj.section_id === sectionId) {
          return {
            ...mainObj,
            section_details:
              mainObj.section_details?.length === 1
                ? mainObj.section_details
                : mainObj.section_details?.filter(
                    (eachTask: any) =>
                      typeof eachTask.task_id === 'number' ||
                      !isEmptyString(eachTask?.task_name),
                  ),
          };
        }
        return mainObj;
      }),
    );
  };
  const saveNewTask = async (taskId: string) => {
    if (typeof sectionId === 'number') {
      try {
        const response = await templateService.createNewTask({
          template_id: templateDetails.template_id,
          section_id: sectionId,
          task_name: taskName?.trim() ?? '',
          task_rank: eachTaskDetail?.task_rank ?? 999,
          task_status_id: 1,
          task_priority_id: 2,
        });
        if (response.messageId === 1) {
          setSections(
            sections?.map((eachSection: any) => {
              if (
                eachSection.section_id === sectionId &&
                eachSection.section_details?.length
              ) {
                return {
                  ...eachSection,
                  section_details: eachSection!.section_details!.map(
                    (eachTask: any) => {
                      if (eachTask.task_id === taskId) {
                        eachTask.task_name = taskName?.trim();
                        eachTask.task_id = response.data.task_id;
                      }
                      return eachTask;
                    },
                  ),
                };
              }
              return eachSection;
            }),
          );

          setIsTaskEmpty?.(false);
        }
      } catch (e) {
        removeBlankNewTask();
        message.error('Error', 3);
      }
    }
  };

  const updateExistingTask = async (taskData: IUpdatedTasks[]) => {
    if (templateDetails.template_id && typeof sectionId === 'number') {
      try {
        if (taskData[0].is_deleted === true) {
          setSections(
            sections?.map((eachSection: any) => {
              if (
                eachSection.section_id === sectionId &&
                eachSection.section_details?.length
              ) {
                const _eachSection = {
                  ...eachSection,
                  section_details: eachSection!.section_details!.filter(
                    (eachTask: any) => eachTask.task_id !== taskData[0].task_id,
                  ),
                };
                if (_eachSection.section_details.length === 0) {
                  let newTask = {
                    task_id: cryptoRandomString({ length: 5 }),
                    task_rank: 1,
                    task_name: '',
                    due_date: '',
                    assignee_dp: '',
                    assignee_id: null,
                    assignee_name: '',
                    assignee_email: '',
                    task_status_id: 1,
                    task_priority_id: 2,
                    task_status_name: '',
                    task_priority_name: '',
                  };
                  _eachSection.section_details?.push(newTask);
                }
                return _eachSection;
              }
              return eachSection;
            }),
          );
        } else {
          setSections(
            sections?.map((eachSection: any) => {
              if (
                eachSection.section_id === sectionId &&
                eachSection.section_details?.length
              ) {
                return {
                  ...eachSection,
                  section_details: eachSection!.section_details!.map(
                    (eachTask: any) => {
                      const taskDatum = taskData.find(
                        (eachTaskDatum) =>
                          eachTaskDatum.task_id === eachTask.task_id,
                      );
                      if (eachTask.task_id === taskDatum?.task_id) {
                        return {
                          ...eachTask,
                          ...taskDatum,
                          assignee_dp: undefined,
                          assignee_id: taskDatum?.assignee_id,
                          assignee_email: undefined,
                          assignee_name: undefined,
                        };
                      }
                      return eachTask;
                    },
                  ),
                };
              }
              return eachSection;
            }),
          );
        }
        await templateService.updateTask({
          template_id: templateDetails.template_id,
          section_id: sectionId,
          section_details: taskData,
        });
      } catch (error) {
        message.error('Error', 3);
      }
    }
  };

  const addNewTaskOnEnter = (
    taskId: string | number,
    event?: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event?.key === 'Enter') {
      if (!isEmptyString(taskName)) {
        // @ts-ignore
        setTaskName((prev: string) => prev?.trim());
      }
      const task_id = cryptoRandomString({ length: 5 });
      setSections(
        sections.map((prev: any) => {
          if (prev.section_id === sectionId) {
            const _task_list_details = prev?.section_details;
            if (_task_list_details) {
              let max = _task_list_details?.reduce(function (
                prev: { task_rank: number },
                current: { task_rank: number },
              ) {
                return prev?.task_rank > current?.task_rank ? prev : current;
              })?.task_rank;

              let newTask = {
                task_id: task_id,
                task_rank: max + 1,
                task_name: '',
                due_date: '',
                assignee_dp: '',
                assignee_id: null,
                assignee_name: '',
                assignee_email: '',
                assigner_id: null,
                task_status_id: 1,
                task_priority_id: 2,
                task_status_name: taskListCmsData?.task_status_options.find(
                  (eachOption: { status_id: number }) =>
                    eachOption.status_id === 1,
                )?.status_name,
                task_priority_name: taskListCmsData?.task_priority_options.find(
                  (eachOption: { priority_id: number }) =>
                    eachOption.priority_id === 2,
                )?.priority_name,
                created_by: userDetails?.user_id,
                custom_data: taskDetail?.custom_data,
              };
              _task_list_details?.push(newTask);
            } else {
              event.currentTarget.blur();
            }
            const _temp_details = _task_list_details?.filter(
              (eachTask: { task_id: any; task_name: any }) =>
                typeof eachTask.task_id === 'number' ||
                !isEmptyString(eachTask?.task_name),
            );
            if (!isEmptyString(taskName)) {
              return { ...prev, section_details: _task_list_details };
            } else if (!_temp_details?.length) {
              event.currentTarget.blur();
              return {
                ...prev,
                section_details: _task_list_details?.slice(0, 1),
              };
            }
            return { ...prev, section_details: _task_list_details };
          }
          return prev;
        }),
      );
    }
  };

  const taskNameOnBlurHandler = (
    taskId: string | number,
    event?: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    if (!isEmptyString(taskName)) {
      // @ts-ignore
      setTaskName((prev: string) => prev?.trim());
    }
    const oldTaskName = eachTaskDetail.task_name;
    if (typeof taskId === 'string' && isEmptyString(taskName)) {
      removeBlankNewTask();
    } else if (
      typeof taskId === 'number' &&
      isEmptyString(taskName) &&
      oldTaskName
    ) {
      setTaskName(oldTaskName);
    } else if (
      typeof taskId === 'string' &&
      !isEmptyString(taskName) &&
      event?.target.value
    ) {
      saveNewTask(taskId);
    } else if (
      typeof taskId === 'number' &&
      oldTaskName &&
      oldTaskName !== taskName
    ) {
      updateExistingTask([
        { ...eachTaskDetail, task_id: taskId, task_name: taskName },
      ]);
    }
    setIsEditMode(false);
    if (taskName) {
      renameTaskInMessageThread(
        `${SectionTypeMessages.SIMPLE_LIST_TASK}-${process.env.REACT_APP_STAGE}-CS-${templateDetails?.template_id}-${sectionId}-${eachTaskDetail.task_id}`,
        taskName,
      );
    }
  };

  const deleteTaskHandler = async () => {
    if (typeof eachTaskDetail.task_id === 'number') {
      updateExistingTask([
        {
          ...eachTaskDetail,
          task_id: eachTaskDetail.task_id,
          is_deleted: true,
        },
      ]);
      markTaskDeleteInMessageThread(
        `${SectionTypeMessages.SIMPLE_LIST_TASK}-${
          process.env.REACT_APP_STAGE
        }-${getCurrentOrganization(org_key, userDetails?.organization_info)
          ?.organization_id!}-${projectDetails?.project_id}-${sectionId}-${
          eachTaskDetail.task_id
        }`,
      );
    }
  };

  const renderTaskName = () => {
    const name = taskName?.slice(0, 255);
    if (!isEditMode && typeof taskDetail.task_id !== 'string') {
      if (taskDetail.task_status_id === 3) {
        const selectedStatus = customizeCmsData?.done_task_list.find(
          (e) => e.status_done_view_id === statusDoneId,
        );
        if (selectedStatus) {
          return (
            <div
              className={classNames('taskNameBlk', {
                cursorPointer: true,
                [selectedStatus.classname]: !!selectedStatus.classname,
              })}
              onClick={() => setIsEditMode(true)}
              onKeyDown={() => {}}
            >
              <Tooltip title={name} placement="left" color={'#2E364C'}>
                <span className="simpleTaskListTaskName">{name}</span>
              </Tooltip>
            </div>
          );
        }
      }
      return (
        <div
          onClick={() => setIsEditMode(true)}
          onKeyDown={() => {}}
          className="taskNameBlk"
        >
          <Tooltip title={name} placement="left" color={'#2E364C'}>
            <span className="simpleTaskListTaskName">{name}</span>
          </Tooltip>
        </div>
      );
    }

    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          autoFocus
          type="text"
          id={`text-input-${sectionId}-${taskDetail.task_id?.toString()}`}
          className="text-input-box"
          placeholder={'Task Name'}
          value={taskName}
          aria-label="task name"
          onChange={taskNameChangeHandler}
          onBlur={(e) => taskNameOnBlurHandler(taskDetail.task_id, e)}
          onKeyDown={(e) => addNewTaskOnEnter(taskDetail.task_id, e)}
          maxLength={255}
          disabled={false}
        />
      </form>
    );
  };

  useEffect(() => {
    if (isEditMode) {
      inputRef.current?.focus();
    }
  }, [isEditMode]);

  const getEachTaskJsx = () => {
    if (renderType === 'status') {
      return (
        <span className="blkStatus cursorDisabled">
          <StatusChange
            // @ts-ignore
            eachTaskDetail={{
              ...eachTaskDetail,
            }}
            packId={packId}
          />
        </span>
      );
    }
    if (renderType === 'task') {
      return (
        <div className="task-column">
          {typeof eachTaskDetail.task_id !== 'string' && (
            <>
              <MessageThreadV2
                sectionId={sectionId}
                taskId={taskDetail.task_id}
                sectionType={SectionTypeMessages.SIMPLE_LIST_TASK}
                threadName={taskDetail.task_name!}
                taskDetails={taskDetail}
                componentId="cs_admin"
              />
            </>
          )}

          <TaskPriority />
          {renderTaskName()}
        </div>
      );
    }

    if (renderType === 'dueDate') {
      return (
        <>
          <TaskDueDate2 eachTaskDetail={taskDetail} />
        </>
      );
    }
    if (renderType === 'assignee') {
      return <SharedAssigneeDropDown2 contextType={ContextTypes.TASKLIST} />;
    }
    if (renderType === 'action') {
      return (
        <>
          {!isEditMode ? (
            <div className="action-icons">
              <span
                className="cmnIcon deleteBin"
                onClick={() => {
                  deleteTaskHandler();
                }}
                onKeyDown={() => {}}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      );
    }

    return <></>;
  };

  return <>{getEachTaskJsx()}</>;
};

export default Task;
