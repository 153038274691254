import { useAuth0 } from "@auth0/auth0-react";
import { withRbac } from "auth/rbac/rbac";
import { useUserStore } from "modules/store";
import React, { useEffect } from "react";
import LeftNav from "./LeftNav/LeftNav";
import MainContent from "./MainContent/MainContent";

const DashBoardPage: React.FC<{}> = () => {
    const { setUserDetail } = useUserStore();
    const { user } = useAuth0();
    useEffect(() => {
        if (user) {
            setUserDetail(user);
        }
    }, []);

    return (
        <div className="adminPanelMainWrapper mainWrapper">
            <LeftNav />
            <div className="adminPanelMainContent mainContent">
                <MainContent />
            </div>
        </div>
    );
}

export default withRbac(DashBoardPage, { isAccessFromPage: false });