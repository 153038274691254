import { IRouteConfig } from "./routes.interface";

import {
  DashBoardPage,
  ErrorPage,
  LandingPage,
  NotFound,
} from "modules/components";
import { NavRoutesEnum } from "modules/models/enums";
import UserManagement from "modules/components/Pages/User/UserManagement";
import AccountSetup from "modules/components/AccountSetup/AccountSetup";
import TeamManagement from "modules/components/Pages/Teams/TeamsManagement";
import TaskStickerManagement from "modules/components/Pages/TaskStickers/TaskStickerManagement";
import StickerManagement from "modules/components/Pages/Stickers/StickerManagement";
import Template from "../modules/components/Pages/Template/Template";
import TemplateList from "../modules/components/Pages/TemplateList/TemplateList";

export const routes: IRouteConfig[] = [
  {
    path: "/",
    component: LandingPage,
    isProtected: true,
  },
  {
    path: "/dashboard",
    component: DashBoardPage,
    isProtected: true,
  },

  {
    path: "/users",
    component: UserManagement,
    isProtected: true,
  },

  {
    path: "/teams",
    component: TeamManagement,
    isProtected: true,
  },

  {
    path: "/tasksticker",
    component: TaskStickerManagement,
    isProtected: true,
  },

  {
    path: "/sticker",
    component: StickerManagement,
    isProtected: true,
  },

  {
    path: "/user-active",
    component: AccountSetup,
    isProtected: false,
  },
  {
    path: "/templates/:id",
    component: Template,
    isProtected: true,
  },
  {
    path: "/templates/create",
    component: Template,
    isProtected: true,
  },
  {
    path: "/templates",
    component: TemplateList,
    isProtected: true,
  },
  {
    path: NavRoutesEnum.ERROR_PAGE,
    component: ErrorPage,
    isProtected: false,
  },

  {
    path: "/*",
    component: NotFound,
    isProtected: true,
  },
];
