import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Tooltip, Divider, Popover } from "antd";
import EditorJS from "@editorjs/editorjs";
import { useTemplateStore } from "../../store";
import templateService from "modules/services/TemplateService/Template.service";
import { ComponentLoader } from "modules/sharedComponents/components";
import MeatBallMenu from "../MeatBallMenu";
import { ISection } from "modules/models/interfaces/template.interface";
import TiptapEditor from "./TiptapEditor";
import { JSONContent } from "@tiptap/react";
import {AlignLeftOutlined, CheckSquareOutlined, CloudOutlined, TableOutlined, UploadOutlined} from "@ant-design/icons";
import cryptoRandomString from 'crypto-random-string';

const DEFAULT_CONTENT = {
  "type": "doc",
  "content-type": "text",
  "is_new": true,
  "content": [
      {
          "type": "paragraph"
      }
  ]
}
const TABLE_CONTENT = {
  "type": "doc",
  "content-type": "table",
  "is_new": true,
  "content": [
      {
          "type": "table",
          "content": [
              {
                  "type": "tableRow",
                  "content": [
                      {
                          "type": "tableHeader",
                          "attrs": {
                              "colspan": 1,
                              "rowspan": 1,
                              "colwidth": null
                          },
                          "content": [
                              {
                                  "type": "paragraph"
                              }
                          ]
                      },
                      {
                          "type": "tableHeader",
                          "attrs": {
                              "colspan": 1,
                              "rowspan": 1,
                              "colwidth": null
                          },
                          "content": [
                              {
                                  "type": "paragraph"
                              }
                          ]
                      },
                      {
                          "type": "tableHeader",
                          "attrs": {
                              "colspan": 1,
                              "rowspan": 1,
                              "colwidth": null
                          },
                          "content": [
                              {
                                  "type": "paragraph"
                              }
                          ]
                      }
                  ]
              },
              {
                  "type": "tableRow",
                  "content": [
                      {
                          "type": "tableCell",
                          "attrs": {
                              "colspan": 1,
                              "rowspan": 1,
                              "colwidth": null
                          },
                          "content": [
                              {
                                  "type": "paragraph"
                              }
                          ]
                      },
                      {
                          "type": "tableCell",
                          "attrs": {
                              "colspan": 1,
                              "rowspan": 1,
                              "colwidth": null
                          },
                          "content": [
                              {
                                  "type": "paragraph"
                              }
                          ]
                      },
                      {
                          "type": "tableCell",
                          "attrs": {
                              "colspan": 1,
                              "rowspan": 1,
                              "colwidth": null
                          },
                          "content": [
                              {
                                  "type": "paragraph"
                              }
                          ]
                      }
                  ]
              },
              {
                  "type": "tableRow",
                  "content": [
                      {
                          "type": "tableCell",
                          "attrs": {
                              "colspan": 1,
                              "rowspan": 1,
                              "colwidth": null
                          },
                          "content": [
                              {
                                  "type": "paragraph"
                              }
                          ]
                      },
                      {
                          "type": "tableCell",
                          "attrs": {
                              "colspan": 1,
                              "rowspan": 1,
                              "colwidth": null
                          },
                          "content": [
                              {
                                  "type": "paragraph"
                              }
                          ]
                      },
                      {
                          "type": "tableCell",
                          "attrs": {
                              "colspan": 1,
                              "rowspan": 1,
                              "colwidth": null
                          },
                          "content": [
                              {
                                  "type": "paragraph"
                              }
                          ]
                      }
                  ]
              }
          ]
      }
  ]
}
const LIST_CONTENT = {
  "type": "doc",
  "content-type": "list",
  "is_new": true,
  "content": [
      {
          "type": "taskList",
          "content": [
              {
                  "type": "taskItem",
                  "attrs": {
                      "checked": true
                  },
                  "content": [
                      {
                          "type": "paragraph",
                          "content": [
                              {
                                  "type": "text",
                                  "text": "Checklist 1"
                              }
                          ]
                      }
                  ]
              },
              {
                  "type": "taskItem",
                  "attrs": {
                      "checked": false
                  },
                  "content": [
                      {
                          "type": "paragraph",
                          "content": [
                              {
                                  "type": "text",
                                  "text": "Checklist 2"
                              }
                          ]
                      }
                  ]
              },
              {
                  "type": "taskItem",
                  "attrs": {
                      "checked": false
                  },
                  "content": [
                      {
                          "type": "paragraph",
                          "content": [
                              {
                                  "type": "text",
                                  "text": "Checklist 3"
                              }
                          ]
                      }
                  ]
              }
          ]
      },
      {
          "type": "paragraph"
      }
  ]
}
const FILE_CONTENT = {
  "type": "doc",
  "content-type": "file",
  "is_new": true,
  "content": {}
}
const LINK_CONTENT = {
  "type": "doc",
  "content-type": "link",
  "is_new": true,
  "content": {}
}

const MemoPad: React.FC<{
  sectionId: string | number;
  pageSpecificationData: ISection;
  sectionDetails: ISection;
}> = ({ sectionId, pageSpecificationData, sectionDetails }) => {
  const [isEditingSectionName, setIsEditingSectionName] = useState(false);
  const editorCore = useRef<EditorJS | null>(null);
  const [sectionName, setSectionName] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [sectionToggleState, setSectionToggleState] = useState<
    "expanded" | "collapsed"
  >("expanded");
  const { sections, setSections } = useTemplateStore();
  const { templateDetails } = useTemplateStore();
  const [editorUpdated, setEditorUpdated] = useState<
    { timestamp: number; data: Object } | undefined
  >(undefined);
  const [isUpdateMemopad, setIsUpdateMemopad] = useState(false);
  const memopadContainerRef = useRef<HTMLDivElement>(null);
  const [tempMemoPadValue, setTempMemopadValue] = useState<any[]>([]);
  const [showPopOver, setShowPopOver] = useState<boolean>(false);

  const sectionNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event?.currentTarget?.value;
    setSectionName(value);
  };

  const saveOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      sectionNameOnBlurHandler(event);
    }
  };

  const handleSectionToggle = () => {
    setSectionToggleState((prev) =>
      prev === "collapsed" ? "expanded" : "collapsed"
    );
  };

  // const onEditorUpdatedCallback = useCallback(
  //   async (data: Object) => {
  //     if (sections) {
  //       const updatedSections = [
  //         {
  //           section_id: +sectionId,
  //           section_name: sectionName,
  //           memopad_data: data,
  //         },
  //       ];
  //       const result = await templateService.updateSection({
  //         template_id: templateDetails?.template_id!,
  //         sections: updatedSections,
  //       });

  //       if (result.messageId === 1) {
  //         const newSections: ISection[] = sections.map((section) => {
  //           if (section.section_id === sectionId) {
  //             section.memopad_data = data;
  //           }
  //           return section;
  //         });
  //         setSections(newSections);
  //       }
  //     }
  //   },
  //   [
  //     sectionId,
  //     sectionName,
  //     sections,
  //     setSections,
  //     templateDetails?.template_id,
  //   ]
  // );

  useEffect(() => {
    if (typeof sectionId === "string") {
      setIsEditingSectionName(true);
    } else {
      setIsEditingSectionName(false);
    }
  }, [sectionId]);

  useEffect(() => {
    if (isEditingSectionName) {
      setSectionName(sectionDetails?.section_name ?? "");
    }
  }, [isEditingSectionName]);

  useEffect(() => {
    if (!isEditingSectionName) {
      setSectionName(sectionDetails?.section_name);
      const checkIsDataDoesHaveId = sectionDetails?.memopad_data ? sectionDetails.memopad_data.map((e: any) => ({...e, docId: e.docId ?? cryptoRandomString({length: 10, type: 'alphanumeric'})})) : []
      setTempMemopadValue(checkIsDataDoesHaveId);
    }
  }, [sectionDetails]);

  // useEffect(() => {
  //   let isCanceled = false;
  //   if (editorUpdated?.timestamp && !isCanceled && isUpdateMemopad) {
  //     onEditorUpdatedCallback(editorUpdated.data);
  //   }

  //   return () => {
  //     isCanceled = true;
  //   };
  // }, [editorUpdated?.timestamp]);

  const sectionNameOnBlurHandler = async (
    _event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    setIsEditingSectionName(false);
    setSectionName((prev) => prev?.trim());
    if (sections) {
      const oldSection = sections.find(
        (eachSection) => eachSection.section_id === sectionId
      );
      if (typeof oldSection?.section_id === "string" && sectionName === "") {
        setSections(
          sections?.filter(
            (eachSection) => eachSection.section_id !== sectionId
          )
        );
      } else if (
        typeof oldSection?.section_id === "string" &&
        sectionName !== ""
      ) {
        await createNewSection(oldSection);
      } else if (
        typeof oldSection?.section_id === "number" &&
        sectionName?.trim() !== "" &&
        oldSection.section_name !== sectionName
      ) {
        await updateExistingSection(oldSection);
      }
    }
  };
  // @ts-ignore ignoring old type section
  const createNewSection = async (oldSection) => {
    setIsLoading(true);
    const response = await templateService.createNewSection({
      template_id: templateDetails?.template_id!,
      section_type_id: 3,
      section_name: sectionName,
      section_rank: oldSection.section_rank,
    });
    if (response.messageId === 1) {
      setSections(
        sections!.map((eachSection) => {
          const _section = eachSection;
          if (_section.section_id === sectionId) {
            _section.section_name = sectionName;
            _section.section_id = response.data.section_id;
          }
          return _section;
        })
      );
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  // @ts-ignore ignoring old type section
  const updateExistingSection = async (oldSection) => {
    setIsLoading(true);
    const result = await templateService.updateSection({
      template_id: templateDetails?.template_id!,
      sections: [
        {
          section_id: oldSection.section_id,
          section_name: sectionName,
        },
      ],
    });
    if (result.messageId === 1) {
      setSections(
        sections!.map((eachSection) => ({
          ...eachSection,
          section_name:
            eachSection.section_id === sectionId
              ? sectionName
              : eachSection.section_name,
        }))
      );
    } else {
      console.log(result);
    }
    setIsLoading(false);
  };

  const updateMemopad = async (data: Object) => {
    if (sections) {
      const result = await templateService.updateSection({
        template_id: templateDetails?.template_id!,
        sections: [
            {
                section_id: +sectionDetails.section_id,
                section_name: sectionName === "" ? sectionDetails.section_name : sectionName,
                memopad_data: data,
            },
        ],
      });

      setIsEditingSectionName(false)
      setSectionToggleState("expanded");
    }
  };

  const openToolbox: MouseEventHandler<HTMLDivElement> = (evt: any) => {
    // @ts-ignore ignoring un-match html DOM type
    const left: number =
      evt.target.parentElement.getBoundingClientRect().width / 2;
    const top: number = 100;

    editorCore.current?.focus(true);
    editorCore.current?.caret?.setToLastBlock();
    editorCore.current?.toolbar.toggleToolbox(true);
    // @ts-ignore ignoring un-match html DOM type
    evt.target.parentElement.previousElementSibling
      .querySelector(".ce-toolbox .ce-popover.ce-popover--opened")
      ?.setAttribute("style", `top: ${top}px; left: ${left}px`);
  };

  const updateContentBlock = (data: JSONContent, index: number) => {
    const tempData = [...tempMemoPadValue]
    tempData[index] = data
    setTempMemopadValue(tempData)
    updateMemopad(tempData)
  }

  const moveUpContentBlock = (index: number) => {
    const tempData = [...tempMemoPadValue]
    const tempComponent = tempData[index - 1]
    const targetContent = tempData[index]
    tempData[index - 1] = targetContent
    tempData[index] = tempComponent
    setTempMemopadValue(tempData)
    updateMemopad(tempData)
  }
  const moveDownContentBlock = (index: number) => {
    const tempData = [...tempMemoPadValue]
    const tempComponent = tempData[index + 1]
    const targetContent = tempData[index]
    tempData[index + 1] = targetContent
    tempData[index] = tempComponent
    setTempMemopadValue(tempData)
    updateMemopad(tempData)
  }

  const removeContentBlock = (index: number) => {
    const tempData = [...tempMemoPadValue]
    tempData.splice(index, 1)
    setTempMemopadValue(tempData)
    updateMemopad(tempData)
  }

  const showContent = () => {
    if (sectionId && typeof sectionId === "string") {
      return false;
    }
    return !isEditingSectionName && sectionToggleState === "expanded";
  };

  const MemopadContent = (
    <div className="memopad-feature-container" onMouseLeave={() => {
        setShowPopOver(false)
    }}>
        <div className="memopad-feature-item" onClick={() => {
            setShowPopOver(false)
            const tempContent = [...tempMemoPadValue]
            tempContent.push({...DEFAULT_CONTENT, docId: cryptoRandomString({length: 10, type: 'alphanumeric'})})
            setTempMemopadValue(tempContent)
            updateMemopad(tempContent)
        }}><AlignLeftOutlined/>
            <span className="memopad-feature-label">Text</span>
        </div>
        <div className="memopad-feature-item" onClick={() => {
            setShowPopOver(false)
            const tempContent = [...tempMemoPadValue]
            tempContent.push({...LINK_CONTENT, docId: cryptoRandomString({length: 10, type: 'alphanumeric'})})
            setTempMemopadValue(tempContent)
            updateMemopad(tempContent)
        }}><CloudOutlined/>
            <span className="memopad-feature-label">Link</span>
        </div>
        <div className="memopad-feature-item" onClick={() => {
            setShowPopOver(false)
            const tempContent = [...tempMemoPadValue]
            tempContent.push({...FILE_CONTENT, docId: cryptoRandomString({length: 10, type: 'alphanumeric'})})
            setTempMemopadValue(tempContent)
            delete tempContent[tempContent.length - 1].is_new
            updateMemopad(tempContent)
        }}><UploadOutlined/>
            <span className="memopad-feature-label">Media File</span>
        </div>
        <div className="memopad-feature-item" onClick={() => {
            setShowPopOver(false)
            const tempContent = [...tempMemoPadValue]
            tempContent.push({...TABLE_CONTENT, docId: cryptoRandomString({length: 10, type: 'alphanumeric'})})
            setTempMemopadValue(tempContent)
           setTimeout(() => {
               memopadContainerRef?.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
           }, 200)
            updateMemopad(tempContent)
        }}><TableOutlined/>
            <span className="memopad-feature-label">Table</span>
        </div>
        <div className="memopad-feature-item" onClick={() => {
            setShowPopOver(false)
            const tempContent = [...tempMemoPadValue]
            tempContent.push({...LIST_CONTENT, docId: cryptoRandomString({length: 10, type: 'alphanumeric'})})
            setTempMemopadValue(tempContent)
            setTimeout(() => {
                memopadContainerRef?.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
            }, 200)
            updateMemopad(tempContent)
        }}><CheckSquareOutlined/>
            <span className="memopad-feature-label">Checklist</span>
        </div>
    </div>
  );

  return (
    <ComponentLoader isLoading={isLoading}>
      <div
        className="createNewSecMainContent"
        onFocus={() => setIsUpdateMemopad(true)}
        onBlur={() => setIsUpdateMemopad(false)}
      >
        <div className="firstTopic">
          <div className="taskNameHeadingPane">
            <div className="itemNameBlk">
              <div className="nameOuterBlk">
                <Tooltip
                  title="Toggle memopad view"
                  color={'#2E364C'}
                  placement="bottom"
                >
                  <span
                    className={`sidePanelIcon ${
                      sectionToggleState === 'expanded'
                        ? 'arrow-down'
                        : 'arrow-right'
                    }`}
                    color={'#2E364C'}
                    onClick={handleSectionToggle}
                  />
                </Tooltip>
                {isEditingSectionName ? (
                  <input
                    className="section-text-input"
                    placeholder="Give this memopad a name"
                    onBlur={sectionNameOnBlurHandler}
                    onChange={sectionNameChangeHandler}
                    onKeyDown={saveOnEnter}
                    value={sectionName}
                    autoFocus={true}
                    maxLength={50}
                  />
                ) : (
                  <span
                    className="section-text-span"
                    onClick={() => {
                      setIsEditingSectionName(true);
                    }}
                  >
                    {sectionName?.trim() === ''
                      ? sections?.find(
                          (eachSection) => eachSection.section_id === sectionId,
                        )?.section_name
                      : sectionName}
                  </span>
                )}
              </div>
              {!isEditingSectionName ? (
                <>
                  <MeatBallMenu
                    sectionType="memo-pad"
                    sectionDetails={pageSpecificationData}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {showContent() ? (
          <div ref={memopadContainerRef} className="memoPadTipTap">
            {tempMemoPadValue.map((e, i) => (
              <TiptapEditor
                key={e.docId}
                content={e}
                index={i}
                maxIndex={tempMemoPadValue.length - 1}
                onMoveUp={moveUpContentBlock}
                onMoveDown={moveDownContentBlock}
                onRemove={removeContentBlock}
                onUpdate={updateContentBlock}
                templateId={templateDetails?.template_id!}
              />
            ))}
            <div className="memopadEmptyContainer">
              <Divider className="memopadDivider" />
              <Popover
                open={showPopOver}
                placement="bottom"
                title={''}
                content={MemopadContent}
                overlayClassName="customAntPopover"
              >
                <div
                  className="memopadPlusIcon"
                  onClick={() => {
                    setShowPopOver(!showPopOver);
                  }}
                />
              </Popover>
            </div>
          </div>
        ) : null}
        {showContent() ? (
          <div className={'userInformation'}>
            Add any content element to this memopad! You can edit, reorder,
            clone, or delete any content
          </div>
        ) : null}
      </div>
    </ComponentLoader>
  );
};

export default MemoPad;
