import { create } from 'zustand';
import { IModalData } from '../models/interfaces/template.interface';

export interface IModalState {
  modalState: IModalData | null,
  setModalState: (value: IModalData) => void
}

export const useModalStore = create<IModalState>((set) => ({
    //initial states
    modalState: null,

    //actions
    setModalState: (value: IModalData) => set({ modalState: value })
}))