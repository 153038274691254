import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { RBAC, useRbac } from "auth/rbac/rbac";
import { useLeftNavStore, useUserStore } from "modules/store";
import userService from "modules/services/UsersManageService/User.manage.service";
import apiService from "modules/services/Api.service";
import ComponentLoader from "modules/sharedComponents/components/Loaders/ComponentLoader";


const LeftNav: React.FC<{}> = () => {
    const { isCollapse, setIsCollapse } = useLeftNavStore();
    const [isSetting, setIsSetting] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { hasPermissions } = useRbac();
    const {btnactive, setBtnactive} = useLeftNavStore();
    const {
        setUserDetails,
        userDetails,
    } = useUserStore();

    const handleCollapse = () => {
        setIsCollapse(!isCollapse);
    };

    const displaySetting = () => {
        setIsSetting(!isSetting);
    }

    const { logout } = useAuth0();
    const { user } = useAuth0();

    const navigate = useNavigate();

    const getUserDetails = useCallback(async () => {
        setLoading(true);
        const userResponseData = await userService.userDetails();

        if (userResponseData.messageId === '-3') {
            window.location.replace('/invitation-not-match')
            setTimeout(() => {
                logout()
            }, 2000)
        }
        if (userResponseData.messageId === '-1') {
            window.location.replace('/invitation-not-match')
            setTimeout(() => {
                logout()
            }, 2000)
        } else {
            setUserDetails(
                userResponseData.data,
                userResponseData.data.permissions,
            );
            setLoading(false);
        }
    }, [setUserDetails]);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        apiService.setTokenGenerator(getAccessTokenSilently);
        if (!userDetails) {
            getUserDetails();
        }
        if (hasPermissions([ERbacPermissions.USER_VIEW])) {
            setLoading(false);
        }
    }, []);

    const picname = () => {
        const fullName: string = user?.name || "";
        const wordsArray: string[] = fullName.split(" ");
        const firstLetter: string = wordsArray[0].charAt(0).toUpperCase();
        let lastLetter: string;
        if (wordsArray.length > 1) {
            const lastWordIndex: number = wordsArray.length - 1;
            lastLetter = wordsArray[lastWordIndex].charAt(0).toUpperCase();
        } else {
            lastLetter = wordsArray[0].substring(0, 1).toUpperCase();
        }
        const PicName: string = firstLetter + lastLetter;
        return (PicName);
    }


    useEffect(()=>{
        const url = window.location.href;
        const regex = /(\/\w+)$/gi;
        const navurl = regex.exec(url);
        if(navurl){;
            setBtnactive(navurl[0]);
        }
    },[]);

    const navigateFunction = (navUrl : string) => {
        setBtnactive(navUrl);
        navigate(navUrl);
        setLoading(false);
    }

    console.log()

    return (
        <Fragment>
            <div className={`expander ${isCollapse ? "collapsedExp" : ""}`} onClick={handleCollapse}>
                <span className="material-icons-outlined">menu</span>
            </div>

            <div className={`adminPanel secondPanel ${isCollapse ? "collapsedSecondPanel" : ""}`}>
                <div className="companyHeadingPanel">
                    <h2 onClick={() => { navigate('/dashboard') }}>CS Admin</h2>
                    <div className="iconList">
                        {/* <div className="notiIndicator active">
                            <span className="notification"></span>
                        </div> */}

                    </div>
                </div>
                {loading ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%',
                        paddingTop: '100px'
                    }}><ComponentLoader isLoading={loading} children={undefined} /></div>
                ) :
                    <div className="itemList defaultItemList">
                        <ul>
                            <RBAC allowedPermissions={[ERbacPermissions.USER_VIEW]}>
                                <li className={`${btnactive=='/users' ? "active-project" : ""}`}>
                                    <div className="itemNameBlk" onClick={() => { navigateFunction('/users') }}>
                                        <span className="sidePanelIcon users" title="Users"></span>
                                        <span className="itemName">Users</span>
                                    </div>

                                </li>
                            </RBAC>

                            <RBAC allowedPermissions={[ERbacPermissions.TEAM_VIEW]}>
                                <li className={`${btnactive=='/teams' ? "active-project" : ""}`}>
                                    <div className="itemNameBlk" onClick={() => { navigateFunction('/teams') }}>
                                        <span className="sidePanelIcon team" title="Teams"></span>
                                        <span className="itemName">Teams</span>
                                    </div>
                                </li>
                            </RBAC>

                            <RBAC allowedPermissions={[ERbacPermissions.TASK_STICKER_VIEW]}>
                                <li className={`${btnactive=='/tasksticker' ? "active-project" : ""}`}>
                                    <div className="itemNameBlk" onClick={() => { navigateFunction('/tasksticker') }}>
                                        <span className="sidePanelIcon layers" title="Task Stickers"></span>
                                        <span className="itemName">Task Stickers</span>
                                    </div>
                                </li>
                            </RBAC>
                            <RBAC allowedPermissions={[ERbacPermissions.STICKER_VIEW]}>
                                <li className={`${btnactive=='/sticker' ? "active-project" : ""}`}>
                                    <div className="itemNameBlk" onClick={() => { navigateFunction('/sticker') }}>
                                        <span className="sidePanelIcon sticker" title="Stickers"></span>
                                        <span className="itemName">Stickers</span>
                                    </div>
                                </li>
                            </RBAC>

                            <RBAC allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}>
                                <li className={`${btnactive=='/templates' ? "active-project" : ""}`}>
                                    <div className="itemNameBlk" onClick={() => { navigateFunction('/templates') }}>
                                        <span className="sidePanelIcon templates" title="Templates"></span>
                                        <span className="itemName">Templates</span>
                                    </div>
                                </li>
                            </RBAC>

                        </ul>
                    </div>
                }

                <div className="siteLogo"><a aria-label="Cleversort" onClick={() => { navigate('/dashboard') }}></a></div>
                <div className="userBlkWrap">
                    
                    <div className="userAvtr" >{picname()}</div>
                    <div className="userName">{user?.name}</div>
                    <div className="userSettings" onClick={displaySetting}>

                        <span className="cmnIcon settings"></span>
                    </div>

                    <div className={`logout ${isSetting ? "show" : ""}`} id="dc10">
                        <div className="logout-content" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                            <span className="cmnIcon logOut">
                            </span>

                            Logout :

                            <span>
                                {user?.email}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default LeftNav;