import CloudUploadOutlined from '@ant-design/icons/lib/icons/CloudUploadOutlined';
import { message } from 'antd';
import taskStickerService from 'modules/services/TaskStickerService/TaskSticker.services';
import React, { Fragment, useEffect, useState } from 'react';

import { useStore } from './store';
import { UploadedStickers } from 'modules/models/interfaces';

interface Sticker {
    id: number;
    dataURL: string;
    status: string;
}

interface Status {
    status_key: number,
    status_label: string,
    name_alias: string,
    sort_value: number,
}



const supportedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

const UploadSticker: React.FC = () => {
    const { selectedImages, setSelectedImages } = useStore();
    const [istickers, setIStickers] = useState<(Sticker | UploadedStickers)[]>([]);
    const [statusOptions, setStatusOptions] = useState<string[]>([]);
    const [allsatusoptions, setAllsatusoptions] = useState<Status[]>([]);
    const { stickers, setStickers } = useStore();
    const [messageApi, contextHolder] = message.useMessage();

    const getStatusData = async () => {
        const response = await taskStickerService.getStatusOption();
        const fstatus = response.data.map((sdata) => {
            return sdata.status_label;
        })
        setStatusOptions(fstatus);
        setAllsatusoptions(response.data);
    }

    useEffect(() => {
        getStatusData();
    }, []);

    const upload_error = () => {
        messageApi.open({
            type: "error",
            content: "Size should less then 50Kb"
        });
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;

        if (files && (selectedImages.length + files.length < 5)) {
            const newSelectedImages: File[] = [...selectedImages] as File[];

            for (let file of files) {
                if (supportedImageTypes.includes(file.type) && file.size <= 60000) {
                    newSelectedImages.push(file);
                } else {
                    upload_error();
                }
            }

            setSelectedImages(newSelectedImages);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const files = event.target.files;

        if (files && (selectedImages.length + files.length < 5)) {
            const newSelectedImages: File[] = [...selectedImages] as File[];

            for (let file of files) {
                if (supportedImageTypes.includes(file.type) && file.size <= 60000) {
                    newSelectedImages.push(file);
                } else {
                    upload_error();
                }
            }

            setSelectedImages(newSelectedImages);
        }
    };

    const handleStatusChange = (stickerId: number, newStatus: string) => {
        setIStickers((prevStickers) =>
            prevStickers.map((sticker, index) => {
                if (index === stickerId) {
                    if ("status_value" in sticker) {
                        const status_key = allsatusoptions.find(fstatusk => newStatus === fstatusk.status_label)?.status_key || 0;
                        const sort_value = allsatusoptions.find(fstatus => newStatus === fstatus.status_label)?.sort_value || 0;
                        return {
                            ...sticker,
                            status_key: status_key,
                            sort_value,
                            status_label: newStatus,
                        }
                    }
                    return { ...sticker, status: newStatus }
                }
                return sticker
            })
        );

        setStickers(istickers);
    };



    const handleUpload = async () => {
        const newStickers: (Sticker | UploadedStickers)[] = [];
        if (selectedImages.length < 5) {
            for (let i = 0; i < selectedImages.length; i++) {
                const image = selectedImages[i];
                if ("status_value" in image) {
                    newStickers.push(image)
                } else {
                    const dataURL = await getBase64(image);

                    if (istickers[i]) {
                        newStickers.push({
                            id: Date.now() + i,
                            dataURL,
                            status: (istickers[i] as Sticker).status,
                        });
                    }
                    else {
                        newStickers.push({
                            id: Date.now() + i,
                            dataURL,
                            status: 'Done',
                        });
                    }
                }

            }
        }
        setIStickers(newStickers);
        setStickers(newStickers);
    };

    const getBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    };


    useEffect(() => {
        handleUpload();
    }, [selectedImages]);

    useEffect(() => {
        setStickers(istickers);
    }, [istickers]);

    function generateUniqueKey(file: any, index: any) {
        return file.id || `generatedKey_${index}`;
    }

    return (
        <Fragment>
            {contextHolder}
            <div
                style={{ display: 'flex' }}
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
            >
                {stickers.map((file, index) => (
                    <div key={generateUniqueKey(file, index)} className="image-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px', marginBottom: '10px' }}>
                        <div className="stickerImgWrap">
                            <img
                                src={"status_value" in file ? `${process.env.REACT_APP_BUCKET_BASE_PATH}${file.status_value}` : file.dataURL}
                                alt={`Selected ${index}`}
                                style={{ width: '120px', height: '120px', marginBottom: '5px' }}
                            />
                        </div>
                        <select
                            value={(file as UploadedStickers).status_label || (file as unknown as Sticker).status}
                            onChange={(e) => handleStatusChange(index, e.target.value)}
                        >
                            {statusOptions.map((status) => (
                                <option key={status} value={status}>
                                    {status}
                                </option>
                            ))}
                        </select>

                        <button
                            className="delete-icon"
                            onClick={(e) => {
                                e.preventDefault();
                                const newSelectedImages = selectedImages.filter((_, i) => i !== index);
                                setSelectedImages(newSelectedImages);
                            }}
                        >

                            <span className="cmnIcon deleteBin"></span>
                        </button>

                    </div>
                ))}
            </div>

            {(selectedImages.length < 4 && selectedImages.length > 0) && (
                <div className="inputHolder uploadedFiles">
                    <label htmlFor="Upload Stickers">Add More Stickers</label>
                </div>
            )}

            {selectedImages.length < 4 && (
                <div className="stickerUploaderInput">
                    <div className="uploadCloudImg">
                        <CloudUploadOutlined style={{ fontSize: '300%' }} />
                        <div className="uploadFileText"><a href="#">Upload files</a> or drag and drop</div>
                        <div className="instruction">JPG, JPEG, PNG or GIF files</div>
                    </div>
                    <input type="file" accept="image/jpeg,image/png,image/gif" multiple onChange={handleFileChange} />
                </div>
            )}

        </Fragment>
    );
};

export default UploadSticker;
