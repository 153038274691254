import { useTemplateStore } from "../store";
import SectionsWrapper from "./SectionWrapper";
import { ISection } from "modules/models/interfaces/template.interface";

const Sections: React.FC<{ getNewEmptySection: () => ISection | any }> = ({
  getNewEmptySection,
}) => {
  const { sections, setSections } = useTemplateStore();
  const newSectionCreateHandler = (sectionType: number, position?: string) => {
    switch (sectionType) {
      case 1:
        if (!sections || sections?.length === 0) {
          setSections([{ ...getNewEmptySection() }]);
        } else {
          setSections([...sections, getNewEmptySection()]);
        }
        break;
      case 2:
        if (!sections || sections?.length === 0) {
          setSections([
            {
              ...getNewEmptySection(),
              section_type_id: 2,
              grid_list_details: {
                task_details: null,
                stage_details: null,
                block_details: null,
              },
            },
          ]);
        } else {
          setSections([
            ...sections,
            {
              ...getNewEmptySection(),
              section_type_id: 2,
              grid_list_details: {
                task_details: null,
                stage_details: null,
                block_details: null,
              },
            },
          ]);
        }
        break;
      case 3:
        if (!sections || sections?.length === 0) {
          setSections([{ ...getNewEmptySection(), section_type_id: 3 }]);
        } else {
          setSections([
            ...sections,
            { ...getNewEmptySection(), section_type_id: 3 },
          ]);
        }
        break;
    }
  };

  const emptySections = (
    <div className="inner-container">
      <div className="CNPScreenDummy">
        <p>Add a section to your new template. </p>
        <div className="row">
          <div className="col-lg-4">
            <div onClick={() => newSectionCreateHandler(1)}>
              <div className="createCard taskList">
                <div className="icon"></div>
                <div className="text">
                  <h3>Task List</h3>
                  <p>Create a flexible table view of your tasks</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              onClick={() => {
                newSectionCreateHandler(2);
              }}
            >
              <div className="createCard gridList">
                <div className="icon"></div>
                <div className="text">
                  <h3>Grid List</h3>
                  <p>Create a bird’s eye view of your tasks</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div onClick={() => newSectionCreateHandler(3)}>
              <div className="createCard memoPad">
                <div className="icon"></div>
                <div className="text">
                  <h3>Memo Pad</h3>
                  <p>Create a centralized place for your notes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return <>{sections?.length ? <SectionsWrapper /> : emptySections}</>;
};

export default Sections;
