import CloudUploadOutlined from '@ant-design/icons/lib/icons/CloudUploadOutlined';
import { message } from 'antd';
import { UploadStickersconfig } from 'modules/models/interfaces';
import React, { Fragment, useEffect, useState } from 'react';

import { useStore } from './store';

interface Sticker {
    id: number;
    dataURL: string;
    is_dafault: boolean;
}

const supportedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

const UploadSticker: React.FC = () => {
    const { selectedImages, setSelectedImages } = useStore();
    const [istickers, setIStickers] = useState<(Sticker | UploadStickersconfig)[]>([]);
    const { stickers, setStickers } = useStore();
    const [messageApi, contextHolder] = message.useMessage();

    const upload_error = () => {
        messageApi.open({
            type: "error",
            content: "Size should less then 50Kb"
        });
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;

        if (files) {
            const newSelectedImages: File[] = [...selectedImages] as File[];

            for (let file of files) {
                if (supportedImageTypes.includes(file.type) && file.size <= 60000) {
                    newSelectedImages.push(file);
                } else {
                    upload_error();
                }
            }

            setSelectedImages(newSelectedImages);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const files = event.target.files;

        if (files) {
            const newSelectedImages: File[] = [...selectedImages] as File[];

            for (let file of files) {
                if (supportedImageTypes.includes(file.type) && file.size <= 60000) {
                    newSelectedImages.push(file);
                } else {
                    upload_error();
                }
            }

            setSelectedImages(newSelectedImages);
        }
    };

    const handleDefaultChange = (index1: any) => {
        console.log("index", index1);
        setIStickers((prevStickers) =>
            prevStickers.map((sticker, index) => {
                if (index1 === index) {
                    return {
                        ...sticker,
                        is_dafault: true,
                    }
                } else {
                    return {
                        ...sticker,
                        is_dafault: false,
                    }
                }
            })
        );

        setStickers(istickers);
    }

    const handleUpload = async () => {
        const newStickers: (Sticker | UploadStickersconfig)[] = [];
        if (selectedImages.length) {
            for (let i = 0; i < selectedImages.length; i++) {
                const image: any = selectedImages[i];
                if ("sticker_url" in image) {
                    newStickers.push(image)
                } else {

                    const dataURL = await getBase64(image);

                    if (istickers[i]) {
                        newStickers.push({
                            id: Date.now() + i,
                            dataURL,
                            is_dafault: (istickers[i] as Sticker).is_dafault,
                        });
                    }
                    else {
                        if (i == 0) {
                            newStickers.push({
                                id: Date.now() + i,
                                dataURL,
                                is_dafault: true,
                            });
                        } else {
                            newStickers.push({
                                id: Date.now() + i,
                                dataURL,
                                is_dafault: false,
                            });
                        }
                    }
                }

            }
        }
        setIStickers(newStickers);
        setStickers(newStickers);
    };

    const getBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    };


    useEffect(() => {
        handleUpload();
    }, [selectedImages]);

    useEffect(() => {
        setStickers(istickers);
    }, [istickers]);

    return (
        <Fragment>
            {contextHolder}
            <div
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                className="uploadStickerContainerWrap"
            >
                {stickers.map((file: any, index) => (
                    <div key={file} className="image-container uploadStickerContainer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px', marginBottom: '10px' }}>
                        <div className="stickerImgWrap">
                            <img
                                // src={URL.createObjectURL(file)}
                                src={"sticker_url" in file ? `${process.env.REACT_APP_BUCKET_BASE_PATH}${file.sticker_url}` : `${file.dataURL}`}
                                alt={`Selected ${index}`}
                                style={{ width: '120px', height: '120px', marginBottom: '5px' }}
                            />
                            <div className="setDefaultText" onClick={() => handleDefaultChange(index)}>
                                Set default image
                            </div>
                            <div className={(istickers[index]?.is_dafault === true) ? "defaultText" : "hideDefaultText"}>
                                Default image
                            </div>

                        </div>

                        <button
                            className="delete-icon"
                            onClick={(e) => {
                                e.preventDefault();
                                const newSelectedImages = selectedImages.filter((_, i) => i !== index);
                                setSelectedImages(newSelectedImages);
                            }}
                        >

                            <span className="cmnIcon deleteBin"></span>
                        </button>

                    </div>
                ))}
            </div>

            {(selectedImages.length > 0) && (
                <div className="inputHolder uploadedFiles">
                    <label htmlFor="Upload Stickers">Add More Stickers</label>
                </div>
            )}

            <div className="stickerUploaderInput">
                <div className="uploadCloudImg">
                    <CloudUploadOutlined style={{ fontSize: '300%' }} />
                    <div className="uploadFileText"><a href="#">Upload files</a> or drag and drop</div>
                    <div className="instruction">JPG, JPEG, PNG or GIF files</div>
                </div>
                <input type="file" accept="image/jpeg,image/png,image/gif" multiple onChange={handleFileChange} />
            </div>

        </Fragment>
    );
};

export default UploadSticker;
