import { RoutesEnum } from 'migration/models/enums/apiRoutes';
import {
  IApiAllProjectsListResponse,
  IApiResponseCommon,
  IAssosiatedProjectInfo,
  ICloneProjectPayload,
  ICloneSectionPayload,
  ICloneSectionResponse,
  ICreateNewSectionResponse,
  ICreateNewTaskResponse,
  ICreateProjectPayload,
  ICreateProjectResponseDetails,
  ICreateStageResponse,
  ICreateTaskResponse,
  IDashboardApiResponse,
  IDeleteStagePayload,
  IDeleteTaskPayload,
  IGridListResponseData,
  IGridlistDetailsQueryParams,
  IMemopadDetailsQueryParams,
  IMemopadResponseData,
  INewGridStagePayload,
  INewGridTaskPayload,
  INewSectionDto,
  INewTaskDto,
  IOrgAllProjetsListQueryParam,
  IPriorityMaster,
  IProjectArchiveOrRestorePayload,
  IProjectDetailsQueryParams,
  IProjectDetailsResponse,
  IProjectFolderDetails,
  IProjectMembersQueryParams,
  IProjectMembersResponse,
  IProjectToggleFavorite,
  IProjectUserMetaPayload,
  IProjectUserRolePayload,
  IReOrderGridStagePayload,
  IReOrderGridTaskPayload,
  IStatusMaster,
  ITaskListResponseData,
  ITaskMyWorkToday,
  ITaskPrioritiesData,
  ITasklistDetailsQueryParams,
  IUpdateProjectColorPayload,
  IUpdateProjectNamePayload,
  IUpdateSectionDoneView,
  IUpdateSectionDto,
  IUpdateStagePayload,
  IUpdateStageResponse,
  IUpdateTaskDto,
  IUpdateTaskPayload,
  IUpdateTaskResponse,
} from 'migration/models/interface';
import { useProjectDashboardStore } from 'modules/store/project.store';
import * as R from 'ramda';
import apiService from '../Api.service';

const byName = R.ascend(R.compose(R.prop('project_name')));

class ProjectService {
  public getProjectDetails = async (
    projectDetailsQueryParams: IProjectDetailsQueryParams,
  ) => {
    return apiService.get<IProjectDetailsResponse>(
      RoutesEnum.GET_PROJECT_DETAILS,
      projectDetailsQueryParams,
    );
  };

  public getTasklistDetails = async (
    projectDetailsQueryParams: ITasklistDetailsQueryParams,
  ) => {
    return apiService.get<ITaskListResponseData>(
      RoutesEnum.GET_TASKLIST_DETAILS,
      projectDetailsQueryParams,
    );
  };

  public getGridlistDetails = async (
    projectDetailsQueryParams: IGridlistDetailsQueryParams,
  ) => {
    return apiService.get<IGridListResponseData>(
      RoutesEnum.GET_GRIDLIST_DETAILS,
      projectDetailsQueryParams,
    );
  };

  public getMemopadDetails = async (
    projectDetailsQueryParams: IMemopadDetailsQueryParams,
  ) => {
    return apiService.get<IMemopadResponseData>(
      RoutesEnum.GET_MEMOPAD_DETAILS,
      projectDetailsQueryParams,
    );
  };

  public createNewSection = async (sectionBodyDto: INewSectionDto) => {
    return apiService.post<ICreateNewSectionResponse>(
      RoutesEnum.ADD_NEW_SECTION,
      sectionBodyDto,
    );
  };

  public updateSection = async (updateSectionBodyDto: IUpdateSectionDto) => {
    return apiService.post<IApiResponseCommon>(
      RoutesEnum.UPDATE_SECTION,
      updateSectionBodyDto,
    );
  };

  public createNewTask = async (taskBodyDto: INewTaskDto) => {
    return apiService.post<ICreateNewTaskResponse>(
      RoutesEnum.ADD_NEW_TASK,
      taskBodyDto,
    );
  };

  public updateTask = async (updateTaskBodyDto: IUpdateTaskDto) => {
    return apiService.post<IApiResponseCommon>(
      RoutesEnum.UPDATE_TASK,
      updateTaskBodyDto,
    );
  };

  public updateTaskV2 = async (updateTaskBodyDto: IUpdateTaskDto) => {
    return apiService.post<any>(RoutesEnum.UPDATE_TASK_V2, updateTaskBodyDto);
  };

  public getStatusMaster = async () => {
    return apiService.get<IStatusMaster>(RoutesEnum.TASK_STATUS_MASTER);
  };

  public getPriorityMaster = async () => {
    return apiService.get<IPriorityMaster>(RoutesEnum.TASK_PRIORITY_MASTER);
  };

  public getProjectMembers = async (
    projectMembersQueryParams: IProjectMembersQueryParams,
  ) => {
    return apiService.get<IProjectMembersResponse>(
      RoutesEnum.GET_PROJECT_MEMBERS,
      projectMembersQueryParams,
    );
  };

  public updateProject = async (
    updateProjectPayload: ICreateProjectPayload,
  ) => {
    return apiService.post<IDashboardApiResponse>(
      RoutesEnum.UPDATE_PROJECT,
      updateProjectPayload,
    );
  };

  public updateProjectName = async (
    updateProjectByNamePayload: IUpdateProjectNamePayload,
  ) => {
    return apiService.post<IDashboardApiResponse>(
      RoutesEnum.UPDATE_PROJECT_NAME,
      updateProjectByNamePayload,
    );
  };

  public toggleProjectFavorite = async (
    toggleProjectFavoritePayload: IProjectToggleFavorite,
  ) => {
    // @ts-ignore ignore zustand type
    const { folderProjectList, setFolderProjectList } =
      useProjectDashboardStore.getState();
    // @ts-ignore ignore zustand type
    const { projectDetails, setProjectDetails } =
      useProjectDashboardStore.getState();
    const toggleFav = (e: IProjectFolderDetails) => {
      const filteredData = e;
      filteredData.assosiated_project_info =
        filteredData.assosiated_project_info.map((el) => {
          return {
            ...el,
            is_favorite:
              el.project_id === toggleProjectFavoritePayload.projectId
                ? !el.is_favorite
                : el.is_favorite,
          };
        });
      if (filteredData.row_id === -1) {
        filteredData.assosiated_project_info =
          filteredData.assosiated_project_info.filter((el) => el.is_favorite);
      }
      filteredData.row_is_favorite =
        e.row_id === toggleProjectFavoritePayload.projectId
          ? !e.row_is_favorite
          : e.row_is_favorite;
      return filteredData;
    };

    const newFolderProjectList = JSON.parse(
      JSON.stringify(folderProjectList),
    ).map(toggleFav);
    const newProjectDetail = JSON.parse(JSON.stringify(projectDetails));
    const favItemInsideFolder = newFolderProjectList
      ?.map((e: IProjectFolderDetails) => e.assosiated_project_info)
      .flat()
      .filter((e: IAssosiatedProjectInfo) => e.is_favorite);
    const favItemOutsideFolder = newFolderProjectList
      .filter((e: IProjectFolderDetails) => e.row_type === 'project')
      .filter((e: IProjectFolderDetails) => e.row_is_favorite)
      .map((e: IProjectFolderDetails) => ({
        project_id: e.row_id,
        is_favorite: e.row_is_favorite,
        project_name: e.row_name,
        project_rank: e.row_rank,
      }));
    if (newProjectDetail) {
      newProjectDetail.is_favorite =
        newProjectDetail.project_id === toggleProjectFavoritePayload.projectId
          ? !newProjectDetail.is_favorite
          : newProjectDetail.is_favorite;
    }
    const combineAllFav = [favItemInsideFolder, favItemOutsideFolder].flat();
    const filteredAllFav = combineAllFav.filter(
      (e, i: number) =>
        combineAllFav.findIndex((s) => e.project_id === s.project_id) === i,
    );
    newFolderProjectList[0].assosiated_project_info = R.sort(
      byName,
      filteredAllFav,
    );
    setFolderProjectList(newFolderProjectList);
    setProjectDetails(newProjectDetail);
    return apiService.post<IDashboardApiResponse>(
      RoutesEnum.TOGGLE_FAVORITE_PROJECT +
      `/${toggleProjectFavoritePayload.projectId}/${toggleProjectFavoritePayload.userId}/${toggleProjectFavoritePayload.organizationId}`,
      {},
    );
  };

  public createNewGridTask = async (
    gridTaskBodyPayload: INewGridTaskPayload,
  ) => {
    return apiService.post<ICreateTaskResponse>(
      RoutesEnum.ADD_NEW_GRID_TASK,
      gridTaskBodyPayload,
    );
  };

  public createNewGridStage = async (
    gridStageBodyPayload: INewGridStagePayload,
  ) => {
    return apiService.post<ICreateStageResponse>(
      RoutesEnum.ADD_NEW_GRID_STAGE,
      gridStageBodyPayload,
    );
  };

  public updateGridTask = async (gridTaskBodyPayload: IUpdateTaskPayload) => {
    return apiService.post<IUpdateTaskResponse>(
      RoutesEnum.UPDATE_GRID_TASK,
      gridTaskBodyPayload,
    );
  };

  public updateGridStage = async (
    gridStageBodyPayload: IUpdateStagePayload,
  ) => {
    return apiService.post<IUpdateStageResponse>(
      RoutesEnum.UPDATE_GRID_STAGE,
      gridStageBodyPayload,
    );
  };

  public updateGridBlock = async (gridBlockBodyPayload: any) => {
    return apiService.post<any>(
      RoutesEnum.UPDATE_GRID_BLOCK,
      gridBlockBodyPayload,
    );
  };

  public updateGridBlockForSectionPrivacy = async (
    gridBlockBodyPayload: any,
  ) => {
    return apiService.post<any>(
      RoutesEnum.UPDATE_GRID_BLOCK_FOR_SECTION_PRIVACY,
      gridBlockBodyPayload,
    );
  };

  public updateStatusDoneView = async (
    updateStatusDone: IUpdateSectionDoneView,
  ) => {
    return apiService.post<any>(
      RoutesEnum.UPDATE_STATUS_DONE_ASSOCIATION,
      updateStatusDone,
    );
  };

  public deleteGridTask = async (gridTaskBodyPayload: IDeleteTaskPayload) => {
    return apiService.post<IApiResponseCommon>(
      RoutesEnum.DELETE_GRID_TASK,
      gridTaskBodyPayload,
    );
  };

  public deleteGridStage = async (
    gridStageBodyPayload: IDeleteStagePayload,
  ) => {
    return apiService.post<IApiResponseCommon>(
      RoutesEnum.DELETE_GRID_STAGE,
      gridStageBodyPayload,
    );
  };

  public updateProjectUserMetadata = async (
    projectMetadataPayload: IProjectUserMetaPayload,
  ) => {
    return apiService.post<IApiResponseCommon>(
      RoutesEnum.UPDATE_PROJECT_USER_METADATA,
      projectMetadataPayload,
    );
  };

  public reOrderGridTask = async (
    reOrderGridTaskPayload: IReOrderGridTaskPayload,
  ) => {
    return apiService.post<ICreateStageResponse>(
      RoutesEnum.REORDER_GRID_TASK,
      reOrderGridTaskPayload,
    );
  };

  public reOrderGridStage = async (
    reOrderGridStagePayload: IReOrderGridStagePayload,
  ) => {
    return apiService.post<ICreateStageResponse>(
      RoutesEnum.REORDER_GRID_STAGE,
      reOrderGridStagePayload,
    );
  };

  public cloneSection = async (cloneSectionPayload: ICloneSectionPayload) => {
    return apiService.post<ICloneSectionResponse>(
      RoutesEnum.CLONE_SECTION,
      cloneSectionPayload,
    );
  };

  public sendMentionNotification = async (payload: any) => {
    return apiService.post<any>(RoutesEnum.SEND_MENTION_NOTIFICATION, payload);
  };
  public sendFeedbackMentionNotification = async (payload: any) => {
    return apiService.post<any>(
      RoutesEnum.SEND_FEEDBACK_MENTION_NOTIFICATION,
      payload,
    );
  };

  public getPublishedTemplate = async (payload?: any) => {
    return apiService.get<any>(RoutesEnum.GET_PUBLISHED_TEMPLATES, payload);
  };

  public getIndustriesPublishedTemplates = async (payload: any) => {
    return apiService.get<any>(
      RoutesEnum.GET_INDUSTRIES_PUBLISHED_TEMPLATES,
      payload,
    );
  };

  public getRecentTemplates = async (payload: any) => {
    return apiService.get<any>(RoutesEnum.GET_RECENT_TEMPLATES, payload);
  };

  public getWebsiteMeta = async (payload: string) => {
    return apiService.get<any>(RoutesEnum.GET_WEBSITE_METADATA, {
      url: payload,
    });
  };

  public getTaskPriorities = async (payload: {
    user_id: number;
    time: 'today' | 'upcoming' | 'overdue';
  }) => {
    return apiService.get<{
      data: {
        message: string;
        messageId: number;
        data: ITaskPrioritiesData[] | null;
      };
    }>(RoutesEnum.GET_TASK_PRIORITIES, payload);
  };

  public getOrgAllProjectlist = async (
    orgAllProjectListQueryParams: IOrgAllProjetsListQueryParam,
  ) => {
    return apiService.get<IApiAllProjectsListResponse>(
      RoutesEnum.GET_ORG_ALL_PROJECT_LIST,
      orgAllProjectListQueryParams,
    );
  };

  public updateProjectUserRole = async (
    projectUserRolePayload: IProjectUserRolePayload,
  ) => {
    return apiService.post<IApiResponseCommon>(
      RoutesEnum.UPDATE_PROJECT_USER_ROLE,
      projectUserRolePayload,
    );
  };

  public getTaskIveAssigned = async (payload: {
    user_id: number;
    time: 'today' | 'upcoming' | 'overdue';
  }) => {
    return apiService.get<{
      data: {
        message: string;
        messageId: number;
        data: ITaskPrioritiesData[] | null;
      };
    }>(RoutesEnum.GET_TASK_IVE_ASSIGNED, payload);
  };

  public getMyPlanToday = async (payload: {
    user_id: number;
    start_date: string;
  }) => {
    return apiService.get<{
      data: {
        message: string;
        messageId: number;
        data: ITaskMyWorkToday[] | null;
      };
    }>(RoutesEnum.GET_PLAN_EVENT, payload);
  };

  public cloneProject = async (payload: ICloneProjectPayload) => {
    return apiService.post<ICreateProjectResponseDetails>(
      RoutesEnum.CLONE_PROJECT,
      payload,
    );
  };

  public getSectionsData = async (payload: any) => {
    return apiService.post<any>(RoutesEnum.GET_SECTIONS_DATA, payload);
  };

  public getAllTask = async (payload: {
    type: 'assigned to me' | 'assigned by me';
  }) => {
    return apiService.get<{
      data: {
        message: string;
        messageId: number;
        data: ITaskPrioritiesData[] | null;
      };
    }>(RoutesEnum.GET_ALL_TASK, payload);
  };

  public getTaskByTime = async (payload: {
    type: 'assigned to me' | 'assigned by me';
    time: 'today' | 'overdue';
  }) => {
    return apiService.get<{
      data: {
        message: string;
        messageId: number;
        data: ITaskPrioritiesData[] | null;
      };
    }>(RoutesEnum.GET_TASK_BY_TIME, payload);
  };

  public getProjectColorPalette = async () => {
    return apiService.get(RoutesEnum.GET_PROJECT_COLOR_PALETTE);
  };

  public updateProjectColor = async (
    updateProjectColorPayload: IUpdateProjectColorPayload,
  ) => {
    return apiService.post<IApiResponseCommon>(
      RoutesEnum.UPDATE_PROJECT_COLOR,
      updateProjectColorPayload,
    );
  };

  public archiveOrRestoreProject = async (
    archiveOrRestoreProjectPayload: IProjectArchiveOrRestorePayload,
  ) => {
    return apiService.post(
      RoutesEnum.ARCHIVE_OR_RESTORE,
      archiveOrRestoreProjectPayload,
    );
  };

  public getCustomFieldData = async (payload?: any) => {
    return apiService.get<any>(RoutesEnum.GET_CUSTOM_FIELDS, payload);
  };

  public addEditCustomMeta = async (payload: any) => {
    return apiService.post<any>(RoutesEnum.ADD_EDIT_CUSTOM_META, payload);
  };

  public deleteCustomMeta = async (payload: any) => {
    return apiService.post<any>(RoutesEnum.DELETE_CUSTOM_FIELD, payload);
  };

  public updateCustomFieldSetting = async (payload: any) => {
    return apiService.post<any>(
      RoutesEnum.UPDATE_CUSTOM_FIELD_SETTING,
      payload,
    );
  };

  public getTasklistDetailsV2 = async (
    projectDetailsQueryParams: ITasklistDetailsQueryParams,
  ) => {
    return apiService.get<ITaskListResponseData>(
      RoutesEnum.GET_TASKLIST_DETAILS_V2,
      projectDetailsQueryParams,
    );
  };

  public updateCustomFieldSettingV2 = async (payload: any) => {
    return apiService.post<any>(
      RoutesEnum.UPDATE_CUSTOM_FIELD_SETTING_V2,
      payload,
    );
  };

  public reOrderGrid = async (payload: any) => {
    return apiService.post<any>(RoutesEnum.RE_ORDER_GRID, payload);
  };
}

const projectService = new ProjectService();
export default projectService;
