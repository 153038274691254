import { Select } from 'antd';
import React from 'react';
import { useTeamsStore } from '../teamsStore';
import { Dayjs } from 'dayjs';

interface ITeamTypeProps {
  label: string;
  teamTypes: any;
  filterOptions: {
    selectedTeamTypes: string[];
    setSelectedTeamTypes: React.Dispatch<React.SetStateAction<string[]>>;
    selectedTeamStatus: string[];
    setSelectedTeamStatus: React.Dispatch<React.SetStateAction<string[]>>;
    startDate: Dayjs | null;
    setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
    endDate: Dayjs | null;
    setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  };
}

const TeamType: React.FC<ITeamTypeProps> = ({
  label,
  teamTypes,
  filterOptions,
}) => {
  const { setFilterTeamsType } = useTeamsStore();

  const handleChange = (value: string[]) => {
    filterOptions.setSelectedTeamTypes(value);
    // if (value?.length) {
    //   setFilterTeamsType(value.toString());
    // }
  };

  return (
    <>
      <label>{label}</label>
      <Select
        options={teamTypes}
        mode="multiple"
        onChange={handleChange}
        allowClear
        placeholder="Select Team Type"
        value={filterOptions.selectedTeamTypes}
      />
    </>
  );
};

export default TeamType;
