import { AES, enc } from "crypto-js";
import dayjs from 'dayjs';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  ICmsData,
  IGridList,
  IOrganization,
  IPageSpecificData,
} from 'migration/models/interface';
import { IUserDetails } from 'modules/models/interfaces';
import {
  deviceDetect,
  isDesktop,
  isMobile,
  isTablet,
} from 'react-device-detect';
import { db } from 'utils/firebase';

export const removeStyle = (name: string) => {
  const link = document.getElementById(name);
  if (link) {
    link.remove();
  }
};

export const setStyle = (stylesheet: string, idName: string) => {
  if (stylesheet && stylesheet !== 'default') {
    const link = document.createElement('link');
    link.href = `${process.env.REACT_APP_LOCALIZATION_STORAGE_URL}css/${stylesheet}`;
    link.rel = 'stylesheet';
    link.id = idName;
    document.head.appendChild(link);
  }
};

export const decryptData = (value: string) => {
  return AES.decrypt(
    value,
    process.env.REACT_APP_DATA_ENCRIPTION_KEY ?? '',
  ).toString(enc.Utf8);
};

export const generateUserAvatar = (userDetails: IUserDetails | any) => {
  if (!userDetails.profile_picture) {
    let nameSplit = userDetails['full_name'].trim().split(' ');
    if (nameSplit.length === 1) {
      return { key: 'string', value: `${nameSplit[0].charAt(0)}` };
    } else {
      return {
        key: 'string',
        value: `${nameSplit[0].charAt(0)}${nameSplit[
          nameSplit.length - 1
        ].charAt(0)}`,
      };
    }
  } else {
    return { key: 'url', value: userDetails.profile_picture };
  }
};

export const getUserGravatar = (name: string) => {
  let _name = name.trim().split(' ');
  if (_name.length === 1) {
    return _name[0].charAt(0).toUpperCase();
  } else if (_name.length > 1) {
    return (
      _name[0].charAt(0).toUpperCase() +
      _name[_name.length - 1].charAt(0).toUpperCase()
    );
  } else {
    return name;
  }
};

export const addRemoveOverLay = () => {
  if (document.body.classList.contains('body-overlay')) {
    document.body.classList.remove('body-overlay');
  } else {
    document.body.classList.add('body-overlay');
  }
};

export const checkStatus = (is_active: boolean) => {
  if (is_active) {
    return 'Active';
  }
  if (!is_active) {
    return 'In-active';
  }
  return '';
};

export const dateConvert = (stringDate: string) => {
  const date = new Date(stringDate);
  const normalDate = date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  return normalDate;
};

export const getDeviceInformation = () => {
  const deviceInformation = deviceDetect(window.navigator.userAgent);
  let deviceType = null;
  if (isDesktop) {
    deviceType = 'desktop';
  } else if (isMobile) {
    deviceType = 'mobile';
  } else if (isTablet) {
    deviceType = 'tablet';
  }

  return {
    browserName: deviceInformation.browserName
      ? deviceInformation.browserName
      : null,
    browserMajorVersion: deviceInformation.browserMajorVersion
      ? deviceInformation.browserMajorVersion
      : null,
    browserFullVersion: deviceInformation.browserFullVersion
      ? deviceInformation.browserFullVersion
      : null,
    osName: deviceInformation.osName ? deviceInformation.osName : null,
    osVersion: deviceInformation.osVersion ? deviceInformation.osVersion : null,
    userAgent: deviceInformation.userAgent
      ? deviceInformation.userAgent
      : window.navigator.userAgent,
    deviceType: deviceType,
  };
};

export const getContrastColor = (bgColor: string): string => {
  // Function to calculate contrast color based on background color
  const getLuminance = (color: string) => {
    // Function to calculate luminance
    color = color.substring(1); // remove #
    let rgb = parseInt(color, 16); // convert to decimal
    let r = (rgb >> 16) & 0xff; // extract red
    let g = (rgb >> 8) & 0xff; // extract green
    let b = (rgb >> 0) & 0xff; // extract blue
    r /= 255;
    g /= 255;
    b /= 255; // normalize to 0-1 range
    r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
    return 0.2126 * r + 0.7152 * g + 0.0722 * b; // calculate luminance
  };

  const luminance = getLuminance(bgColor);
  return luminance > 0.5 ? '#000000' : '#ffffff'; // return black or white based on background luminance
};

export const getCurrentOrganization = (
  org_key: string,
  organizationDetails: IOrganization[],
): IOrganization | undefined => {
  let currentOrganization = organizationDetails?.find(
    (ele) => ele?.org_key === org_key,
  );
  return currentOrganization;
};

export const getPageSpecificData = (
  pageSpecificData: ICmsData,
  pageSlug: string,
) => {
  const cmsLocalData: ICmsData = pageSpecificData;
  if (cmsLocalData?.pageSpecificData?.length) {
    return cmsLocalData?.pageSpecificData?.find(
      (eachPage: IPageSpecificData[]) => {
        return eachPage[0]?.pageSlug === pageSlug;
      },
    );
  }
};

export const markTaskDeleteInMessageThread = async (
  messageThreadId: string,
) => {
  const document = await getDoc(doc(db, 'message-thread', messageThreadId));
  if (document.exists()) {
    await updateDoc(doc(db, 'message-thread', messageThreadId), {
      is_deleted: true,
    });
  }
};

export function isEmptyString(str: any): boolean {
  if (!str || typeof str !== 'string') return true;
  if (typeof str === 'string') {
    return String(str).trim().length === 0;
  }
  return true;
}

export const debounce = (func: Function, timeout = 300) => {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const addOverlay = () => {
  document.body.classList.add('body-overlay');
};

export const formatMessageItems = (message: string) => {
  if (/\s{2,}/.test(message)) {
    return message.replace(/\s{2,}/g, (match, index) => {
      if (match.length % 2 === 0) {
        return ' &nbsp;'.repeat(match.length / 2);
      }
      if (index === match.length - 1) {
        return ' ';
      }
      return ' &nbsp;'.repeat(match.length / 2);
    });
  }

  return message;
};

export const getDateFormat = (data: any, cmsData: any) => {
  const timeStamp = dayjs(data);
  const diffH = dayjs().diff(timeStamp, 'h');
  const diffD = dayjs().diff(timeStamp, 'd');

  if (diffH < 24) {
    return timeStamp.format('hh:mm A');
  } else if (diffD === 1) {
    return cmsData
      ? `${diffD} ${cmsData?.lbl_time_difference_new.singular}`
      : `${diffD} day ago`;
  } else if (diffD > 1 && diffD < 7) {
    return cmsData
      ? `${diffD} ${cmsData?.lbl_time_difference_new.plural}`
      : `${diffD} days ago`;
  } else {
    return timeStamp.format('MM/DD/YYYY');
  }
};

export const modifyMessageString = (messageContent: string) => {
  if (messageContent) {
    let str = reformatMessageText(messageContent);

    const regexStart = /^<p class="tiptapExtensionParagraph"><\/p>/,
      regexEnd = /<p class="tiptapExtensionParagraph"><\/p>$/;

    while (regexStart.test(str)) {
      str = str.replace(regexStart, '');
    }

    while (regexEnd.test(str)) {
      str = str.replace(regexEnd, '');
    }

    str = str.replaceAll(
      /<p class="tiptapExtensionParagraph"><\/p>/g,
      '<p class="tiptapExtensionParagraph"><br class="ProseMirror-trailingBreak"></p>',
    );
    return str;
  } else {
    return '';
  }
};

export const reformatMessageText = (message: string) => {
  if (/&nbsp;/.test(message)) {
    return message.replace(/&nbsp;/g, ' ');
  }

  return message;
};

export const removeOverlay = () => {
  document.body.classList.remove('body-overlay');
};

export const groupGridBlock = (block: any[], task: any[] | null) => {
  let blocks: any[] = [];
  if (task) {
    const taskIds = task
      .sort((a, b) => a.task_rank - b.task_rank)
      .map((item) => item.task_id);

    taskIds.forEach((ele) => {
      let newBlock = block.filter((item) => item.task_id === ele);
      blocks.push(newBlock);
    });
  } else {
    if (block !== undefined && block !== null) {
      let newBlock = block.filter((item) => item.task_id === 0);
      blocks.push(newBlock);
    }
  }

  return blocks;
};
export function getBlockStatus(date: string, originalStatus: number) {
  if ([3, 4].includes(originalStatus)) return originalStatus;
  return dayjs().isBefore(date, 'day') || dayjs().isSame(date, 'day') ? 1 : 2;
}

export const checkTaskList = (gridList: IGridList) => {
  if (!gridList) {
    return false;
  } else {
    if (gridList?.grid_list_details?.task_details === null) {
      return false;
    }
    return true;
  }
};


