import { useAuth0 } from '@auth0/auth0-react';
import { message, Modal, Switch } from 'antd';
import { Formik, Field, ErrorMessage, FormikProps, FormikValues } from 'formik';
import { ComponentLoader } from 'modules/sharedComponents/components';
import { ADminRoles, FormValues } from 'modules/models/interfaces';
import apiService from 'modules/services/Api.service';
import userService from 'modules/services/UsersManageService/User.manage.service';
import { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useStore } from './store';


export interface IInviteUserFormImperative {
    resetForm: () => void

}
const InviteUserForm = forwardRef<IInviteUserFormImperative, {}>(({ }, ref) => {
    const formRef = useRef<FormikProps<FormValues>>(null);
    const { isDrawer, setIsDrawer } = useStore();
    const [loading, setLoading] = useState(true);
    const [isRolesData, setIsRolesData] = useState<ADminRoles[]>([]);
    const { iuser, setIUser } = useStore();
    const [messageApi, contextHolder] = message.useMessage();
    const [status, setStatus] = useState(false);
    const { setUsers } = useStore();
    const { setTLoading } = useStore();
    const { user } = useAuth0();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            resetForm: () => {
                if (formRef?.current) {
                    formRef?.current.resetForm()
                }
            }
        };
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setIsModalOpen(false);
        setTLoading(true);
        handleDrawer();
        const response = await userService.userDelete({
            emailId: iuser.email,
            userId: iuser.user_id
        });

        if (response.messageId == '1') {
            const response = await userService.usersTable();
            if (response) {
                setUsers(response.data);
                delete_success();
                setTLoading(false);
            }
        } else {
            setTLoading(false);
            invite_error();
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setStatus(iuser.status);
    }, [iuser]);

    const initialValues: FormValues = {
        name: iuser.name,
        email: iuser.email,
        roles: iuser.roles,
        is_active: iuser.status
    };

    const onChange = (checked: boolean) => {
        setStatus(checked);
        console.log(checked);
    };

    const handleDrawer = () => {
        if (formRef?.current) {
            formRef?.current.resetForm()
        }
        setIsDrawer(!isDrawer);
    };

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        apiService.setTokenGenerator(getAccessTokenSilently);
        getAdminRoles();
    }, []);

    const getAdminRoles = async () => {
        try {
            const response = await userService.adminRolesData();
            setIsRolesData(response.data);
            if (response) {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const validateName = (value: string) => {
        let error: string | undefined;
        if (!value) {
            error = 'Name is required';
        } else if (!/^.{2,120}$/i.test(value)) {
            error = 'The Name must be between 20 and 120 characters.';
        }
        return error;
    };


    const validateEmail = async (value: string) => {
        let error: string | undefined;
        if (!value) {
            error = 'Email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            error = 'Invalid email address';
        } else if (value.length < 5 || value.length > 200) {
            error = 'Email must be between 5 and 200 characters';
        } else {
            try {
                const response = await userService.uniqueEmail({ email: value });
                console.log(response);
                if (response.messageId == '-1') {
                    error = 'Email ID already exist';
                }
            } catch (error) {
                console.error('Error checking email uniqueness:', error);
            }
        }
        return error;
    };


    const invite_success = () => {
        if (iuser.email) {
            messageApi.open({
                type: "success",
                content: "User updated successfully"
            });
        } else {
            messageApi.open({
                type: "success",
                content: "Invitation send successfully"
            });
        }
    };

    const invite_error = () => {
        messageApi.open({
            type: "error",
            content: "Server error"
        });
    };

    const delete_success = () => {
        messageApi.open({
            type: "success",
            content: "User deleted successfully"
        });
    };

    const userDelete = async () => {
        showModal();
    }


    const handleSubmit = async (values: FormValues, { resetForm }: { resetForm: () => void }) => {

        try {
            setTLoading(true);

            setIUser({
                ...iuser,
                name: values.name,
                email: values.email,
            });


            if (iuser.email) {
                const response = await userService.userUpdate({
                    emailId: values.email,
                    name: values.name,
                    roles: values.roles,
                    is_active: status
                });

                if (response.messageId == '1') {
                    const response = await userService.usersTable();
                    if (response) {
                        setUsers(response.data);
                        invite_success();
                        setTLoading(false);
                    }
                } else {
                    setTLoading(false);
                }

            } else {
                const response = await userService.userCreate({
                    emailId: values.email,
                    name: values.name,
                    roles: values.roles
                });
                console.log(response);
                if (response.messageId == '1') {
                    const response = await userService.usersTable();
                    setUsers(response.data);
                    invite_success();
                    setTLoading(false);
                } else {
                    invite_error();
                    setTLoading(false);
                }
            }
            resetForm();
        } catch (error) {
            invite_error();
            console.error('Error checking email uniqueness:', error);
            setTLoading(false);
        }
    };

    useEffect(() => {
        if (isDrawer) {
            // @ts-ignore
            document.querySelector(`#name`)?.focus();
        }
    }, [isDrawer])

    return (
        <Fragment>
            {contextHolder}{loading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}><ComponentLoader isLoading={loading} children={undefined} /></div>
            ) :
                <Fragment>
                    <div className="formContent">
                        <Formik innerRef={formRef} enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
                            {({ handleSubmit, values, errors, setFieldValue, dirty }: FormikProps<FormValues>) => (

                                <form onSubmit={handleSubmit}>
                                    <div className="inputHolder">
                                        <label htmlFor="name">Name</label>
                                        <Field focus type="text" name="name" id="name" validate={validateName} aria-label="name" />
                                        <ErrorMessage name="name" component="div" className="error-message errorColor msgError" />
                                    </div>
                                    <br />

                                    {iuser.email ? (
                                        <div className="inputHolder readOnlyEmail" >
                                            <label htmlFor="email">Email Address</label>
                                            <Fragment><Field type="email" name="email" id="email" aria-label="email" readOnly /></Fragment>
                                        </div>
                                    ) : (
                                        <div className="inputHolder" >
                                            <label htmlFor="email">Email Address</label>
                                            <Fragment>
                                                <Field type="email" name="email" id="email" validate={validateEmail} aria-label="email" />
                                                <ErrorMessage name="email" component="div" className="error-message errorColor msgError" />
                                            </Fragment>
                                        </div>
                                    )}
                                    <br />
                                    <div className="cloneSectionTask">
                                        <h3>Roles</h3>
                                        <div className="cloneSectionTaskChckBxWrp">
                                            {isRolesData.map((role) => (
                                                <div className="chckbxHolder" key={`roleCheckbox-${role.value}`}>
                                                    <Field
                                                        type="checkbox"
                                                        name="roles"
                                                        value={role.value}
                                                        id={`roleCheckbox-${role.value}`}
                                                        disabled={iuser.email === user?.email}
                                                    />
                                                    <label htmlFor={`roleCheckbox-${role.value}`}>{role.label}</label>
                                                </div>
                                            ))}
                                        </div>
                                        <ErrorMessage name="roles" component="div" className="error-message errorColor msgError" />
                                    </div>

                                    {iuser.email ? (
                                        <Fragment>
                                            <div className="switchCaseWrapper">
                                                <h3>Status</h3>

                                                <div className="sound-switcher mode-wrapper">
                                                    <label >
                                                        <span>
                                                            {iuser.invite_accepted === true ? (
                                                                <Switch checked={status} onChange={onChange} disabled={iuser.email === user?.email} />
                                                            ) : (
                                                                <Switch disabled={true} checked={status} onChange={onChange} />
                                                            )}
                                                        </span>

                                                    </label>
                                                    <div className="switchText">
                                                        <p>{status ? "Active" : "In active"}</p>
                                                    </div>

                                                </div>


                                            </div>
                                            <div className="btnPart multipleBtn">
                                                <div className="deleteUser" >
                                                    <span className="cmnIcon deleteBin"></span> <span className="delProject" onClick={userDelete}>Delete User</span>
                                                </div>
                                                <div className="cancelCreate">
                                                    <button type="button" className="noStyle" onClick={handleDrawer}>
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btnStyle1"
                                                        onClick={handleDrawer}
                                                        disabled={Object.keys(errors).length !== 0 || values.roles.length === 0}
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <div className="btnPart">
                                            <div className="cancelCreate">
                                                <button type="button" className="noStyle" onClick={handleDrawer}>
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btnStyle1"
                                                    onClick={handleDrawer}
                                                        disabled={Object.keys(errors).length !== 0 || values.roles.length === 0}
                                                >
                                                    Invite User
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </div>

                    <Modal title="User Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <p>Are you sure you want to delete this user?</p>
                    </Modal>

                </Fragment>
            }
        </Fragment>
    );
});

export default InviteUserForm;
