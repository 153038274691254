import { RBAC, withRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import LeftNav from "../../Dashboard/LeftNav/LeftNav";
import TemplatesTable from "./TemplatesTable";

const TemplateList: React.FC<{}> = () => {
  return (
    <RBAC allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}>
      <div className={`adminPanelMainWrapper mainWrapper `}>
        <LeftNav />
        <div className="adminPanelMainContent mainContent templateDetailPage addtaskComponent">
          <TemplatesTable />
        </div>
      </div>
    </RBAC>
  );
};

export default withRbac(TemplateList, {
  allowedPermissions: [ERbacPermissions.TEMPLATE_VIEW],
  isAccessFromPage: true,
});
