import { ContextTypes } from 'migration/models/enums/constants';
import { IGridList, IGridListBlockDetail } from 'migration/models/interface';
import { FC, memo } from 'react';
import BlockDueDate from './BlockDueDate';
import BlockStatus from './BlockStatus';
import SharedAssigneeDropDown2 from 'migration/SharedAssigneeDropDown/SharedAssigneeDropDown2';

export const getThreadName = (
  gridListDetails: IGridList,
  blockDetails: IGridListBlockDetail,
) => {
  if (gridListDetails) {
    let taskName =
      blockDetails.task_id > 0
        ? gridListDetails.grid_list_details!.task_details?.find(
            (ele) => ele.task_id === blockDetails.task_id,
          )?.task_name
        : '';

    let stageName =
      blockDetails.stage_id > 0
        ? gridListDetails.grid_list_details!.stage_details?.find(
            (ele) => ele.stage_id === blockDetails.stage_id,
          )?.stage_name
        : '';

    if (stageName && taskName) {
      return `${taskName} | ${stageName}`;
    } else {
      return `${taskName ?? ''}${stageName ?? ''}`;
    }
  }
  return '';
};

export const getBlockPosition = (
  gridListDetails: IGridList,
  blockDetails: IGridListBlockDetail,
) => {
  if (gridListDetails) {
    const blockId = blockDetails.block_id;
    const sectionId = gridListDetails?.section_id;
    let taskId =
      blockDetails.task_id > 0
        ? gridListDetails.grid_list_details!.task_details?.find(
            (ele) => ele.task_id === blockDetails.task_id,
          )?.task_id
        : '';

    let stageId =
      blockDetails.stage_id > 0
        ? gridListDetails.grid_list_details!.stage_details?.find(
            (ele) => ele.stage_id === blockDetails.stage_id,
          )?.stage_id
        : '';

    return `${sectionId ?? ''}-${taskId ?? ''}-${stageId ?? ''}-${
      blockId ?? ''
    }`;
  }
  return '';
};

const BlockWithCompleteDetailsWrapper: FC<{
  blockDetails: IGridListBlockDetail;
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  updateBlock: (block: IGridListBlockDetail) => Promise<void>;
  showDropDown: number;
  setShowDropDown: React.Dispatch<React.SetStateAction<number>>;
  showCalender: number;
  setShowCalender: React.Dispatch<React.SetStateAction<number>>;
  index?: number;
}> = ({
  gridListDetails,
  setGridListDetails,
  updateBlock,
  blockDetails,
  showDropDown,
  setShowDropDown,
  showCalender,
  setShowCalender,
  index,
}) => {
  return (
    <div
      className={'gridBlk cursorDisabled' /*gridNewBlk*/}
      id={getBlockPosition(gridListDetails, blockDetails)}
      style={{
        opacity:
          gridListDetails.hide_completed_tasks &&
          blockDetails.block_status_id === 3
            ? 0.5
            : 1,
      }}
    >
      {!gridListDetails?.compactView ? (
        <>
          <BlockDueDate />
          <SharedAssigneeDropDown2
            blockDetails={blockDetails}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            blockId={blockDetails.block_id}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            assigneeDetails={{
              assignee_id: blockDetails.assignee_id,
              assignee_name: blockDetails.assignee_name,
              assignee_email: blockDetails.assignee_email,
              assignee_profile_picture: blockDetails.assignee_profile_picture,
            }}
            updateBlock={updateBlock}
            contextType={ContextTypes.GRIDLIST}
          />
          <BlockStatus
            blockId={blockDetails.block_id}
            blockDetails={blockDetails}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            updateBlock={updateBlock}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default memo(BlockWithCompleteDetailsWrapper);
